import React from 'react'
import { connect } from 'react-redux'
import { confirmEmailChange } from '../../actions/auth'
import { Navigate } from 'react-router-dom'
import { VERSION } from '../../config'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { IconBack } from '../partials/IconsNavigation'
import fetchStates from '../../types/fetchStates'
import { container, item } from '../../helpers/framer'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'


interface AuthEmailChangeConfirmationProps {
  router: {navigate: (to: string) => any, params: { token: string }},
  auth: { status: string, authenticated: boolean },
  confirmEmailChange: (options: { token: string }) => Promise<void>,
}


export class AuthEmailChangeConfirmation extends React.Component<AuthEmailChangeConfirmationProps> {


  componentDidMount(){
    const token = this.props.router.params.token; 
    this.setState({ token });
    this.props.confirmEmailChange({ token })
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        this.props.router.navigate('/settings/email');
      }
    });
  }


  render() {

    const { status, authenticated } = this.props.auth

    return authenticated ? <Navigate to="/settings/email" /> : (

      <div className="template template-auth">
        <div className="page-content">

        <motion.div className="auth-container" variants={container}
        initial="hidden"
        animate="visible">
            <div className="auth-back-button">
              <Link to="/" className="btn-square">
                <IconBack color="#ffffff" />
              </Link>
            </div>
            <div className="auth-logo" />
            <div className="auth-box">
              <div className="auth-box__wrapper">
                <motion.div className="auth-form__header" key={1} variants={item}>
                  <h1 className="auth-box__title">Email change confirmation</h1>
                </motion.div>

                { status === fetchStates.fetching && (
                  <motion.p className="auth-box__text" key={2} variants={item}>Please wait, we are confirming your email change...</motion.p>
                )}
                { status === fetchStates.success && (
                  <motion.p className="auth-box__text" key={2} variants={item}>Your email change was completed successfully.</motion.p>
                )}
                { status === fetchStates.error && (
                  <motion.p className="auth-box__text" key={2} variants={item}>There was an error confirming your email change. Please contact support.</motion.p>
                )}

              </div>
            </div>
          <div className="auth-version">v{VERSION}</div>
        </motion.div>

      </div>
    </div>

    )
  }
}

export default withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { confirmEmailChange }
)(AuthEmailChangeConfirmation));