import { DATA } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchData = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: DATA.FETCH });
  return fetch(`${API_ADDRESS}/data/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: DATA.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: string}) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: DATA.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchLocations = () => fetchData({
  endpoint: `locations`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_LOCATIONS_SUCCESS
});


export const fetchStocks = () => fetchData({
  endpoint: `stocks`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_STOCKS_SUCCESS
});


export const fetchEventsHistory = (options: { limit: number, skip: number, filter: string }) => fetchData({
  endpoint: `events/history?limit=${options.limit}&skip=${options.skip}&filter=${options.filter}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_EVENTS_HISTORY_SUCCESS
});


export const getNewAlarmsCount = () => fetchData({
  endpoint: `alarms/new`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_ALARMS_NEW_COUNT_SUCCESS
});


export const markEventsHistoryAsRed = (ids: number[]) => fetchData({
  endpoint: 'events/red',
  options: {
    method: 'POST',
    body: JSON.stringify({ ids }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DATA.FETCH_EVENTS_NEW_RED_SUCCESS
});


export const fetchDataBySerialsTypeRange = (options: { device_serials: Array<string>, data_type: string, data_range: string }) => fetchData({
  endpoint: `data-filter/?device_serials=${options.device_serials}&data_type=${options.data_type}&data_range=${options.data_range}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_DATA_SERIAL_TYPE_RANGE_SUCCESS
});


export const deleteEvents = (ids: any) => fetchData({
  endpoint: 'delete',
  options: {
    method: 'DELETE',
    body: JSON.stringify({ ids }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DATA.FETCH_DELETE_EVENTS_SUCCESS
});



export const fetchCurrentWeatherByLocationId = (loc_id: number) => fetchData({
  endpoint: `weather/${loc_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_WEATHER_BY_LOCATION_SUCCESS
});


export const fetchMovementHistory = (options: { device_serial: string, data_range: string }) => fetchData({
  endpoint: `movement/?device_serial=${options.device_serial}&data_range=${options.data_range}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_MOVEMENT_HISTORY_SUCCESS
});


export const fetchEnergyUsageChart = (options: { data_range: string }) => fetchData({
  endpoint: `history/energy-chart/?data_range=${options.data_range}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_HISTORY_ENERGY_CHART_SUCCESS
});


export const fetchEnergyUsageHistory = () => fetchData({
  endpoint: `history/energy-usage`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_HISTORY_ENERGY_USAGE_SUCCESS
});


export const fetchEnergyProducedHistory = () => fetchData({
  endpoint: `history/energy-produced`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_HISTORY_ENERGY_PRODUCED_SUCCESS
});


export const fetchEnergyConsumedHistory = () => fetchData({
  endpoint: `history/energy-consumed`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_HISTORY_ENERGY_CONSUMED_SUCCESS
});


export const fetchDevMovementHistory = (options: { limit: number, skip: number, device_serial: string }) => fetchData({
  endpoint: `/dev/movement?limit=${options.limit}&skip=${options.skip}&device_serial=${options.device_serial}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DATA.FETCH_DEV_MOVEMENT_SUCCESS
});