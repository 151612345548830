import React from 'react'
import { connect } from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import ContentMain from '../partials/ContentMain'
import { IconTown } from '../partials/IconsForm'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import { fetchLocations } from '../../actions/data'
import { fetchAccountById, updateAccountWeatherSettings } from '../../actions/account'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import { danger } from '../../helpers/notifications'



interface SettingsWeatherProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  data: any,
  auth: any,
  ws: any,
  account: any,
  fetchLocations: () => Promise<void>,
  fetchAccountById: (account_id: string) => Promise<void>,
  updateAccountWeatherSettings: (options: { account_id: string, account_weather_location_id: number, account_weather_alert_rain: number, account_weather_alert_wind: number, account_weather_wind_speed: string }) => Promise<void>,
}


interface SettingsWeatherState {
  locations: any,
  account_weather_location_id: number,
  account_weather_alert_rain: number,
  account_weather_alert_wind: number,
  account_weather_wind_speed: string,
  selected: string,
  formSubmitted: boolean
}


export class SettingsWeather extends React.Component<SettingsWeatherProps, SettingsWeatherState> {


  state = {
    locations: [],
    account_weather_location_id: 0,
    account_weather_alert_rain: 0,
    account_weather_alert_wind: 0,
    account_weather_wind_speed: '60',
    selected: 'weather',
    formSubmitted: false
  }


  componentDidMount() {
    this.fetchLocations()
    this.fetchAccount();
  }


  fetchLocations = async () => {
    await this.props.fetchLocations();
    if(this.props.data.status === fetchStates.success) {
      const { locations } = this.props.data;
      this.setState({ locations });
    }
  }


  fetchAccount = async () => {
    const { account_id } = this.props.auth.person;
    await this.props.fetchAccountById(account_id);
    const { status } = this.props.account;
    const { account_weather_location_id, account_weather_alert_wind, account_weather_alert_rain, account_weather_wind_speed } = this.props.account.account;
    if( status !== fetchStates.success ) { return; }
    this.setState({ account_weather_location_id, account_weather_alert_wind, account_weather_alert_rain, account_weather_wind_speed });
  }


  changeSelectedItem = (selected: string) => {
    this.setState({ selected })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { account_weather_location_id, account_weather_alert_rain, account_weather_alert_wind, account_weather_wind_speed } = this.state;
    const { account_id } = this.props.account.account;
    if(account_weather_location_id !== 0) {
      this.props.updateAccountWeatherSettings({ account_id, account_weather_location_id, account_weather_alert_rain, account_weather_alert_wind, account_weather_wind_speed });
    } else {
      danger('Please select the location first')
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { locations, account_weather_location_id, account_weather_alert_wind, account_weather_alert_rain, account_weather_wind_speed, formSubmitted } = this.state;
    const { status, fields } = this.props.account;

    return (
      <TemplatePage title="Settings → Weather" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="account_weather_location_id">
                Location
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconTown color="#A19BCD" />
                </span>
                <select 
                  name="account_weather_location_id" 
                  onChange={e => this.setState({ account_weather_location_id: parseInt(e.target.value) })}
                  className={classnames('', { 'input-error': fields && fields.includes('account_weather_location_id') })}
                  value={account_weather_location_id}
                >
                  <option value={0}>Select Location</option>
                  { 
                    locations && locations.map((location: {id: number, location_title: string}) => <option key={location.id} value={location.id}>{location.location_title}</option>)
                  }
                </select>
              </div>
            </div>


            <div className="form-group" onClick={() => this.changeSelectedItem('alert-rain')}>
                <label htmlFor="enabled" onClick={() => this.setState({ account_weather_alert_rain: account_weather_alert_rain === 0 ? 1 : 0 })}>
                  <div className="label-title">
                    Rain alert
                  </div>
                  <SwitchTrueFalseHorizontal switchState={account_weather_alert_rain === 0 ? false : true} />
                </label>
                <span className="form-explanation">Show a warning about the possibility of rain</span>
              </div>

              <div className="form-group" onClick={() => this.changeSelectedItem('alert-wind')}>
                <label htmlFor="enabled" onClick={() => this.setState({ account_weather_alert_wind: account_weather_alert_wind === 0 ? 1 : 0 })}>
                  <div className="label-title">
                    Wind alert
                  </div>
                  <SwitchTrueFalseHorizontal switchState={account_weather_alert_wind === 0 ? false : true} />
                </label>
                <span className="form-explanation">Show warning about the wind occurrence</span>
              </div>

              { account_weather_alert_wind === 1 && (
                <div className="form-group" onClick={() => this.changeSelectedItem('alert-wind')} >
                  <label htmlFor="time_in">
                    Wind speed ({account_weather_wind_speed}km/h)
                  </label>
                  <div className="slidecontainer">
                    <input 
                      name="account_weather_wind_speed" 
                      id="account_weather_wind_speed" 
                      type="range" 
                      min="1" 
                      max="100" 
                      value={account_weather_wind_speed} 
                      className="slider" 
                      onChange={e => this.setState({ account_weather_wind_speed: e.target.value })} 
                    />
                  </div>
                  <span className="form-explanation">Set the wind speed, above which you would like to be notified</span>
                </div>
              )}


            <div className="content-box__footer">
              <input 
                type="submit" 
                value="Update Weather Settings"
                className="btn btn--primary btn--left"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>

          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person, data, account }: RootState) => ({ person, data, account }),
  { fetchLocations, fetchAccountById, updateAccountWeatherSettings }
)(SettingsWeather)));