import { 
  IconRoomBathroom,
  IconRoomBathroom2,
  IconRoomBedroom,
  IconRoomGarage,
  IconRoomKitchen, 
  IconRoomLibrary, 
  IconRoomLibrary2, 
  IconRoomLivingroom,
  IconRoomLivingroom2,
  IconRoomLivingroom3,
  IconRoomLivingroom4,
  IconRoomLoundry,
  IconRoomOffice,
  IconRoomOffice2, 
} from '../components/partials/IconsRooms'

export const room_icons = [
  {
    id: 1,
    icon: <IconRoomLivingroom size={0.7} color="#ffffff" />
  },
  {
    id: 2,
    icon: <IconRoomLivingroom2 size={0.7} color="#ffffff" />
  },
  {
    id: 3,
    icon: <IconRoomLivingroom3 size={0.7} color="#ffffff" />
  },
  {
    id: 4,
    icon: <IconRoomLivingroom4 size={0.7} color="#ffffff" />
  },
  {
    id: 5,
    icon: <IconRoomBedroom size={0.7} color="#ffffff" />
  },
  {
    id: 6,
    icon: <IconRoomOffice size={0.7} color="#ffffff" />
  },
  {
    id: 7,
    icon: <IconRoomOffice2 size={0.7} color="#ffffff" />
  },
  {
    id: 8,
    icon: <IconRoomLibrary size={0.7} color="#ffffff" />
  },
  {
    id: 9,
    icon: <IconRoomLibrary2 size={0.7} color="#ffffff" />
  },
  {
    id: 10,
    icon: <IconRoomKitchen size={0.7} color="#ffffff" />
  },
  {
    id: 11,
    icon: <IconRoomLoundry size={0.7} color="#ffffff" />
  },
  {
    id: 12,
    icon: <IconRoomGarage size={0.7} color="#ffffff" />
  },
  {
    id: 13,
    icon: <IconRoomBathroom size={0.7} color="#ffffff" />
  },
  {
    id: 14,
    icon: <IconRoomBathroom2 size={0.7} color="#ffffff" />
  },
]