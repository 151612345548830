import { ROOM } from '../types';
import fetchStates from '../types/fetchStates';

interface RoomState {
  room: {},
  roomMaster: {},
  rooms: [],
  roomsCount: 0,
  roomsMagicBox: [],
  roomsMagicBoxCount: 0
}

const DEFAULT_ROOM: RoomState = {
  room: {},
  roomMaster: {},
  rooms: [],
  roomsCount: 0,
  roomsMagicBox: [],
  roomsMagicBoxCount: 0
};

const room = (state = DEFAULT_ROOM, action: any) => {
  switch(action.type) {
    case ROOM.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case ROOM.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case ROOM.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        rooms: action.rooms,
        roomsCount: action.roomsCount
      };
    case ROOM.FETCH_MASTER_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        roomMaster: action.roomMaster,
      };
    case ROOM.FETCH_ALL_WITH_MAGICBOX_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        roomsMagicBox: action.roomsMagicBox,
        roomsMagicBoxCount: action.roomsMagicBoxCount
      };
    case ROOM.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        room: action.room,
      };
    case ROOM.ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ROOM.REMOVE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ROOM.UPDATE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ROOM.UPDATE_BUTTONS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ROOM.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};

export default room;