import { IconArrowLeft, IconArrowRight } from './IconsNavigation';


interface PaginationProps {
  perPage: number,
  currentPage: number,
  itemsCount: number,
  prevPage: (options: number) => void,
  nextPage: (options: number) => void,
  updatePagination?: (event: React.ChangeEvent<HTMLSelectElement>) => any,
}

const Pagination: React.FC<PaginationProps> = ({ perPage, currentPage, itemsCount, prevPage, nextPage, updatePagination }) => {

  return (
    <div className={`pagination ${updatePagination && 'pagination--with-perpage'}`}>
      {currentPage > 0 ? (
        <span className="pagination__left-arrow pagination--clickable" onClick={() => prevPage(currentPage - 1)}>
          <IconArrowLeft color="#fff" size={32} />
        </span>
      ) : (
        <span className="pagination__left-arrow pagination--disabled">
          <IconArrowLeft color="#A19BCD" size={32} />
        </span>
      )} 
      {itemsCount < perPage * (currentPage + 1) +1 ? (
        <span className="pagination__right-arrow pagination--disabled">
          <IconArrowRight color="#A19BCD" size={32} />
        </span>
      ) : (                              
        <span className="pagination__right-arrow pagination--clickable" onClick={() => nextPage(currentPage + 1)}>
          <IconArrowRight color="#fff" size={32} />
        </span>
      )} 
      <span className="pagination__info">
        {
          updatePagination && (
            <span className="pagination__perpage">
              <select className="pagination__perpage-select" value={perPage} onChange={updatePagination}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              per page
            </span>
          )
        }
        <span className="pagination__pages">
          {itemsCount === 0 ? 0 : currentPage * perPage + 1} - {itemsCount < perPage * (currentPage + 1) ? itemsCount : perPage * (currentPage + 1)} of {itemsCount}
        </span>
      </span>
    </div>
  );
};



export default Pagination;