interface InfoBoxProps {
  children?: JSX.Element|JSX.Element[]|string,
}


const InfoBox: React.FC<InfoBoxProps> = ({ children }) => (
  <div className="infobox-wrapper">
    <div className="infobox">
      <span className="infobox-content">
        {children}
      </span>
    </div>
  </div>
)


export default InfoBox