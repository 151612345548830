import React from 'react'
import withAuthorization from '../auth/withAuthorization'
import { fetchCurrentWeatherByLocationId, fetchEnergyUsageHistory } from '../../actions/data'
import { fetchAccountById } from '../../actions/account'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { getWeatherIcon } from '../../helpers/getWeatherIcon'
import fetchStates from '../../types/fetchStates'
import { IconWeatherRainAlert, IconWeatherWindAlert } from './IconsWeather'
import { Link } from 'react-router-dom'


// browser detect visibility change
let visibilityChange: string = '';
if (typeof document.hidden !== 'undefined') {
  visibilityChange = 'visibilitychange';
}


interface ToolbarProps {
  auth: any,
  data: any,
  account: any,
  fetchAccountById: (account_id: string) => Promise<void>,
  fetchCurrentWeatherByLocationId: (id: number) => Promise<any>,
  fetchEnergyUsageHistory: () => Promise<void>,
}


interface ToolbarState {
  location_ws: number,
  location_temp: number,
  location_tempmax: number,
  location_tempmin: number,
  location_hum: number,
  location_wind: number,
  location_press: number,
  account_weather_alert_rain: number, 
  account_weather_alert_wind: number, 
  account_weather_wind_speed: number,
  runInterval: boolean,
  intervalId: any,
  account_weather_location_id: number,
  account_currency: string,
  energy_usage_today: number, 
  energy_usage_cost_today: number,
}


export class Toolbar extends React.Component<ToolbarProps, ToolbarState> {

  state = {
    location_ws: 0,
    location_temp: 0,
    location_tempmax: 0,
    location_tempmin: 0,
    location_hum: 0,
    location_wind: 0,
    location_press: 0,
    account_weather_alert_rain: 0, 
    account_weather_alert_wind: 0, 
    account_weather_wind_speed: 0,
    runInterval: true,
    intervalId: 0,
    account_weather_location_id: 0,
    account_currency: '',
    energy_usage_today: 0,
    energy_usage_cost_today: 0
  }


  componentDidMount() {
    this.fetchAccount();
    this.fetchHistoryData();
    this.getCurrentWeatherByLocationId();

    // add event listener for visibilityChange
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    const intervalId = setInterval(this.timer, 1000 * 60 * 10);
    this.setState({ intervalId: intervalId });
  }


  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    this.setState({ runInterval: false });
    clearInterval(this.state.intervalId);
  }


  handleVisibilityChange = () => {
    if (document['hidden']) {
      this.setState({runInterval: false});
    } else {
      this.setState({runInterval: true});
      this.fetchAccount();
    }
  }

  fetchAccount = async () => {
    const { account_id } = this.props.auth.person;
    await this.props.fetchAccountById(account_id);
    const { status } = this.props.account;
    const { account_weather_location_id, account_weather_alert_wind, account_weather_alert_rain, account_weather_wind_speed, account_currency } = this.props.account.account;
    if( status !== fetchStates.success ) { return; }
    this.setState({ account_weather_location_id, account_weather_alert_wind, account_weather_alert_rain, account_weather_wind_speed, account_currency }, () => this.getCurrentWeatherByLocationId() );
  }


  fetchHistoryData = async () => {
    await this.props.fetchEnergyUsageHistory();
    if(this.props.data.status === fetchStates.success) {
      const { energy_usage_today, energy_usage_cost_today } = this.props.data;
      this.setState({ energy_usage_today, energy_usage_cost_today });
    }
  }


  timer = () => {
    this.fetchAccount();
  }


  getCurrentWeatherByLocationId = async () => {
    await this.props.fetchCurrentWeatherByLocationId(this.state.account_weather_location_id);
    if(this.props.data.status === fetchStates.success) {
      const { location_ws, location_temp, location_tempmax, location_tempmin, location_hum, location_wind, location_press } = this.props.data.weather_status;
      this.setState({ location_ws, location_temp, location_tempmax, location_tempmin, location_hum, location_wind, location_press });
    }
  }


  renderWeatherStatus = () => {
    const { location_ws, account_weather_alert_wind, account_weather_wind_speed, location_wind, account_weather_alert_rain } = this.state
    if(account_weather_alert_wind === 1 && account_weather_wind_speed < location_wind) {
      return <span className="toolbar-weather__weather-status"><IconWeatherWindAlert color="#f43f75" size={44} /></span>
    }
    if( account_weather_alert_rain === 1 && (location_ws > 4 && location_ws < 10 ) ) {
      return <span className="toolbar-weather__weather-status"><IconWeatherRainAlert color="#f43f75" size={34} /></span>
    }
    return <span className="toolbar-weather__weather-status">{ getWeatherIcon({ ws: location_ws, color: '#000000', size: 34 }) }</span>
  }
  

  render() {

    const { location_temp, location_tempmax, location_tempmin, location_hum, location_press, account_currency, energy_usage_today, energy_usage_cost_today } = this.state;


    return (
      <div className="toolbar">
        <div>
          <Link to="/">
            <div className="toolbar__logo" />
          </Link>
        </div>

        
        <div className="toolbar__item-group">

          <Link to="/energy/usage" className="toolbar__item-box">
              <span>USAGE / COST</span>
              <span>{ Math.round(energy_usage_today * 10) / 10 }kWh / { energy_usage_cost_today }{ account_currency }</span>
          </Link>
          <div className="toolbar-weather__main">
            { this.renderWeatherStatus() } 
            <span className="toolbar-weather__temperature">{ location_temp }°C</span>
          </div>

          <div className="toolbar__item-box">
            <span>MIN / MAX</span>
            <span>{location_tempmin}°C / {location_tempmax}°C</span>
          </div>

          <div className="toolbar__item-box">
            <span>HUM: {location_hum}%</span>
            <span>PRESS: {location_press}hPa</span>
          </div>
        </div>
      </div>
    )
  }
}


export default withAuthorization(connect(
  ({ data, auth, account }: RootState) => ({ data, auth, account }),
  { fetchCurrentWeatherByLocationId, fetchAccountById, fetchEnergyUsageHistory }
)(Toolbar))