import DeviceUpdate from '../partials/DeviceUpdate'
import SwitchOnOffVertical from '../partials/SwitchOnOffVertical'
import { IconDeviceLightOn, IconDeviceLightOff } from '../partials/IconsDevices'


interface DeviceMagicLightProps {
  device: any,
  pressMagicBoxDou: (options: {device_serial: string, position: number, value: number, dou_array: Array<number>}) => void,
}


const DeviceMagicLight: React.FC<DeviceMagicLightProps> = ({ device, pressMagicBoxDou }) => {

  if(device && device.device_data && device.device_data.dou) {
    //console.log('[DEBUG]: getting from the server', device.device_data.dou)
  }


  if(device.device_data && device.device_data.updSt === -1) {
    // UPDATE ERROR
    return <DeviceUpdate type="error" />
  }
  

  if(device.device_data && device.device_data.updSt === 1) {
    // UPDATE IN PROGRESS
    return <DeviceUpdate type="updating" />
  }


  if(device.device_data && device.device_data.updSt === 2) {
    // UPDATE COMPLETED, GETTING DATA...    
    return <DeviceUpdate type="updated" />
  }


  return (
    <div className="device-type device-type__magiclight">

      <div className="magicswitch-light__row">
        <div className="light-row__icon">
          {
            device.device_data && device.device_data.dou && device.device_data.dou[0] === 1 ? ( <IconDeviceLightOn size={0.75} color={device.device_data ? '#000000' : '#828BA9'} /> ) : (  <IconDeviceLightOff size={0.75} color={device.device_data ? '#000000' : '#828BA9'} />  )
          }
        </div>
        <div className="light-row__description">
          Light
          <div className="light-row__state">{ device.device_data ? ( device.device_data.dou[0] === 1 ? <span className="state--on">ON</span> : <span className="state--off">OFF</span> ) : 'UNKNOWN'}</div>
        </div>
        <div className="light-row__switch" onClick={ () => pressMagicBoxDou({device_serial: device.device_serial, position: 0, value: device.device_data.dou[0], dou_array: device.device_data.dou}) }>
          <SwitchOnOffVertical disabled={!device.device_data && true} switchState={ device.device_data && device.device_data.dou && device.device_data.dou[0] === 1 ? true : false } />
        </div>
      </div>

      <div className="magicswitch-light__row">
        <div className="light-row__icon">
        {
            device.device_data && device.device_data.dou && device.device_data.dou[1] === 1 ? ( <IconDeviceLightOn size={0.75} color={device.device_data ? '#000000' : '#828BA9'} /> ) : (  <IconDeviceLightOff size={0.75} color={device.device_data ? '#000000' : '#828BA9'} />  )
          }
        </div>
        <div className="light-row__description">
          Light
          <div className="light-row__state">{ device.device_data ? ( device.device_data.dou[1] === 1 ? <span className="state--on">ON</span> : <span className="state--off">OFF</span> ) : 'UNKNOWN'}</div>
        </div>
        <div className="light-row__switch" onClick={ () => pressMagicBoxDou({device_serial: device.device_serial, position: 1, value: device.device_data.dou[1], dou_array: device.device_data.dou}) }>
          <SwitchOnOffVertical disabled={!device.device_data && true} switchState={ device.device_data && device.device_data.dou && device.device_data.dou[1] === 1 ? true : false } />
        </div>
      </div>
      

    </div>
  )
}


export default DeviceMagicLight