import React from 'react'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import IconNavSettings from '../partials/IconNavSettings'
import withRouter from '../partials/WithRouter'


interface SettingsProps {
  router: { location: string }
}


export class Settings extends React.Component<SettingsProps> {

  render() {
    return (
      <TemplatePage title="Settings">
        <IconNavSettings location={this.props.router.location} />
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(Settings))