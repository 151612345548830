import { NavLink } from 'react-router-dom';
import { IconTabLight, IconTabAlarm, IconTabSecurity, IconTabScenarios, IconTabTemperature, IconTabMovement, IconTabEvent, IconTabHumidity, IconTabChartColumn, IconTabChartLine, IconTabList } from './IconsTabs';


interface IconNavHistoryProps {
  location: any
}


const IconNavHistory: React.FC<IconNavHistoryProps> = ({ location }) => (
  <nav className="iconnav">

    <NavLink 
      to={{ pathname: `/history/temperature` }} 
      className={location.pathname === '/history/temperature' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabTemperature size={40} color={location.pathname === '/history/temperature-humidity' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Temperature</div>
      <div className="iconnav-tab--type">
        <IconTabChartLine size={14} color="#828BA9" />
      </div>
    </NavLink>

    <NavLink 
      to={{ pathname: `/history/humidity` }} 
      className={location.pathname === '/history/humidity' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabHumidity size={40} color={location.pathname === '/history/temperature-humidity' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Humidity</div>
      <div className="iconnav-tab--type">
        <IconTabChartLine size={14} color="#828BA9" />
      </div>
    </NavLink>

    <NavLink 
      to={{ pathname: `/history/light` }} 
      className={location.pathname === '/history/light' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabLight size={40} color={location.pathname === '/history/light' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Light</div>
      <div className="iconnav-tab--type">        
        <IconTabChartLine size={14} color="#828BA9" />
      </div>
    </NavLink>

    <NavLink 
      to={{ pathname: `/history/movement` }} 
      className={location.pathname === '/history/movement' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabMovement size={50} color={location.pathname === '/history/movement' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Movement</div>
      <div className="iconnav-tab--type">        
        <IconTabChartColumn size={16} color="#828BA9" />
      </div>
    </NavLink>

    <NavLink 
      to={{ pathname: `/history/alarm` }} 
      className={location.pathname === '/history/alarm' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabAlarm size={40} color={location.pathname === '/history/alarm' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Alarm</div>
      <div className="iconnav-tab--type">        
        <IconTabList size={14} color="#828BA9" />
      </div>
    </NavLink>

    <NavLink 
      to={{ pathname: `/history/security` }} 
      className={location.pathname === '/history/security' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabSecurity size={40} color={location.pathname === '/history/security' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Security</div>
      <div className="iconnav-tab--type">        
        <IconTabList size={14} color="#828BA9" />
      </div>
    </NavLink>

    <NavLink 
      to={{ pathname: `/history/scenario` }} 
      className={location.pathname === '/history/scenario' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabScenarios size={46} color={location.pathname === '/history/scenario' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Scenarios</div>
      <div className="iconnav-tab--type">        
        <IconTabList size={14} color="#828BA9" />
      </div>
    </NavLink>

    <NavLink 
      to={{ pathname: `/history/events` }} 
      className={location.pathname === '/history/events' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
    >
      <IconTabEvent size={40} color={location.pathname === '/history/events' ? '#000000' : '#4D409E'} />
      <div className="iconnav-tab--title">Events</div>
      <div className="iconnav-tab--type">        
        <IconTabList size={14} color="#828BA9" />
      </div>
    </NavLink>

  </nav>
);


export default IconNavHistory;