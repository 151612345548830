import moment from 'moment'


export const temperature_chart_settings = {
  options: {
    // chart options
    xaxis: {
      type: 'datetime' as 'datetime',
      labels: {
        formatter: function (value:any) {
          return moment.unix(value/1000).format('HH:mm:ss');
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (value:any) {
          return value.toFixed(0) + `°C`;
        }
      },
    },
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: true, // hide toolbar
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      fontFamily: 'Poppins',
      foreColor: '#000000', // chart text color
      zoom: {
        enabled: true, // disable zoom
      },
      yaxis: {
        min: 5,
        max: 40
      },
      stroke: {
        curve: 'smooth'
      },
    },
    grid: {
      show: true, // show grid
      borderColor: '#A19BCD',
      xaxis: {
        lines: {
          show: true
        }
      },   
      yaxis: {
        lines: {
          show: true
        }
      }, 
      row: {
        colors: ['#A19BCD', '#FFFFFF'],
        opacity: 0.2
      },  
      column: {
          colors: undefined,
          opacity: 0.5
      }, 

    },
    /*
    markers: {
      size: 3,
      colors: ['#000000'],
      strokeWidth: 1,
      strokeOpacity: 1,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: ["circle"],
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: 5,
        sizeOffset: 5
      }
    },
    */
    /*
    dataLabels: {
      enabled: true,
      formatter: function(val: any) {
        return val + `°C`;
      },
      style: {
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#333']
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        borderRadius: 2,
        padding: 8,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#fff'
      },
    },
    */
    stroke: {
      show: true,
      curve: "smooth" as "smooth",
      lineCap: "round" as "round",
      width: 2,
      dashArray: 0,      
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      z: {
        formatter: undefined,
        title: 'Size: '
      },
      marker: {
        show: false,
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 90, 100]
    },
  },
  series: [], 
}


export const humidity_chart_settings = {
  options: {
    // chart options
    xaxis: {
      type: 'datetime' as 'datetime',
      labels: {
        formatter: function (value:any) {
          return moment.unix(value/1000).format('YYYY/MM/DD HH:mm:ss');
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (value:any) {
          return value.toFixed(0) + `%`;
        }
      },
    },
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: true, // hide toolbar
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      fontFamily: 'Poppins',
      foreColor: '#000000', // chart text color
      zoom: {
        enabled: true, // disable zoom
      },
      yaxis: {
        min: 5,
        max: 40
      },
      stroke: {
        curve: 'smooth'
      },
    },
    grid: {
      show: true, // show grid
      borderColor: '#A19BCD',
      xaxis: {
        lines: {
          show: true
        }
      },   
      yaxis: {
        lines: {
          show: true
        }
      }, 
      row: {
        colors: ['#A19BCD', '#FFFFFF'],
        opacity: 0.2
      },  
      column: {
          colors: undefined,
          opacity: 0.5
      }, 

    },
    /*
    markers: {
      size: 3,
      colors: ['#000000'],
      strokeWidth: 1,
      strokeOpacity: 1,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: ["circle"],
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: 5,
        sizeOffset: 5
      }
    },
    */
    /*
    dataLabels: {
      enabled: true,
      formatter: function(val: any) {
        return val + `°C`;
      },
      style: {
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#333']
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        borderRadius: 2,
        padding: 8,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#fff'
      },
    },
    */
    stroke: {
      show: true,
      curve: "smooth" as "smooth",
      lineCap: "round" as "round",
      width: 2,
      dashArray: 0,      
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      z: {
        formatter: undefined,
        title: 'Size: '
      },
      marker: {
        show: false,
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 90, 100]
    },
  },
  series: [],  
}


export const light_chart_settings = {
  options: {
    // chart options
    xaxis: {
      type: 'datetime' as 'datetime',
      labels: {
        formatter: function (value:any) {
          return moment.unix(value/1000).format('YYYY/MM/DD HH:mm:ss');
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (value:any) {
          return value.toFixed(0) + ` lux`;
        }
      },
    },
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: true, // hide toolbar
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      fontFamily: 'Poppins',
      foreColor: '#000000', // chart text color
      zoom: {
        enabled: true, // disable zoom
      },
      yaxis: {
        min: 5,
        max: 40
      },
      stroke: {
        curve: 'smooth'
      },
    },
    grid: {
      show: true, // show grid
      borderColor: '#A19BCD',
      xaxis: {
        lines: {
          show: true
        }
      },   
      yaxis: {
        lines: {
          show: true
        }
      }, 
      row: {
        colors: ['#A19BCD', '#FFFFFF'],
        opacity: 0.2
      },  
      column: {
          colors: undefined,
          opacity: 0.5
      }, 

    },
    /*
    markers: {
      size: 3,
      colors: ['#000000'],
      strokeWidth: 1,
      strokeOpacity: 1,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: ["circle"],
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: 5,
        sizeOffset: 5
      }
    },
    */
    /*
    dataLabels: {
      enabled: true,
      formatter: function(val: any) {
        return val + `°C`;
      },
      style: {
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#333']
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        borderRadius: 2,
        padding: 8,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#fff'
      },
    },
    */
    stroke: {
      show: true,
      curve: "smooth" as "smooth",
      lineCap: "round" as "round",
      width: 2,
      dashArray: 0,      
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      z: {
        formatter: undefined,
        title: 'Size: '
      },
      marker: {
        show: false,
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 90, 100]
    },
  },
  series: [],
}


export const movement_chart_settings = {
  options: {
    // chart options
    title: {
      text: 'Movement',
    },
    xaxis: {
      type: 'datetime' as 'datetime',
      labels: {
        formatter: function (value:any) {
          return moment.unix(value/1000).format('YYYY/MM/DD HH:mm:ss');
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (value:any) {
          return value.toFixed(0) + ``;
        }
      },
    },
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: true, // hide toolbar
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      fontFamily: 'Poppins',
      foreColor: '#000000', // chart text color
      zoom: {
        enabled: true, // disable zoom
      },
      yaxis: {
        min: 5,
        max: 40
      },
      stroke: {
        curve: 'smooth'
      },
    },
    grid: {
      show: true, // show grid
      borderColor: '#A19BCD',
      xaxis: {
        lines: {
          show: true
        }
      },   
      yaxis: {
        lines: {
          show: true
        }
      }, 
      row: {
        colors: ['#A19BCD', '#FFFFFF'],
        opacity: 0.2
      },  
      column: {
          colors: undefined,
          opacity: 0.5
      }, 

    },
    /*
    markers: {
      size: 3,
      colors: ['#000000'],
      strokeWidth: 1,
      strokeOpacity: 1,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: ["circle"],
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: 5,
        sizeOffset: 5
      }
    },
    */

    dataLabels: {
      enabled: true,
      formatter: function(val: any) {
        return val;
      },
      style: {
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#333']
      },
      background: {
        enabled: false,
        foreColor: '#fff',
        borderRadius: 2,
        padding: 8,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#fff'
      },
    },

    stroke: {
      show: true,
      curve: "smooth" as "smooth",
      lineCap: "round" as "round",
      width: 2,
      dashArray: 0,      
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      z: {
        formatter: undefined,
        title: 'Size: '
      },
      marker: {
        show: false,
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 90, 100]
    },
  },
  series: [], 
}










export const energy_usage_settings = {
  options: {
    chart: {
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '12px',
              fontWeight: 900
            }
          }
        }
      },
    },
    xaxis: {
      type: 'datetime' as 'datetime',
      categories: [],
    },
    fill: {
      opacity: 1
    }
  },
}