import { IconPreloader } from './Icons'


const Preloader: React.FC = () => {
  return (
    <div className="preloader-container">
      <IconPreloader />
    </div>
  )
}


export default Preloader