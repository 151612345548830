import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceIo } from '../../actions/device'
import { wsHandleDeviceUpdate, wsGetDataByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Alert from '../partials/Alert'
import { IconDevice } from '../partials/IconsForm'
import { success, danger } from '../../helpers/notifications'
import DeviceUpdateSmall from '../partials/DeviceUpdateSmall'


interface DevicesEditProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  ws: { wsConnected: boolean },
  wsGetDataByDeviceSerial: (device_serial: string) => Promise<void>,
  wsHandleDeviceUpdate: (device_serial: string) => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  updateDeviceIo: (options: {id: string, device_capabilities: any}) => Promise<void>,
}


interface DevicesEditState {
  device: { room_id: string, device_serial: string, device_id: string, device_title: string, device_data?: { fvV: string, updSt: number } },
  formSubmitted: boolean,
  error: string,
  noData: boolean,
}


export class DevicesEdit extends React.Component<DevicesEditProps, DevicesEditState> {


  state = {
    device: {
      room_id: '',
      device_serial: '',
      device_id: '',
      device_title: '',
      device_data: { fvV: '', updSt: 0 }
    },
    formSubmitted: false,
    error: '',
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      this.setState({ device: { ...device}, noData: false }, () => this.dataRequest())
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  componentDidUpdate(prevProps:any) {
    if (this.props.device.device !== prevProps.device.device) {
      const { device } = this.props.device
      this.setState({ device: { ...device}})
    }
  }


  // WS: requesting data
  dataRequest = () => {
    console.log('DEBUG: [WS] requesting the data', this.state.device.device_serial)
    this.props.wsGetDataByDeviceSerial(this.state.device.device_serial)
  }


  // WS: updating device
  handleDeviceUpdate = (device_serial: string) => {
    if(this.props.ws.wsConnected) {
      this.props.wsHandleDeviceUpdate(device_serial)
      success('Command send successfully. Device update will start shortly. Follow the progress on the device screen.')
    } else {
      danger('You are disconnected from the WS server')
    }
  }
  

  render() {

    const { device, noData } = this.state

    if(noData) {
      return (
        <TemplatePage title="Device → About" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → About" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="device_serial">
                  Firmware version
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconDevice color="#A19BCD" />
                  </span>
                  <input 
                    type="text" 
                    name="device_serial"
                    id="device_serial" 
                    value={ device.device_data && device.device_data.fvV !== '' ? device.device_data.fvV : 'DEVICE IS OFFLINE' }
                    disabled={true}
                  />
                </div>
              </div>
              {
                device.device_data && device.device_data.updSt && device.device_data.updSt === -1 ? (
                  <div className="form-group"><DeviceUpdateSmall type="error" /></div>
                ) : null
              }
              {
                device.device_data && device.device_data.updSt && device.device_data.updSt === 1 ? (
                  <div className="form-group"><DeviceUpdateSmall type="updating" /></div>
                ) : null
              }
              {
                device.device_data && device.device_data.updSt && device.device_data.updSt === 2 ? (
                  <div className="form-group"><DeviceUpdateSmall type="updated" /></div>
                ) : null
              }
            </div>
          </form>

          <div className="form__footer">
            <input 
              onClick={() => this.handleDeviceUpdate(device.device_serial)}
              type="submit" 
              value="Update Firmware" 
              className="btn btn--primary btn--large"
              disabled={device.device_data && device.device_data.updSt === 0 ? false : true }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, scenario, ws }: RootState) => ({ device, scenario, ws }),
  { fetchDeviceById, updateDeviceIo, wsGetDataByDeviceSerial, wsHandleDeviceUpdate }
)(DevicesEdit)))