import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import classnames from 'classnames'

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
}

interface SwitchOnOffVerticalProps {
  switchState: boolean,
  disabled?: boolean,
  small?: boolean
}

export const SwitchOnOffVertical: React.FC<SwitchOnOffVerticalProps> = ({ switchState, disabled, small }) => {

  const [isOn, setIsOn] = useState(switchState);

  useEffect(
    () => {setIsOn(switchState)},
    [switchState],
  )

  const toggleSwitch = () => setIsOn(!isOn);

  if(disabled) {
    return (
      <span className="switch-wrapper switch--vertical switch--disabled">
        <span className={classnames('switch', { 'switch--small': small })}>
          <div className="switch-handle" />
        </span>
      </span>
    )
  } 

  return (
    <span className="switch-wrapper switch--vertical" data-ison={isOn}>
      <span className={classnames('switch', { 'switch--small': small, 'switch-state__on': isOn, 'switch-state__off': !isOn })} onClick={toggleSwitch}>
        <span className="switch-state">
          {isOn ? 'ON' : 'OFF'}
        </span>
        <motion.div className="switch-handle" layout transition={spring} />
      </span>
    </span>
  );
}

export default SwitchOnOffVertical