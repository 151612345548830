import { IconDeviceUpdateError, IconDeviceUpdateProgress, IconDeviceUpdateSucccess } from "./IconsDeviceUpdate"



interface DeviceUpdateProps {
  type: string,
}


const DeviceUpdate: React.FC<DeviceUpdateProps> = ({ type }) => {

  if(type === 'error') {
    return (<div className="device-update-small device-updat--error">
      <div className="device-update-small__icon">
        <IconDeviceUpdateError size={32} color="#E82F55" />
      </div>
      There was an error updating this device.
    </div>)
  }

  if(type === 'updating') {
    return (<div className="device-update-small-small device-update-small--updating">
      <div className="device-update-small__icon">
        <IconDeviceUpdateProgress size={32} color="#593995" />
      </div>
      Device update is in progress ...
    </div>)
  }

  if(type === 'updated') {
    return (<div className="device-update-small device-update-small--updated">
      <div className="device-update-small__icon">
        <IconDeviceUpdateSucccess size={32} color="#45B869" />
      </div>
      Device was updated successfully.
    </div>)
  }
  return <></>
}


export default DeviceUpdate