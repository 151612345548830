const AUTH = {
  FETCH: 'AUTH_FETCH',
  FETCH_ERROR: 'AUTH_FETCH_ERROR',
  FETCH_LOGIN_SUCCESS: 'AUTH_FETCH_LOGIN_SUCCESS',
  FETCH_LOGIN_GOOGLE_SUCCESS: 'AUTH_FETCH_LOGIN_GOOGLE_SUCCESS',
  FETCH_AUTHENTICATED_SUCCESS: 'AUTH_FETCH_AUTHENTICATED_SUCCESS',
  FETCH_LOGOUT_SUCCESS: 'AUTH_FETCH_LOGOUT_SUCCESS',
  FETCH_LOGOUT_EVERYWHERE_SUCCESS: 'AUTH_FETCH_LOGOUT_EVERYWHERE_SUCCESS',
  FETCH_FORGOT_SUCCESS: 'AUTH_FETCH_FORGOT_SUCCESS',
  FETCH_CHECK_SUCCESS: 'AUTH_FETCH_CHECK_SUCCESS',
  FETCH_RESET_SUCCESS: 'AUTH_FETCH_RESET_SUCCESS',
  FETCH_SIGNUP_SUCCESS: 'AUTH_FETCH_SIGNUP_SUCCESS',
  FETCH_REGISTRATION_SUCCESS: 'AUTH_FETCH_REGISTRATION_SUCCESS',
  FETCH_EMAIL_CHANGE_SUCCESS: 'AUTH_FETCH_EMAIL_CHANGE_SUCCESS',
  FETCH_RESEND_REGISTRATION_CONFIRMATION_SUCCESS: 'AUTH_FETCH_RESEND_REGISTRATION_CONFIRMATION_SUCCESS',
  FETCH_REMOVE_ACCOUNT_SUCCESS: 'FETCH_REMOVE_ACCOUNT_SUCCESS',
  RESET_FIELDS: 'AUTH_RESET_FIELDS',
  RESET: 'AUTH_RESET'
}

const PERSON = {
  FETCH: 'PERSON_FETCH',
  FETCH_ERROR: 'PERSON_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'PERSON_FETCH_ALL_SUCCESS',
  FETCH_ID_SUCCESS: 'PERSON_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'PERSON_FETCH_DELETE_SUCCESS',
  FETCH_ADD_SUCCESS: 'PERSON_FETCH_ADD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'PERSON_FETCH_UPDATE_SUCCESS',
  FETCH_SETTINGS_SUCCESS: 'PERSON_FETCH_SETTINGS_SUCCESS',
  FETCH_WEATHER_SETTINGS_SUCCESS: 'PERSON_FETCH_WEATHER_SETTINGS_SUCCESS',
  FETCH_UPDATE_WEATHER_SETTINGS_SUCCESS: 'PERSON_FETCH_UPDATE_WEATHER_SETTINGS_SUCCESS',
  PUSH_MESSAGE: 'PERSON_PUSH_MESSAGE',
  RESET: 'PERSON_RESET'
}


const ACCOUNT = {
  FETCH: 'ACCOUNT_FETCH',
  FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
  FETCH_ID_SUCCESS: 'ACCOUNT_FETCH_ID_SUCCESS',
  UPDATE_ENERGY_PRICE_SUCCESS: 'ACCOUNT_UPDATE_ENERGY_PRICE_SUCCESS',
  UPDATE_WEATHER_SETTINGS_SUCCESS: 'ACCOUNT_UPDATE_WEATHER_SETTINGS_SUCCESS',
  RESET: 'ACCOUNT_RESET'
}


const DEVICE = {
  FETCH: 'DEVICE_FETCH',
  FETCH_ERROR: 'DEVICE_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'DEVICE_FETCH_ALL_SUCCESS',
  FETCH_MASTER_SUCCESS: 'DEVICE_FETCH_MASTER_SUCCESS',
  FETCH_SLAVE_SUCCESS: 'DEVICE_FETCH_SLAVE_SUCCESS',
  FETCH_ROOM_DEVICES_SUCCESS: 'DEVICE_FETCH_ROOM_DEVICES_SUCCESS',
  FETCH_ALL_MAGICBOX_SUCCESS: 'DEVICE_FETCH_ALL_MAGICBOX_SUCCESS',
  FETCH_ALL_MAGICSWITCH_SUCCESS: 'DEVICE_FETCH_ALL_MAGICSWITCH_SUCCESS',
  FETCH_ALL_REGISTER_SUCCESS: 'DEVICE_FETCH_ALL_REGISTER_SUCCESS',
  FETCH_ALL_MAP_SUCCESS: 'DEVICE_FETCH_ALL_MAP_SUCCESS',
  FETCH_ADD_SUCCESS: 'DEVICE_FETCH_ADD_SUCCESS',
  FETCH_ID_SUCCESS: 'DEVICE_FETCH_ID_SUCCESS',
  FETCH_GENERAL_SETTINGS_SUCCESS: 'DEVICE_FETCH_GENERAL_SETTINGS_SUCCESS',
  FETCH_UPDATE_ID_SUCCESS: 'DEVICE_FETCH_UPDATE_ID_SUCCESS',
  FETCH_UPDATE_CLOCK_SUCCESS: 'DEVICE_FETCH_UPDATE_CLOCK_SUCCESS',
  FETCH_UPDATE_ALARM_SUCCESS: 'DEVICE_FETCH_UPDATE_ALARM_SUCCESS',
  FETCH_UPDATE_STOCKS_SUCCESS: 'DEVICE_FETCH_UPDATE_STOCKS_SUCCESS',
  FETCH_UPDATE_BUTTONS_SUCCESS: 'DEVICE_FETCH_UPDATE_BUTTONS_SUCCESS',
  FETCH_UPDATE_STATUS_SUCCESS: 'DEVICE_FETCH_UPDATE_STATUS_SUCCESS',
  FETCH_UPDATE_SCREENS_SUCCESS: 'DEVICE_FETCH_UPDATE_SCREENS_SUCCESS',
  FETCH_UPDATE_WEATHER_SUCCESS: 'DEVICE_FETCH_UPDATE_WEATHER_SUCCESS',
  FETCH_UPDATE_PRESENCE_SENSOR_SUCCESS: 'DEVICE_FETCH_UPDATE_PRESENCE_SENSOR_SUCCESS',
  FETCH_UPDATE_THERMOSTAT_SUCCESS: 'DEVICE_FETCH_UPDATE_THERMOSTAT_SUCCESS',
  FETCH_UPDATE_IO_SUCCESS: 'DEVICE_FETCH_UPDATE_IO_SUCCESS',
  FETCH_REMOVE_ID_SUCCESS: 'DEVICE_FETCH_REMOVE_ID_SUCCESS',
  FETCH_REGISTER_SUCCESS: 'DEVICE_FETCH_REGISTER_SUCCESS',
  FETCH_OUTPUT_BUTTON_OPTIONS_SUCCESS: 'DEVICE_FETCH_OUTPUT_BUTTON_OPTIONS_SUCCESS',
  FETCH_UPDATE_GET_NOTIFICATIONS_SETTINGS: 'DEVICE_FETCH_UPDATE_GET_NOTIFICATIONS_SETTINGS',
  FETCH_UPDATE_GET_ALARM_SETTINGS: 'DEVICE_FETCH_UPDATE_GET_ALARM_SETTINGS',
  FETCH_UPDATE_PIN_GET_ALARM_SETTINGS: 'DEVICE_FETCH_UPDATE_PIN_GET_ALARM_SETTINGS',
  GOT_DATA_BY_DEVICE_SERIAL: 'WS_GOT_DATA_BY_DEVICE_SERIAL',
  RESET: 'DEVICE_RESET'
}


const ROOM = {
  FETCH: 'ROOM_FETCH',
  FETCH_ERROR: 'ROOM_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'ROOM_FETCH_ALL_SUCCESS',
  FETCH_MASTER_SUCCESS: 'ROOM_FETCH_MASTER_SUCCESS',
  FETCH_ALL_WITH_MAGICBOX_SUCCESS: 'SCENARIO_FETCH_ALL_WITH_MAGICBOX_SUCCESS',
  FETCH_ID_SUCCESS: 'ROOM_FETCH_ID_SUCCESS',
  ADD_SUCCESS: 'ROOM_ADD_SUCCESS',
  REMOVE_ID_SUCCESS: 'ROOM_REMOVE_ID_SUCCESS',
  UPDATE_ID_SUCCESS: 'ROOM_UPDATE_ID_SUCCESS',
  UPDATE_BUTTONS_ID_SUCCESS: 'UPDATE_BUTTONS_ID_SUCCESS',
  RESET: 'ROOM_RESET'
}


const SCENARIO = {
  FETCH: 'SCENARIO_FETCH',
  FETCH_ERROR: 'SECNARIO_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'SCENARIO_FETCH_ALL_SUCCESS',
  ADD_SUCCESS: 'SCENARIO_ADD_SUCCESS',
  UPDATE_SUCCESS: 'SCENARIO_UPDATE_SUCCESS',
  FETCH_ID_SUCCESS: 'SCENARIO_FETCH_ID_SUCCESS',
  DELETE_ID_SUCCESS: 'SCENARIO_DELETE_ID_SUCCESS',
  UPDATE_ID_SUCCESS: 'SCENARIO_UPDATE_ID_SUCCESS',
  UPDATE_ID_ACTION_SUCCESS: 'SCENARIO_UPDATE_ID_ACTION_SUCCESS',
  UPDATE_ID_TITLE_SUCCESS: 'SCENARIO_UPDATE_ID_TITLE_SUCCESS',
  UPDATE_ID_ACTIVE_SUCCESS: 'SCENARIO_UPDATE_ID_ACTIVE_SUCCESS',
  RESET: 'SCENARIO_RESET'
}


const DATA = {
  FETCH: 'DATA_FETCH',
  FETCH_ERROR: 'DATA_FETCH_ERROR',
  FETCH_TEMP_SERIAL_SUCCESS: 'DATA_FETCH_TEMP_SERIAL_SUCCESS',
  FETCH_HUM_SERIAL_SUCCESS: 'DATA_FETCH_HUM_SERIAL_SUCCESS',
  FETCH_LIGHT_SERIAL_SUCCESS: 'DATA_FETCH_LIGHT_SERIAL_SUCCESS',
  FETCH_MOVE_SERIAL_SUCCESS: 'DATA_FETCH_MOVE_SERIAL_SUCCESS',
  FETCH_LOCATIONS_SUCCESS: 'DATA_FETCH_LOCATIONS_SUCCESS',
  FETCH_STOCKS_SUCCESS: 'DATA_FETCH_STOCKS_SUCCESS',
  FETCH_EVENTS_HISTORY_SUCCESS: 'DATA_FETCH_EVENTS_HISTORY_SUCCESS',
  FETCH_EVENTS_NEW_RED_SUCCESS: 'DATA_FETCH_EVENTS_NEW_RED_SUCCESS',
  FETCH_ALARMS_NEW_COUNT_SUCCESS: 'DATA_FETCH_ALARMS_NEW_COUNT_SUCCESS',
  FETCH_DATA_SERIAL_TYPE_RANGE_SUCCESS: 'DATA_FETCH_DATA_SERIAL_TYPE_RANGE_SUCCESS',
  FETCH_DELETE_EVENTS_SUCCESS: 'DATA_FETCH_DELETE_EVENTS_SUCCESS',
  FETCH_WEATHER_BY_LOCATION_SUCCESS: 'DATA_FETCH_WEATHER_BY_LOCATION_SUCCESS',
  FETCH_MOVEMENT_HISTORY_SUCCESS: 'DATA_FETCH_MOVEMENT_HISTORY_SUCCESS',
  FETCH_HISTORY_ENERGY_CHART_SUCCESS: 'DATA_FETCH_HISTORY_ENERGY_CHART_SUCCESS',
  FETCH_HISTORY_ENERGY_USAGE_SUCCESS: 'DATA_FETCH_HISTORY_ENERGY_USAGE_SUCCESS',
  FETCH_HISTORY_ENERGY_PRODUCED_SUCCESS: 'DATA_FETCH_HISTORY_ENERGY_PRODUCED_SUCCESS',
  FETCH_HISTORY_ENERGY_CONSUMED_SUCCESS: 'DATA_FETCH_HISTORY_ENERGY_CONSUMED_SUCCESS',
  FETCH_DEV_MOVEMENT_SUCCESS: 'DATA_FETCH_DEV_MOVEMENT_SUCCESS',
  GOT_ALERT: 'DATA_GOT_ALERT',
  RESET: 'DATA_RESET'
}


const NOTIFICATION = {
  FETCH: 'NOTIFICATION_FETCH',
  FETCH_ERROR: 'NOTIFICATION_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'NOTIFICATION_FETCH_ALL_SUCCESS',
  FETCH_ADD_SUCCESS: 'NOTIFICATION_FETCH_ADD_SUCCESS',
  UPDATE_SUCCESS: 'NOTIFICATION_UPDATE_SUCCESS',
  REMOVE_ID_SUCCESS: 'NOTIFICATION_REMOVE_ID_SUCCESS',
  RESET: 'NOTIFICATION_RESET'
}


const WS = {
  RESET_CONNECTION: 'WS_RESET_CONNECTION',
  CONNECT: 'WS_CONNECT',
  DISCONNECT: 'WS_DISCONNECT',
  GET_DATA_BY_DEVICE_SERIAL: 'WS_GET_DATA_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_ALARM_SETTINGS: 'WS_DEVICE_UPDATE_ALARM_SETTINGS',
  DEVICE_UPDATE_NOTIFICATIONS_SETTINGS: 'WS_DEVICE_UPDATE_NOTIFICATIONS_SETTINGS',
  DEVICE_PRESS_IDENTIFY_BY_DEVICE_SERIAL: 'WS_DEVICE_PRESS_IDENTIFY_BY_DEVICE_SERIAL',
  DEVICE_PRESS_BUTTON_BY_DEVICE_SERIAL: 'WS_DEVICE_PRESS_BUTTON_BY_DEVICE_SERIAL',
  DEVICE_PRESS_DOU_ARRAY_BY_DEVICE_SERIAL: 'WS_DEVICE_PRESS_DOU_ARRAY_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_ALARM_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_ALARM_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_CLOCK_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_CLOCK_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_GENERAL_SETTINGS_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_GENERAL_SETTINGS_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_CORNER_BUTTONS_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_CORNER_BUTTONS_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_STATUS_ICONS_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_STATUS_ICONS_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_STOCKS_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_STOCKS_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_THERMOSTAT_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_THERMOSTATS_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_SCENARIO_SETTINGS_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_SCENARIO_SETTINGS_BY_DEVICE_SERIAL',
  DEVICE_UPDATE_DEVICE_LIST_BY_DEVICE_SERIAL: 'WS_DEVICE_UPDATE_DEVICE_LIST_BY_DEVICE_SERIAL',
  DEVICE_GET_ENERGY_USAGE_FROM_DATA_PROVIDER: 'WS_DEVICE_GET_ENERGY_USAGE_FROM_DATA_PROVIDER',
  DEVICE_PRESS_RUN_SCENARIO_BY_SCENARIO_ID: 'WS_DEVICE_PRESS_RUN_SCENARIO_BY_SCENARIO_ID',
  DEVICE_GET_DEVELOPER_DEVICE_SETTINGS_BY_DEVICE_SERIAL: 'WS_DEVICE_GET_DEVELOPER_DEVICE_SETTINGS_BY_DEVICE_SERIAL'
}


const DEVELOPER = {
  FETCH: 'DEVELOPER_FETCH',
  FETCH_ERROR: 'DEVELOPER_FETCH_ERROR',
  FETCH_DEV_SETTINGS_SUCCESS: 'DEVELOPER_FETCH_DEV_SETTINGS_SUCCESS',
  FETCH_UPDATE_DEV_SETTINGS_SUCCESS: 'DEVELOPER_FETCH_UPDATE_DEV_SETTINGS_SUCCESS',
  RESET: 'DEVELOPER_RESET'
}


export { AUTH, PERSON, ACCOUNT, DEVICE, ROOM, SCENARIO, DATA, NOTIFICATION, WS, DEVELOPER }