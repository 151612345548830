import { NavLink } from 'react-router-dom'
import { IconTabDeviceSettings, IconTabDeviceIO, IconTabDeviceScreenOrder, IconTabDeviceCornerButtons, IconTabDeviceStatusIcons, IconTabDeviceAlarmClock, IconTabDeviceAlarm, IconTabDeviceStocks, IconTabDeviceWeather, IconTabDeviceThermostat, IconTabDeviceAbout, IconTabProximitySensor } from './IconsTabs'


interface IconNavDeviceSettingsProps {
  location: any
  tabnavId: string,
  deviceCategory: string,
}


const IconNavDeviceSettings: React.FC<IconNavDeviceSettingsProps> = ({ location, tabnavId, deviceCategory }) => (

  <nav className="iconnav">
  {
    deviceCategory === 'MB' ? (
        <>
          <NavLink 
            to={{ pathname: `/devices/settings/thermostat/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceThermostat size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Thermostat</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/alarm-clock/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceAlarmClock size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Alarm Clock</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/stocks/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceStocks size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Stocks</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/corner-buttons/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceCornerButtons size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Corner Buttons</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/status-icons/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceStatusIcons size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Status Icons</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/weather/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceWeather size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Weather</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/screens-order/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceScreenOrder color="#4D409E" />
            <div className="iconnav-tab--title">Screens Order</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/alarm/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceAlarm size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Security Alarm</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/presence-sensor/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabProximitySensor size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Presence Sensor</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/io/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceIO size={40} color="#4D409E" />
            <div className="iconnav-tab--title">I/O Naming</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/general/${tabnavId}` }} 
            className="iconnav-tab"
          >
            <IconTabDeviceSettings size={40} color="#4D409E" />
            <div className="iconnav-tab--title">General Settings</div>
          </NavLink>
    
          <NavLink 
            to={{ pathname: `/devices/settings/about/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceAbout size={40} color="#4D409E" />
            <div className="iconnav-tab--title">About Device</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/developer/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceIO size={40} color="#4D409E" />
            <div className="iconnav-tab--title">Developer</div>
          </NavLink>

        </>

    ) : (
        <>
          <NavLink 
            to={{ pathname: `/devices/settings/io/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceIO size={40} color="#4D409E" />
            <div className="iconnav-tab--title">I/O Naming</div>
          </NavLink>

          <NavLink 
            to={{ pathname: `/devices/settings/general/${tabnavId}`}} 
            className="iconnav-tab"
          >
            <IconTabDeviceSettings size={40} color="#4D409E" />
            <div className="iconnav-tab--title">General Settings</div>
            
          </NavLink>
        </>
    )
  }

  </nav>


)


export default IconNavDeviceSettings