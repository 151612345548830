import {
  IconStatusKey, 
  IconStatusMailbox, 
  IconStatusBin, 
  IconStatusGarage,
  IconStatusBlinds,
  IconStatusDoors,
  IconStatusGate,
  IconStatusScenario
} from '../components/partials/IconsStatus'


interface getStatusIconProps {
  icon_number: number;
  size: number;
  color: string;
}

export const getStatusIcon: React.FC<getStatusIconProps> = ({ icon_number, size, color }) => {
  switch(icon_number) {
    case 1:
      return <IconStatusKey size={size} color={color} />
    case 2:
      return <IconStatusMailbox size={size} color={color} />
    case 3:
      return <IconStatusBin size={size} color={color} />
    case 4:
      return <IconStatusGarage size={size} color={color} />
    case 5:
      return <IconStatusBlinds size={size} color={color} />
    case 6:
      return <IconStatusDoors size={size} color={color} />
    case 7:
      return <IconStatusGate size={size} color={color} />
    case 8:
      return <IconStatusScenario size={size} color={color} />
    default: 
      return <IconStatusScenario size={size} color={color} />
  }
}