import { DEVICE } from '../types';
import fetchStates from '../types/fetchStates';

interface DeviceState {
  device: { device_serial: string },
  deviceMaster: {},
  devicesSlave: [],
  devices: [],
  devicesCount: number,
  devicesMagicBox: [],
  devicesMagicBoxCount: number,
  devicesMagicSwitch: [],
  devicesMagicSwitchCount: number,
  devicesMap: [],
  notifications_settings: [],
  devices_alarm_settings: [],
  devices_output_options: [],
  roomDevices: [],
  deviceGeneralSettings: []
}

const DEFAULT_UNIT: DeviceState = {
  device: { device_serial: '' },
  deviceMaster: {},
  devicesSlave: [],
  devices: [],
  devicesCount: 0,
  devicesMagicBox: [],
  devicesMagicBoxCount: 0,
  devicesMagicSwitch: [],
  devicesMagicSwitchCount: 0,
  devicesMap: [],
  notifications_settings: [],
  devices_alarm_settings: [],
  devices_output_options: [],
  roomDevices: [],
  deviceGeneralSettings: []
};

const device = (state = DEFAULT_UNIT, action: any) => {
  switch(action.type) {
    case DEVICE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case DEVICE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case DEVICE.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        devices: action.devices,
        devicesCount: parseInt(action.devicesCount, 10),
      };
    case DEVICE.FETCH_MASTER_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        deviceMaster: action.deviceMaster,
      };
    case DEVICE.FETCH_SLAVE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        devicesSlave: action.devicesSlave,
      };
    case DEVICE.FETCH_ALL_MAGICBOX_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        devicesMagicBox: action.devicesMagicBox,
        devicesMagicBoxCount: parseInt(action.devicesMagicBoxCount, 10),
      };
    case DEVICE.FETCH_ALL_MAGICSWITCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        devicesMagicSwitch: action.devicesMagicSwitch,
        devicesMagicSwitchCount: parseInt(action.devicesMagicSwitchCount, 10),
      };
    case DEVICE.FETCH_ROOM_DEVICES_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        roomDevices: action.roomDevices,
      }
    case DEVICE.FETCH_ALL_MAP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        devicesMap: action.devicesMap,
      };
    case DEVICE.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        device: action.device,
      };
    case DEVICE.FETCH_GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        deviceGeneralSettings: action.deviceGeneralSettings,
      };
    case DEVICE.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_CLOCK_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_ALARM_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_STOCKS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_BUTTONS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      }
    case DEVICE.FETCH_UPDATE_WEATHER_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_PRESENCE_SENSOR_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_THERMOSTAT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_IO_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_UPDATE_SCREENS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_REMOVE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case DEVICE.FETCH_OUTPUT_BUTTON_OPTIONS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        devices_output_options: action.devices_output_options,
      }
    case DEVICE.FETCH_UPDATE_GET_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        status: fetchStates.success,
        notifications_settings: action.notifications_settings,
      }
    case DEVICE.FETCH_UPDATE_GET_ALARM_SETTINGS:
      return {
        ...state,
        status: fetchStates.success,
        devices_alarm_settings: action.devices_alarm_settings,
      }
    case DEVICE.FETCH_UPDATE_PIN_GET_ALARM_SETTINGS:
      return {
        ...state,
        status: fetchStates.success,
        devices_alarm_settings: action.devices_alarm_settings,
      }
    case DEVICE.GOT_DATA_BY_DEVICE_SERIAL:
      const device = state.device.device_serial === action.device_data.device_serial ? {...state.device, device_data: action.device_data} : state.device
      const devicesMagicBox = state.devicesMagicBox.map((device: any) => action.device_data.device_serial === device.device_serial ? {...device, device_data: action.device_data} : device )
      const devicesMagicSwitch = state.devicesMagicSwitch.map((device: any) => action.device_data.device_serial === device.device_serial ? {...device, device_data: action.device_data} : device )
      const roomDevices = state.roomDevices.map((device: any) => action.device_data.device_serial === device.device_serial ? {...device, device_data: action.device_data} : device )
      const devices = state.devices.map((device: any) => 
      {
        if(action.device_data.device_serial === device.device_serial) {
          return {...device, device_data: action.device_data}
        } else { return device } 
      }
      )
      return {
        ...state,
        device,
        devicesMagicBox,
        devicesMagicSwitch,
        roomDevices,
        devices
      }
    case DEVICE.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};

export default device;