const API_ADDRESS = '/api';
const DEFAULT_LANGUAGE = 'en';
const TIMEZONE = 'Europe/Berlin';
const VERSION = '4.5.7';
const MQTT_DOMAIN = '51.83.187.204';
const WS_TYPE = 'wss';
const WS_DOMAIN = 'app.magicbox.com.pl';
const WS_PORT = '8008';
const FIREBASE_API_KEY = 'AIzaSyAWr4zR93kCwqpucQThI14u3xNsxc8zIo0';

export { 
  API_ADDRESS,
  DEFAULT_LANGUAGE,
  TIMEZONE,
  VERSION,
  MQTT_DOMAIN,
  WS_TYPE,
  WS_DOMAIN,
  WS_PORT,
  FIREBASE_API_KEY
}