import React from 'react'
import { connect } from 'react-redux'
import { fetchAllNotifications, addNotification, removeNotificationById, updateNotifications } from '../../actions/notification'
import { wsUpdateNotificationsSettings } from '../../actions/ws'
import { fetchAllDevices } from '../../actions/device'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import ContentMain from '../partials/ContentMain'
import fetchStates from '../../types/fetchStates'
import { IconClose } from '../partials/Icons'
import { IconAdd } from '../partials/IconsNavigation'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
import { danger } from '../../helpers/notifications'
import classnames from 'classnames'
import { IconTime } from '../partials/IconsForm'


interface SettingsNotificationsProps {
  router: { location: string, navigate: (to: string) => any },
  notification: any,
  auth: any,
  device: any,
  ws: any,
  fetchAllDevices: () => Promise<void>,
  fetchAllNotifications: () => Promise<void>,
  addNotification: () => Promise<void>,
  removeNotificationById: (id: number) => Promise<void>,
  updateNotifications: (notifications: any) => Promise<void>,
  wsUpdateNotificationsSettings: (options: { device_serial: string, notifications_settings: any }) => Promise<void>,
}


interface SettingsNotificationsState {
  formSubmitted: boolean,
  notifications: any,
  devices: any,
  notification_frequency: string,
}


export class SettingsNotifications extends React.Component<SettingsNotificationsProps, SettingsNotificationsState> {


  state = {
    formSubmitted: false,
    notifications: [],
    devices: [],
    notification_frequency: '180',
  }


  async componentDidMount(): Promise<void> {
    await this.fetchAllNotifications();
    await this.fetchAllDevices();
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      const { devices } = this.props.device
      this.setState({ devices })
    }
  }


  fetchAllNotifications = async () => {
    await this.props.fetchAllNotifications();
    const { status, notifications } = this.props.notification;
    if(status === fetchStates.success) {
      this.setState({ notifications, notification_frequency: notifications[0].notification_frequency ? notifications[0].notification_frequency: this.state.notification_frequency });
    }
  }


  removeNotification = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    e.preventDefault();
    // copy state
    const notifications = [...this.state.notifications];
    const filtered_notifications = notifications.filter((notification: any) => {
      if(notification.id !== id) {
        return notification;
      }
      return null;
    });
    await this.props.removeNotificationById(id);
    this.setState({ notifications: filtered_notifications });
  }


  addNotification = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await this.props.addNotification();
    const { notification_frequency } = this.state;
    const { status, notification_id } = this.props.notification;
    if(status === fetchStates.success) {
      const { person_id, account_id } = this.props.auth.person;
      // copy state
      const notification = { id: notification_id, account_id, person_id, notification_email: '', notification_phone: '', notification_frequency, notification_sos: false, notification_alarm: false };
      const notifications = [...this.state.notifications, notification];
      this.setState({ notifications });
    }
  }


  handleUpdateNotificationItem = (e: any, item_index: number, type: String) => {
    e.preventDefault();
    // copy state
    const notifications = [...this.state.notifications];
    notifications.map((notification: any, index: number) => {
      if(item_index === index) {
        if(type === 'email') {
          notification.notification_email = e.target.value;
          return notification;
        }
        if(type === 'phone') {
          notification.notification_phone = e.target.value;
          return notification;
        }
        if(type === 'sos') {
          notification.notification_sos = !notification.notification_sos;
          return notification;
        }
        if(type === 'alarm') {
          notification.notification_alarm = !notification.notification_alarm;
          return notification;
        }
      }
      return notification;
    })
    this.setState({ notifications });
  }


  handleUpdateNotificationFrequency = (notification_frequency: string) => {
    // copy state
    const notifications = [...this.state.notifications];
    notifications.map((notification: any, index: number) => {
      return notification.notification_frequency = notification_frequency
    })
    this.setState({ notifications, notification_frequency })
  } 


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { notifications, notification_frequency, devices } = this.state;
    await this.props.updateNotifications(notifications);
    const tst = Date.now();
    let a_em:any = [];
    let a_sms:any = [];
    let s_em:any = [];
    let s_sms:any = [];
    notifications.map((notification: any) => {
      if(notification.notification_alarm) {
        a_em.push(notification.notification_email);
        a_sms.push(notification.notification_phone);
      }
      if(notification.notification_sos) {
        s_em.push(notification.notification_email);
        s_sms.push(notification.notification_phone);
      }
      notification.notification_frequency = parseInt(notification_frequency);
      return notification;
    });
    const notifications_settings = {
      tst,
      a_em,
      a_sms,
      s_em,
      s_sms,
      n_freq: parseInt(notification_frequency)
    };
    // check at least one device exists
    if(devices.length > 0) {
      // check we have wsConnection
      if(this.props.ws.wsConnected === true) {
        devices.forEach((device:{device_serial: string, device_set_alarm: any, device_id: string}) => {
          // make sure we have MB category
          const prefixToCheck = device.device_serial.substring(0, 2).toUpperCase();
          if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
            this.props.wsUpdateNotificationsSettings({ device_serial: device.device_serial, notifications_settings })
          }
        })
      } else {
        danger('You are disconnected from the WS server')
      }
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { notifications, notification_frequency, formSubmitted } = this.state;
    const { status, fields } = this.props.notification;

    return (
      <TemplatePage title="Settings → Notifications" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>

          <form className="form" onSubmit={this.handleOnSubmit}>



            <div className="form-section">
              <div className="form-group">
                <div className="notifications">
                  <div className="notifications__header">
                    <span className="">Email</span>
                    <span className="">SMS</span>
                    <span className="">SOS</span>
                    <span className="">Alarm</span>
                  </div>
                  {
                    notifications && notifications.map((notification: { id: number, notification_email: string, notification_phone: string, notification_sos: boolean, notification_alarm: boolean }, index) => (
                      <div className="notifications__row" key={notification.id}>
                        <div className="notifications__row-data">
                          <span>
                            <label className="only-mobile" htmlFor={`notification_email_${notification.id}`}>
                              Email
                            </label>
                            <input 
                              type="text" 
                              name={`notification_email_${notification.id}`}
                              id={`notification_email_${notification.id}`}
                              value={ notification.notification_email }
                              onChange={(e) => this.handleUpdateNotificationItem(e, index, 'email')}
                              autoComplete="off"
                              className={classnames('', { 'input-error': fields && fields.includes(`notifications[${index}].notification_email`) })}
                            />
                          </span>
                          <span>
                            <label className="only-mobile" htmlFor={`notification_phone_${notification.id}`}>
                              Phone
                            </label>
                            <input 
                              type="text" 
                              name={`notification_phone_${notification.id}`}
                              id={`notification_phone_${notification.id}`}
                              value={ notification.notification_phone }
                              onChange={(e) => this.handleUpdateNotificationItem(e, index, 'phone')}
                              autoComplete="off"
                              className={classnames('', { 'input-error': fields && fields.includes(`notifications[${index}].notification_phone`) })}
                            />
                          </span>
                          <span onClick={(e) => this.handleUpdateNotificationItem(e, index, 'sos')}>
                            <label className="only-mobile">
                              SOS
                            </label>
                            <SwitchTrueFalseHorizontal switchState={ notification.notification_sos } />
                          </span>
                          <span onClick={(e) => this.handleUpdateNotificationItem(e, index, 'alarm')}>
                            <label className="only-mobile">
                              ALARM
                            </label>
                            <SwitchTrueFalseHorizontal switchState={ notification.notification_alarm } />
                          </span>
                        </div>
                        <div className="notifications__row-button">
                          <button className="btn-square" onClick={e => this.removeNotification(e, notification.id)}>
                            <IconClose color="#ffffff" size={24} />
                          </button>
                        </div>
                      </div>
                    ))
                  }
                  {
                    notifications && notifications.length < 5 && (
                      <button className="btn-square btn--margin-bottom" onClick={e => this.addNotification(e)}>
                        <IconAdd color="#ffffff" size={24} />
                      </button>
                    )
                  }
                </div>
              </div>
            </div>



            <div className="form-section">
              <div className="form-group">
                <label htmlFor="notification_frequency">
                  Maximum alert frequency (m)
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconTime color="#A19BCD" />
                  </span>
                  <select
                    name="notification_frequency" 
                    value={ notification_frequency }
                    onChange={(e) => this.handleUpdateNotificationFrequency(e.target.value)}
                    autoComplete="off"
                    className={classnames('', { 'input-error': fields && fields.includes('notification_frequency') })}
                  >
                    <option value="60">1</option>
                    <option value="180">3</option>
                    <option value="300">5</option>
                    <option value="900">15</option>
                    <option value="1800">30</option>
                    <option value="3600">60</option>
                  </select>
                </div>
                <span className="form-explanation">The number you set here represents how frequently the notifications will be sent. It is the minimum number between two notifications</span>
              </div>
            </div>



            <div className="form-section">
              <div className="form-group">
                <input 
                  type="submit" 
                  value="Update Notifications" 
                  className="btn btn--primary"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>



          </form>

        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, notification, device, ws }: RootState) => ({ auth, notification, device, ws }),
  { fetchAllNotifications, addNotification, removeNotificationById, updateNotifications, wsUpdateNotificationsSettings, fetchAllDevices }
)(SettingsNotifications)))