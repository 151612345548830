import DeviceUpdate from '../partials/DeviceUpdate'
import { IconDeviceBlindsDown, IconDeviceBlindsUp } from '../partials/IconsDevices'
import SwitchUpDown from '../partials/SwitchOnOffVertical'


interface DeviceMagicBlindsProps {
  device: any,
}


const DeviceMagicBlinds: React.FC<DeviceMagicBlindsProps> = ({ device }) => {


  if(device.device_data && device.device_data.updSt === -1) {
    // UPDATE ERROR
    return <DeviceUpdate type="error" />
  }
  

  if(device.device_data && device.device_data.updSt === 1) {
    // UPDATE IN PROGRESS
    return <DeviceUpdate type="updating" />
  }


  if(device.device_data && device.device_data.updSt === 2) {
    // UPDATE COMPLETED, GETTING DATA...    
    return <DeviceUpdate type="updated" />
  }


  return (
    <div className="device-type device-type__magicblinds">
      <div className="magicswitch-blinds__row">
        <div className="blinds-row__icon">
          <IconDeviceBlindsUp size={0.75} color={device.device_data ? '#000000' : '#828BA9'} />
        </div>
        <div className="blinds-row__description">
          Windows
          <div className="blinds-row__state">{ device.device_data ? (<span className="state--on">UP</span>) : 'UNKNOWN'}</div>
        </div>
        <div className="blinds-row__switch">
          <SwitchUpDown disabled={!device.device_data && true} switchState={true} />
        </div>
      </div>
      <div className="magicswitch-blinds__row">
        <div className="blinds-row__icon">
          <IconDeviceBlindsDown size={0.75} color={device.device_data ? '#000000' : '#828BA9'}/>
        </div>
        <div className="blinds-row__description">
          Windows
          <div className="blinds-row__state">{ device.device_data ? (<span className="state--off">DOWN</span>) : 'UNKNOWN'}</div>
        </div>
        <div className="blinds-row__switch">
          <SwitchUpDown disabled={!device.device_data && true} switchState={false} />
        </div>
      </div>
      <div className="magicswitch-blinds__row">
        <div className="blinds-row__icon">
          <IconDeviceBlindsUp size={0.75} color={device.device_data ? '#000000' : '#828BA9'} />
        </div>
        <div className="blinds-row__description">
          Windows
          <div className="blinds-row__state">{ device.device_data ? (<span className="state--on">UP</span>) : 'UNKNOWN'}</div>
        </div>
        <div className="blinds-row__switch">
          <SwitchUpDown disabled={!device.device_data && true} switchState={true} />
        </div>
      </div>
    </div>
  )
}


export default DeviceMagicBlinds