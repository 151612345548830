export const IconAdd = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--add">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <polygon id="Path" fill={props.color ? props.color : '#2A2C44'} points="19 13 13 13 13 19 11 19 11 13 5 13 5 11 11 11 11 5 13 5 13 11 19 11"></polygon>
        </g>
      </g>
    </svg>
  </span>
);


export const IconDelete = (props: { size?: number, color?: string, table?: boolean, button?: boolean, task?: boolean, password?: boolean }) => (
  <span className="app-icon app-icon--delete">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
            <polygon  points="0 0 24 0 24 24 0 24"></polygon>
            <path d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z" id="Shape" fill={props.color ? props.color : '#2A2C44'}  fillRule="nonzero"></path>
        </g>
      </g>
    </svg>
  </span>
);


export const IconDone = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--done">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g >
              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              <polygon id="Path" fill={props.color ? props.color : '#2A2C44'} points="9 16.2 4.8 12 3.4 13.4 9 19 21 7 19.6 5.6"></polygon>
          </g>
      </g>
    </svg>
  </span>
);