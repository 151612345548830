import { IconWeatherCloudy, IconWeatherOvercast, IconWeatherPartiallyCloudy, IconWeatherSunny, IconWeatherRain, IconWeatherDrizzle, IconWeatherSnow, IconWeatherStormy, IconWeatherTunderstorm, IconWeatherFoggy, IconWeatherHail } from '../components/partials/IconsWeather'

export const getWeatherIcon = (options: { ws: number, color: string, size: number }) => {
  const { ws, color, size } = options;
  switch(ws) {
    case 1:
      return <IconWeatherCloudy size={size} color={color} />
    case 2:
      return <IconWeatherPartiallyCloudy size={size} color={color} />
    case 3:
      return <IconWeatherSunny size={size} color={color} />
    case 4:
      return <IconWeatherOvercast size={size} color={color} />
    case 5:
      return <IconWeatherRain size={size} color={color} />
    case 6:
      return <IconWeatherDrizzle size={size} color={color} />
    case 7:
      return <IconWeatherSnow size={size} color={color} />
    case 8:
      return <IconWeatherStormy size={size} color={color} />
    case 9:
      return <IconWeatherTunderstorm size={size} color={color} />
    case 10:
      return <IconWeatherFoggy size={size} color={color} />
    case 11:
      return <IconWeatherHail size={size} color={color} />
    default:
      return ''
  }
}