import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import classnames from 'classnames'
import { IconSwitchCross, IconSwitchTick } from './IconsSwitch'

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
}

interface SwitchTrueFalseHorizontalProps {
  switchState: boolean,
  disabled?: boolean,
  small?: boolean,
  title?: string
}

export const SwitchTrueFalseHorizontal: React.FC<SwitchTrueFalseHorizontalProps> = ({ switchState, disabled, small, title }) => {

  const [isOn, setIsOn] = useState(switchState);

  useEffect(
    () => {setIsOn(switchState)},
    [switchState],
  )

  const toggleSwitch = () => setIsOn(!isOn);

  if(disabled) {
    return (
      <span> 
        <span className="switch-wrapper switch--horizontal-true-false switch--disabled">
          <span className={classnames('switch', { 'switch--small': small })}>
            <span className="switch-handle" />
          </span>
        </span>
        <span className="switch-title switch-title--disabled">
          { title }
        </span>
      </span>

    )
  } 

  return (
    <span>
      <span className={classnames('switch-wrapper switch--horizontal-true-false', { 'switch-state__on': isOn, 'switch-state__off': !isOn })} data-ison={isOn}>
        <span className={classnames('switch', { 'switch--small': small })} onClick={toggleSwitch}>
          <span className="switch-state">
            {isOn ? <IconSwitchTick color="#FFFFFF" size={18} /> : <IconSwitchCross color="#A19BCD" size={18} />}
          </span>
          <motion.span className="switch-handle" layout transition={spring} />
        </span>
      </span>
      <span className="switch-title">{ title }</span>
    </span>
  )
}

export default SwitchTrueFalseHorizontal