import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceScreens, fetchDeviceGeneralSettings } from '../../actions/device'
import { wsUpdateGeneralSettingsByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import classnames from 'classnames'
import { devicePresets } from '../../helpers/devicePresets'
import Alert from '../partials/Alert'
import { IconScreenOrderStocks, IconScreenOrderHistory, IconScreenOrderWeather, IconScreenOrderAlarm, IconScreenOrderMain, IconScreenOrderEnergyUsage } from '../partials/IconsScreenOrder'


interface DevicesSettingsScreensProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  ws: { wsConnected: boolean },
  fetchDeviceById: (id: string) => Promise<void>,
  updateDeviceScreens: (options: {id: string, screens_settings: {tst: number, scr_ord: Array<number>}}) => Promise<void>,
  handleButtonSubmit: () => Promise<void>,
  wsUpdateGeneralSettingsByDeviceSerial: (options: { device_serial: string, general_settings: any }) => Promise<void>,
  fetchDeviceGeneralSettings: (device_serial: string) => Promise<void>,
}


interface DevicesSettingsScreensState {
  device: { 
    room_id: string, 
    device_id: string,
    device_serial: string, 
    device_title: string, 
    device_set_screens: any, 
  },
  screens: Array<any>,
  formSubmitted: boolean,
  noData: boolean,
}


export class DevicesSettingsScreens extends React.Component<DevicesSettingsScreensProps, DevicesSettingsScreensState> {


  state = {
    device: {
      room_id: '',
      device_id: '',
      device_serial: '',
      device_title: '',
      device_set_screens: devicePresets.device_set_screens
    },
    screens: [
      {id: 1, enabled: false, icon: <IconScreenOrderMain />,  title: 'Main screen'},
      {id: 2, enabled: false, icon: <IconScreenOrderWeather />, title: 'Weather screen'},
      {id: 3, enabled: false, icon: <IconScreenOrderHistory />, title: 'Thermostat screen'},
      {id: 4, enabled: false, icon: <IconScreenOrderAlarm />,title: 'Alarm screen'},
      {id: 5, enabled: false, icon: <IconScreenOrderStocks />, title: 'Stocks screen'},
      {id: 6, enabled: false, icon: <IconScreenOrderEnergyUsage />, title: 'Energy usage screen'},
      // {id: 7, enabled: false, icon: <IconScreenOrderFotovoltaic />, title: 'Fotovoltaic screen'}
    ],
    formSubmitted: false,
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      let scr_ord: any = this.state.device.device_set_screens.scr_ord
      const { device } = this.props.device
      if(device) {
        this.setState({ device })
      }
      if(device.device_set_screens !== null && device.device_set_screens.scr_ord){
        scr_ord = this.props.device.device.device_set_screens.scr_ord
      } 
        const { screens } = this.state
        const scr_ord_numeric = scr_ord.map(function(item:string) {
          return parseInt(item, 10);
        });
        let disabled_screens: any[] = []
        const enabled_screens = screens.map(screen => {
          if(scr_ord_numeric.includes(screen.id)) {
            screen.enabled = true
            return screen
          }
          disabled_screens.push(screen)
          return screen
        })
        // order the screens first
        let ordered_screens = []
        scr_ord.map((screen:any) => {
          enabled_screens.map(item => {
            if(item.id === parseInt(screen)) {
              return ordered_screens.push(item)
            }
            return null
          })
          return null
        })
        ordered_screens.push(...disabled_screens)
        this.setState({ 
          screens: ordered_screens,
          noData: false
        })
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true })
    const { screens, device } = this.state
    const { device_serial } = device
    const filtered_screens = screens.filter(screen => screen.enabled === true && screen)
    const scr_ord = filtered_screens.map(screen => screen.id)
    const tst = Date.now()
    const screens_settings = {
      tst,
      scr_ord
    }
    await this.props.updateDeviceScreens({ id: this.props.router.params.id, screens_settings })
    if(this.props.device.status === fetchStates.success) {
      if(this.props.ws.wsConnected) {
        await this.props.fetchDeviceGeneralSettings(device_serial);
        const { deviceGeneralSettings } = this.props.device
        console.log('[DEBUG]: - emitting following data:', device_serial, deviceGeneralSettings)
        this.props.wsUpdateGeneralSettingsByDeviceSerial({ device_serial, general_settings: deviceGeneralSettings })
      }
    }
    this.setState({ formSubmitted: false })
  }


  // a little function to help us with reordering the result
  reorder = (list:any, startIndex:number, endIndex:number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };


  getItemStyle = (isDragging:any, draggableStyle:any) => ({
    userSelect: "none",
    ...draggableStyle,
  });


  getListStyle = (isDraggingOver:any) => ({
    background: isDraggingOver ? "#bbbfcc" : "transparent",
    borderWidth: 3,
    borderStyle: 'dashed',
    borderColor: isDraggingOver ? "#593995" : "lightgrey",
    borderRadius: 4,
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8,
    maxWidth: 800,
  });


  onDragEnd = (result:any) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = this.reorder(
      this.state.screens,
      result.source.index,
      result.destination.index
    );
    this.setState({ screens: reorderedItems })
  };


  handleEnableScreen = (id: number) => {
    const { screens } = this.state
    screens.map(screen => {
      if(screen.id === id) {
        screen.enabled = !screen.enabled
      }
      return screen
    })
    this.setState({ screens })
  };


  render() {

    const { device, screens, formSubmitted, noData } = this.state

    if(noData) {
      return (
        <TemplatePage title="Device → Screens Order" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Screens Order" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">
            <div className="order-screens">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >
                        {screens.map((item:any, index:number) => {

                          if(index === 0) {
                            return <div key={item.id} className="order-screens__screen order-screens__screen--non-movable">
                              <div>{item.icon}</div>
                              <div className="screen__title">{item.title}</div>
                              <div className={classnames('screen__image', { 'screen__image--enabled': item.enabled === true,
                              'screen__image--disabled': item.enabled === false,
                            })}></div>
                            {  item.id !== 1 &&

                              <span className="screen__enable" onClick={() => this.handleEnableScreen(item.id)}>
                                <SwitchTrueFalseHorizontal switchState={item.enabled} />
                              </span>

                            }
                            </div>
                          } else {
                            return <Draggable key={item.id} draggableId={item.title} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={this.getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div key={item.id} className={`order-screens__screen ${snapshot.isDragging && `order-screens__screen--dragged`} ${item.enabled === false && `order-screens__screen--disabled`}`}>
                                    <div>{item.icon}</div>
                                    <div className="screen__title">{item.title}</div>
                                  {  item.id !== 1 &&

                                    <span className="screen__enable" onClick={() => this.handleEnableScreen(item.id)}>
                                      <SwitchTrueFalseHorizontal switchState={item.enabled} />
                                    </span>

                                  }

                                  </div>
                                </div>
                              )}
                            </Draggable>
                          }
                          
                        })}
                        {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </form>
          <div className="form__footer">
            <input 
              onClick={() => this.handleButtonSubmit()}
              type="submit" 
              value="Update Device Settings" 
              className="btn btn--primary btn--large"
              disabled={ formSubmitted ? true : false }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, scenario, ws }: RootState) => ({ device, scenario, ws }),
  { fetchDeviceById, updateDeviceScreens, wsUpdateGeneralSettingsByDeviceSerial, fetchDeviceGeneralSettings }
)(DevicesSettingsScreens)))