import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceStocks, fetchDeviceGeneralSettings } from '../../actions/device'
import { wsUpdateGeneralSettingsByDeviceSerial } from '../../actions/ws'
import { fetchStocks } from '../../actions/data'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { IconClose } from '../partials/Icons'
import { IconStock } from '../partials/IconsForm'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { devicePresets } from '../../helpers/devicePresets'
import { danger } from '../../helpers/notifications'
import Alert from '../partials/Alert'
import { IconUpArrow, IconDownArrow } from '../partials/IconsStocks'
import classnames from 'classnames'


interface DevicesSettingsStocksProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  data: any,
  ws: { wsConnected: boolean },
  updateDeviceStocks: (options: { id: string, stocks_settings: any }) => Promise<void>,
  fetchStocks: () => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  handleButtonSubmit: () => Promise<void>,
  wsUpdateGeneralSettingsByDeviceSerial: (options: { device_serial: string, general_settings: any }) => Promise<void>,
  fetchDeviceGeneralSettings: (device_serial: string) => Promise<void>,
}


interface DevicesSettingsStocksState {
  device: { room_id: string, device_id: string, device_serial: string, device_title: string },
  formSubmitted: boolean,
  stocks: Array<any>,
  selectedStocks: Array<number>,
  selectedStock: number,
  noData: boolean,
}


export class DevicesSettingsStocks extends React.Component<DevicesSettingsStocksProps, DevicesSettingsStocksState> {


  state = {
    device: {
      room_id: '',
      device_id: '',
      device_serial: '',
      device_title: '',
    },
    formSubmitted: false,
    stocks: [],
    selectedStocks: [],
    selectedStock: 1,
    noData: false
  }


  componentDidMount = async () => {
    await this.props.fetchStocks()
    if(this.props.data.status === fetchStates.success) {
      let stocks: any
      stocks = this.props.data.stocks        
      await this.props.fetchDeviceById(this.props.router.params.id)
      if(this.props.device.status === fetchStates.success) {
        let { device } = this.props.device
        let selectedStocks: any
        let selectedStock: number
        selectedStocks = []
        selectedStock = 1
        if(device.device_set_stock === null) {device.device_set_stock = devicePresets.device_set_stock} 
        if(stocks && stocks.length > 0) {
          stocks.filter((stock:{id:string}) => !device.device_set_stock.stock.includes(stock.id))
          selectedStocks = stocks.filter((stock:{id:string}) => device.device_set_stock.stock.includes(stock.id))
          selectedStock = stocks[0]
        }
        this.setState({ device, stocks, selectedStocks, selectedStock, noData: false })
      }
      if(this.props.device.status === fetchStates.error) {
        this.setState({ noData: true })
      }
    }
  }


  removeStock = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    let { selectedStocks } = this.state
    selectedStocks = selectedStocks.filter((stock:{id: string}) => stock.id !== id)
    this.setState({ selectedStocks })
  }


  addStock = (e: React.ChangeEvent<HTMLSelectElement>, id: string) => {
    e.preventDefault();
    const { selectedStocks, stocks } = this.state
    const addingStock = stocks.filter((stock:{id: number}) => stock.id === parseInt(id))
    const filteredStocks = stocks.filter((stock:{id: number}) => {
      if(stock.id !== parseInt(id)) {
        return stock
      }
      return null
    })
    selectedStocks.push(addingStock[0])
    this.setState({ selectedStocks, stocks: filteredStocks })
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true });
    if(this.props.ws.wsConnected) {
      const { selectedStocks, device } = this.state
      const { device_serial } = device
      const tst = Date.now()
      const stock = selectedStocks.map((stock:{id: string}) => stock.id)
      const stocks_settings = {
        tst,
        stock
      }
      await this.props.updateDeviceStocks({ id: this.props.router.params.id, stocks_settings });
      await this.props.fetchDeviceGeneralSettings(device_serial);
      const { deviceGeneralSettings } = this.props.device
      await this.props.wsUpdateGeneralSettingsByDeviceSerial({ device_serial, general_settings: deviceGeneralSettings});
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { device, stocks, selectedStocks, formSubmitted, noData } = this.state

    if(noData) {
      return (
        <TemplatePage title="Device → Stocks" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Stocks" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">
            {
              selectedStocks && selectedStocks.map((stock:{id: string, stock_title: string, stock_value: number, stock_percent: number, stock_rise: number}) => (
                <div className="stock-item" key={stock.id}>
                  <div className="stock-item__stock">
                    <span className="stock-item__title">{stock.stock_title}</span>
                    <span className="stock-item__value">{stock.stock_value}</span>
                    <span className={classnames('stock-item__percent', { 
                      'stock-item__percent--rise-up': stock.stock_rise === 1,
                      'stock-item__percent--rise-down': stock.stock_rise === 0
                    })}>{stock.stock_percent}%</span>
                    <span className="stock-item__rise">{ stock.stock_rise === 1 ? <IconUpArrow size={17} color="#45B869" /> : <IconDownArrow size={17} color="#E72F54" />}</span>
                  </div>
                  <div className="stock-item__button">          
                    <button className="btn-square" onClick={e => this.removeStock(e, stock.id)}>
                      <IconClose color="#ffffff" size={24} />
                    </button>
                  </div>
                </div>
              ))
            }
            <div className="form-group form-group--grid">
              <div className="input-group">
                <span className="input-icon">
                  <IconStock color="#A19BCD" />
                </span>
                <select 
                  name="selected_stock" 
                  onChange={e => this.addStock(e, e.target.value)}
                >
                  <option value="">Select stock</option>
                  { 
                    stocks && stocks.map((stock:{id: string, stock_title: string}) => <option key={stock.id} value={stock.id}>{stock.stock_title}</option>)
                  }
                </select>
              </div>
            </div>
          </form>
          <div className="form__footer">
            <input 
              onClick={() => this.handleButtonSubmit()}
              type="submit" 
              value="Update Device Settings" 
              className="btn btn--primary btn--large"
              disabled={ formSubmitted ? true : false }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, data, ws }: RootState) => ({ device, data, ws }),
  { fetchDeviceById, fetchStocks, updateDeviceStocks, wsUpdateGeneralSettingsByDeviceSerial, fetchDeviceGeneralSettings }
)(DevicesSettingsStocks)))