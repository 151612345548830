import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, fetchDeviceGeneralSettings, updateDevicePresenceSensor } from '../../actions/device'
import { wsUpdateGeneralSettingsByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Alert from '../partials/Alert'
import { danger } from '../../helpers/notifications'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'


interface DevicesEditProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  ws: { wsConnected: boolean },
  fetchDeviceById: (id: string) => Promise<void>,
  updateDevicePresenceSensor: (optinos: {id: string, presence_sensor_settings: {}}) => Promise<void>,
  fetchDeviceGeneralSettings: (device_serial: string) => Promise<void>,
  wsUpdateGeneralSettingsByDeviceSerial: (options: { device_serial: string, general_settings: any }) => Promise<void>,
}


interface DevicesEditState {
  device: { room_id: string, device_serial: string, device_id: string, device_title: string },
  backlight_on: number,
  backlight_time: string,
  formSubmitted: boolean,
  error: string,
  noData: boolean,
}


export class DevicesEdit extends React.Component<DevicesEditProps, DevicesEditState> {


  state = {
    device: {
      room_id: '',
      device_serial: '',
      device_id: '',
      device_title: '',
    },
    backlight_on: 1,
    backlight_time: '10',
    formSubmitted: false,
    error: '',
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById();
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device;
      this.setState({ device: { ...device }, backlight_on: device.device_set_presence_sensor.backlight_on, backlight_time: device.device_set_presence_sensor.backlight_time, noData: false });
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true });
    }
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true });
    if(this.props.ws.wsConnected) {
      const { device, backlight_on, backlight_time } = this.state
      const { device_serial } = device
      const tst = Date.now()
      const presence_sensor_settings = {
        tst,
        backlight_on,
        backlight_time
      }
      await this.props.updateDevicePresenceSensor({ id: device.device_id, presence_sensor_settings })
      await this.props.fetchDeviceGeneralSettings(device_serial);
      const { deviceGeneralSettings } = this.props.device
      console.log('[DEBUG]: - emitting following data:', device_serial, deviceGeneralSettings)
      await this.props.wsUpdateGeneralSettingsByDeviceSerial({ device_serial: device_serial, general_settings: deviceGeneralSettings })
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false });
  }
  

  render() {

    const { backlight_on, backlight_time, device, noData, formSubmitted } = this.state;

    if(noData) {
      return (
        <TemplatePage title="Device → Presence Sensor" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Presence Sensor" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">
            <div className="form-section">

              <div className="form-group">
                <label htmlFor="enabled" onClick={() => this.setState({ backlight_on: backlight_on === 0 ? 1 : 0 })}>
                  <div className="label-title">
                    Turn backlight on
                  </div>
                  <SwitchTrueFalseHorizontal switchState={backlight_on === 0 ? false : true} />
                </label>
              </div>

              {
                backlight_on === 1 && (
                  <div className="form-group">
                    <label htmlFor="backlight_time">
                      Backlight time ({backlight_time} s)
                    </label>
                    <div className="slidecontainer">
                      <input 
                        name="backlight_time" 
                        id="backlight_time" 
                        type="range" 
                        min="1" 
                        max="120" 
                        value={ backlight_time } 
                        className="slider" 
                        onChange={e => this.setState({ backlight_time: e.target.value })} 
                      />
                    </div>
                    <span className="form-explanation">Set the time (in seconds) you would like to keep the backlight on after detecting the movement</span>
                  </div>
                )
              }

            </div>
          </form>

          <div className="form__footer">
            <input 
              onClick={() => this.handleButtonSubmit()}
              type="submit" 
              value="Update Device Settings" 
              className="btn btn--primary btn--large"
              disabled={ formSubmitted ? true : false }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, scenario, ws }: RootState) => ({ device, scenario, ws }),
  { fetchDeviceById, fetchDeviceGeneralSettings, updateDevicePresenceSensor, wsUpdateGeneralSettingsByDeviceSerial }
)(DevicesEdit)))