interface CardProps {
  children: any,
  title?: string,
}


const Card: React.FC<CardProps> = ({ children, title }) => {
  return (
    <div className="content-card">
      {
        title && (
          <div className="content-card__title">
            <h3>{title}</h3>
          </div>
        )
      }
      <div className="content-card__body">
        { children }
      </div>
    </div>
  )
}


export default Card;