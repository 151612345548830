import React from 'react'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import { connect } from 'react-redux';
import { loginGoogle } from '../../actions/auth';
import { app } from '../auth/firebase';
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import { danger } from '../../helpers/notifications';
import { IconGoogle } from './Icons';


interface GoogleSigninProps {
  loginGoogle: (options: { email: string }) => Promise<void>,
}

interface GoogleSigninState {
}


export class GoogleSignin extends React.Component<GoogleSigninProps, GoogleSigninState> {

  handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithPopup(auth, provider);
      console.log('google loging in', result.user.email);
      if(result.user.email) {
        this.props.loginGoogle({ email: result.user.email });
      } else {
        danger('There was a problem logging you in with your Google account')
      }
    } catch (error) {
      danger('There was an error')
    }
  }


  render() {
    return (
      <button onClick={() => this.handleGoogleLogin()} type="button" className="btn btn--large btn--fullwidth btn--google">
        <IconGoogle /> Continue with Google
      </button>
    )
  }
}


export default withRouter(connect(
  ({ data }: RootState) => ({ data }),
  { loginGoogle }
)(GoogleSignin))