import React from 'react';
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { signupPerson } from '../../actions/auth'
import { VERSION } from '../../config'
import { IconVisibility, IconVisibilityOff, IconEmail, IconLock } from '../partials/IconsForm'
import { IconBack } from '../partials/IconsNavigation'
import classnames from 'classnames'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { container, item } from '../../helpers/framer'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import OAuth from '../partials/GoogleSignin'



interface AuthRegisterProps {
  router: {navigate: any},
  auth: { status: string, fields: Array<string>, authenticated: boolean },
  signupPerson: (options: { register_email: string, register_password: string }) => Promise<void>,
}


interface AuthRegisterState {
  formSubmitted: boolean,
  register_password: string,
  register_email: string,
  passwordShow: boolean,
}


export class AuthRegister extends React.Component<AuthRegisterProps & AuthRegisterState> {


  state = { 
    formSubmitted: false,
    register_password: '',
    register_email: '',
    passwordShow: false
  };


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { register_email, register_password } = this.state;
    await this.props.signupPerson({ register_email, register_password })
    if(this.props.auth.status === 'success') {
      this.setState({ 
        register_email: '', 
        register_password: '',
        formSubmitted: false 
      });
      this.props.router.navigate('/');
    }
  }


  render() {

    const { formSubmitted, register_password, register_email, passwordShow } = this.state;
    const { authenticated, fields, status } = this.props.auth


    return authenticated ? <Navigate to="/" /> : (

      <div className="template template-auth">
        <div className="page-content">

        <motion.div className="auth-container" variants={container}
          initial="hidden"
          animate="visible"
        >
          <div className="auth-back-button">
            <Link to="/" className="btn-square">
              <IconBack color="#ffffff" />
            </Link>
          </div>
          <div className="auth-logo" />
          <div className="auth-box">

            <div className="auth-box__wrapper">

              <motion.div className="auth-form__header" key={1} variants={item}>
                <h1 className="auth-box__title">Register</h1>
              </motion.div>

              <form className="auth-form" onSubmit={this.handleOnSubmit}>
                <motion.div className="form-group" key={2} variants={item}>
                  <label htmlFor="register_email">Email</label>
                  <div className="input-group"> 
                    <span className="input-icon input-icon--email">
                      <IconEmail color="#A19BCD" />
                    </span>
                    <input
                      id="register_email"
                      type="email"
                      placeholder="Email"
                      autoComplete="email"
                      value={register_email}
                      onChange={e => this.setState({ register_email: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('register_email') })}
                    />
                  </div>
                </motion.div>
                <motion.div className="form-group" key={3} variants={item}>
                  <label htmlFor="register_password">Password</label>
                  <div className="input-group">
                    <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                      { passwordShow === false ? (
                        <IconVisibility password size={18} />
                      ) : (
                        <IconVisibilityOff password size={18} />
                      )}
                    </span>
                    <span className="input-icon">
                      <IconLock color="#A19BCD" />
                    </span>
                    <input
                      id="register_password"
                      type={ passwordShow === false ? 'password' : 'text' } 
                      placeholder="Password"
                      autoComplete="password"
                      value={register_password}
                      className={classnames('', { 'input-error': fields && fields.includes('register_password') })}
                      onChange={e => this.setState({ register_password: e.target.value })}
                    />
                    <PasswordStrengthMeter password={register_password} />
                  </div>
                </motion.div>
                <motion.div className="form-group" key={4} variants={item}>
                  <input 
                    type="submit"
                    className="btn btn--primary btn--large btn--fullwidth"
                    value="Register" 
                    disabled={formSubmitted && status === 'fetching' ? true : false}
                  />
                </motion.div>

                <motion.div className="form-group text-center" key={5} variants={item}>
                  or
                </motion.div>

                <motion.div className="form-group" key={6} variants={item}>
                  <OAuth />
                </motion.div>
              </form>

              </div>
          </div>
          <div className="auth-version">v{VERSION}</div>
        </motion.div>
        </div>
      </div>
    )
  }
}


export default withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { signupPerson }
)(AuthRegister))