import { IconScreenHumidity, IconScreenWarning } from '../partials/IconsScreen'
import { getWeatherIcon } from '../../helpers/getWeatherIcon'
import { getButtonIcon } from '../../helpers/getButtonIcon'
import { getStatusIcon } from '../../helpers/getStatusIcon'
import DeviceUpdate from '../partials/DeviceUpdate'
import classnames from 'classnames'


interface DeviceMagicBoxProps {
  device: any,
  pressMagicboxButton: (button: number, device_serial: string) => void,
}


const DeviceMagicBox: React.FC<DeviceMagicBoxProps> = ({ device, pressMagicboxButton }) => {


  if(!device.device_data || Object.keys(device.device_data).length === 0) {
    return (
      <div className="mb-screen-wrapper">
        <div className="mb-screen">
          <div className="mb-screen-overlay">
            <div className="mb-screen-offline-wrapper">
              <div>
                <IconScreenWarning size={32} color="#E82F55" />
                <div className="mb-screen-offline">Device is Offline</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  if(device.device_data.updSt === -1) {
    // UPDATE ERROR
    return <DeviceUpdate type="error" />
  }
  

  if(device.device_data.updSt === 1) {
    // UPDATE IN PROGRESS
    return <DeviceUpdate type="updating" />
  }


  if(device.device_data.updSt === 2) {
    // UPDATE COMPLETED, GETTING DATA...    
    return <DeviceUpdate type="updated" />
  }


  return (
    <div className="mb-screen-wrapper">
      <div className="mb-screen">
        <div className="mb-screen-overlay">

          <div className="mb-screen__temperature">
            {device.device_data.tem && device.device_data.tem.toFixed(1) + '°C'}
          </div>

          <div className="mb-screen__humidity">
            {device.device_data.hum} <IconScreenHumidity color="#ffffff" size={30} />
          </div>

          <div className="mb-screen__weather">
            {getWeatherIcon(device.device_data.ws)}
          </div>

          {
            // STATUS ICONS
            device.device_data.statusIco && (
              <div className="mb-screen__status-bar">
                <div className="mb-screen__nav-item nav-item--left">
                  { /* <IconStatusLeftArrow size={17} color="#ffffff" /> */ }
                </div>
                <div className="mb-screen__nav-item mb-screen__nav-items">
                  {
                    device.device_set_status && device.device_set_status.stat && device.device_set_status.stat.map((item: any, index: number) => {
                      return  <div key={index} className={classnames('mb-screen__status-bar-item', { 'status-bar-item--active': device.device_data.statusIco[index] === 1})}>
                        { 
                          item.icon && getStatusIcon({ icon_number: parseInt(item.icon), size: 24, color: '#ffffff' })
                        }
                      </div>
                    })
                  }
                </div>
                <div className="mb-screen__nav-item nav-item--right">
                  { /* <IconStatusRightArrow size={17}color="#ffffff" /> */ }
                </div>
              </div>
            )
          }

          {
            device.device_data.btnIco && (
              <>
                <div
                className={classnames('mb-screen__button mb-screen__button--one', { 'mb-screen__button--active': device.device_data.btnIco[0]})} onClick={ () => pressMagicboxButton(1, device.device_serial) }>
                  { 
                    device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[0] && device.device_set_btn.btn[0].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[0].icon, size: 34, color: '#ffffff' })
                  }
                </div>
                <div className={classnames('mb-screen__button mb-screen__button--two', { 'mb-screen__button--active': device.device_data.btnIco[1]})} onClick={ () => pressMagicboxButton(2, device.device_serial) }>
                  {
                    device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[1] && device.device_set_btn.btn[1].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[1].icon, size: 34, color: '#ffffff' })
                  }
                </div>
                <div className={classnames('mb-screen__button mb-screen__button--three', { 'mb-screen__button--active': device.device_data.btnIco[2]})} onClick={ () => pressMagicboxButton(3, device.device_serial) }>
                  {
                    device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[2] && device.device_set_btn.btn[2].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[2].icon, size: 34, color: '#ffffff' })
                  }
                </div>
                <div className={classnames('mb-screen__button mb-screen__button--four', { 'mb-screen__button--active': device.device_data.btnIco[3]})} onClick={ () => pressMagicboxButton(4, device.device_serial) }>
                  {
                    device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[3] && device.device_set_btn.btn[3].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[3].icon, size: 34, color: '#ffffff' })
                  }
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}


export default DeviceMagicBox