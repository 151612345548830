export const IconSwitchCross = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--switch-cross">
    <svg width={props.size ? props.size : '10px'} height={props.size ? props.size : '10px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#2A2C44'}>
                <path d="M15.9467213,11.9907787 L23.3652664,4.57223357 C24.1849385,3.75256144 24.1849385,2.42366807 23.3652664,1.60527666 L22.3762807,0.616291016 C21.5563525,-0.203637311 20.227459,-0.203637311 19.4090676,0.616291016 L11.9907787,8.03457993 L4.57223357,0.614754098 C3.75256144,-0.204918033 2.42366807,-0.204918033 1.60527666,0.614754098 L0.614754098,1.60373974 C-0.204918033,2.42366807 -0.204918033,3.75256144 0.614754098,4.57095285 L8.03457993,11.9907787 L0.616291016,19.4090676 C-0.203637311,20.2289959 -0.203637311,21.5578893 0.616291016,22.3762807 L1.60527666,23.3652664 C2.42494879,24.1849385 3.75384223,24.1849385 4.57223357,23.3652664 L11.9907787,15.9467213 L19.4090676,23.3652664 C20.2289959,24.1849385 21.5578893,24.1849385 22.3762807,23.3652664 L23.3652664,22.3762807 C24.1849385,21.5563525 24.1849385,20.227459 23.3652664,19.4090676 L15.9467213,11.9907787 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);



export const IconSwitchTick = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--switch-tick">
    <svg width={props.size ? props.size : '10px'} height={props.size ? props.size : '10px'} viewBox="0 0 24 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#000000'}>
                <path d="M9.18593001,18.2917913 C8.9569378,18.5221292 8.64451257,18.6506429 8.31997608,18.6506429 C7.99543958,18.6506429 7.68301435,18.5221292 7.45402214,18.2917913 L0.538277512,11.3749252 C-0.179425837,10.6572219 -0.179425837,9.49342105 0.538277512,8.77706343 L1.40423144,7.91088517 C2.12215912,7.19318182 3.28461422,7.19318182 4.00231757,7.91088517 L8.31997608,12.2287679 L19.9869169,0.561602871 C20.7048445,-0.156100478 21.8684211,-0.156100478 22.585003,0.561602871 L23.4509569,1.42778113 C24.1686603,2.14548448 24.1686603,3.30906098 23.4509569,4.02564293 L9.18593001,18.2917913 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);