import { Routes, Route } from 'react-router-dom'

import RoomsPage from './components/pages/Rooms'
import RoomsNewPage from './components/pages/RoomsNew'
import RoomAddPage from './components/pages/RoomsAdd'
import RoomEditPage from './components/pages/RoomsEdit'
import RoomDeletePage from './components/pages/RoomsDelete'
import RoomPage from './components/pages/Room'

import HistoryPage from './components/pages/History'
import HistoryDataTemperaturePage from './components/pages/HistoryDataTemperature'
import HistoryDataHumidityPage from './components/pages/HistoryDataHumidity'
import HistoryDataMovementPage from './components/pages/HistoryDataMovement'
import HistoryDataLightPage from './components/pages/HistoryDataLight'
import HistoryEventsAlarmPage from './components/pages/HistoryEventsAlarm'
import HistoryEventsSecurityPage from './components/pages/HistoryEventsSecurity'
import HistoryEventsScenarioPage from './components/pages/HistoryEventsScenario'
import HistoryEventsEventsPage from './components/pages/HistoryEventsEvents'

import HistoryEventsPage from './components/pages/HistoryEvents'
import HistoryDataPage from './components/pages/HistoryData'

import EnergyUsagePage from './components/pages/EnergyUsage'
import EnergyProductionPage from './components/pages/EnergyProduction'

import ScenariosPage from './components/pages/Scenarios'
import ScenariosEditPage from './components/pages/ScenariosEdit'
import ScenariosAddPage from './components/pages/ScenariosAdd'
import ScenariosDeletePage from './components/pages/ScenariosDelete'

import DevicesMagicBoxPage from './components/pages/DevicesMagicBox'
import DevicesMagicSwitchPage from './components/pages/DevicesMagicSwitch'

import DevicesAddPage from './components/pages/DevicesAdd'
import DevicesAddRegisterPage from './components/pages/DevicesAddRegister'
import DevicesDeletePage from './components/pages/DevicesDelete'

import DevicesSettingsPage from './components/pages/Device'
import DevicesSettingsGeneralPage from './components/pages/DevicesEdit'
import DevicesSettingsIoPage from './components/pages/DeviceSettingsIo'
import DevicesSettingsCornerButtonsPage from './components/pages/DeviceSettingsCornerButtons'
import DevicesSettingsStatusIconsPage from './components/pages/DeviceSettingsStatusIcons'
import DevicesSettingsScreensOrderPage from './components/pages/DeviceSettingsScreensOrder'
import DevicesSettingsAlarmClockPage from './components/pages/DeviceSettingsAlarmClock'
import DevicesSettingsAlarmPage from './components/pages/DeviceSettingsAlarm'
import DevicesSettingsStocksPage from './components/pages/DeviceSettingsStocks'
import DevicesSettingsWeatherPage from './components/pages/DeviceSettingsWeather'
import DevicesSettingsThermostatPage from './components/pages/DeviceSettingsThermostat'
import DevicesSettingsAboutPage from './components/pages/DeviceSettingsAbout'
import DevicesSettingsPresenceSensorPage from './components/pages/DeviceSettingsPresenceSensor'
import DevicesSettingsDeveloperPage from './components/pages/DeviceSettingsDeveloper'
import DevicesSettingsDeveloperSettingsPage from './components/pages/DeviceSettingsDeveloperSettings'

import UsersPage from './components/pages/Users'
import UsersAddPage from './components/pages/UsersAdd'
import UsersEditPage from './components/pages/UsersEdit'
import UsersDeletePage from './components/pages/UsersDelete'

import SettingsPage from './components/pages/Settings'
import SettingsWeatherPage from './components/pages/SettingsWeather'
import SettingsEnergyPricePage from './components/pages/SettingsEnergyPrice'
import SettingsSecurityPage from './components/pages/SettingsSecurity'
import SettingsNotificationsPage from './components/pages/SettingsNotifications'
import SettingsEmailPage from './components/pages/SettingsChangeEmail'
import SettingsChangePasswordPage from './components/pages/SettingsChangePassword'
import SettingsLogoutPage from './components/pages/SettingsLogout'
import SettingsRemoveAccountPage from './components/pages/SettingsRemoveAccount'


import AuthLoginPage from './components/pages/AuthLogin'
import AuthForgotPasswordPage from './components/pages/AuthForgotPassword'
import AuthResetPasswordPage from './components/pages/AuthResetPassword'
import AuthRegisterPage from './components/pages/AuthRegister'
import AuthRegisterConfirmationPage from './components/pages/AuthRegisterConfirmation'
import AuthResendConfirmationPage from './components/pages/AuthResendConfirmation'
import NotFoundPage from './components/pages/NotFound'
import AuthEmailChangeConfirmation from './components/pages/AuthEmailChangeConfirmation'


const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<RoomsPage />} />
    <Route path="/rooms" element={<RoomsNewPage />} />
    <Route path="/rooms/:id" element={<RoomPage />} />
    <Route path="/rooms/add" element={<RoomAddPage />} />
    <Route path="/rooms/edit/:id" element={<RoomEditPage />} />
    <Route path="/rooms/delete/:id" element={<RoomDeletePage />} />

    <Route path="/history" element={<HistoryPage />} />
    <Route path="/history/temperature" element={<HistoryDataTemperaturePage />} />
    <Route path="/history/humidity" element={<HistoryDataHumidityPage />} />
    <Route path="/history/movement" element={<HistoryDataMovementPage />} />
    <Route path="/history/light" element={<HistoryDataLightPage />} />
    <Route path="/history/alarm" element={<HistoryEventsAlarmPage />} />
    <Route path="/history/security" element={<HistoryEventsSecurityPage />} />
    <Route path="/history/scenario" element={<HistoryEventsScenarioPage />} />
    <Route path="/history/events" element={<HistoryEventsEventsPage />} />

    <Route path="/history/events" element={<HistoryEventsPage />} />
    <Route path="/history/data" element={<HistoryDataPage />} />

    <Route path="/energy/usage" element={<EnergyUsagePage />} />
    <Route path="/energy/production" element={<EnergyProductionPage />} />

    <Route path="/scenarios" element={<ScenariosPage />} />
    <Route path="/scenarios/add" element={<ScenariosAddPage />} />
    <Route path="/scenarios/edit/:id" element={<ScenariosEditPage />} />
    <Route path="/scenarios/delete/:id" element={<ScenariosDeletePage />} />

    <Route path="/devices/magicbox" element={<DevicesMagicBoxPage />} />
    <Route path="/devices/magicswitch" element={<DevicesMagicSwitchPage />} />

    <Route path="/devices/add" element={<DevicesAddPage />} />
    <Route path="/devices/add/:id" element={<DevicesAddPage />} />
    <Route path="/devices/register/:id" element={<DevicesAddRegisterPage />} />
    <Route path="/devices/delete/:id" element={<DevicesDeletePage />} />

    <Route path="/devices/settings/:id" element={<DevicesSettingsPage />} />
    <Route path="/devices/settings/general/:id" element={<DevicesSettingsGeneralPage />} />
    <Route path="/devices/settings/screens-order/:id" element={<DevicesSettingsScreensOrderPage />} />
    <Route path="/devices/settings/io/:id" element={<DevicesSettingsIoPage />} />
    <Route path="/devices/settings/corner-buttons/:id" element={<DevicesSettingsCornerButtonsPage />} />
    <Route path="/devices/settings/status-icons/:id" element={<DevicesSettingsStatusIconsPage />} />
    <Route path="/devices/settings/alarm-clock/:id" element={<DevicesSettingsAlarmClockPage />} />
    <Route path="/devices/settings/alarm/:id" element={<DevicesSettingsAlarmPage />} />
    <Route path="/devices/settings/stocks/:id" element={<DevicesSettingsStocksPage />} />
    <Route path="/devices/settings/weather/:id" element={<DevicesSettingsWeatherPage />} />
    <Route path="/devices/settings/thermostat/:id" element={<DevicesSettingsThermostatPage />} />
    <Route path="/devices/settings/about/:id" element={<DevicesSettingsAboutPage />} />
    <Route path="/devices/settings/presence-sensor/:id" element={<DevicesSettingsPresenceSensorPage />} />
    <Route path="/devices/settings/developer/:id" element={<DevicesSettingsDeveloperPage />} />
    <Route path="/devices/settings/developer-settings/:id" element={<DevicesSettingsDeveloperSettingsPage />} />

    <Route path="/users" element={<UsersPage />} />
    <Route path="/users/add" element={<UsersAddPage />} />
    <Route path="/users/edit/:id" element={<UsersEditPage />} />
    <Route path="/users/delete/:id" element={<UsersDeletePage />} />

    <Route path="/settings" element={<SettingsPage />} />
    <Route path="/settings/weather" element={<SettingsWeatherPage />} />
    <Route path="/settings/energy-price" element={<SettingsEnergyPricePage />} />
    <Route path="/settings/security" element={<SettingsSecurityPage />} />
    <Route path="/settings/notifications" element={<SettingsNotificationsPage />} />
    <Route path="/settings/email" element={<SettingsEmailPage />} />
    <Route path="/settings/password" element={<SettingsChangePasswordPage />} />
    <Route path="/settings/logout" element={<SettingsLogoutPage />} />
    <Route path="/settings/remove-account" element={<SettingsRemoveAccountPage />} />

    <Route path="/login" element={<AuthLoginPage />} />
    <Route path="/forgot-password" element={<AuthForgotPasswordPage />} />
    <Route path="/reset-password/:token" element={<AuthResetPasswordPage />} />

    <Route path="/register" element={<AuthRegisterPage />} />
    <Route path="/confirm-registration/:token" element={<AuthRegisterConfirmationPage />} />
    <Route path="/resend-confirmation" element={<AuthResendConfirmationPage />} />
    <Route path="/confirm-email-change/:token" element={<AuthEmailChangeConfirmation />} />

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)


export default AppRoutes