import React from 'react'
import withAuthorization from '../auth/withAuthorization'
import { connect } from 'react-redux'
import { addNewPerson } from '../../actions/person'
import { fetchAllRooms } from '../../actions/room'
import { fetchAllDevices, fetchDeviceById, updateDeviceAlarm, updateAndGetAlarmSettings } from '../../actions/device'
import { wsUpdateAlarmSettings } from '../../actions/ws'
import classnames from 'classnames'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import { IconVisibility, IconVisibilityOff, IconLock, IconEmail, IconPin, IconUsers, IconName, IconRoom } from '../partials/IconsForm'
import { IconClose } from '../partials/Icons'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import { numberToRole } from '../../helpers/roles'
import { RootState } from '../../reducers'
import fetchStates from '../../types/fetchStates'
import withRouter from '../partials/WithRouter'
import Alert from '../partials/Alert'
import { danger } from '../../helpers/notifications'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'


const requiresPermissions = 'manage_users'


interface UsersAddProps {
  router: { navigate: (to: string) => any },
  auth: { person: { role: number } },
  person: {status: string, fields: Array<string>},
  room: {status: string, rooms: { room_id: string }},
  device: {status: string, notifications_settings: any, devices_alarm_settings: any, devices:{}, device: any},
  ws: { wsConnected: boolean },
  pushPersonMessage: (options: {status: string, fields: Array<string>, message: { msg: string; }[]}) => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  fetchAllRooms: () => Promise<void>,
  fetchAllDevices: () => Promise<void>,
  addNewPerson: (options: { person: {
    role: string,
    email: string,
    password: string,
    name: string,
    alarm_pin: string,
    rooms: Array<any>,
    notify: boolean,
  } }) => Promise<void>,
  updateDeviceAlarm: (options: {id: string, alarm_settings: { tst: number, time_out: string, time_in: string, sensitivity: string }}) => Promise<void>,
  wsUpdateAlarmSettings: (options: { device_serial: string, alarm_settings: any }) => Promise<void>,
  updateAndGetAlarmSettings: () => Promise<void>,
}


interface UsersAddState {
  role: string,
  email: string,
  password: string,
  passwordShow: boolean,
  name: string,
  alarm_pin: string,
  notify: boolean,
  formSubmitted: boolean,
  rooms: Array<any>,
  devices: Array<any>,
  selectedRooms: Array<any>,
  room: any,
}


export class UsersAdd extends React.Component<UsersAddProps & UsersAddState> {


  state = {
    role: '2',
    email: '',
    password: '',
    passwordShow: false,
    name: '',
    alarm_pin: '',
    notify: true,
    rooms: [],
    devices: [],
    selectedRooms: [],
    formSubmitted: false,
  }


  componentDidMount() {
    this.fetchAllRooms()
    this.fetchAllDevices()
  }


  fetchAllRooms = async () => {
    await this.props.fetchAllRooms()
    if(this.props.room.status === fetchStates.success) {
      const { rooms } = this.props.room
      this.setState({ rooms })
    }
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      const { devices } = this.props.device
      this.setState({ devices })
    }
  }


  handleSelectRoom = (id: string) => {
    const filteredRooms = this.state.rooms.filter((room:{room_id: string}) => {
      if(room.room_id === id) {
        let selectedRooms: any[] = []
        selectedRooms.push(...this.state.selectedRooms, room)
        this.setState({ selectedRooms })
      }
      return room.room_id !== id
    })
    this.setState({ rooms: filteredRooms })
  }


  handleRoomRemove = (id: string) => {
    const filteredRooms = this.state.selectedRooms.filter((room:{room_id:string}) => {
      if(room.room_id === id) {
        let rooms: any[] = []
        rooms.push(...this.state.rooms, room)
        this.setState({ rooms })
      }
      return room.room_id !== id
    })
    this.setState({ selectedRooms: filteredRooms })
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { role, email, password, name, alarm_pin, notify, selectedRooms } = this.state;
    const selectedRoomsArray = selectedRooms.map((room:{room_id:string}) => room.room_id)
    await this.props.addNewPerson({ 
      person: { 
        role,
        email, 
        password,
        name,
        alarm_pin,
        rooms: selectedRoomsArray,
        notify
      } 
    })
    if(this.props.person.status === fetchStates.success) {
      await this.props.updateAndGetAlarmSettings()
      if(this.props.device.status === fetchStates.success) {
        const { devices_alarm_settings } = this.props.device;
        // check at least one device exists
        if(devices_alarm_settings.length > 0) {
          // check we have wsConnection
          if(this.props.ws.wsConnected === true) {
            devices_alarm_settings.forEach((device: {device_serial: string, device_set_alarm: any, device_id: string}) => {
              // make sure we have MB category
              const prefixToCheck = device.device_serial.substring(0, 2).toUpperCase();
              if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
                this.props.wsUpdateAlarmSettings({ device_serial: device.device_serial, alarm_settings: device.device_set_alarm })
              }
            })
          } else {
            danger('You are disconnected from the WS server')
          }
        }
      }
      this.props.router.navigate('/users')
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { fields, status } = this.props.person;
    const { role, email, password, name, notify, alarm_pin, rooms, selectedRooms, passwordShow, formSubmitted } = this.state;
    const creatorRole = this.props.auth.person.role;

    return (
      <TemplatePage title="Users → Add User" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>

          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="role">
                Role (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconUsers color="#A19BCD" />
                </span>
                <select 
                  name="role" 
                  value={role}
                  onChange={e => this.setState({ role: parseInt(e.target.value) })}
                  className={classnames('', { 'input-error': fields && fields.includes('role') })}
                >
                  {[1].includes(creatorRole) && <option value={2}>{numberToRole(2)}</option>}
                  {[1].includes(creatorRole) && <option value={1}>{numberToRole(1)}</option>}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">
                Email (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconEmail color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="email"
                  id="email" 
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('email') })}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="password">
                Password (required)
              </label>
              <div className="input-group">
                <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                  { passwordShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
                <span className="input-icon">
                  <IconLock color="#A19BCD" />
                </span>
                <input 
                  type={ passwordShow === false ? 'password' : 'text' } 
                  name="password"
                  id="password" 
                  value={ password }
                  onChange={e => this.setState({ password: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('password') })}
                />
              </div>
              <PasswordStrengthMeter password={password} />
            </div>


            <div className="form-group">
              <label htmlFor="name">
                Name
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconName color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="name"
                  id="name" 
                  value={ name }
                  onChange={e => this.setState({ name: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('name') })}
                />
              </div>
            </div>


            <div className="form-group">
              <label htmlFor="alarm_pin">
                Alarm PIN
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconPin color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ alarm_pin }
                  onChange={e => this.setState({ alarm_pin: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('alarm_pin') })}
                />
              </div>
            </div>


            { role === '2' && (
              <div className="form-group">
                <label htmlFor="rooms">
                  Can manage rooms
                </label>
                <div className="selected-tags">
                  { selectedRooms.map((room:{room_id: string, room_name: string}) => (
                    <span className="tag tag--small" key={room.room_id}>
                      <span className="tag__title">{room.room_name}</span>
                      <span className="tag__remove" onClick={e => this.handleRoomRemove(room.room_id)}>
                        <IconClose color="#ffffff"/>
                      </span>
                    </span>
                  ))}
                </div>
                { rooms.length > 0 ? (
                  <div className="input-group">
                    <span className="input-icon">
                      <IconRoom color="#A19BCD" />
                    </span>
                    <select 
                      name="rooms" 
                      onChange={e => this.handleSelectRoom(e.target.value)}
                      className={classnames('', { 'input-error': fields && fields.includes('rooms') })}
                    >
                      <option key="0">Select room</option>
                      { 
                        rooms.map((room:{room_id:string, room_name:string}) => <option key={room.room_id} value={room.room_id}>{room.room_name}</option>)
                      }
                    </select>
                  </div>
                ) : (
                  <Alert type="info">There are currently no rooms to manage. Please create at least one room.</Alert>
                )}
              </div>
            )}


            <div className="form-group">
              <label onClick={e => this.setState({ notify: !notify })}>
                <div className="label-title">
                  Notify user about the new account
                </div>
                <SwitchTrueFalseHorizontal switchState={notify} />
              </label>
            </div>


            <div className="content-box__footer">
              <input 
                type="submit" 
                value="Add User" 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>

          </form>
        </ContentMain>
      </TemplatePage>
    )
  }

}



export default withAuthorization(withRouter(connect(
  ({ auth, person, room, device, ws }: RootState) => ({ auth, person, room, device, ws }),
  { addNewPerson, fetchAllRooms, fetchAllDevices, fetchDeviceById, updateDeviceAlarm, wsUpdateAlarmSettings, updateAndGetAlarmSettings }
)(UsersAdd)), requiresPermissions);