import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDevice } from '../../actions/device'
import { fetchAllRooms } from '../../actions/room'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Alert from '../partials/Alert'
import { IconName, IconDevice, IconRoom } from '../partials/IconsForm'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import InfoBox from '../partials/InfoBox'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'


interface DevicesEditProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  room: any,
  fetchAllRooms: () => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  updateDevice: (options: {device_id: string, device_serial: string, device_title: string, device_master: boolean, room_id: string}) => Promise<void>,
}


interface DevicesEditState {
  device: { room_id: string, device_id: string, device_title: string, device_serial: string }
  rooms: { room_id: string }[],
  device_master: boolean,
  masterByDefault: boolean,
  room_id: string,
  dataReady: boolean,
  formSubmitted: boolean,
  noData: boolean,
}


export class DevicesEdit extends React.Component<DevicesEditProps, DevicesEditState> {


  state = {
    device: {
      room_id: '',
      device_id: '',
      device_title: '',
      device_serial: '',
    },
    rooms: [],
    device_master: false,
    masterByDefault: false,
    room_id: '',
    dataReady: false,
    formSubmitted: false,
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      this.setState({ device, masterByDefault: device.device_master, device_master: device.device_master, room_id: device.room_id, noData: false }, () => this.fetchAllRooms())
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  fetchAllRooms = async () => {
    await this.props.fetchAllRooms()
    if(this.props.room.status === fetchStates.success) {
      const { rooms } = this.props.room;
      const { device } = this.props.device;
      const device_capabilities = checkDeviceCapabilities(device.device_serial.substring(0, 2))
      if(device_capabilities.device_category === 'MB') {
        const filteredEmptyRooms = rooms.filter((room:any) => room.devices_count === '0')
        const filteredCurrentRoom = rooms.filter((room:any) => room.room_id === this.state.room_id)
        this.setState({ rooms: [...filteredEmptyRooms, ...filteredCurrentRoom], dataReady: true })
      } else {
        this.setState({ rooms, dataReady: true })
      }
    }
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true })
    const { device, device_master, room_id } = this.state
    await this.props.updateDevice({ device_id: device.device_id, device_serial: device.device_serial, device_title: device.device_title, device_master, room_id })
    if(this.props.device.status === fetchStates.success) {
      this.setState({ formSubmitted: false });
    }
  }


  render() {

    const { device, rooms, device_master, masterByDefault, formSubmitted, noData, room_id } = this.state;
    const { fields } = this.props.device;

    if(noData) {
      return (
        <TemplatePage title="Device → Edit Device" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Edit Device" buttonBack={true} navigate={this.props.router.navigate} button={true} buttonLink={`/devices/delete/${this.props.router.params.id}`} buttonType="delete" buttonText="Remove Device">
        <ContentMain>
          { rooms && rooms.length === 0 ? (

              <InfoBox>
                <p>In order to be able to add Magic Box you must create a room first.</p>
                <Link to={`/rooms/add`} className="btn btn--primary">Add room now</Link>
              </InfoBox>

            ) : (

              <>
                <h3>{device.device_title} ({device.device_serial})</h3>
                <form className="form">
                  <div className="form-section">

                    <div className="form-group">
                      <label htmlFor="device_serial">
                        Serial number
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconDevice color="#A19BCD" />
                        </span>
                        <input 
                          type="text" 
                          name="device_serial"
                          id="device_serial" 
                          value={ device.device_serial }
                          className={classnames('', { 'input-error': fields && fields.includes('device_serial') })}
                          disabled={true}
                        />
                      </div>
                    </div>


                    <div className="form-group">
                      <label htmlFor="device_title">
                        Title (required)
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconName color="#A19BCD" />
                        </span>
                        <input 
                          type="text" 
                          name="device_title"
                          id="device_title" 
                          value={ device.device_title }
                          onChange={e => this.setState({ device: { ...device, device_title: e.target.value } })}
                          className={classnames('', { 'input-error': fields && fields.includes('device_title') })}
                        />
                      </div>
                    </div>


                    <div className="form-group">
                      <label htmlFor="room_id">
                        Room
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconRoom color="#A19BCD" />
                        </span>
                        <select 
                          name="room_id" 
                          onChange={e => this.setState({ room_id: e.target.value })}
                          className={classnames('', { 'input-error': fields && fields.includes('room_id') })}
                          value={room_id}
                        >
                          { 
                            rooms && rooms.map((room: {room_id: string, room_name: string }) => <option key={room.room_id} value={room.room_id}>{room.room_name}</option>)
                          }
                        </select>
                      </div>
                    </div>

                    
                    <div className="form-group">
                      {
                        device_master === false && masterByDefault === false ? (
                          <label htmlFor="enabled" onClick={e => this.setState({ device_master: !device_master })}>
                            <div className="label-title">
                              Set this device as the master
                            </div>
                            <SwitchTrueFalseHorizontal switchState={device_master} />
                          </label>
                        ) : (
                          <Alert type="info">This device is currently set as the master.</Alert>
                        )
                      }
                    </div>

                  </div>
                </form>
                <div className="form__footer">
                  <input 
                    onClick={() => this.handleButtonSubmit()}
                    type="submit" 
                    value="Update Device Settings" 
                    className="btn btn--primary btn--large"
                    disabled={ formSubmitted ? true : false }
                  />
                </div>
              </>

            )
          }
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, room }: RootState) => ({ device, room }),
  { fetchDeviceById, updateDevice, fetchAllRooms }
)(DevicesEdit)))