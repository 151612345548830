import { DATA } from '../types';
import fetchStates from '../types/fetchStates';


interface DataState {
  tempData: [],
  humData: [],
  lightData: [],
  moveData: [],
  chart_data: [],
  chart_data_count: number,
  eventsHistory: [],
  eventsHistoryCount: number,
  movementHistory: [],
  movementHistoryCount: number,
  alarmsNewCount: number,
  locations: [],
  stocks: [],
  weather_status: {}
}


const DEFAULT_DATA: DataState = {
  tempData: [],
  humData: [],
  lightData: [],
  moveData: [],
  chart_data: [],
  chart_data_count: 0,
  eventsHistory: [],
  eventsHistoryCount: 0,
  movementHistory: [],
  movementHistoryCount: 0,
  alarmsNewCount: 0,
  locations: [],
  stocks: [],
  weather_status: {}
};

const data = (state = DEFAULT_DATA, action: any) => {
  switch(action.type) {
    case DATA.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case DATA.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: any) => item.path && item.path.toString())
      };
    case DATA.FETCH_DATA_SERIAL_TYPE_RANGE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        chart_data: action.chart_data,
        chart_data_count: action.chart_data_count
      };
    case DATA.FETCH_EVENTS_HISTORY_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        eventsHistory: action.eventsHistory,
        eventsHistoryCount: action.eventsHistoryCount
      };
    case DATA.FETCH_MOVEMENT_HISTORY_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        chart_data: action.chart_data,
        chart_data_count: action.chart_data_count
      };
    case DATA.FETCH_ALARMS_NEW_COUNT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        alarmsNewCount: action.alarmsNewCount,
      }
    case DATA.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        locations: action.locations
      };
    case DATA.FETCH_STOCKS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        stocks: action.stocks
      };
    case DATA.FETCH_DELETE_EVENTS_SUCCESS:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case DATA.FETCH_WEATHER_BY_LOCATION_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        weather_status: action.weather_status
      };
    case DATA.FETCH_HISTORY_ENERGY_CHART_SUCCESS: 
      return {
        ...state,
        status: fetchStates.success,
        series: action.series,
        categories: action.categories
      }
    case DATA.FETCH_HISTORY_ENERGY_USAGE_SUCCESS: 
    return {
      ...state,
      status: fetchStates.success,
      energy_usage_today: action.energy_usage_today,
      energy_usage_this_month: action.energy_usage_this_month,
      energy_usage_previous_month: action.energy_usage_previous_month,
      energy_usage_this_year: action.energy_usage_this_year,
      energy_usage_cost_today: action.energy_usage_cost_today, 
      energy_usage_cost_this_month: action.energy_usage_cost_this_month, 
      energy_usage_cost_previous_month: action.energy_usage_cost_previous_month,
      energy_usage_cost_this_year: action.energy_usage_cost_this_year
    }
    case DATA.FETCH_HISTORY_ENERGY_PRODUCED_SUCCESS: 
    return {
      ...state,
      status: fetchStates.success,
      energy_produced_today: action.energy_produced_today,
      energy_produced_this_month: action.energy_produced_this_month,
      energy_produced_this_year: action.energy_produced_this_year,
      energy_produced_cost_today: action.energy_produced_cost_today, 
      energy_produced_cost_this_month: action.energy_produced_cost_this_month, 
      energy_produced_cost_this_year: action.energy_produced_cost_this_year
    }
    case DATA.FETCH_HISTORY_ENERGY_CONSUMED_SUCCESS: 
    return {
      ...state,
      status: fetchStates.success,
      energy_consumed_today: action.energy_consumed_today,
      energy_consumed_this_month: action.energy_consumed_this_month,
      energy_consumed_this_year: action.energy_consumed_this_year,
      energy_consumed_cost_today: action.energy_consumed_cost_today, 
      energy_consumed_cost_this_month: action.energy_consumed_cost_this_month, 
      energy_consumed_cost_this_year: action.energy_consumed_cost_this_year
    }
    case DATA.FETCH_DEV_MOVEMENT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        devMovement: action.devMovement,
        devMovementCount: action.devMovementCount
      };
    case DATA.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};

export default data;