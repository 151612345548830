import React from 'react'
import { connect } from 'react-redux'
import { fetchAllRooms, addNewRoom } from '../../actions/room'
import { fetchAllDevices } from '../../actions/device'
import { RootState } from '../../reducers'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Alert from '../partials/Alert'
import { IconName, IconDevice } from '../partials/IconsForm'
import { IconClose } from '../partials/Icons'
import { room_icons } from '../../helpers/roomIcons'
import fetchStates from '../../types/fetchStates'
import { motion } from 'framer-motion'
import { roomsContainer } from '../../helpers/framer'
import classnames from 'classnames'
import withRouter from '../partials/WithRouter'


interface RoomAddProps {
  router: {navigate: (to: string) => any},
  auth: { person: { account_id: string } },
  room: { status: string, fields: Array<string>, rooms: {}[], roomsCount: number },
  device: { devices: any},
  fetchAllDevices: (options: { limit: number, skip: number }) => Promise<void>,
  fetchAllRooms: () => Promise<any>,
  fetchAllRoomAdd: () => Promise<any>,
  addNewRoom: (options: {room_order: number, room_name: string, room_image: number, account_id: string, assigned_devices: Array<string>}) => Promise<any>,
}


interface RoomAddState {
  formSubmitted: boolean,
  room_order: number, 
  room_name: string, 
  room_image: number, 
  roomsCount: number,
  free_devices: Array<number>,
  assigned_devices: Array<number>,
}


export class RoomAdd extends React.Component<RoomAddProps, RoomAddState> {


  state = {
    formSubmitted: false,
    room_order: 0, 
    room_name: '', 
    room_image: 1, 
    roomsCount: 0,
    free_devices: [],
    assigned_devices: []
  }


  componentDidMount = async () => {
    await this.fetchAllDevices()
    await this.fetchAllRooms()
  }

  fetchAllRooms = async() => {
    await this.props.fetchAllRooms()
    if(this.props.room.status === fetchStates.success) {
      const { roomsCount } = this.props.room
      this.setState({ room_order: roomsCount + 1 })
    }
  }

  
  fetchAllDevices = async () => {
    await this.props.fetchAllDevices({ limit: 1000, skip: 0 })
    const { devices } = this.props.device
    const free_devices = devices.filter((device:{room_id:string}) => device.room_id === null)
    const assigned_devices: any[] = []
    this.setState({ free_devices, assigned_devices })
  }


  handleSelectImage = (image: number) => {
    this.setState({ room_image: image })
  }


  handleSubmitAddRoom = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    const { room_order, room_name, room_image, assigned_devices } = this.state
    const assigned_devices_array = assigned_devices.map((device: {device_id: string}) => device.device_id)
    await this.props.addNewRoom({ room_order, room_name, room_image, account_id: this.props.auth.person.account_id, assigned_devices: assigned_devices_array })
    if(this.props.room.status === fetchStates.success) {
      this.setState({ room_name: '', formSubmitted: false })
      this.props.router.navigate('/')
    }
  }


  removeDevice = (device: {device_id: string, device_title: string}) => {
    const assigned_devices = this.state.assigned_devices.filter((item:{device_id: string}) => device.device_id !== item.device_id)
    let free_devices: any[] = []
    free_devices.push(...this.state.free_devices, device)
    this.setState({ assigned_devices, free_devices })
  }


  assignDevice = (device_id: string) => {
    if(device_id) {
      const device = this.state.free_devices.filter((item:{device_id: string}) => device_id === item.device_id)
      const free_devices = this.state.free_devices.filter((item:{device_id: string}) => device_id !== item.device_id)
      const assigned_devices = this.state.assigned_devices
      assigned_devices.push(device[0])
      this.setState({ assigned_devices, free_devices })
    }
  }


  render() {

    const { room_name, room_image, free_devices, assigned_devices, formSubmitted } = this.state;
    const { fields, status } = this.props.room;

    return (
      <TemplatePage title="Rooms → Add Room" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <motion.div
            variants={roomsContainer}
            initial="hidden"
            animate="visible"
          >
            <form className="form" onSubmit={this.handleSubmitAddRoom}>
              <div className="form-section">

                <div className="form-group">
                  <label htmlFor="room_name">
                    Room name (required)
                  </label>
                  <div className="input-group">
                    <span className="input-icon">
                      <IconName color="#A19BCD" />
                    </span>
                    <input 
                      type="text" 
                      name="room_name"
                      id="room_name" 
                      value={ room_name }
                      onChange={e => this.setState({ room_name: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('room_name') })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="room_image">
                    Select room image (required)
                  </label>
                  <div className="image-selector">
                    {
                      room_icons && room_icons.map(image => (
                        <motion.div 
                          key={image.id} 
                          className={classnames('image-selector__image-wrapper', { 'image-selector__image--selected': room_image === image.id })} 
                          onClick={() => this.handleSelectImage(image.id)}
                          whileHover={{ scale: 1.05 }}
                          transition={{ duration: 0.05 }}
                        >
                          <div className="image-selector__image">{image.icon}</div>
                        </motion.div>
                      ))
                    }
                    <div className="image-selector__clear" />
                  </div>
                </div>


                <div className="form-group">
                  <label htmlFor="ms_devices">
                    Add device to the room (required)
                  </label>
                  <div className="assigned-items">
                  {
                    assigned_devices && assigned_devices.map((item:{ device_id: string, device_title: string }) => 
                      <motion.span 
                        className="tag tag--small" 
                        key={item.device_id}
                        whileHover={{ scale: 1.08 }}
                        transition={{ duration: 0.05 }}
                      >
                        <span className="tag__title">{item.device_title}</span>
                        <span className="tag__remove" onClick={e => this.removeDevice(item)}>
                          <IconClose color="#ffffff"/>
                        </span>
                      </motion.span>
                    )
                  }
                  </div>
                  {
                    free_devices && free_devices.length > 0 ? (
                      <div className="input-group">
                        <span className="input-icon">
                          <IconDevice color="#A19BCD" />
                        </span>
                        <select 
                          onChange={e => this.assignDevice(e.target.value)}
                          className={classnames('', { 'input-error': fields && fields.includes('assigned_devices[0]') })}
                        >
                          <option key="0" value="">Select Device</option>
                          {
                            free_devices && free_devices.length > 0 && free_devices.map((device:{device_id: string, device_title: string}) => 
                              <option key={device.device_id} value={device.device_id}>{device.device_title}</option>
                            )
                          }
                        </select>
                      </div> ) : ( <Alert type="info">There are no devices left to be assigned to this room. Please add more devices into the application.</Alert> )
                  }
                </div>

                <div className="form-group">
                  <input 
                    type="submit" 
                    value="Add Room" 
                    className="btn btn--primary btn--large"
                    disabled={ formSubmitted && status === 'fetching' ? true : false }
                  />
                </div>

              </div>
            </form>
          </motion.div>
        </ContentMain>
      </TemplatePage>
    )

  }

}


export default withAuthorization(withRouter(connect(
  ({ room, device, auth }: RootState) => ({ room, device, auth }),
  { fetchAllRooms, addNewRoom, fetchAllDevices }
)(RoomAdd)))