import React from 'react'
import { connect } from 'react-redux'
import { checkAuth, removeAccount } from '../../actions/auth'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import CardAlert from '../partials/CardAlert'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import { IconEmail } from '../partials/IconsForm'
import fetchStates from '../../types/fetchStates'


interface SettingsRemoveAccountProps {
  router: { location: string, navigate: (to: string) => any },
  auth: { person: { email: string, account_id: string, person_id: string }, status: string },
  removeAccount: (options: { account_id: string, person_id: string }) => Promise<void>,
}


interface SettingsRemoveAccountState {
  email: string,
  current_email: string,
  formSubmitted: boolean,
}


export class SettingsRemoveAccount extends React.Component<SettingsRemoveAccountProps, SettingsRemoveAccountState> {


  state = {
    email: '',
    current_email: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.setState({ email: this.props.auth.person.email })
  }


  handleOnSubmit = async () => {
    await this.props.removeAccount({ account_id: this.props.auth.person.account_id, person_id: this.props.auth.person.person_id })
    if(this.props.auth.status === fetchStates.success) {
      this.props.router.navigate('/')
    }
  }


  render() {

    const { status } = this.props.auth
    const { email, current_email, formSubmitted } = this.state

    return (
      <TemplatePage title="Settings → Remove Account" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <CardAlert title="Danger zone!">
            <p>You are about to remove your profile from the MagicBox app. Access to the app and all the historic data will be disabled. This action can not be undone. Please confirm your decision by entering your email address.</p>
            <form className="form" onSubmit={this.handleOnSubmit}>
              <div className="form-section">
                <div className="form-group">
                  <label htmlFor="email">
                    Current Email
                  </label>
                  <div className="input-group">
                    <span className="input-icon">
                      <IconEmail color="#A19BCD" />
                    </span>
                    <input 
                      type="text" 
                      name="current_email"
                      id="current_email" 
                      value={ current_email }
                      onChange={e => this.setState({ current_email: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input 
                    type="submit" 
                    value="Remove Account" 
                    className="btn btn--primary"
                    disabled={ formSubmitted && status === 'fetching' ? true : false || current_email !== email ? true : false }
                  />
                </div>
              </div>
            </form>
          </CardAlert>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { checkAuth, removeAccount }
)(SettingsRemoveAccount)))