import React from 'react';
import { connect } from 'react-redux';
import { fetchDeviceById } from '../../actions/device';
import { fetchDevSettingsByDeviceId, updateDevSettingsByDeviceId } from '../../actions/developer'
import { wsGetDevDevicesSettings } from '../../actions/ws';
import withAuthorization from '../auth/withAuthorization';
import TemplatePage from '../templates/TemplatePage';
import withRouter from '../partials/WithRouter';
import fetchStates from '../../types/fetchStates';
import ContentMain from '../partials/ContentMain';
import TabNavDeveloper from '../partials/TabNavDeveloper';
import classnames from 'classnames';
import { IconIO } from '../partials/IconsForm';
import { RootState } from '../../reducers';
import { danger } from '../../helpers/notifications';
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities';
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal';


interface SettingsDeveloperSettingsProps {
  router: {location: string, navigate: (to: string) => any, params: { id: string }},
  updateDevSettingsByDeviceId: (options: { device_id: string, dev_settings: any }) => Promise<void>,
  fetchDevSettingsByDeviceId: (device_id: string) => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  wsGetDevDevicesSettings: (options: { device_serial: string, dev_settings: any }) => Promise<void>,
  device: any,
  developer: any,
  ws: any,
}


interface SettingsDeveloperSettingsState {
  formSubmitted: boolean,
  device_serial: string,
  radar_operating_mode: string,
  radar_sensitiveness: string,
  radar_gain: string,
  radar_measurement_mode: string,
  radar_measurement_time: string,
  number_of_td_pulses: string,
  number_of_tp_pulses: string,
  dev_act: number
}


export class SettingsDeveloperSettings extends React.Component<SettingsDeveloperSettingsProps, SettingsDeveloperSettingsState> {


  state = {
    formSubmitted: false,
    device_serial: '',
    radar_operating_mode: '0',
    radar_sensitiveness: '0',
    radar_gain: '0',
    radar_measurement_mode: '0',
    radar_measurement_time: '0',
    number_of_td_pulses: '0',
    number_of_tp_pulses: '0',
    dev_act: 0
  }


  async componentDidMount(): Promise<void> {
    await this.fetchDevSettingsByDeviceId();
    await this.fetchDeviceById();
  }


  fetchDevSettingsByDeviceId = async () => {
    await this.props.fetchDevSettingsByDeviceId(this.props.router.params.id);
    if(this.props.developer.status === fetchStates.success) {
      console.log(this.props.developer.dev_settings.empty)
      if(!this.props.developer.dev_settings.empty) {
        const { radar_operating_mode, radar_sensitiveness, radar_gain, radar_measurement_mode, radar_measurement_time, number_of_td_pulses, number_of_tp_pulses, dev_act } = this.props.developer.dev_settings
        this.setState({ radar_operating_mode, radar_sensitiveness, radar_gain, radar_measurement_mode, radar_measurement_time, number_of_td_pulses, number_of_tp_pulses, dev_act });
      }
    }
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id);
    if(this.props.device.status === fetchStates.success) {
      const { device_serial } = this.props.device.device;
      this.setState({ device_serial });
    }
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { device_serial, radar_operating_mode, radar_sensitiveness, radar_gain, radar_measurement_mode, radar_measurement_time, number_of_td_pulses, number_of_tp_pulses, dev_act } = this.state;
    const dev_settings = { device_serial, radar_operating_mode, radar_sensitiveness, radar_gain, radar_measurement_mode, radar_measurement_time, number_of_td_pulses, number_of_tp_pulses, dev_act };
    await this.props.updateDevSettingsByDeviceId({ device_id: this.props.router.params.id, dev_settings });
    // check we have wsConnection
    if(this.props.ws.wsConnected === true) {
      // make sure we have MB category
      const prefixToCheck = device_serial.substring(0, 2).toUpperCase();
      if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
        this.props.wsGetDevDevicesSettings({ device_serial, dev_settings });
      }
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { radar_operating_mode, radar_sensitiveness, radar_gain, radar_measurement_mode, radar_measurement_time, number_of_td_pulses, number_of_tp_pulses, formSubmitted, dev_act } = this.state;
    const { fields, status } = this.props.developer;

    return (
      <TemplatePage title="Developer" buttonBack={true} navigate={this.props.router.navigate}>
        <TabNavDeveloper location={this.props.router.location} id={this.props.router.params.id} />
        <ContentMain>
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="alarm_pin">
                Radar operating mode
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconIO color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ radar_operating_mode }
                  onChange={e => this.setState({ radar_operating_mode: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('radar_operating_mode') })}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="alarm_pin">
                Radar sensitiveness
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconIO color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ radar_sensitiveness }
                  onChange={e => this.setState({ radar_sensitiveness: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('radar_sensitiveness') })}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="alarm_pin">
                Radar gain
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconIO color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ radar_gain }
                  onChange={e => this.setState({ radar_gain: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('radar_gain') })}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="alarm_pin">
                Radar measurement mode
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconIO color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ radar_measurement_mode }
                  onChange={e => this.setState({ radar_measurement_mode: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('radar_measurement_mode') })}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="alarm_pin">
                Radar measurement time
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconIO color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ radar_measurement_time }
                  onChange={e => this.setState({ radar_measurement_time: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('radar_measurement_time') })}
                />
              </div>
            </div>


            <div className="form-group">
              <label htmlFor="alarm_pin">
                Number of TD pulses
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconIO color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ number_of_td_pulses }
                  onChange={e => this.setState({ number_of_td_pulses: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('number_of_td_pulses') })}
                />
              </div>
            </div>


            <div className="form-group">
              <label htmlFor="alarm_pin">
                Number of TP pulses
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconIO color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ number_of_tp_pulses }
                  onChange={e => this.setState({ number_of_tp_pulses: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('number_of_tp_pulses') })}
                />
              </div>
            </div>


            <div className="form-group">
              <label htmlFor="enabled" onClick={() => this.setState({ dev_act: dev_act === 0 ? 1 : 0 })}>
                <div className="label-title">
                  ACTIVATE DEVELOPER MODE
                </div>
                <SwitchTrueFalseHorizontal switchState={dev_act === 0 ? false : true} />
              </label>
            </div>


            <div className="form-group">
              <input 
                type="submit" 
                value="Update Developers Settings"
                className="btn btn--primary btn--left"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>

          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}



export default withAuthorization(withRouter(connect(
  ({ device, developer, ws }: RootState) => ({ device, developer, ws }),
  { fetchDeviceById, fetchDevSettingsByDeviceId, updateDevSettingsByDeviceId, wsGetDevDevicesSettings }
)(SettingsDeveloperSettings)))