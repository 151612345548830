import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceWeather, fetchDeviceGeneralSettings } from '../../actions/device'
import { wsGetDataByDeviceSerial, wsUpdateGeneralSettingsByDeviceSerial } from '../../actions/ws'
import { fetchAllScenarios } from '../../actions/scenario'
import { fetchLocations } from '../../actions/data'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import { IconTown } from '../partials/IconsForm'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import classnames from 'classnames'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { devicePresets } from '../../helpers/devicePresets'
import { danger } from '../../helpers/notifications'
import Alert from '../partials/Alert'
import { IconScreenHumidity } from '../partials/IconsScreen'
import { getWeatherIcon } from '../../helpers/getWeatherIcon'
import { getButtonIcon } from '../../helpers/getButtonIcon'
import { getStatusIcon } from '../../helpers/getStatusIcon'
import { IconWeatherRainAlert, IconWeatherWindAlert } from '../partials/IconsWeather'


interface DeviceSettingsWeatherProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  data: any,
  ws: { wsConnected: boolean },
  fetchDeviceById: (id: string) => Promise<void>,
  fetchLocations: () => Promise<void>,
  updateDeviceWeather: (optinos: {id: string, weather_settings: {}}) => Promise<void>,
  handleButtonSubmit: () => Promise<void>,
  wsUpdateGeneralSettingsByDeviceSerial: (options: { device_serial: string, general_settings: any }) => Promise<void>,
  fetchDeviceGeneralSettings: (device_serial: string) => Promise<void>,
  wsGetDataByDeviceSerial: (device_serial: string) => Promise<void>,
}


interface DeviceSettingsWeatherState {
  device: { 
    room_id: string, 
    device_serial: string, 
    device_id: string, 
    device_title: string,
    device_data: {
      tem: number,
      hum: number,
      ws: number,
      statusIco: any,
      btnIco: any,
    },
    device_set_btn: any,
    device_set_status: any,
  },
  locations: Array<any>,
  loc_id: number,
  loc_tit: string,
  alert_rain: number,
  alert_wind: number,
  wind_spd: string,
  formSubmitted: boolean,
  wsConnected: boolean,
  noData: boolean,
  selected: string,
}


export class DeviceSettingsWeather extends React.Component<DeviceSettingsWeatherProps, DeviceSettingsWeatherState> {


  state = {
    device: {
      room_id: '',
      device_serial: '',
      device_id: '',
      device_title: '',
      device_data: {
        tem: 21,
        hum: 50,
        ws: 1,
        statusIco: [],
        btnIco: []
      },
      device_set_btn: {
        btn: [
          { icon: '0' },
          { icon: '0' },
          { icon: '0' },
          { icon: '0' },
        ]
      },
      device_set_status: {
        stat: []
      }
    },
    locations: [],  
    loc_id: 1,
    loc_tit: 'Warsaw',
    alert_rain: 0,
    alert_wind: 0,
    wind_spd: '100',
    formSubmitted: false,
    wsConnected: false,
    noData: false,
    selected: 'weather'
  }


  componentDidMount() {
    this.fetchDeviceById()
    this.fetchLocations()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device_set_location, device_set_btn } = this.props.device.device
      const { device } = this.props.device
      if(device_set_location !== null) {
        this.setState({ 
          device: { ...device, device_data: this.state.device.device_data, device_set_btn},
          loc_id: device_set_location.loc_id,
          loc_tit: device_set_location.loc_tit,
          alert_rain: device_set_location.alert_rain,
          alert_wind: device_set_location.alert_wind,
          wind_spd: device_set_location.wind_spd,
          noData: false
        }, () => this.dataRequest())
      } else {
        this.setState({ 
          device: { ...device, device_data: this.state.device.device_data, device_set_btn},
          loc_id: devicePresets.device_set_location.loc_id,
          loc_tit: devicePresets.device_set_location.loc_tit,
          alert_rain: devicePresets.device_set_location.alert_rain,
          alert_wind: devicePresets.device_set_location.alert_wind,
          wind_spd: devicePresets.device_set_location.wind_spd,
          noData: false
        }, () => this.dataRequest())
      }
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  fetchLocations = async () => {
    await this.props.fetchLocations()
    if(this.props.data.status === fetchStates.success) {
      const { locations } = this.props.data
      this.setState({ locations })
    }
  }


  // WS: requesting data
  dataRequest = async () => {
    await this.props.wsGetDataByDeviceSerial(this.state.device.device_serial)
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true });
    if(this.props.ws.wsConnected) {
      const { device, loc_id, alert_rain, alert_wind, wind_spd, locations } = this.state
      const { device_serial } = device
      const tst = Date.now()
      let loc_tit = 'Warsaw'
      locations.map((loc: {id: number, location_title: string}) => {
          if(loc_id === loc.id) {
            return loc_tit = loc.location_title
          }
          return null
        })
      const weather_settings = {
        tst,
        loc_id,
        loc_tit,
        alert_rain,
        alert_wind,
        wind_spd
      }
      await this.props.updateDeviceWeather({ id: device.device_id, weather_settings })
      await this.props.fetchDeviceGeneralSettings(device_serial);
      const { deviceGeneralSettings } = this.props.device
      console.log('[DEBUG]: - emitting following data:', device_serial, deviceGeneralSettings)
      await this.props.wsUpdateGeneralSettingsByDeviceSerial({ device_serial: device_serial, general_settings: deviceGeneralSettings })
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false });
  }


  changeSelectedItem = (selected: string) => {
    this.setState({ selected })
  }


  render() {

    const { device, locations, loc_id, alert_rain, alert_wind, wind_spd, formSubmitted, noData, selected } = this.state
    const { fields } = this.props.device;

    if(noData) {
      return (
        <TemplatePage title="Device → Weather" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Weather" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>


          <div className="mb-screen-wrapper mb-screen-wrapper--boxed">
            <div className="mb-screen">
              <div className="mb-screen-overlay">

                <div className="mb-screen__temperature mb-screen-item--inactive">
                  {device.device_data && device.device_data.tem && device.device_data.tem.toFixed(1) + '°C'}
                </div>

                <div className="mb-screen__humidity mb-screen-item--inactive">
                  {device.device_data && device.device_data.hum} <IconScreenHumidity color="#ffffff" size={30} />
                </div>

                <div className="mb-screen__weather mb-screen-item--active mb-screen-item--selected">
                  { selected === 'weather' && device.device_data && getWeatherIcon({ ws: device.device_data.ws, color: '#fff', size: 34 }) }

                  { selected === 'alert-rain' && <IconWeatherRainAlert size={34} /> }
                  
                  { selected === 'alert-wind' && <IconWeatherWindAlert size={34} /> }
                  
                </div>

                {
                  // STATUS ICONS
                  device.device_data && device.device_data.statusIco && (
                    <div className="mb-screen__status-bar">
                      <div className="mb-screen__nav-item nav-item--left">
                        { /* <IconStatusLeftArrow size={17} color="#ffffff" /> */ }
                      </div>
                      <div className="mb-screen__nav-item mb-screen__nav-items">
                        {
                          device.device_data.statusIco.map((icon: any, index: number) => {
                            return (
                              <div key={index} className="mb-screen__nav-item mb-screen__nav-item--active">
                                {icon}
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className="mb-screen__nav-item nav-item--right">
                        { /* <IconStatusRightArrow size={17}color="#ffffff" /> */ }
                      </div>
                    </div>
                  )
                }



                {
                  // STATUS ICONS
                  device.device_data && device.device_data.statusIco && (
                    <div className="mb-screen__status-bar">
                      <div className="mb-screen__nav-item nav-item--left">
                        { /* <IconStatusLeftArrow size={17} color="#ffffff" /> */ }
                      </div>
                      <div className="mb-screen__nav-item mb-screen__nav-items">
                        {
                          device.device_set_status && device.device_set_status.stat && device.device_set_status.stat.map((item: any, index: number) => {
                            return <div key={index} className="mb-screen__status-bar-item-wrapper">
                              <div className={classnames('mb-screen__status-bar-item mb-screen-item--active', { 'status-bar-item--active': device.device_data.statusIco[0] === 1})}>
                                { 
                                  device.device_set_status.stat[index] && item.icon && getStatusIcon({ icon_number: parseInt(item.icon), size: 24, color: '#ffffff' })
                                }
                              </div>
                            </div>
                          })
                        }
                      </div>
                      <div className="mb-screen__nav-item nav-item--right">
                        { /* <IconStatusRightArrow size={17}color="#ffffff" /> */ }
                      </div>
                    </div>
                  )
                }




                {
                  device.device_data.btnIco && (
                    <>
                      <div
                      className={classnames('mb-screen__button mb-screen__button--one', { 'mb-screen__button--active': device.device_data.btnIco[0]})}>
                        { 
                          device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[0] && device.device_set_btn.btn[0].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[0].icon, size: 34, color: '#ffffff' })
                        }
                      </div>
                      <div className={classnames('mb-screen__button mb-screen__button--two', { 'mb-screen__button--active': device.device_data.btnIco[1]})}>
                        {
                          device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[1] && device.device_set_btn.btn[1].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[1].icon, size: 34, color: '#ffffff' })
                        }
                      </div>
                      <div className={classnames('mb-screen__button mb-screen__button--three', { 'mb-screen__button--active': device.device_data.btnIco[2]})}>
                        {
                          device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[2] && device.device_set_btn.btn[2].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[2].icon, size: 34, color: '#ffffff' })
                        }
                      </div>
                      <div className={classnames('mb-screen__button mb-screen__button--four', { 'mb-screen__button--active': device.device_data.btnIco[3]})}>
                        {
                          device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[3] && device.device_set_btn.btn[3].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[3].icon, size: 34, color: '#ffffff' })
                        }
                      </div>
                    </>
                  )
                }

              </div>
            </div>
          </div>


          <form className="form">
            <div className="form-section">

              <div className="form-group" onClick={() => this.changeSelectedItem('weather')}>
                <label htmlFor="loc_id">
                  Location
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconTown color="#A19BCD" />
                  </span>
                  <select 
                    name="loc_id" 
                    onChange={e => this.setState({ loc_id: parseInt(e.target.value) })}
                    className={classnames('', { 'input-error': fields && fields.includes('loc_id') })}
                    value={loc_id}
                  >
                    { 
                      locations && locations.map((location: {id: number, location_title: string}) => <option key={location.id} value={location.id}>{location.location_title}</option>)
                    }
                  </select>
                </div>
              </div>

              <div className="form-group" onClick={() => this.changeSelectedItem('alert-rain')}>
                <label htmlFor="enabled" onClick={() => this.setState({ alert_rain: alert_rain === 0 ? 1 : 0 })}>
                  <div className="label-title">
                    Rain alert
                  </div>
                  <SwitchTrueFalseHorizontal switchState={alert_rain === 0 ? false : true} />
                </label>
                <span className="form-explanation">Show a warning about the possibility of rain</span>
              </div>

              <div className="form-group" onClick={() => this.changeSelectedItem('alert-wind')}>
                <label htmlFor="enabled" onClick={() => this.setState({ alert_wind: alert_wind === 0 ? 1 : 0 })}>
                  <div className="label-title">
                    Wind alert
                  </div>
                  <SwitchTrueFalseHorizontal switchState={alert_wind === 0 ? false : true} />
                </label>
                <span className="form-explanation">Show warning about the wind occurrence</span>
              </div>

              { alert_wind === 1 && (
                <div className="form-group" onClick={() => this.changeSelectedItem('alert-wind')} >
                  <label htmlFor="time_in">
                    Wind speed ({wind_spd}km/h)
                  </label>
                  <div className="slidecontainer">
                    <input 
                      name="wind_spd" 
                      id="wind_spd" 
                      type="range" 
                      min="1" 
                      max="100" 
                      value={wind_spd} 
                      className="slider" 
                      onChange={e => this.setState({ wind_spd: e.target.value })} 
                    />
                  </div>
                  <span className="form-explanation">Set the wind strenght, above which you want to be alerted</span>
                </div>
              )}

            </div>
          </form>
          <div className="form__footer">
            <input 
              onClick={() => this.handleButtonSubmit()}
              type="submit" 
              value="Update Device Settings" 
              className="btn btn--primary btn--large"
              disabled={ formSubmitted ? true : false }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, scenario, data, ws }: RootState) => ({ device, scenario, data, ws }),
  { fetchDeviceById, updateDeviceWeather, fetchAllScenarios, fetchLocations, wsUpdateGeneralSettingsByDeviceSerial, fetchDeviceGeneralSettings, wsGetDataByDeviceSerial }
)(DeviceSettingsWeather)))