import React from 'react'
import { connect } from 'react-redux'
import { fetchRoomById, removeRoomById } from '../../actions/room'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { IconName } from '../partials/IconsForm'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import Alert from '../partials/Alert'


interface RoomsDeleteProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  room: { status: string, fields: Array<string>, room: { room_name: string }},
  removeRoomById: (options: { id: string, room_name: string }) => Promise<void>,
  fetchRoomById: (id: string) => Promise<void>,
}


interface RoomsDeleteState {
  formSubmitted: boolean,
  room_name: string,
  room: {},
  noData: boolean,
}


export class RoomsDelete extends React.Component<RoomsDeleteProps, RoomsDeleteState> {


  state = {
    formSubmitted: false,
    room_name: '',
    room: {},
    noData: false
  }


  componentDidMount() {
    this.fetchRoomById();
  }


  fetchRoomById = async () => {
    await this.props.fetchRoomById(this.props.router.params.id)
    if(this.props.room.status === fetchStates.success) {
      const { room } = this.props.room
      this.setState({ room, noData: false })
    } 
    if(this.props.room.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  handleSubmitRemoveRoom = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    await this.props.removeRoomById({ id: this.props.router.params.id, room_name: this.state.room_name })
    if(this.props.room.status === fetchStates.success) {
      this.setState({ room_name: '', formSubmitted: false });
      this.props.router.navigate('/');
    }
  }


  render() {

    const { room_name, formSubmitted, noData } = this.state;
    const { room, fields, status } = this.props.room;

    if(noData) {
      return (
        <TemplatePage title="Rooms → Delete Room" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Room does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Rooms → Delete Room" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <p>
            You are about to remove room: <strong>"{room.room_name}"</strong>. This action can not be reversed. Are you sure you want to proceed?
          </p>
          <form className="form" onSubmit={this.handleSubmitRemoveRoom}>
            <div className="form-group">
              <label htmlFor="room_name">
                Room name (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconName color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="room_name"
                  id="room_name" 
                  value={room_name}
                  onChange={e => this.setState({ room_name: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('room_name') })}
                />
                </div>
              <span className="form-explanation">Confirm your choice by typing in the room name</span>
            </div>
            <div className="form__footer">
              <input 
                type="submit" 
                value="Remove Room" 
                className="btn btn--primary btn--large"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ room }: RootState) => ({ room }),
  { fetchRoomById, removeRoomById }
)(RoomsDelete)))