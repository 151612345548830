import React from 'react'
import { connect } from 'react-redux'
import { fetchPersonById, deletePersonById } from '../../actions/person';
import { fetchAllDevices, fetchDeviceById, updateDeviceAlarm, updateAndGetAlarmSettings } from '../../actions/device'
import { wsUpdateAlarmSettings } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { IconEmail } from '../partials/IconsForm'
import { RootState } from '../../reducers'
import fetchStates from '../../types/fetchStates'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import { danger } from '../../helpers/notifications'
import Alert from '../partials/Alert'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
const requiresPermissions = 'manage_users'


interface UsersDeleteProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  person: { status: string, fields: Array<string>, person: { email: string, person_id: string, alarm_pin: string, phone: string } },
  device: {status: string, devices_alarm_settings: any, devices:{}, device: any},
  ws: { wsConnected: boolean },
  fetchPersonById: (options: { id: string}) => Promise<void>,
  deletePersonById: (options: { id: string}) => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  fetchAllDevices: () => Promise<void>,
  updateDeviceAlarm: (options: {id: string, alarm_settings: { tst: number, time_out: string, time_in: string, sensitivity: string }}) => Promise<void>,
  wsUpdateAlarmSettings: (options: { device_serial: string, alarm_settings: any }) => Promise<void>,
  updateAndGetAlarmSettings: () => Promise<void>,
}


interface UsersDeleteState {
  formSubmitted: boolean,
  email: string,
  wsConnected: boolean,
  devices: Array<any>,
  noData: boolean
}


export class UsersDelete extends React.Component<UsersDeleteProps, UsersDeleteState> {


  state = {
    formSubmitted: false,
    email: '',
    wsConnected: false,
    devices: [],
    noData: false,
  }


  componentDidMount() {
    this.fetchAllDevices()
    this.fetchPersonById()
  }

  fetchPersonById = async () => {
    await this.props.fetchPersonById({ id: this.props.router.params.id })
    if(this.props.device.status === fetchStates.success) {
      this.setState({ noData: false })
    }
    if(this.props.person.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  fetchAllDevices = () => {
    this.props.fetchAllDevices()
    .then(() => {
      if(this.props.device.status === fetchStates.success) {
        const devices: any = this.props.device.devices
        this.setState({ devices })
      }
    })
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    await this.props.deletePersonById({ id: this.props.person.person.person_id })
    if(this.props.person.status === fetchStates.success) {
      await this.props.updateAndGetAlarmSettings()
      if(this.props.device.status === fetchStates.success) {
        const { devices_alarm_settings } = this.props.device;
        // check at least one device exists
        if(devices_alarm_settings.length > 0) {
          // check we have wsConnection
          if(this.props.ws.wsConnected === true) {
            devices_alarm_settings.forEach((device: {device_serial: string, device_set_alarm: any, device_id: string}) => {
              // make sure we have MB category
              const prefixToCheck = device.device_serial.substring(0, 2).toUpperCase();
              if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
                this.props.wsUpdateAlarmSettings({ device_serial: device.device_serial, alarm_settings: device.device_set_alarm })
              }
            })
          } else {
            danger('You are disconnected from the WS server')
          }
        }
      }
      this.props.router.navigate('/users')
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { person, fields, status } = this.props.person
    const { formSubmitted, email, noData } = this.state

    if(noData) {
      return (
        <TemplatePage title="Users → Delete User" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">User does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Users → Delete User" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <p>
            You are about to delete user: <strong>"{person.email}"</strong>. Access to the aplication will be blocked. This action can not be reversed. Are you sure you want to proceed?
          </p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="email">
                Email (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconEmail color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="email"
                  id="email" 
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('email') })}
                />
              </div>
              <span className="form-explanation">Confirm your choice by typing in the email of the user</span>
            </div>
            <div className="content-box__footer">
              <input 
                type="submit" 
                value="Delete User"
                className="btn btn--primary btn--left"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person, device, ws }: RootState) => ({ person, device, ws }),
  { fetchPersonById, deletePersonById, fetchAllDevices, fetchDeviceById, updateDeviceAlarm, wsUpdateAlarmSettings, updateAndGetAlarmSettings }
)(UsersDelete)), requiresPermissions)