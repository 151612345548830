import React from 'react'
import AppRoutes from './AppRoutes'
import { connect } from 'react-redux'
import { checkAuth } from './actions/auth'
import Navigation from './components/partials/Navigation'
import { RootState } from './reducers'
import MobileNavigation from './components/partials/MobileNavigation'
import WsConnector from './components/partials/WsConnector'
import Toolbar from './components/partials/Toolbar'


interface MagicBoxAppProps {
  auth: {status: string, authenticated: boolean, person: { settings: { theme: string }}}
  checkAuth: () => Promise<void>,
}


interface MagicBoxAppState {
  dataLoaded: boolean,
}


export class MagicBoxApp extends React.Component<MagicBoxAppProps, MagicBoxAppState> {

  state = {
    dataLoaded: false,
  }

  componentDidMount(){
    this.props.checkAuth()
    .then(() => {
      this.setState({ dataLoaded: true })
    })
  }
  

  renderApplication = (auth: {authenticated: boolean}) => {
    return <div>
    <div className={auth.authenticated ? 'page' : 'auth'}>
      <div className="page-content__grid">
        { auth.authenticated && <Navigation /> }
        <div className="content-wrapper">
          { auth.authenticated && <WsConnector /> }
          { auth.authenticated && <Toolbar />}
          <AppRoutes />
        </div>
        { auth.authenticated && <MobileNavigation /> }
      </div>
    </div>
  </div>
  }


  render() {
    const { auth } = this.props
    const { dataLoaded } = this.state
    if(dataLoaded) {
      return this.renderApplication(auth)
    }
    return null
  }

}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  { checkAuth }
)(MagicBoxApp)