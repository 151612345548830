import { ACCOUNT } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchAccount = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: ACCOUNT.FETCH });
  return fetch(`${API_ADDRESS}/account/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: ACCOUNT.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: string}) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: ACCOUNT.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAccountById = (account_id: string) => fetchAccount({
  endpoint: `${account_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ID_SUCCESS
});


export const updateAccountEnergyPrice = (options: { id: string, account_currency: string, account_energy_cost: string }) => fetchAccount({
  endpoint: `energy-price/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ account_currency: options.account_currency, account_energy_cost: options.account_energy_cost }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ACCOUNT.UPDATE_ENERGY_PRICE_SUCCESS
});


export const updateAccountWeatherSettings = (options: { account_id: string, account_weather_location_id: number, account_weather_alert_rain: number, account_weather_alert_wind: number, account_weather_wind_speed: string }) => fetchAccount({
  endpoint: `weather-settings/${options.account_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ account_weather_location_id: options.account_weather_location_id, account_weather_alert_rain: options.account_weather_alert_rain, account_weather_alert_wind: options.account_weather_alert_wind, account_weather_wind_speed: options.account_weather_wind_speed }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ACCOUNT.UPDATE_WEATHER_SETTINGS_SUCCESS
});