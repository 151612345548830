// admin, moderator, user
export const numberToRole = (number: number) => {
  switch(number) {
    case 1:
      return 'Admin'
    case 2:
      return 'User'
    default:
      return '?'
  }
}