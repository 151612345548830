import { FIREBASE_API_KEY } from '../../config'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "magicbox-app-auth.firebaseapp.com",
  projectId: "magicbox-app-auth",
  storageBucket: "magicbox-app-auth.appspot.com",
  messagingSenderId: "112923753358",
  appId: "1:112923753358:web:7d282143333f363495c2df"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);