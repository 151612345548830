import { IconDeviceUpdateError, IconDeviceUpdateProgress, IconDeviceUpdateSucccess } from "./IconsDeviceUpdate"



interface DeviceUpdateProps {
  type: string,
}


const DeviceUpdate: React.FC<DeviceUpdateProps> = ({ type }) => {

  if(type === 'error') {
    return (<div className="device-update device-update--error">
      <div className="device-update__icon">
        <IconDeviceUpdateError size={80} color="#E82F55" />
      </div>
      There was an error updating this device
    </div>)
  }

  if(type === 'updating') {
    return (<div className="device-update device-update--updating">
      <div className="device-update__icon">
        <IconDeviceUpdateProgress size={80} color="#593995" />
      </div>
      Device update is in progress ...
    </div>)
  }

  if(type === 'updated') {
    return (<div className="device-update device-update--updated">
      <div className="device-update__icon">
        <IconDeviceUpdateSucccess size={80} color="#45B869" />
      </div>
      Device was updated successfully.
    </div>)
  }
  return <></>
}


export default DeviceUpdate