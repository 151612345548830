import { NavLink } from 'react-router-dom'


interface TabNavHistoryProps {
  location: any
}

const TabNavHistory: React.FC<TabNavHistoryProps> = ({ location }) => (
  
    <nav className="tabnav">
      <NavLink 
        to={{ pathname: `/history/events` }} 
        className={location.pathname === '/history/events' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        Events History
      </NavLink>
      <NavLink 
        to={{ pathname: `/history/data` }} 
        className={location.pathname === '/history/data' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        Data History
      </NavLink>
    </nav>
)




export default TabNavHistory