import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceButtons, fetchOutputButtonOptions, fetchAllDevices } from '../../actions/device'
import { fetchAllScenarios } from '../../actions/scenario'
import { wsGetDataByDeviceSerial, wsUpdateCornerButtonsByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { devicePresets } from '../../helpers/devicePresets'
import { getButtonIcon } from '../../helpers/getButtonIcon'
import { getStatusIcon } from '../../helpers/getStatusIcon'
import { danger } from '../../helpers/notifications'
import { IconScreenHumidity } from '../partials/IconsScreen'
import { getWeatherIcon } from '../../helpers/getWeatherIcon'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import Alert from '../partials/Alert'


interface DeviceSettingsCornerButtonsProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  ws: { wsConnected: boolean },
  scenario: { status: string, fields: [string], scenarios: [] },
  updateDeviceButtons: (optinos: {id: string, btn_settings: {}}) => Promise<void>,
  fetchDeviceById: (id: string) => Promise<any>,
  fetchAllDevices: () => Promise<void>,
  fetchAllScenarios: () => Promise<void>,
  fetchOutputButtonOptions: () => Promise<void>,
  wsGetDataByDeviceSerial: (device_serial: string) => Promise<void>,
  wsUpdateCornerButtonsByDeviceSerial: (options: { device_serial: string, corner_buttons: any }) => Promise<void>,
}


interface DeviceSettingsCornerButtonsState {
  devices: any,
  defaultDevice: { device_serial: string, device_capabilities: { dou: { id: string; }[] } },
  io_options: any,
  device: { 
    room_id: string, 
    device_id: string,
    device_title: string,
    device_set_status: any,
    device_set_btn: any,
    device_capabilities: any,
    device_data: {
      tem: number,
      hum: number,
      ws: number,
      statusIco: any,
      btnIco: any,
    }
  },
  corner_buttons: {
    icon: string|undefined,
    type: string|undefined,
    scenario: string|undefined,
    device_serial: string|undefined,
    io: string|undefined,
    action: string|undefined,
    active: boolean,
    enabled: boolean,
    sos_type: string|undefined,
    btn_presslength: string|undefined
  }[],
  scenarios: {}[],
  buttons_output_options: {}[],
  iconListOpened: number,
  formSubmitted: boolean,
  wsError: boolean,
  noData: boolean,
}


export class DeviceSettingsCornerButtons extends React.Component<DeviceSettingsCornerButtonsProps, DeviceSettingsCornerButtonsState> {

  state = {
    devices: [],
    defaultDevice: { device_serial: '', device_capabilities: { dou: [ { id: '' } ] } },
    io_options: [],
    device: {
      room_id: '',
      device_id: '',
      device_serial: '', 
      device_title: '', 
      device_data: {
        tem: 21,
        hum: 50,
        ws: 1,
        statusIco: [],
        btnIco: []
      },
      device_set_status: devicePresets.device_set_status,
      device_set_btn: devicePresets.device_set_btn,
      device_capabilities: { dou: [ { id: '' } ] },
    },
    corner_buttons: [
      { enabled: false, active: false, icon: '0', type: '0', scenario: '0', device_serial: '0', btn_presslength: '2', sos_type: '1', io: '0', action: '0' },
      { enabled: false, active: false, icon: '0', type: '0', scenario: '0', device_serial: '0', btn_presslength: '2', sos_type: '1', io: '0', action: '0' },
      { enabled: false, active: false, icon: '0', type: '0', scenario: '0', device_serial: '0', btn_presslength: '2', sos_type: '1', io: '0', action: '0' },
      { enabled: false, active: false, icon: '0', type: '0', scenario: '0', device_serial: '0', btn_presslength: '2', sos_type: '1', io: '0', action: '0' }
    ],
    scenarios: [],
    buttons_output_options: [],
    iconListOpened: 0,
    formSubmitted: false,
    wsError: false,
    noData: false,
  }


  async componentDidMount(): Promise<void> {
    await this.fetchAllDevices()
    await this.getAllScenarios()
    await this.getDeviceById()
    await this.getAllButtonOutputOptions()
  }


  // WS: requesting data
  dataRequest = async () => {
    await this.props.wsGetDataByDeviceSerial(this.state.device.device_serial)
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      let devices: any = {};
      devices = this.props.device.devices
      if(devices.length > 0) {

        let defaultDevice = devices[0]
        const filteredDefaultDevices = devices.filter((device: {device_id: string}) => {
          if(device.device_id === this.props.router.params.id){
            return device
          }
          return null
        })
        defaultDevice = filteredDefaultDevices[0]

        this.setState({ devices, defaultDevice })
      }
    }
  }


  getAllScenarios = async () => {
    await this.props.fetchAllScenarios()
    if(this.props.scenario.status === fetchStates.success) {
      const { scenarios } = this.props.scenario
      this.setState({ scenarios })
    }
  }


  getDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      this.setState({ device })
      if(device.device_set_btn && device.device_set_btn.btn){
        const { device_set_btn } = device
        const corner_buttons:any = []
        device_set_btn.btn.map((btn: any) => {
          let fixed_btn = {...btn, enabled: btn.enabled === 'true' ? true : false, active: false}
          if(btn.type === 'out') {
            let io_options: any = []
            this.state.devices.map((device: any) => {
              if(device.device_serial === btn.device_serial) {
                return io_options.push(...device.device_capabilities.dou)
              }
              return null
            })
            this.setState({ io_options })
            fixed_btn =  {...btn, enabled: btn.enabled === 'true' ? true : false, active: false}
          } else {
            fixed_btn =  {...btn, enabled: btn.enabled === 'true' ? true : false, active: false}
          }
          return corner_buttons.push(fixed_btn)
        })
        this.setState({ device: { ...device, device_data: this.state.device.device_data }, corner_buttons, noData: false }, () => this.dataRequest())
      } else {
        this.setState({ device: { ...device, device_data: this.state.device.device_data }, noData: false }, () => this.dataRequest())
      }
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  getAllButtonOutputOptions = async () => {
    await this.props.fetchOutputButtonOptions()
    if(this.props.device.status === fetchStates.success) {
      const { devices_output_options } = this.props.device
      this.setState({ buttons_output_options: devices_output_options })
    }
  }


  activateButtonArea = (index: number, boolean: boolean) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[0].active = false
    updated_corner_buttons[1].active = false
    updated_corner_buttons[2].active = false
    updated_corner_buttons[3].active = false
    updated_corner_buttons[index].active = boolean
    this.setState({ corner_buttons: updated_corner_buttons })
  }


  selectIconList = (index: number) => {
    this.setState({ iconListOpened: index })
  }


  closeIconList = () => {
    this.setState({ iconListOpened: 0 })
    this.activateButtonArea(0, false)
    this.activateButtonArea(1, false)
    this.activateButtonArea(2, false)
    this.activateButtonArea(3, false)
  }


  changeButtonStatus = (index: number, value: boolean) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].enabled = value
    if(value === false) {
      updated_corner_buttons[index].icon = '0'
      updated_corner_buttons[index].scenario = '0'
      updated_corner_buttons[index].type = '0'
      updated_corner_buttons[index].device_serial = '0'
      updated_corner_buttons[index].io = '0'
      updated_corner_buttons[index].action = '0'
    }
    if(value === true) {
      updated_corner_buttons[index].icon = '1'
      updated_corner_buttons[index].scenario = '1'
      updated_corner_buttons[index].type = 'out'
      updated_corner_buttons[index].device_serial = this.state.defaultDevice.device_serial
      updated_corner_buttons[index].io = this.state.defaultDevice.device_capabilities.dou[0].id
      updated_corner_buttons[index].action = 'tog'
    }
    let io_options: any = []
    this.state.devices.map((device: any) => {
      if(device.device_serial === this.state.defaultDevice.device_serial) {
        return io_options.push(...device.device_capabilities.dou)
      }
      return null
    })
    this.setState({ corner_buttons: updated_corner_buttons, io_options })
  }


  changeButtonIcon = (index: number, icon: number) => {
    const { corner_buttons } = this.state
    const corner_buttons_updated = corner_buttons.map((btn,i) => {
      if(i === index - 1) {
        btn.icon = icon.toString()
      }
      return btn
    })
    this.setState({ corner_buttons: corner_buttons_updated })
  }


  setButtonType = (index: number, value: string) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].type = value
    if(value === 'sos') {
      updated_corner_buttons[index].btn_presslength = '2'
      updated_corner_buttons[index].sos_type = '1'
    }
    this.setState({ corner_buttons: updated_corner_buttons })
  }


  setButtonDevice = (index: number, value: string) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].device_serial = value
    let io_options: any = []
    this.state.devices.map((device: any) => {
      if(device.device_serial === value) {
        return io_options.push(...device.device_capabilities.dou)
      }
      return null
    })
    this.setState({ corner_buttons: updated_corner_buttons, io_options })
  }


  setButtonIO = (index: number, value: string) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].io = value
    this.setState({ corner_buttons: updated_corner_buttons })
  }


  setButtonAction = (index: number, value: string) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].action = value
    this.setState({ corner_buttons: updated_corner_buttons })
  }


  setButtonScenario = (index: number, value: string) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].scenario = value
    this.setState({ corner_buttons: updated_corner_buttons })
  }


  setButtonPressLength = (index: number, value: string) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].btn_presslength = value
    console.log('updated_corner_buttons', updated_corner_buttons)
    this.setState({ corner_buttons: updated_corner_buttons })
  }


  setButtonSOSType = (index: number, value: string) => {
    const updated_corner_buttons = this.state.corner_buttons
    updated_corner_buttons[index].sos_type = value
    this.setState({ corner_buttons: updated_corner_buttons })
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true })
    if(this.props.ws.wsConnected) {
      
      const { corner_buttons } = this.state
      const tst = Date.now()
      const btn_settings = {
        tst,
        btn: corner_buttons
      }

      const corner_buttons_filtered = corner_buttons.map((btn: any) => {
        // button disabled
        if(btn.icon === '0' || 0) {
          return { icon: 0 }
        }
        // if we talk about the scenario
        if(btn.type === 'sce') {
          return { icon: btn.icon, scenario: btn.scenario, action: btn.action }
        }
        // if we talk about the SOS
        if(btn.type === 'sos') {
          console.log(btn)
          return { icon: btn.icon, btn_presslength: btn.btn_presslength, sos_type: btn.sos_type }
        }
        // if we talk about the output
        return { icon: btn.icon, device_serial: btn.device_serial, io: btn.io, action: btn.action }
      })
      const corner_btn_settings_filtered = {
        tst,
        btn: corner_buttons_filtered
      }
      await this.props.updateDeviceButtons({ id: this.props.router.params.id, btn_settings })
      if(this.props.device.status === fetchStates.success) {
        console.log('[DEBUG]: - emitting following data:', this.props.device.device.device_serial, corner_btn_settings_filtered)
        // WS
        await this.props.wsUpdateCornerButtonsByDeviceSerial({ device_serial: this.props.device.device.device_serial, corner_buttons: corner_btn_settings_filtered })
      }
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { device, scenarios, corner_buttons, iconListOpened, formSubmitted, noData, devices, io_options } = this.state

    console.log(corner_buttons)

    if(noData) {
      return (
        <TemplatePage title="Device → Corner Buttons" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Corner Buttons" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          
              <div className="screen-setup screen-setup--half">

                <div className="mb-screen-wrapper mb-screen-wrapper--boxed">
                  <div className="mb-screen">
                    <div className="mb-screen-overlay">

                      <div className="mb-screen__temperature mb-screen-item--inactive">
                        {device.device_data && device.device_data.tem && device.device_data.tem.toFixed(1) + '°C'}
                      </div>

                      <div className="mb-screen__humidity mb-screen-item--inactive">
                        {device.device_data && device.device_data.hum} <IconScreenHumidity color="#ffffff" size={30} />
                      </div>

                      <div className="mb-screen__weather mb-screen-item--inactive">
                        {device.device_data && getWeatherIcon({ ws: device.device_data.ws, color: '#fff', size: 34 })}
                      </div>

                      {
                        // STATUS ICONS
                        device.device_data && device.device_data.statusIco && (
                          <div className="mb-screen__status-bar">
                            <div className="mb-screen__nav-item nav-item--left">
                              { /* <IconStatusLeftArrow size={17} color="#ffffff" /> */ }
                            </div>
                            <div className="mb-screen__nav-item mb-screen__nav-items">
                              {
                                device.device_set_status && device.device_set_status.stat && device.device_set_status.stat.map((item: any, index: number) => {
                                  return  <div key={index} className="mb-screen__status-bar-item-wrapper">
                                    <div className={classnames('mb-screen__status-bar-item mb-screen-item--inactive', { 'status-bar-item--active': device.device_data.statusIco[0] === 1})}>
                                      { 
                                        device.device_set_status.stat[index] && item.icon && getStatusIcon({ icon_number: parseInt(item.icon), size: 24, color: '#ffffff' })
                                      }
                                    </div>
                                  </div>
                                })
                              }
                            </div>
                            <div className="mb-screen__nav-item nav-item--right">
                              { /* <IconStatusRightArrow size={17}color="#ffffff" /> */ }
                            </div>
                          </div>
                        )
                      }

                      {
                        device.device_data && device.device_data.btnIco && (
                          <>
                            <div className={classnames('mb-screen__button mb-screen__button--one mb-screen-item--active mb-screen__button-wrapper', { 'mb-screen__button-wrapper--active': corner_buttons && corner_buttons[0].active })} onClick={() => this.closeIconList()}>
                              <div>
                                { 
                                  corner_buttons && corner_buttons[0] && corner_buttons[0].enabled && getButtonIcon({ button_image: corner_buttons[0].icon, size: 34, color: '#ffffff' })
                                }
                              </div>
                            </div>
                            <div className={classnames('mb-screen__button mb-screen__button--two mb-screen-item--active mb-screen__button-wrapper', { 'mb-screen__button-wrapper--active': corner_buttons && corner_buttons[1].active })}>
                              <div>
                                {
                                  corner_buttons && corner_buttons[1] && corner_buttons[1].enabled  && getButtonIcon({ button_image: corner_buttons[1].icon, size: 34, color: '#ffffff' })
                                }
                              </div>
                            </div>
                            <div className={classnames('mb-screen__button mb-screen__button--three mb-screen-item--active mb-screen__button-wrapper', { 'mb-screen__button-wrapper--active': corner_buttons && corner_buttons[2].active })}>
                              <div>
                                {
                                  corner_buttons && corner_buttons[2] && corner_buttons[2].enabled && getButtonIcon({ button_image: corner_buttons[2].icon, size: 34, color: '#ffffff' })
                                }
                              </div>
                            </div>
                            <div className={classnames('mb-screen__button mb-screen__button--four mb-screen-item--active mb-screen__button-wrapper', { 'mb-screen__button-wrapper--active': corner_buttons && corner_buttons[3].active })}>
                              <div>
                                { 
                                  corner_buttons && corner_buttons[3] && corner_buttons[3].enabled && getButtonIcon({ button_image: corner_buttons[3].icon, size: 34, color: '#ffffff' })
                                }
                              </div>
                            </div>
                          </>
                        )
                      }

                    </div>
                  </div>
                </div>

                <div className="screen-setup__section">
                  <form className="form screen-setup__control-buttons">
                    {
                      corner_buttons && corner_buttons.map((btn, index) => {
                        return <div key={index}
                          className={classnames('settings-row screen-button', { 'screen-buttons--out': btn.type === 'out' })}
                          onClick={() => this.activateButtonArea(index, true)}
                          onBlur={() => this.activateButtonArea(index, false)}
                        >
                          
                          { /* btn.enable */ }
                          <div className={classnames('screen-button-title', { 'screen-button-title--disabled': !btn.enabled })}>
                          
                            <h3>
                              { index === 0 && 'Top left'}
                              { index === 1 && 'Top right'}
                              { index === 2 && 'Bottom left'}
                              { index === 3 && 'Bottom right'}
                            </h3>
                          </div>

                          { /* btn.enable */ }
                          <div className="screen-icon-enabled">
                            <label htmlFor="enabled" 
                              onClick={ () => this.changeButtonStatus(index, !btn.enabled) }
                            >
                              <SwitchTrueFalseHorizontal switchState={btn.enabled === false ? false : true} title="Enable" />
                            </label>
                          </div>


                          { /* btn.icon */ }
                          <div className={classnames('corner-button-input screen-icon-selector', { 'screen-icon-selector--disabled': !btn.enabled })}>
                            <div onClick={() => btn.enabled && this.selectIconList(index + 1)}>
                              { getButtonIcon({ button_image: btn.icon, size: 24, color: !btn.enabled ? '#A19BCD' : '#000000' }) }
                            </div>
                            <div className={classnames('screen-icon-list', { 'screen-icon-list--active': iconListOpened === index + 1 })} onClick={() => this.closeIconList()}>
                              <div className="screen-icon-list__title">
                                Select Icon
                              </div>
                              <div className="screen-icon-list__body">
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '1' })} onClick={() => this.changeButtonIcon(index + 1, 1)}>
                                  {getButtonIcon({ button_image: '1', size: 24, color: '#000000' })}
                                </span>
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '2' })} onClick={() => this.changeButtonIcon(index + 1, 2)}>
                                  {getButtonIcon({ button_image: '2', size: 24, color: '#000000' })}
                                </span>
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '3' })} onClick={() => this.changeButtonIcon(index + 1, 3)}>
                                  {getButtonIcon({ button_image: '3', size: 24, color: '#000000' })}
                                </span>
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '4' })} onClick={() => this.changeButtonIcon(index + 1, 4)}>
                                  {getButtonIcon({ button_image: '4', size: 24, color: '#000000' })}
                                </span>
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '5' })} onClick={() => this.changeButtonIcon(index + 1, 5)}>
                                  {getButtonIcon({ button_image: '5', size: 24, color: '#000000' })}
                                </span>
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '6' })} onClick={() => this.changeButtonIcon(index + 1, 6)}>
                                  {getButtonIcon({ button_image: '6', size: 24, color: '#000000' })}
                                </span>
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '7' })} onClick={() => this.changeButtonIcon(index + 1, 7)}>
                                  {getButtonIcon({ button_image: '7', size: 24, color: '#000000' })}
                                </span>
                                <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': btn.icon === '8' })} onClick={() => this.changeButtonIcon(index + 1, 8)}>
                                  {getButtonIcon({ button_image: '8', size: 24, color: '#000000' })}
                                </span>
                              </div>
                            </div>
                          </div>


                          { /* btn.type */ }
                          <div className="corner-button-input">
                            <select 
                              disabled={!btn.enabled}
                              value={btn.type}
                              onChange={e => this.setButtonType(index, e.target.value)}
                            >
                              {
                                !btn.enabled && <option key={0} value="0">None selected</option>
                              }

                              <>
                                <option value="out">output</option>
                                {
                                  scenarios && scenarios.length > 0 && (
                                    <option value="sce">scenario</option>
                                  )
                                }
                                <option value="sos">sos</option>
                              </>


                            </select>
                          </div>


                          { /* btn.scenario */}
                          {
                            btn.type === 'sce' && (
                              <div className="corner-button-input">
                                <select 
                                  disabled={!btn.enabled}
                                  value={btn.scenario}
                                  onChange={e => this.setButtonScenario(index, e.target.value)}
                                >
                                  {
                                    !btn.enabled && <option key={0} value="0">None selected</option>
                                  }
                                  {
                                    scenarios && scenarios.map((scenario:{sc_id: string, sc_title: string}) => (
                                      <option key={scenario.sc_id} value={scenario.sc_id}>{scenario.sc_title}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            )
                          }



                          { /* btn.btn_presslength */}
                          {
                            btn.type === 'sos' && (
                              <div className="corner-button-input">
                                <select 
                                  disabled={!btn.enabled}
                                  value={btn.btn_presslength}
                                  onChange={e => this.setButtonPressLength(index, e.target.value)}
                                >
                                  {
                                    !btn.enabled && <option key={0} value="0">None selected</option>
                                  }
                                  <option key={2} value="2">press for 2s</option>
                                  <option key={3} value="3">press for 3s</option>
                                  <option key={4} value="4">press for 4s</option>
                                  <option key={5} value="2">press for 5s</option>
                                </select>
                              </div>
                            )
                          }



                          { /* btn.device_serial */}
                          {
                            btn.type === 'out' && (
                              <select 
                                className="corner-button-input"
                                disabled={!btn.enabled}
                                value={btn.device_serial}
                                onChange={e => this.setButtonDevice(index, e.target.value)}
                              >
                                {
                                  !btn.enabled && <option key={0} value="0">None selected</option>
                                }
                                {
                                  devices && devices.map((device:{device_serial:string, device_title: string}) => (
                                    <option key={device.device_serial} value={device.device_serial}>
                                      {device.device_title} ({device.device_serial})
                                    </option>  
                                  ))
                                }
                              </select>
                            )
                          }



                          { /* btn.io */}
                          {
                            btn.type === 'out' && (
                              <select 
                                className="corner-button-input"
                                disabled={!btn.enabled}
                                value={btn.io}
                                onChange={e => this.setButtonIO(index, e.target.value)}
                              >
                                {
                                  !btn.enabled && <option key={0} value="0">None selected</option>
                                }
                                {
                                  io_options && io_options.map((option: any) => (
                                    <option key={option.id} value={option.id}>{option.title}</option> 
                                  ))
                                  /*
                                  devices && devices.map((device: any) => {
                                    if(device.device_serial === defaultDevice.device_serial) {
                                      device.device_capabilities.dou.map((dou: any) => {
                                        return <option key={dou.id} value={dou.id}>{dou.title}</option> 
                                      })
                                    }
                                  })
                                  */
                                }
                              </select>
                            )
                          }



                          { /* btn.action */ }
                          {
                            btn.type !== 'sos' && (
                              <div className="corner-button-input">
                                <select 
                                  disabled={!btn.enabled}
                                  value={btn.action}
                                  onChange={e => this.setButtonAction(index, e.target.value)}
                                >
                                  {
                                    !btn.enabled && <option key={0} value="0">None selected</option>
                                  }
                                  <option value="tog">TOGGLE</option>
                                  <option value="on">ON</option>
                                  <option value="off">OFF</option>
                                </select>
                              </div>
                            )
                          }


                          { /* btn.sos_type */}
                          {
                            btn.type === 'sos' && (
                              <div className="corner-button-input">
                                <select 
                                  disabled={!btn.enabled}
                                  value={btn.sos_type}
                                  onChange={e => this.setButtonSOSType(index, e.target.value)}
                                >
                                  {
                                    !btn.enabled && <option key={0} value="0">None selected</option>
                                  }
                                  <option key={1} value="1">Loud SOS</option>
                                  <option key={0} value="0">Silent SOS</option>
                                </select>
                              </div>
                            )
                          }

                        </div>
                      })
                    }
                  </form>
                  <div className="form__footer">
                    <input 
                      onClick={() => this.handleButtonSubmit()}
                      type="submit" 
                      value="Update Device Settings" 
                      className="btn btn--primary btn--large"
                      disabled={ formSubmitted ? true : false }
                    />
                  </div>
                </div>
              </div>


        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, scenario, ws }: RootState) => ({ device, scenario, ws }),
  { fetchDeviceById, updateDeviceButtons, fetchAllScenarios, fetchOutputButtonOptions, fetchAllDevices, wsGetDataByDeviceSerial, wsUpdateCornerButtonsByDeviceSerial }
)(DeviceSettingsCornerButtons)))