import { PERSON } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchPerson = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: PERSON.FETCH });
  return fetch(`${API_ADDRESS}/person/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: PERSON.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: string}) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: PERSON.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllPeople = (options: { limit: number, skip: number }) => fetchPerson({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: PERSON.FETCH_ALL_SUCCESS
});


export const fetchPersonById = (options: { id: string }) => fetchPerson({
  endpoint: `${options.id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: PERSON.FETCH_ID_SUCCESS
});


export const deletePersonById = (options: { id: string }) => fetchPerson({
  endpoint: `${options.id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: PERSON.FETCH_DELETE_SUCCESS
});


export const addNewPerson = (options: { person: any }) => fetchPerson({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...options.person }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_ADD_SUCCESS
});


export const updatePerson = (options: { person: any }) => fetchPerson({
  endpoint: `${options.person.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.person }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_UPDATE_SUCCESS
});


export const updatePersonEmailSettings = (options: { id: string, new_email: string, password_email: string }) => fetchPerson({
  endpoint: `settings/email/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ new_email: options.new_email, password_email: options.password_email }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_SUCCESS
});


export const updatePersonPasswordSettings = (options: { id: string, password_confirmation: string, password_old: string, password_new: string }) => fetchPerson({
  endpoint: `settings/password/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ password_confirmation: options.password_confirmation, password_old: options.password_old, password_new: options.password_new }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_SUCCESS
});


export const updatePersonNotificationsSettings = (options: { id: string, settings: any }) => fetchPerson({
  endpoint: `settings/notifications/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_SUCCESS
});


export const updatePersonSettings = (options: { id: string, settings: any }) => fetchPerson({
  endpoint: `settings/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ settings: options.settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_SUCCESS
});


export const updateWeatherSettings = (options: { loc_id: number, alert_rain: number, alert_wind: number, wind_spd: string }) => fetchPerson({
  endpoint: 'weather',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...options }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_UPDATE_WEATHER_SETTINGS_SUCCESS
});


export const resetPerson = () => (dispatch: any) => { 
  dispatch({ type: PERSON.RESET, status: 'success' }); 
}