import { AUTH } from '../types';
import fetchStates from '../types/fetchStates';
import { store } from '../store';
import { wsConnect, wsDisconnect } from '../actions/ws';


interface AuthState {
  person: [],
  token: string,
  authenticated: Boolean,
  isAuthResolved: Boolean,
}

const DEFAULT_AUTH: AuthState = {
  person: [],
  token: '',
  authenticated: false,
  isAuthResolved: true
};



const auth = (state = DEFAULT_AUTH, action: any) => {
  switch(action.type) {
    case AUTH.FETCH:
      return { 
        ...state,
        status: fetchStates.fetching,
        isAuthResolved: false,
        msg: [],
      };
    case AUTH.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,  
        fields: action.errors && action.errors.map((item:any) => item.path !== undefined && item.path.toString()),
        authenticated: false,
        isAuthResolved: true,
        msg: action.errors,
      };
    case AUTH.FETCH_LOGIN_SUCCESS:
      store.dispatch(wsConnect());
      return {
        ...state,
        status: fetchStates.success,
        authenticated: true,
        isAuthResolved: true,
        person: action.person
      };
    case AUTH.FETCH_LOGIN_GOOGLE_SUCCESS:
      store.dispatch(wsConnect());
      return {
        ...state,
        status: fetchStates.success,
        authenticated: true,
        isAuthResolved: true,
        person: action.person
      };
    case AUTH.FETCH_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        authenticated: action.authenticated === undefined ? false : action.authenticated,
        isAuthResolved: true,
        person: action.person
      };
    case AUTH.FETCH_LOGOUT_SUCCESS:
      store.dispatch(wsDisconnect());
      return {
        ...state,
        status: fetchStates.success,
        authenticated: false,
        isAuthResolved: true,
        msg: action.success,
        person: null
      };
    case AUTH.FETCH_LOGOUT_EVERYWHERE_SUCCESS:
      store.dispatch(wsDisconnect());
      return {
        ...state,
        status: fetchStates.success,
        authenticated: false,
        isAuthResolved: true,
        msg: action.success,
        person: null
      };
    case AUTH.FETCH_FORGOT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        isAuthResolved: true,
        msg: action.success,
        token: action.token,
      };
    case AUTH.FETCH_CHECK_SUCCESS:
      return {
        ...state,
        token: action.token,
        status: fetchStates.success,
        msg: action.success,
        isAuthResolved: true,
      };
    case AUTH.FETCH_RESET_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        isAuthResolved: true,
      };
    case AUTH.FETCH_SIGNUP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
        fields: action.errors && action.errors.map((item:any) => item.path !== undefined && item.path.toString()),
      };
    case AUTH.FETCH_REGISTRATION_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
        fields: action.errors && action.errors.map((item:any) => item.path !== undefined && item.path.toString()),
      };
    case AUTH.FETCH_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
        fields: action.errors && action.errors.map((item:any) => item.path !== undefined && item.path.toString()),
      };
    case AUTH.FETCH_RESEND_REGISTRATION_CONFIRMATION_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
        fields: action.errors && action.errors.map((item:any) => item.path !== undefined && item.path.toString()),
      };
    case AUTH.FETCH_REMOVE_ACCOUNT_SUCCESS:
      store.dispatch(wsDisconnect());
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case AUTH.RESET_FIELDS:
      return {
        ...state,
        fields: []
      }
    case AUTH.RESET:
      return {
        state: { 
          person: null,
          authenticated: true,
          isAuthResolved: true
        },
      };
    default:
      return state;
  }
};

export default auth;