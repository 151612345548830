export const IconFilterCalendarDay = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--filter-calendar-day">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 496 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={props.color ? props.color : '#2A2C44'}>
              <path d="M434.72,24.296 L398.176,24.296 C397.6,13.768 388.96,5.384 378.288,5.384 C367.616,5.384 358.976,13.768 358.4,24.296 L267.888,24.296 C267.312,13.768 258.672,5.384 248,5.384 C237.328,5.384 228.688,13.768 228.112,24.296 L190.88,24.296 C180.16,24.296 170.48,32.216 169.76,42.856 C168.984,54.328 178.152,64.296 189.68,64.296 L228.112,64.296 C228.688,74.824 237.328,83.208 248,83.208 C258.672,83.208 267.312,74.824 267.888,64.296 L358.4,64.296 C358.976,74.824 367.616,83.208 378.288,83.208 C388.96,83.208 397.6,74.824 398.176,64.296 L434.72,64.296 C446.32,64.296 455.68,73.656 455.68,85.256 L455.68,132.856 L40.32,132.856 L40.32,85.256 C40.32,73.656 49.68,64.296 61.28,64.296 L97.824,64.296 C98.4,74.824 107.04,83.208 117.712,83.208 C128.384,83.208 137.024,74.824 137.68,64.296 L137.68,63.552 C137.68,63.432 137.712,63.328 137.712,63.208 L137.712,25.384 C137.712,25.264 137.68,25.16 137.68,25.04 C137.68,25.04 137.68,24.296 137.608,24.296 C137.032,13.768 128.392,5.384 117.72,5.384 C107.048,5.384 98.4,13.768 97.824,24.296 L61.28,24.296 C27.6,24.296 0.32,51.576 0.32,85.256 L0.32,445.656 C0.32,479.336 27.6,506.616 61.28,506.616 L434.72,506.616 C468.4,506.616 495.68,479.336 495.68,445.656 L495.68,224.856 C495.68,213.816 486.72,204.856 475.68,204.856 C464.696,204.856 455.68,213.864 455.68,224.856 L455.68,445.656 C455.68,457.256 446.32,466.616 434.72,466.616 L61.28,466.616 C49.68,466.616 40.32,457.256 40.32,445.656 L40.32,172.856 L468.16,172.856 C483.36,172.856 495.68,160.536 495.68,145.336 C495.68,125.312 495.68,105.288 495.68,85.256 C495.68,51.576 468.4,24.296 434.72,24.296 Z" id="Path"></path>
              <path d="M282.504,378.568 L269.072,378.568 L269.072,238 C269.072,230.744 265.144,224.064 258.816,220.528 C252.48,217 244.736,217.168 238.56,220.984 L202.992,242.968 C193.6,248.776 190.688,261.096 196.496,270.496 C202.304,279.896 214.648,282.784 224.024,277 L229.08,273.872 L229.08,378.568 L215.656,378.568 C204.608,378.568 195.656,387.52 195.656,398.568 C195.656,409.616 204.608,418.568 215.656,418.568 L282.504,418.568 C293.552,418.568 302.504,409.616 302.504,398.568 C302.504,387.52 293.552,378.568 282.504,378.568 Z" id="Path"></path>
          </g>
      </g>
    </svg>
  </span>
);


export const IconFilterCalendarWeek = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--filter-calendar-week">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 496 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={props.color ? props.color : '#2A2C44'}>
              <path d="M434.72,24.296 L398.176,24.296 C397.6,13.768 388.96,5.384 378.288,5.384 C367.616,5.384 358.976,13.768 358.4,24.296 L267.888,24.296 C267.312,13.768 258.672,5.384 248,5.384 C237.328,5.384 228.688,13.768 228.112,24.296 L190.88,24.296 C180.16,24.296 170.48,32.216 169.76,42.856 C168.984,54.328 178.152,64.296 189.68,64.296 L228.112,64.296 C228.688,74.824 237.328,83.208 248,83.208 C258.672,83.208 267.312,74.824 267.888,64.296 L358.4,64.296 C358.976,74.824 367.616,83.208 378.288,83.208 C388.96,83.208 397.6,74.824 398.176,64.296 L434.72,64.296 C446.32,64.296 455.68,73.656 455.68,85.256 L455.68,132.856 L40.32,132.856 L40.32,85.256 C40.32,73.656 49.68,64.296 61.28,64.296 L97.824,64.296 C98.4,74.824 107.04,83.208 117.712,83.208 C128.384,83.208 137.024,74.824 137.68,64.296 L137.68,63.552 C137.68,63.432 137.712,63.328 137.712,63.208 L137.712,25.384 C137.712,25.264 137.68,25.16 137.68,25.04 C137.68,25.04 137.68,24.296 137.608,24.296 C137.032,13.768 128.392,5.384 117.72,5.384 C107.048,5.384 98.4,13.768 97.824,24.296 L61.28,24.296 C27.6,24.296 0.32,51.576 0.32,85.256 L0.32,445.656 C0.32,479.336 27.6,506.616 61.28,506.616 L434.72,506.616 C468.4,506.616 495.68,479.336 495.68,445.656 L495.68,224.856 C495.68,213.816 486.72,204.856 475.68,204.856 C464.696,204.856 455.68,213.864 455.68,224.856 L455.68,445.656 C455.68,457.256 446.32,466.616 434.72,466.616 L61.28,466.616 C49.68,466.616 40.32,457.256 40.32,445.656 L40.32,172.856 L468.16,172.856 C483.36,172.856 495.68,160.536 495.68,145.336 C495.68,125.312 495.68,105.288 495.68,85.256 C495.68,51.576 468.4,24.296 434.72,24.296 Z" id="Path"></path>
              <path d="M239.592,420.664 C237.048,420.664 234.448,420.168 231.952,419.136 C221.752,414.92 216.896,403.216 221.12,393.008 L280.352,257.912 L206.272,257.912 C195.224,257.912 186.272,248.96 186.272,237.912 C186.272,226.864 195.224,217.912 206.272,217.912 L310.272,217.912 C316.952,217.912 323.184,221.248 326.896,226.8 C330.608,232.352 331.304,239.392 328.744,245.56 L258.08,408.304 C254.896,416 247.448,420.664 239.592,420.664 Z" id="Path"></path>
          </g>
      </g>
    </svg>
  </span>
);


export const IconFilterCalendarMonth = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--filter-calendar-month">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 496 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={props.color ? props.color : '#2A2C44'}>
              <path d="M434.72,24.296 L398.176,24.296 C397.6,13.768 388.96,5.384 378.288,5.384 C367.616,5.384 358.976,13.768 358.4,24.296 L267.888,24.296 C267.312,13.768 258.672,5.384 248,5.384 C237.328,5.384 228.688,13.768 228.112,24.296 L190.88,24.296 C180.16,24.296 170.48,32.216 169.76,42.856 C168.984,54.328 178.152,64.296 189.68,64.296 L228.112,64.296 C228.688,74.824 237.328,83.208 248,83.208 C258.672,83.208 267.312,74.824 267.888,64.296 L358.4,64.296 C358.976,74.824 367.616,83.208 378.288,83.208 C388.96,83.208 397.6,74.824 398.176,64.296 L434.72,64.296 C446.32,64.296 455.68,73.656 455.68,85.256 L455.68,132.856 L40.32,132.856 L40.32,85.256 C40.32,73.656 49.68,64.296 61.28,64.296 L97.824,64.296 C98.4,74.824 107.04,83.208 117.712,83.208 C128.384,83.208 137.024,74.824 137.68,64.296 L137.68,63.552 C137.68,63.432 137.712,63.328 137.712,63.208 L137.712,25.384 C137.712,25.264 137.68,25.16 137.68,25.04 C137.68,25.04 137.68,24.296 137.608,24.296 C137.032,13.768 128.392,5.384 117.72,5.384 C107.048,5.384 98.4,13.768 97.824,24.296 L61.28,24.296 C27.6,24.296 0.32,51.576 0.32,85.256 L0.32,445.656 C0.32,479.336 27.6,506.616 61.28,506.616 L434.72,506.616 C468.4,506.616 495.68,479.336 495.68,445.656 L495.68,224.856 C495.68,213.816 486.72,204.856 475.68,204.856 C464.696,204.856 455.68,213.864 455.68,224.856 L455.68,445.656 C455.68,457.256 446.32,466.616 434.72,466.616 L61.28,466.616 C49.68,466.616 40.32,457.256 40.32,445.656 L40.32,172.856 L468.16,172.856 C483.36,172.856 495.68,160.536 495.68,145.336 C495.68,125.312 495.68,105.288 495.68,85.256 C495.68,51.576 468.4,24.296 434.72,24.296 Z" id="Path"></path>
              <g id="Group" transform="translate(104.000000, 216.000000)">
                  <path d="M116.024,63.72 C116.024,30.648 90.728,3.736 59.632,3.736 C38.4,3.736 19.216,16.2 9.568,36.272 C4.784,46.224 8.976,58.176 18.928,62.96 C28.872,67.744 40.832,63.56 45.616,53.6 C48.536,47.52 53.912,43.736 59.624,43.736 C68.664,43.736 76.016,52.704 76.016,63.72 C76.016,74.776 68.664,83.776 59.624,83.776 C48.576,83.776 39.624,92.728 39.624,103.776 C39.624,114.824 48.576,123.776 59.624,123.776 C68.664,123.776 76.016,132.744 76.016,143.76 C76.016,154.776 68.664,163.744 59.624,163.744 C53.904,163.744 48.536,159.96 45.616,153.88 C40.832,143.928 28.896,139.728 18.928,144.52 C8.976,149.304 4.776,161.248 9.568,171.208 C19.216,191.28 38.4,203.744 59.632,203.744 C90.728,203.744 116.024,176.84 116.024,143.76 C116.024,128.384 110.512,114.392 101.536,103.76 C110.512,93.12 116.024,79.112 116.024,63.72 Z" id="Path"></path>
                  <path d="M217.56,204.568 C182.904,204.568 154.712,176.376 154.712,141.712 L154.712,65.76 C154.712,31.096 182.904,2.904 217.56,2.904 C252.224,2.904 280.416,31.096 280.416,65.76 L280.416,141.712 C280.416,176.376 252.224,204.568 217.56,204.568 Z M217.56,42.904 C204.96,42.904 194.712,53.16 194.712,65.76 L194.712,141.712 C194.712,154.312 204.96,164.568 217.56,164.568 C230.16,164.568 240.416,154.312 240.416,141.712 L240.416,65.76 C240.416,53.16 230.16,42.904 217.56,42.904 Z" id="Shape" fillRule="nonzero"></path>
              </g>
          </g>
      </g>
    </svg>
  </span>
);