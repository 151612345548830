import { NavLink } from 'react-router-dom'


interface TabNavEnergyProps {
  location: any,
}

const TabNavEnergy: React.FC<TabNavEnergyProps> = ({ location }) => (
    <nav className="tabnav">
      <NavLink 
        to={{ pathname: `/energy/usage` }} 
        className={location.pathname === '/energy/usage' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        Energy Usage
      </NavLink>
      <NavLink 
        to={{ pathname: `/energy/production` }} 
        className={location.pathname === '/energy/production' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        Energy Production
      </NavLink>
    </nav>
)


export default TabNavEnergy