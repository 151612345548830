import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { forgotPassword,  } from '../../actions/auth'
import classnames from 'classnames'
import { VERSION } from '../../config'
import { IconEmail } from '../partials/IconsForm'
import { IconBack } from '../partials/IconsNavigation'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { container, item } from '../../helpers/framer'
import { RootState } from '../../reducers'
import fetchStates from '../../types/fetchStates'
import withRouter from '../partials/WithRouter'


interface AuthForgotPasswordProps {
  router: {params: {token: string}, navigate: any},
  auth: { status: string, fields: Array<string>, authenticated: boolean, token: string },
  forgotPassword: (options: { reset_email: string }) => Promise<void>,
}


interface AuthForgotPasswordState {
  formSubmitted: boolean,
  reset_email: string,
}


export class AuthForgotPassword extends React.Component<AuthForgotPasswordProps, AuthForgotPasswordState> {


  state = {
    formSubmitted: false,
    reset_email: '',
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { reset_email } = this.state;
    await this.props.forgotPassword({ reset_email })
    if(this.props.auth.status === fetchStates.success) {
      this.setState({ reset_email: '', formSubmitted: false });
      this.props.router.navigate(`/reset-password/${this.props.auth.token}`);
    }
  }


  render() {

    const { formSubmitted, reset_email } = this.state;
    const { authenticated, fields, status } = this.props.auth;
    
    return authenticated ? <Navigate to="/" /> : (
      <div className="template template-auth">
        <div className="page-content">
          <motion.div 
            className="auth-container" 
            variants={container}
            initial="hidden"
            animate="visible"
          >
            <div className="auth-back-button">
              <Link to="/" className="btn-square">
                <IconBack color="#ffffff" />
              </Link>
            </div>
            <div className="auth-logo" />
            <div className="auth-box">

              <div className="auth-box__wrapper">
                <motion.div className="auth-form__header" key={1} variants={item}>
                  <h1 className="auth-box__title">Reset Password</h1>
                </motion.div>


                <motion.p className="auth-box__text" key={2} variants={item}>Enter your email address and we'll send you a link to reset your password.</motion.p>

                <form className='auth-form' onSubmit={this.handleOnSubmit}>
                  <motion.div className="form-group" key={3} variants={item}>
                    <label htmlFor='reset_email'>
                      Email
                    </label>
                    <div className="input-group"> 
                      <span className="input-icon input-icon--email">
                        <IconEmail color="#A19BCD" />
                      </span>
                      <input
                        id='reset_email'
                        type='email'
                        placeholder="Email"
                        autoComplete="email"
                        value={reset_email}
                        onChange={e => this.setState({ reset_email: e.target.value })}
                        className={classnames('', { 'input-error': fields && fields.includes('reset_email') })}
                      />
                    </div>
                  </motion.div>
                  <motion.div className="form-group" key={4} variants={item}>
                    <input 
                      type='submit' 
                      className='btn btn--primary btn--large btn--fullwidth' 
                      value='Reset Your Password' 
                      disabled={formSubmitted && status === 'fetching' ? true : false} 
                    />
                  </motion.div>
                </form>
                </div>
            </div>
            <div className="auth-version">v{VERSION}</div>
          </motion.div>
        </div>
      </div>
    )
  }
}


export default withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { forgotPassword }
)(AuthForgotPassword));