import React from 'react'
import { connect } from 'react-redux'
import { fetchEventsHistory, markEventsHistoryAsRed, deleteEvents } from '../../actions/data'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import moment from 'moment'
import Pagination from '../partials/Pagination'
import classnames from 'classnames'


interface HistoryEventsSecurityProps {
  router: {navigate: (to: string) => any, params: { id: string }, location: string},
  data: any,
  fetchEventsHistory: (options: { limit: number , skip: number, filter: string }) => Promise<void>,
  markEventsHistoryAsRed: (ids: any) => Promise<void>,
  deleteEvents: (ids: any) => Promise<any>
}


interface HistoryEventsSecurityState {
  currentPage: number,
  perPage: number,
  eventsHistory: Array<Object>,
  eventsHistoryCount: number,
  eventsFilter: string,
  checkedArray: any,
  checkedAll: any,
}


export class HistoryEventsSecurity extends React.Component<HistoryEventsSecurityProps, HistoryEventsSecurityState> {


  state = {
    currentPage: 0,
    perPage: 10,
    eventsHistory: [],
    eventsHistoryCount: 0,
    eventsFilter: '2',
    checkedArray: [],
    checkedAll: [],
  }


  componentDidMount() {
    this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage, filter: this.state.eventsFilter })
  }


  updatePagination = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(event.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(event.target.value), currentPage: 0, filter: this.state.eventsFilter })
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, filter: this.state.eventsFilter })
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, filter: this.state.eventsFilter })
  }


  reloadPage = ({ perPage, currentPage, filter }: { perPage: number, currentPage: number, filter: string }) => {
    let arrayIds: number[] = []
    this.props.fetchEventsHistory({ limit: perPage, skip: perPage * currentPage, filter })
    .then(() => {
      if(this.props.data.status === fetchStates.success) {
        const { eventsHistory, eventsHistoryCount } = this.props.data
        const filteredArrayIds = eventsHistory.filter((item:any) => item.event_red === false && item)
        arrayIds = filteredArrayIds.map((item:any) => item.id)
        this.setState({ eventsHistory, eventsHistoryCount })
      }
    })
    .then(() => {
      if(arrayIds.length > 0) {
        this.props.markEventsHistoryAsRed(arrayIds)
      }
    })
  }


  updateCheckedArray = (id: number) => {
    const checkedArray:any = this.state.checkedArray
    if(checkedArray.includes(id)) {
      const updatedArray = this.state.checkedArray.filter(function(item) {return item !== id})
      const updatedArrayAll = this.state.checkedAll.filter(item => item !== this.state.currentPage)
      this.setState({ checkedArray: updatedArray, checkedAll: updatedArrayAll })
    } else {
      this.setState({ checkedArray: checkedArray.concat(id)})
    }
  }


  updateCheckedArrayMultiple = () => {
    let filteredCheckedArray: any = []
    let updatedCheckedArray: any = []
    let checkedArray:any = this.state.checkedArray
    filteredCheckedArray = this.state.eventsHistory.filter((item: {id: number}) => !checkedArray.includes(item.id))
    updatedCheckedArray = filteredCheckedArray.map((item: {id: number}) => item.id)
    const checkedAll: any = this.state.checkedAll
    if(checkedAll.includes(this.state.currentPage)) {
      const updatedArrayAll = this.state.checkedAll.filter(item => item !== this.state.currentPage)
      let currentPageCheckedArray: any = []
      currentPageCheckedArray = this.state.eventsHistory.map((item: {id: number}) => item.id)
      let filteredCheckedArray: any = []
      filteredCheckedArray = checkedArray.filter((item: number) => !currentPageCheckedArray.includes(item))
      this.setState({ checkedAll: updatedArrayAll, checkedArray: filteredCheckedArray })
    } else {
      this.setState({ checkedAll: checkedAll.concat(this.state.currentPage), checkedArray: this.state.checkedArray.concat(updatedCheckedArray) })
    }
  }


  deleteCheckedEvents = () => {
    const ids = this.state.checkedArray
    this.props.deleteEvents(ids)
    .then(() => {
      this.reloadPage({ perPage: this.state.perPage, currentPage: 0, filter: this.state.eventsFilter })
      this.setState({ checkedArray: [], checkedAll: [], currentPage: 0 })
    })
  }


  render() {

    const { eventsHistory, eventsHistoryCount, perPage, currentPage, checkedArray, checkedAll } = this.state

    let testCheckedArray: any = []
    testCheckedArray = checkedArray
    let testCheckedAll: any = []
    testCheckedAll = checkedAll

    return (
      <TemplatePage title="History → Security" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>

          <div className="events">
            <div className="events-column-titles">
              <span>Event category</span>
              <span>Event description</span>
              <span>Event parameters</span>
              <span>Event data</span>
              <span>Device</span>
              <span>Time</span>
              <span className="event-column-title event-column-title--checkbox">
                <input 
                  onChange={this.updateCheckedArrayMultiple}
                  checked={testCheckedAll.includes(currentPage)}
                  type="checkbox" 
                  name="default"  
                />
              </span>
            </div>
            {
              eventsHistory && eventsHistory.length > 0 && 
                eventsHistory.map((item: { id: number, ts: number, device_title: string, event_cat: string, event_type: string, event_par: string, event_data: string, event_red: boolean }) => {
                  return (
                    <div className={classnames('event-item', { 'event-item--red': item && item.event_red === true })} key={item.id}>
                      <span className={classnames('event-item__type', { 
                        'event-item__type--red': item && item.event_cat === '1',
                        'event-item__type--orange': item && item.event_cat === '2',
                        'event-item__type--green': item && item.event_cat === '3',
                        'event-item__type--blue': item && item.event_cat === '4',
                        'event-item__type--purple': item && item.event_cat === '5',
                        })} 
                        key={item.id}
                      >
                        {item.event_cat === '1' && 'ALARM'}
                        {item.event_cat === '2' && 'SECURITY'}
                        {item.event_cat === '3' && 'EVENT'}
                        {item.event_cat === '4' && 'I/O'}
                        {item.event_cat === '5' && 'SCENARIO'}
                      </span>
                      <span className="event-item__event">
                        {item.event_cat === '1' && item.event_type === '1' && 'Burglar alarm'}
                        {item.event_cat === '1' && item.event_type === '2' && 'SOS'}
                        {item.event_cat === '2' && item.event_type === '1' && 'Sending the SMS'}
                        {item.event_cat === '3' && item.event_type === '1' && 'Movement detection'}
                        {item.event_cat === '3' && item.event_type === '2' && 'Temperature exceeded'}
                        {item.event_cat === '4' && item.event_type === '1' && 'Doors opened/closed'}
                        {item.event_cat === '4' && item.event_type === '2' && 'Pressing the button'}
                        {item.event_cat === '4' && item.event_type === '3' && 'Input changed'}
                        {item.event_cat === '4' && item.event_type === '4' && 'Output changed'}
                        {item.event_cat === '5' && item.event_type === '1' && 'Scenario was called'}
                      </span>
                      <span>
                        { item.event_type === '1' && item.event_par === '0' && 'alarm is disarmed' }
                        { item.event_type === '1' && item.event_par === '1' && 'alarm is arming' }
                        { item.event_type === '1' && item.event_par === '2' && 'alarm is armed' }
                        { item.event_type === '1' && item.event_par === '3' && 'alarm is disarming' }
                        { item.event_type === '1' && item.event_par === '4' && 'alarm is activated' }
                        { item.event_cat === '3' && item.event_type === '2' && item.event_par === '1' && 'UP'}
                        { item.event_cat === '3' && item.event_type === '2' && item.event_par === '2' && 'DOWN'}
                        { item.event_cat === '4' && item.event_type === '1' && item.event_par === '1' && 'OPEN'}
                        { item.event_cat === '4' && item.event_type === '1' && item.event_par === '2' && 'CLOSED'}
                        { item.event_cat === '4' && item.event_type === '2' && `${item.event_par !== null && item.event_par}`}
                        { item.event_cat === '4' && item.event_type === '3' && item.event_par === '1' && 'OFF'}
                        { item.event_cat === '4' && item.event_type === '3' && item.event_par === '2' && 'ON'}
                        { item.event_cat === '4' && item.event_type === '4' && item.event_par === '1' && 'OFF'}
                        { item.event_cat === '4' && item.event_type === '4' && item.event_par === '2' && 'ON'}
                        { item.event_cat === '5' && item.event_par }
                      </span>
                      <span>
                        { item.event_cat === '3' && item.event_type === '2' && `${item.event_data} C`}
                        { item.event_cat === '4' && item.event_type === '1' && `${item.event_data} ID`}
                      </span>
                      <span className="event-item__device-serial">
                        {item.device_title}
                      </span>
                      <span className="event-item__time">
                        {moment(item.ts).format('DD/MM/YYYY HH:mm:ss')}
                      </span>
                      <span className="event-item__checkbox">
                        <input 
                          onChange={() => this.updateCheckedArray(item.id)}
                          checked={testCheckedArray.includes(item.id)}
                          type="checkbox" 
                          name="delete"  
                        /> 
                      </span>
                    </div>
                  )
              })
            }
            {
              eventsHistory && eventsHistory.length > 0 && (
                <div className="btn--confirm-checkbox-removal">
                  <button type="button" className="btn btn--primary" disabled={testCheckedArray.length === 0} onClick={this.deleteCheckedEvents}>
                    Delete
                  </button>
                </div>
              )
            }

          </div>

          <Pagination 
            perPage={perPage}
            currentPage={currentPage} 
            itemsCount={eventsHistoryCount} 
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ data }: RootState) => ({ data }),
  { fetchEventsHistory, markEventsHistoryAsRed, deleteEvents }
)(HistoryEventsSecurity)))