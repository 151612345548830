import React from 'react'
import { connect } from 'react-redux'
import { fetchRoomById, updateRoomById } from '../../actions/room'
import { fetchAllDevices } from '../../actions/device'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Alert from '../partials/Alert'
import { IconName, IconDevice } from '../partials/IconsForm'
import { IconClose } from '../partials/Icons'
import classnames from 'classnames'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { room_icons } from '../../helpers/roomIcons'
import { motion } from 'framer-motion'


interface RoomsEditProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  room: { status: string, fields: Array<string>, roomsCount: number, room: { room_name: string, room_color: string, room_image: number, device_serial: string} },
  device: { devices: any},
  fetchAllDevices: (options: { limit: number, skip: number }) => Promise<void>,
  fetchRoomById: (id: string) => Promise<void>,
  updateRoomById: (options: { id: string, room_name: string, room_image: number, assigned_devices: Array<string> }) => Promise<void>,
}


interface RoomsEditState {
  room_name: string,
  room_image: number,
  device_serial: string,
  free_devices: Array<string>,
  assigned_devices: Array<string>,
  formSubmitted: boolean,
  noData: boolean,
}


export class RoomsEdit extends React.Component<RoomsEditProps, RoomsEditState> {


  state = {
    room_name: '',
    room_image: 1,
    device_serial: '',
    free_devices: [],
    assigned_devices: [],
    formSubmitted: false,
    noData: false,
  }


  componentDidMount() {
    this.fetchRoomById();
  }



  fetchRoomById = async () => {
    await this.props.fetchRoomById(this.props.router.params.id)
    if(this.props.room.status === fetchStates.success) {
      const { room_name, room_image, device_serial } = this.props.room.room
      this.setState({ room_name, room_image, device_serial: device_serial ? device_serial : '', noData: false })
      this.props.fetchAllDevices({ limit: 1000, skip: 0 })
      .then(() => {
        const { devices } = this.props.device
        const assigned_devices = devices.filter((device:{room_id:string}) => device.room_id === this.props.router.params.id)
        const free_devices = devices.filter((device:{room_id:string}) => device.room_id === null)
        this.setState({ free_devices, assigned_devices })
      })
    } 
    if(this.props.room.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  handleSelectImage = (image: number) => {
    this.setState({ room_image: image })
  }


  handleSubmitUpdateRoom = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    const { room_name, room_image, assigned_devices } = this.state
    const assigned_devices_array = assigned_devices.map((device: {device_id: string}) => device.device_id)
    await this.props.updateRoomById({ id: this.props.router.params.id, room_name, room_image, assigned_devices: assigned_devices_array })
    if(this.props.room.status === fetchStates.success) {
      this.setState({ room_name: '', formSubmitted: false })
      this.props.router.navigate('/')
    }
  }


  removeDevice = (device: {device_id: string, device_title: string}) => {
    const assigned_devices = this.state.assigned_devices.filter((item:{device_id: string}) => device.device_id !== item.device_id)
    let free_devices: any[] = []
    free_devices.push(...this.state.free_devices, device)
    this.setState({ assigned_devices, free_devices })
  }


  assignDevice = (device_id:string) => {
    if(device_id) {
      const device = this.state.free_devices.filter((item:{device_id: string}) => device_id === item.device_id)
      const free_devices = this.state.free_devices.filter((item:{device_id: string}) => device_id !== item.device_id)
      const assigned_devices = this.state.assigned_devices
      assigned_devices.push(device[0])
      this.setState({ assigned_devices, free_devices })
    }
  }


  render() {

    const { room_name, room_image, free_devices, assigned_devices, formSubmitted, noData } = this.state;
    const { fields, status } = this.props.room;

    if(noData) {
      return (
        <TemplatePage title="Rooms → Edit Room" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Room does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Rooms → Edit Room" buttonBack={true} navigate={this.props.router.navigate} button={true} buttonLink={`/rooms/delete/${this.props.router.params.id}`} buttonType="delete" buttonText="Delete Room">
        <ContentMain>
          <form className="form" onSubmit={this.handleSubmitUpdateRoom}>

            <div className="form-group">
              <label htmlFor="room_name">
                Room name (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconName color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="room_name"
                  id="room_name" 
                  value={ room_name }
                  onChange={e => this.setState({ room_name: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('room_name') })}
                />
              </div>
            </div>

            <div className="form-group form-group--no-margin">
              <label htmlFor="room_image">
                Select room image (required)
              </label>
              <div className="image-selector">
                {
                  room_icons && room_icons.map(image => (
                    <motion.div 
                      key={image.id} 
                      className={classnames('image-selector__image-wrapper', { 'image-selector__image--selected': room_image === image.id })} 
                      onClick={() => this.handleSelectImage(image.id)}
                      whileHover={{ scale: 1.08 }}
                      transition={{ duration: 0.05 }}
                    >
                      <div className="image-selector__image">
                        {image.icon}
                      </div>
                    </motion.div>
                  ))
                }
                <div className="image-selector__clear" />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="ms_devices">
                Add devices to the room
              </label>
              <div className="assigned-items">
              {
                assigned_devices && assigned_devices.map((item:{ device_id: string, device_title: string }) => 
                  <motion.span 
                    className="tag tag--small" 
                    key={item.device_id}
                    whileHover={{ scale: 1.08 }}
                    transition={{ duration: 0.05 }}
                  >
                    <span className="tag__title">{item.device_title}</span>
                    <span className="tag__remove" onClick={e => this.removeDevice(item)}>
                      <IconClose color="#ffffff"/>
                    </span>
                  </motion.span>
                )
              }
              </div>
              {
                free_devices && free_devices.length > 0 ? (
                <div className="input-group">
                  <span className="input-icon">
                    <IconDevice color="#A19BCD" />
                  </span>
                  <select 
                    onChange={e => this.assignDevice(e.target.value)}
                    className={classnames('', { 'input-error': fields && fields.includes('assigned_devices[0]') })}
                  >


                    <option key="0" value="">Select Device</option>
                    {
                      free_devices && free_devices.length > 0 && free_devices.map((device:{device_id: string, device_title: string}) => 
                        <option key={device.device_id} value={device.device_id}>{device.device_title}</option>
                      )
                    }
                  </select></div> ) : ( <Alert type="info">There are no devices left to be assigned to this room. Please add more devices into your account.</Alert> )
              }

            </div>

            <div className="form__footer">
              <input 
                type="submit" 
                value="Update Room" 
                className="btn btn--primary btn--large"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>

          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ room, device }: RootState) => ({ room, device }),
  { fetchRoomById, updateRoomById, fetchAllDevices }
)(RoomsEdit)))