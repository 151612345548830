import React from 'react';
import { connect } from 'react-redux';
import { fetchDeviceById, updateDevice } from '../../actions/device';
import { fetchAllRooms } from '../../actions/room';
import withAuthorization from '../auth/withAuthorization';
import TemplatePage from '../templates/TemplatePage';
import ContentMain from '../partials/ContentMain';
import { RootState } from '../../reducers';
import withRouter from '../partials/WithRouter';
import IconNavHistory from '../partials/IconNavHistory';


interface DevicesEditProps {
  router: { location: string, navigate: (to: string) => any, params: { id: string } },
}


export class DevicesEdit extends React.Component<DevicesEditProps> {
  render() {
    return (
      <TemplatePage title="History">
        <ContentMain>
          <IconNavHistory location={this.props.router.location} />
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, room }: RootState) => ({ device, room }),
  { fetchDeviceById, updateDevice, fetchAllRooms }
)(DevicesEdit)));