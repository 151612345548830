import DeviceUpdate from '../partials/DeviceUpdate'
import { IconDeviceMailbox } from '../partials/IconsDevices'


interface DeviceMagicMailboxProps {
  device: any,
}


const DeviceMagicMailbox: React.FC<DeviceMagicMailboxProps> = ({ device }) => {


  if(device.device_data && device.device_data.updSt === -1) {
    // UPDATE ERROR
    return <DeviceUpdate type="error" />
  }
  

  if(device.device_data && device.device_data.updSt === 1) {
    // UPDATE IN PROGRESS
    return <DeviceUpdate type="updating" />
  }


  if(device.device_data && device.device_data.updSt === 2) {
    // UPDATE COMPLETED, GETTING DATA...    
    return <DeviceUpdate type="updated" />
  }


  return (
    <div className="device-type device-type__magicgarage">
      <div className="garage-row__icon">
        <IconDeviceMailbox color={device.device_data ? '#000000' : '#828BA9'} />
      </div>
      <div className="garage-row__tools">
        <div className="garage-tools__description">
          <div className="garage-description__title">State:</div>
          <div className="garage-description__state">{ device.device_data ? (<span className="state--on">FULL</span>) : 'UNKNOWN'}</div>
        </div>
      </div>
    </div>
  )
}


export default DeviceMagicMailbox