import { WS } from '../types'


export const wsResetConnection = () => async (dispatch: any) => { 
  await dispatch({ type: WS.RESET_CONNECTION }); 
}


export const wsConnect = () => async (dispatch: any) => { 
  await dispatch({ type: WS.CONNECT }); 
}


export const wsDisconnect = () => async (dispatch: any) => { 
  await dispatch({ type: WS.DISCONNECT }); 
}


export const wsGetDataByDeviceSerial = (device_serial:string) => async (dispatch: any) => {
  await dispatch({ type: WS.GET_DATA_BY_DEVICE_SERIAL, device_serial })
}


export const wsHandleDeviceUpdate = (device_serial:string) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_BY_DEVICE_SERIAL, device_serial })
}


export const wsUpdateAlarmSettings = (options: { device_serial: string, alarm_settings: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_ALARM_SETTINGS, device_serial: options.device_serial, alarm_settings: options.alarm_settings })
}


export const wsUpdateNotificationsSettings = (options: { device_serial: string, notifications_settings: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_NOTIFICATIONS_SETTINGS, device_serial: options.device_serial, notifications_settings: options.notifications_settings })
}


export const wsPressButtonByDeviceSerial = (options: { device_serial: string, btn: number }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_PRESS_BUTTON_BY_DEVICE_SERIAL, device_serial: options.device_serial, btn: options.btn })
}


export const wsPressIdentifyByDeviceSerial = (device_serial: string) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_PRESS_IDENTIFY_BY_DEVICE_SERIAL, device_serial })
}


export const wsPressDouByDeviceSerial = (options: { device_serial: string, dou_array: Array<number> }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_PRESS_DOU_ARRAY_BY_DEVICE_SERIAL, device_serial: options.device_serial, dou_array: options.dou_array })
}


export const wsUpdateAlarmByDeviceSerial = (options: { device_serial: string, alarm_settings: Array<number> }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_ALARM_BY_DEVICE_SERIAL, device_serial: options.device_serial, alarm_settings: options.alarm_settings })
}


export const wsUpdateClockByDeviceSerial = (options: { device_serial: string, clock_settings: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_CLOCK_BY_DEVICE_SERIAL, device_serial: options.device_serial, clock_settings: options.clock_settings })
}


export const wsUpdateGeneralSettingsByDeviceSerial = (options: { device_serial: string, general_settings: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_GENERAL_SETTINGS_BY_DEVICE_SERIAL, device_serial: options.device_serial, general_settings: options.general_settings })
}


export const wsUpdateCornerButtonsByDeviceSerial = (options: { device_serial: string, corner_buttons: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_CORNER_BUTTONS_BY_DEVICE_SERIAL, device_serial: options.device_serial, corner_buttons: options.corner_buttons })
}


export const wsUpdateStatusIconsByDeviceSerial = (options: { device_serial: string, status_icons: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_STATUS_ICONS_BY_DEVICE_SERIAL, device_serial: options.device_serial, status_icons: options.status_icons })
}


export const wsUpdateThermostatSettingsByDeviceSerial = (options: { device_serial: string, thermostat_settings: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_THERMOSTAT_BY_DEVICE_SERIAL, device_serial: options.device_serial, thermostat_settings: options.thermostat_settings })
}


export const wsUpdateScenarioSettingsByDeviceSerial = (options: { device_serial: string, scenario_settings: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_SCENARIO_SETTINGS_BY_DEVICE_SERIAL, device_serial: options.device_serial, scenario_settings: options.scenario_settings })
}


export const wsUpdateDevicesList = (options: { device_serial: string, devices: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_UPDATE_DEVICE_LIST_BY_DEVICE_SERIAL, device_serial: options.device_serial, devices: options.devices })
}


export const wsGetEnergyUsageFromDataProvider = (options: { device_serial: string }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_GET_ENERGY_USAGE_FROM_DATA_PROVIDER, device_serial: options.device_serial })
}


export const wsPressRunScenarioByScenarioId = (options: { device_serial: string, scenario_id: number }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_PRESS_RUN_SCENARIO_BY_SCENARIO_ID, device_serial: options.device_serial,scenario_id: options.scenario_id })
}


export const wsGetDevDevicesSettings = (options: { device_serial: string, dev_settings: any }) => async (dispatch: any) => {
  await dispatch({ type: WS.DEVICE_GET_DEVELOPER_DEVICE_SETTINGS_BY_DEVICE_SERIAL, device_serial: options.device_serial, dev_settings: options.dev_settings })
}
