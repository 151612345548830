import React from 'react';
import { connect } from 'react-redux';
import { fetchDeviceById } from '../../actions/device'
import { fetchDevMovementHistory } from '../../actions/data';
import fetchStates from '../../types/fetchStates';
import { RootState } from '../../reducers';
import withAuthorization from '../auth/withAuthorization';
import TemplatePage from '../templates/TemplatePage';
import ContentMain from '../partials/ContentMain';
import withRouter from '../partials/WithRouter';
import Pagination from '../partials/Pagination';
import moment from 'moment';
import TabNavDeveloper from '../partials/TabNavDeveloper';


interface SettingsDeveloperProps {
  router: {location: string, navigate: (to: string) => any, params: { id: string }},
  data: any,
  device: any,
  fetchDeviceById: (id: string) => Promise<any>,
  fetchDevMovementHistory: (options: { limit: number , skip: number, device_serial: string }) => Promise<void>,
}


interface SettingsDeveloperState {
  device: any,
  currentPage: number,
  perPage: number,
  devMovement: Array<Object>,
  devMovementCount: number
}


export class SettingsDeveloper extends React.Component<SettingsDeveloperProps, SettingsDeveloperState> {

  state = {
    device: {},
    currentPage: 0,
    perPage: 10,
    devMovement: [],
    devMovementCount: 0
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id);
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device;
      if(device.device_set_clock !== null ) {
        this.setState({ device });
        this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage });
      }
    }
  }


  updatePagination = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(event.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(event.target.value), currentPage: 0 });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
  }


  reloadPage = async ({ perPage, currentPage }: { perPage: number, currentPage: number }) => {
    await this.props.fetchDevMovementHistory({ limit: perPage, skip: perPage * currentPage, device_serial: this.props.device.device.device_serial });
    if(this.props.data.status === fetchStates.success) {
      const { devMovement, devMovementCount } = this.props.data;
      this.setState({ devMovement, devMovementCount });
    }
  }

  render() {


    const { devMovement, devMovementCount, perPage, currentPage } = this.state

    return (
      <TemplatePage title="Developer" buttonBack={true} navigate={this.props.router.navigate}>

        <TabNavDeveloper location={this.props.router.location} id={this.props.router.params.id} />

        <ContentMain>

          <div className="events">
            <div className="events-column-titles">
              <span>Time</span>
              <span>Radar status</span>
              <span>Radar TD</span>
              <span>Radar TP</span>
            </div>
            {
              devMovement && devMovement.length > 0 && 
                devMovement.map((item: { id: number, tst: number, r_st: number, r_td: number, r_tp: number }) => {
                  return (
                    <div className="event-item" key={item.id}>
                      <span className="event-item__event">
                        {moment(item.tst * 1000).format('DD/MM/YYYY HH:mm:ss')}
                      </span>
                      <span className="event-item__event">
                        {item.r_st === null ? 0 : item.r_st}
                      </span>
                      <span className="event-item__event">
                        {item.r_td === null ? 0 : item.r_td}
                      </span>
                      <span className="event-item__event">
                        {item.r_tp === null ? 0 : item.r_tp}
                      </span>
                    </div>
                  )
              })
            }
          </div>

          <Pagination 
            perPage={perPage}
            currentPage={currentPage} 
            itemsCount={devMovementCount} 
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </ContentMain>
      </TemplatePage>
    )
  }

}


export default withAuthorization(withRouter(connect(
  ({ data, device }: RootState) => ({ data, device }),
  { fetchDevMovementHistory, fetchDeviceById }
)(SettingsDeveloper)))