import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'


interface AuthByPermissionsProps {
  auth: { person: any },
  requiresPermissions: string,
  children: JSX.Element,
}


export class AuthByPermissions extends React.Component<AuthByPermissionsProps> {

  render() {

    let { requiresPermissions, auth } = this.props
    
    if(requiresPermissions === undefined) {
      console.error('[ERROR]: Please set requirements for authorization')
      return 'Please set requirements for authorization'
    }

    if(!auth.person.permissions) {
      console.error('[ERROR]: Missing auth permissions altogether')
      return 'Missing auth permissions altogether'
    }

    if(auth.person.permissions[requiresPermissions] === undefined) {
      console.error('[ERROR]: Authorization option does not exist')
      return 'Authorization option does not exist'
    }
    
    if (auth.person.permissions[requiresPermissions] === true){
      return (
        <>
          {this.props.children}
        </>
      );
    } else {
      return auth.person.permissions[requiresPermissions]
    }
    
  }
}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  {}
)(AuthByPermissions);