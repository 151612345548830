import React from 'react'
import { connect } from 'react-redux'
import { fetchEventsHistory, markEventsHistoryAsRed, fetchDataBySerialsTypeRange } from '../../actions/data'
import { fetchMagicBoxAll } from '../../actions/device'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import { IconDevice } from '../partials/IconsForm'
import fetchStates from '../../types/fetchStates'
import Alert from '../partials/Alert'
import Chart from 'react-apexcharts'
import moment from 'moment'
import { temperature_chart_settings } from '../../helpers/charts'
import { IconClose } from '../partials/Icons'
import Preloader from '../partials/Preloader'
import { IconFilterCalendarDay, IconFilterCalendarWeek, IconFilterCalendarMonth } from '../partials/IconsFilter'


interface HistoryDataTemperatureProps {
  router: {navigate: (to: string) => any, params: { id: string }, location: string},
  device: any,
  data: any,
  fetchMagicBoxAll: (options: { limit: number , skip: number }) => Promise<void>,
  fetchDataBySerialsTypeRange: (options: { device_serials: Array<string>, data_type: string, data_range: string }) => Promise<void>,
}


interface HistoryDataTemperatureState {
  data_range: string,
  data_ranges: any,
  data_type: string,
  device_serial: string,
  device_title: string,
  devices: any,
  selectedDevices: Array<string>,
  defaultDevice: string,
  chart_settings: {options: { chart: any}}
  temperature_chart_settings: {options: { chart: any}},
  noData: boolean,
  dataLoading: boolean,
}


export class HistoryDataTemperature extends React.Component<HistoryDataTemperatureProps, HistoryDataTemperatureState> {

  state = {
    data_type: 'temperature',
    data_range: 'day',
    data_ranges: [
      { range_id: 'day', range_title: 'Day' },
      { range_id: 'week', range_title: 'Week' },
      { range_id: 'month', range_title: 'Month' }
    ],
    device_serial: '',
    device_title: '',
    devices: [],
    defaultDevice: '',
    selectedDevices: [],
    chart_settings: temperature_chart_settings,
    temperature_chart_settings,
    noData: true,
    dataLoading: false,
  }


  async componentDidMount(): Promise<void> {
    await this.fetchAllDevices();
  }


  fetchAllDevices = async () => {
    await this.props.fetchMagicBoxAll({ limit: 1000, skip: 0 });
    if(this.props.device.status === fetchStates.success) {
      let devices: any = {};
      devices = this.props.device.devicesMagicBox;
      let defaultDevice: any = null
      if(devices.length > 0) {
        defaultDevice = devices[0];
      }
      const filteredDefaultDevices = devices.filter((device: {device_master: boolean}) => {
        if(device.device_master === true){
          return device;
        }
        return null;
      });
      if(filteredDefaultDevices > 0) {
        defaultDevice = filteredDefaultDevices[0];
      } 
      this.setState({ devices, device_serial: defaultDevice.device_serial }, () => {
        this.handleSelectDevice(defaultDevice.device_serial)
      });
    }
  }


  filterChartsByTimeRange = (range_id: string) => {
    this.setState({ data_range: range_id });
    this.fetchDataBySerialsTypeRange({ device_serials: this.state.selectedDevices.map((device:{device_serial: string}) => device.device_serial), data_type: this.state.data_type, data_range: range_id });
  }


  formatNewSeriesData = (data: any) => {
    let new_series_data = [];
    for(const series of data) {
      let new_data = [];
      new_data = series.data.map((sample: any) => {
        const ts = new Date(moment.unix(sample.x).format('DD MMM YYYY HH:mm:ss')).getTime();
        return { x:ts, y:sample.y };
      });
      new_series_data.push({ name: series.name, data: new_data.reverse() }); //
    }
    return new_series_data;
  }


  handleSelectDevice = (id: string) => {
    let selectedDevices: any[] = []
    const filteredDevices = this.state.devices.filter((device:{device_serial: string}) => {
      if(device.device_serial === id) {
        selectedDevices.push(...this.state.selectedDevices, device)
        this.setState({ selectedDevices })
      }
      return device.device_serial !== id
    })
    this.setState({ devices: filteredDevices })
    this.fetchDataBySerialsTypeRange({ device_serials: selectedDevices.map((device) => device.device_serial), data_type: this.state.data_type, data_range: this.state.data_range });
  }


  handleDeviceRemove = (id: string) => {
    const filteredDevices = this.state.selectedDevices.filter((device:{device_serial:string}) => {
      if(device.device_serial === id) {
        let devices: any[] = []
        devices.push(...this.state.devices, device)
        this.setState({ devices })
      }
      return device.device_serial !== id
    })
    this.setState({ selectedDevices: filteredDevices });
    this.fetchDataBySerialsTypeRange({ device_serials: filteredDevices.map((device:{device_serial:string}) => device.device_serial), data_type: this.state.data_type, data_range: this.state.data_range });
  }


  fetchDataBySerialsTypeRange = async (options: { device_serials: Array<string>, data_type: string, data_range: string }) => {
    this.setState({ dataLoading: true });
    const { device_serials, data_type, data_range } = options;
    await this.props.fetchDataBySerialsTypeRange({ device_serials, data_type, data_range })
    if(this.props.data.status === fetchStates.success) {
      const { chart_data } = this.props.data;
      if(chart_data.length > 0) {
        const new_series_data_formated = this.formatNewSeriesData(chart_data);
        let chart_settings: any;
        chart_settings = { ...temperature_chart_settings, series: new_series_data_formated };
        if(data_range === 'month' || data_range === 'week') {
          const xaxis = {
            categories: [],
            labels: {
              formatter: function (value:any) {
                return moment.unix(value/1000).format('DD');
              }
            }
          }
          chart_settings = { ...chart_settings, options: { ...chart_settings.options, xaxis } };
        }
        if(data_range === 'day') {
          const xaxis = {
            categories: [],
            labels: {
              formatter: function (value:any) {
                return moment.unix(value/1000).format('HH:00');
              }
            }
          }
          chart_settings = { ...chart_settings, options: { ...chart_settings.options, xaxis } };
        }
        this.setState({ chart_settings, noData: false });

      } else {
        this.setState({ noData: true });
      }
      this.setState({ dataLoading: false });
    }
  }


  render() {

    const { data_range, data_ranges, devices, selectedDevices, noData, chart_settings, dataLoading } = this.state;

    return (
      <TemplatePage title="History → Temperature" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>


          {
            dataLoading ? (
              <Preloader />
            ) : (
              <>
                <div className="form form--margin-bottom">
                  <div className="form-group">
                    <label htmlFor="devices">
                      SELECT DEVICES:
                    </label>
                    <div className="selected-tags">
                      { selectedDevices.map((device:{device_serial: string, device_title: string}) => (
                        <span className="tag tag--small" key={device.device_serial}>
                          <span className="tag__title">{device.device_title}</span>
                          <span className="tag__remove" onClick={e => this.handleDeviceRemove(device.device_serial)}>
                            <IconClose color="#ffffff"/>
                          </span>
                        </span>
                      ))}
                    </div>
                    { devices.length > 0 ? (
                      <div className="input-group">
                        <span className="input-icon">
                        <IconDevice color="#A19BCD" />
                        </span>
                        <select 
                          name="devices" 
                          onChange={e => this.handleSelectDevice(e.target.value)}
                        >
                          <option key="0">Select device</option>
                          { 
                            devices && devices.map((device: { device_serial: string, device_title: string }) => <option key={device.device_serial} value={device.device_serial}>{device.device_title} ({device.device_serial})</option>)
                          }
                        </select>
                      </div>
                    ) : (
                      selectedDevices.length === 0 && <Alert type="info">There are currently no devices to select from.</Alert>
                    )}
                  </div>
                </div>

                <div className="labels-filter">
                  <div className="labels-filter__title">
                    FILTER BY TIME:
                  </div>
                  { 
                    data_ranges && data_ranges.map((range: {range_id: string, range_title: string }) => {
                      return <span key={range.range_id} className={classnames(`labels-filter__item`, { 'labels-filter__item--event--selected': range.range_id === data_range })} onClick={() => this.filterChartsByTimeRange(range.range_id)}>
                        <span className="labels-filter__item-icon">
                          { range.range_id === 'day' && <IconFilterCalendarDay size={24} color="#828BA9" /> }
                          { range.range_id === 'week' && <IconFilterCalendarWeek size={24} color="#828BA9" /> }
                          { range.range_id === 'month' && <IconFilterCalendarMonth size={24} color="#828BA9" /> }
                        </span>
                        <span className="labels-filter__item-text">
                          {range.range_title}
                        </span>
                      </span>
                    })
                  }
                </div>


                  {
                    noData ? (
                      <div>
                        <Alert type="info">There are no data to be shown</Alert>
                      </div>
                    ) : (
                      <Chart
                        options={chart_settings.options}
                        series={chart_settings.series}
                        type="line"
                        width="100%"
                      />
                    )
                  }

              </>
            )
          }




        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ data, device }: RootState) => ({ data, device }),
  { fetchEventsHistory, markEventsHistoryAsRed, fetchMagicBoxAll, fetchDataBySerialsTypeRange }
)(HistoryDataTemperature)))