import { AUTH } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchAuth = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: AUTH.FETCH });
  return fetch(`${API_ADDRESS}/auth/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: AUTH.FETCH_ERROR, ...json, authenticated: false });
            json && json.errors && json.errors.map((item: {msg: string}) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }   
        })
        .catch((error) => 
          dispatch({
            type: AUTH.FETCH_ERROR, errors: [{ msg: error }], authenticated: false
          })
        );
}


export const login = (options: { email: string, password: string, keepMeSigned: boolean }) => fetchAuth({
  endpoint: 'login',
  options: {
    method: 'POST',
    body: JSON.stringify({ email: options.email, password: options.password, keepMeSigned: options.keepMeSigned }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: AUTH.FETCH_LOGIN_SUCCESS
});


export const loginGoogle = (options: { email: string }) => fetchAuth({
  endpoint: 'login-google',
  options: {
    method: 'POST',
    body: JSON.stringify({ email: options.email }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: AUTH.FETCH_LOGIN_GOOGLE_SUCCESS
});


export const checkAuth = () => fetchAuth({
  endpoint: '',
  options: { credentials: 'include' },
  SUCCESS_TYPE: AUTH.FETCH_AUTHENTICATED_SUCCESS
});


export const logout = () => fetchAuth({
  endpoint: 'logout',
  options: { credentials: 'include' },
  SUCCESS_TYPE: AUTH.FETCH_LOGOUT_SUCCESS
});


export const logoutEverywhere = () => fetchAuth({
  endpoint: 'logouteverywhere',
  options: { credentials: 'include' },
  SUCCESS_TYPE: AUTH.FETCH_LOGOUT_EVERYWHERE_SUCCESS
});


export const forgotPassword = (options: { reset_email: string }) => fetchAuth({
  endpoint: 'password/forgot',
  options: {
    method: 'POST',
    body: JSON.stringify({ reset_email: options.reset_email }),
    headers: { 'Content-Type': 'application/json' },
  },
  SUCCESS_TYPE: AUTH.FETCH_FORGOT_SUCCESS
});


export const checkTokenValid = (options: { token: string }) => fetchAuth({
  endpoint: 'password/check',
  options: {
    method: 'POST',
    body: JSON.stringify({ token: options.token }),
    headers: { 'Content-Type': 'application/json' },
  },
  SUCCESS_TYPE: AUTH.FETCH_CHECK_SUCCESS
});


export const resetPassword = (options: { reset_password: string, confirm_reset_password: string, token: string }) => fetchAuth({
  endpoint: 'password/reset',
  options: {
    method: 'POST',
    body: JSON.stringify({ reset_password: options.reset_password, confirm_reset_password: options.confirm_reset_password, token: options.token }),
    headers: { 'Content-Type': 'application/json' },
  },
  SUCCESS_TYPE: AUTH.FETCH_RESET_SUCCESS
});


export const signupPerson = (options: { register_email: string, register_password: string }) => fetchAuth({
  endpoint: 'signup',
  options: {
    method: 'POST',
    body: JSON.stringify({ register_email: options.register_email, register_password: options.register_password }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: AUTH.FETCH_SIGNUP_SUCCESS
});


export const confirmRegistration = (options: { token: string }) => fetchAuth({
  endpoint: 'confirm-registration',
  options: {
    method: 'POST',
    body: JSON.stringify({ token: options.token }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: AUTH.FETCH_REGISTRATION_SUCCESS
});


export const confirmEmailChange = (options: { token: string }) => fetchAuth({
  endpoint: 'confirm-email-change',
  options: {
    method: 'POST',
    body: JSON.stringify({ token: options.token }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: AUTH.FETCH_EMAIL_CHANGE_SUCCESS
});


export const resendRegistrationConfirmation = (options: { email: string }) => fetchAuth({
  endpoint: 'resend-confirm-registration',
  options: {
    method: 'POST',
    body: JSON.stringify({ email: options.email }),
    headers: { 'Content-Type': 'application/json' },
  },
  SUCCESS_TYPE: AUTH.FETCH_RESEND_REGISTRATION_CONFIRMATION_SUCCESS
});


export const removeAccount = (options: { account_id: string, person_id: string }) => fetchAuth({
  endpoint: `remove-account`,
  options: {
    method: 'DELETE',
    body: JSON.stringify({ account_id: options.account_id, person_id: options.person_id }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: AUTH.FETCH_REMOVE_ACCOUNT_SUCCESS
});