import React from 'react'
import { connect } from 'react-redux'
import { updatePersonPasswordSettings } from '../../actions/person'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { IconVisibility, IconVisibilityOff, IconLock } from '../partials/IconsForm'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'



interface SettingsChangePasswordProps {
  router: { location: string, navigate: (to: string) => any },
  person: { status: string, fields: Array<string> },
  auth: { person: { person_id: string, email: string, settings: { theme: string } }},
  updatePersonPasswordSettings: (options: { id: string, password_confirmation: string, password_old: string, password_new: string }) => Promise<void>,
}


interface SettingsChangePasswordState {
  formSubmitted: boolean,
  password_confirmation: string,
  password_old: string,
  password_new: string,
  passwordShow: boolean,
  passwordOldShow: boolean,
  passwordConfirmationShow: boolean,
}


export class SettingsChangePassword extends React.Component<SettingsChangePasswordProps, SettingsChangePasswordState> {


  state = {
    formSubmitted: false,
    password_confirmation: '',
    password_old: '',
    password_new: '',
    passwordShow: false,
    passwordOldShow: false,
    passwordConfirmationShow: false,
  }
  

  handleOnPaswordUpdateSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password_confirmation, password_old, password_new } = this.state;
    await this.props.updatePersonPasswordSettings({ id: this.props.auth.person.person_id, password_confirmation, password_old, password_new })
    if(this.props.person.status === fetchStates.success) {
      this.setState({ 
        password_confirmation: '', 
        password_old: '', 
        password_new: ''
      })
    }
  }


  render() {

    const { status, fields } = this.props.person
    const { passwordShow, passwordConfirmationShow, passwordOldShow, password_confirmation, password_old, password_new, formSubmitted } = this.state

    return (
      <TemplatePage title="Settings → Change Password" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <form className="form" onSubmit={this.handleOnPaswordUpdateSubmit}>
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="password_old">
                  Old password (required)
                </label>
                <div className="input-group">
                  <span className="password-reveal" onClick={e => this.setState({ passwordOldShow: !passwordOldShow })}>
                    { passwordOldShow === false ? (
                      <IconVisibility color="#000" password size={18} />
                    ) : (
                      <IconVisibilityOff color="#000" password size={18} />
                    )}
                  </span>
                  <span className="input-icon">
                    <IconLock color="#A19BCD" />
                  </span>
                  <input 
                    type={ passwordOldShow === false ? 'password' : 'text' } 
                    name="password_old"
                    id="password_old" 
                    value={ password_old }
                    onChange={e => this.setState({ password_old: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('password_old') })}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  New password (required)
                </label>
                <div className="input-group">
                  <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                    { passwordShow === false ? (
                      <IconVisibility color="#000" password size={18} />
                    ) : (
                      <IconVisibilityOff color="#000" password size={18} />
                    )}
                  </span>
                  <span className="input-icon">
                    <IconLock color="#A19BCD" />
                  </span>
                  <input 
                    type={ passwordShow === false ? 'password' : 'text' } 
                    name="password"
                    id="password" 
                    value={ password_new }
                    onChange={e => this.setState({ password_new: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('password_new') })}
                  />
                </div>
                <PasswordStrengthMeter password={password_new} />
              </div>
              <div className="form-group">
                <label htmlFor="password_confirmation">
                  New password confirmation (required)
                </label>
                <div className="input-group">
                  <span className="password-reveal" onClick={e => this.setState({ passwordConfirmationShow: !passwordConfirmationShow })}>
                    { passwordConfirmationShow === false ? (
                      <IconVisibility color="#000" password size={18} />
                    ) : (
                      <IconVisibilityOff color="#000" password size={18} />
                    )}
                  </span>
                  <span className="input-icon">
                    <IconLock color="#A19BCD" />
                  </span>
                  <input 
                    type={ passwordConfirmationShow === false ? 'password' : 'text' } 
                    name="password_confirmation"
                    id="password_confirmation" 
                    value={ password_confirmation }
                    onChange={e => this.setState({ password_confirmation: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('password_confirmation') })}
                  />
                </div>
              </div>
              <div className="form-group">
                <input 
                  type="submit" 
                  value="Change Password" 
                  className="btn btn--primary"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, person }: RootState) => ({ auth, person }),
  { updatePersonPasswordSettings }
)(SettingsChangePassword)))