import React from 'react'
import withAuthorization from '../auth/withAuthorization'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import classnames from 'classnames'


interface WsConnectorProps {
  ws: { wsConnected: boolean },
}


export class WsConnector extends React.Component<WsConnectorProps> {
  render() {
    const { wsConnected } = this.props.ws
    return (
      <div className={classnames('wsconnector', { 'wsconnector--connected': wsConnected === true,
      'wsconnector-disconnected': wsConnected === false })}>
      </div>
    )
  }
}


export default withAuthorization(connect(
  ({ ws }: RootState) => ({ ws }),
  { }
)(WsConnector))