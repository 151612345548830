import React from 'react'
import { connect } from 'react-redux'
import { checkAuth, logoutEverywhere, logout } from '../../actions/auth'
import { wsDisconnect } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Card from '../partials/Card'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'


interface SettingsLogoutProps {
  router: { location: string, navigate: (to: string) => any },
  auth: { status: string },
  logout: () => Promise<void>,
  logoutEverywhere: () => Promise<void>,
  wsDisconnect: () => Promise<void>,
}


interface SettingsLogoutState {
  formSubmitted: boolean,
}


export class SettingsLogout extends React.Component<SettingsLogoutProps, SettingsLogoutState> {


  state = {
    formSubmitted: false
  }


  handleOnLogoutEverywhere = async () => {
    await this.props.wsDisconnect()
    await this.props.logoutEverywhere()
    await this.props.router.navigate('/')
  }


  handleOnLogout = async () => {
    await this.props.wsDisconnect()
    await this.props.logout()
    await this.props.router.navigate('/')
  }


  render() {

    const { status } = this.props.auth
    const { formSubmitted } = this.state

    return (
      <TemplatePage title="Settings → Logout" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <Card>
            <form className="form" onSubmit={this.handleOnLogout}>
              <div className="form-section">
                <div className="form-group">
                  <p>
                    Log out securely from this app.
                  </p>
                  <input 
                    type="submit" 
                    value="Logout" 
                    className="btn btn--primary"
                    disabled={ formSubmitted && status === 'fetching' ? true : false }
                  />
                </div>
              </div>
            </form>
          </Card>
          <Card>
            <form className="form" onSubmit={this.handleOnLogoutEverywhere}>
              <div className="form-section">
                <div className="form-group">
                  <p>
                    Log out securely from all the devices.
                  </p>
                  <input 
                    type="submit" 
                    value="Logout Everywhere" 
                    className="btn btn--primary"
                    disabled={ formSubmitted && status === 'fetching' ? true : false }
                  />
                </div>
              </div>
            </form>
          </Card>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { checkAuth, logoutEverywhere, logout, wsDisconnect }
)(SettingsLogout)))