import { SCENARIO } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchScenario = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: SCENARIO.FETCH });
  return fetch(`${API_ADDRESS}/scenario/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: SCENARIO.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: string }) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: SCENARIO.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllScenarios = () => fetchScenario({
  endpoint: '',
  options: { credentials: 'include' },
  SUCCESS_TYPE: SCENARIO.FETCH_ALL_SUCCESS
});


export const fetchScenarioById = (id: string) => fetchScenario({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: SCENARIO.FETCH_ID_SUCCESS
});


export const addNewScenario = (options: { scenario: any }) => fetchScenario({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...options.scenario }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: SCENARIO.ADD_SUCCESS
});


export const updateScenarioById = (options: { scenario: any }) => fetchScenario({
  endpoint: `${options.scenario.scenario_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.scenario }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: SCENARIO.UPDATE_SUCCESS
});


export const toggleScenarioActiveById = (options: { scenario_id: string, tst_upd: string, sc_act: number }) => fetchScenario({
  endpoint: `active/${options.scenario_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ tst_upd: options.tst_upd, sc_act: options.sc_act }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: SCENARIO.UPDATE_ID_ACTIVE_SUCCESS
});


export const deleteScenarioById = (id: string) => fetchScenario({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: SCENARIO.DELETE_ID_SUCCESS
});