import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { container, item } from '../../helpers/framer'
import { IconBack, IconAdd, IconDelete, IconEdit } from '../partials/IconsNavigation'
import classnames from 'classnames'


declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    variants?: object,
    initial?: string,
    animate?: string,
  }
}


interface TemplatePageProps {
  children?: JSX.Element|JSX.Element[],
  title?: string,
  buttonBack?: boolean,
  buttonBackLink?: any,
  navigate?: any,
  button?: boolean,
  buttonLink?: string,
  buttonType?: string,
  buttonText?: string,
}


const TemplatePage: React.FC<TemplatePageProps> = ({ children, title, buttonBack, buttonBackLink, navigate, button, buttonLink, buttonType, buttonText }) => {
  return (
    <div className="template template-page">
      <motion.div 
        className="page-content" 
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <motion.div key={1} variants={item} className="page-content__header">
          <span>
            { buttonBack !== undefined && buttonBackLink !== undefined && <Link to={buttonBackLink !== undefined && buttonBackLink} className="btn-square btn-square--back"><IconBack color="#ffffff" /></Link> }
            { buttonBack !== undefined && navigate !== undefined && <Link to="/" onClick={() => navigate(-1)} className="btn-square btn-square--back"><IconBack color="#ffffff" /></Link> }
          </span>
          <span>
            { title !== undefined && <h1>{title}</h1> }
          </span>
          <span>
            { button && (
                <>
                  <Link to={buttonLink !== undefined ? buttonLink : '/'} className={classnames('show--only-s btn-square', { 'btn--pulsate': buttonType === 'add' })}>
                    { buttonType === 'add' && <IconAdd color="#ffffff" /> }
                    { buttonType === 'delete' && <IconDelete color="#ffffff" /> }
                    { buttonType === 'edit' && <IconEdit color="#ffffff" /> }
                  </Link> 
                  <Link to={buttonLink !== undefined ? buttonLink : '/'} className={classnames('show--from-m btn btn--primary', { 'btn--pulsate': buttonType === 'add' })}>
                    {buttonText}
                  </Link> 
                </>
              )

            }
          </span>
        </motion.div>
        { children }
      </motion.div>
    </div>
  )
}

export default TemplatePage;