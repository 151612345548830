import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceAlarm } from '../../actions/device'
import { wsUpdateAlarmByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { IconTime } from '../partials/IconsForm'
import classnames from 'classnames'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import moment from 'moment'
import { danger } from '../../helpers/notifications'
import Alert from '../partials/Alert'
import { Link } from 'react-router-dom'


interface DeviceSettingsAlarmProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  auth: any,
  ws: { wsConnected: boolean },
  updateDeviceAlarm: (options: {id: string, alarm_settings: { tst: number, time_out: string, time_in: string, sensitivity: string }}) => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  handleButtonSubmit: () => Promise<void>,
  wsUpdateAlarmByDeviceSerial: (options: {device_serial: string, alarm_settings: any}) => Promise<void>,
}


interface DeviceSettingsAlarmState {
  device: { room_id: string, device_serial: string, device_id: string, device_title: string },
  formSubmitted: boolean,
  time_out: string,
  time_in: string,
  pins: Array<string>,
  sensitivity: string,
  noData: boolean,
  noPin: boolean
}


export class DeviceSettingsAlarm extends React.Component<DeviceSettingsAlarmProps, DeviceSettingsAlarmState> {


  state = {
    device: {
      room_id: '',
      device_serial: '',
      device_id: '',
      device_title: ''
    },
    formSubmitted: false,
    time_out: '15',
    time_in: '15',
    sensitivity: '50',
    pins: [],
    noData: false,
    noPin: false,
  }


  componentDidMount() {
    if(this.props.auth.person.alarm_pin === '') {
      this.setState({ noPin: true });
    }
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      const { time_out, time_in, sensitivity } = this.props.device.device.device_set_alarm
      this.setState({ 
        noData: false,
        device, 
        time_out,
        time_in,
        sensitivity
      })
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true })
    if(this.props.ws.wsConnected) {
      const { time_out, time_in, sensitivity } = this.state
      const alarm_settings:{tst: number, time_out: string, time_in: string, sensitivity: string, pins?:Array<string>} = {
        tst: moment().valueOf(),
        time_out,
        time_in,
        sensitivity
      }
      await this.props.updateDeviceAlarm({ id: this.props.router.params.id, alarm_settings })
      await this.props.fetchDeviceById(this.props.router.params.id)
      if(this.props.device.status === fetchStates.success) {
        const { pins } = this.props.device.device
        alarm_settings.pins = pins
        console.log('[DEBUG]: - updating device alarm:', this.state.device.device_serial, alarm_settings)
        // WS
        this.props.wsUpdateAlarmByDeviceSerial({ device_serial: this.state.device.device_serial, alarm_settings })
      }
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { device, time_out, time_in, sensitivity, formSubmitted, noData, noPin } = this.state;
    const { fields } = this.props.device;

    if(noData) {
      return (
        <TemplatePage title="Device → Security Alarm" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    if(noPin) {
      return (
        <TemplatePage title="Device → Security Alarm" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error"><span>You need to set a PIN code in your <Link to="/settings/notifications">profile</Link></span></Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Security Alarm" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">
            <div className="form-group">
              <label htmlFor="time_out">
                Time out (s)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconTime color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="time_out"
                  id="time_out" 
                  value={time_out}
                  onChange={e => this.setState({ time_out: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes(`time_out`) })}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="time_in">
                Time in (s)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconTime color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="time_in"
                  id="time_in" 
                  value={time_in}
                  onChange={e => this.setState({ time_in: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes(`time_in`) })}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="time_in">
                Sensitivity ({sensitivity}%)
              </label>
              <div className="slidecontainer">
                <input 
                  name="sensitivity" 
                  id="sensitivity" 
                  type="range" 
                  min="1" 
                  max="100" 
                  value={sensitivity} 
                  className="slider" 
                  onChange={e => this.setState({ sensitivity: e.target.value })} 
                />
              </div>
            </div>
          </form>
          <div className="form__footer">
            <input 
              onClick={() => this.handleButtonSubmit()}
              type="submit" 
              value="Update Device Settings" 
              className="btn btn--primary btn--large"
              disabled={ formSubmitted ? true : false }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, ws, auth }: RootState) => ({ device, ws, auth }),
  { fetchDeviceById, updateDeviceAlarm, wsUpdateAlarmByDeviceSerial }
)(DeviceSettingsAlarm)))