import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../types/fetchStates';
import { addNewScenario } from '../../actions/scenario';
import { fetchAllRooms, fetchMasterRoom } from '../../actions/room';
import { fetchAllDevices, fetchMasterDevice } from '../../actions/device';
import { wsUpdateScenarioSettingsByDeviceSerial } from '../../actions/ws';
import withAuthorization from '../auth/withAuthorization';
import { RootState } from '../../reducers';
import withRouter from '../partials/WithRouter';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import moment from 'moment';
import classnames from 'classnames';

import { danger } from '../../helpers/notifications';
import TemplatePage from '../templates/TemplatePage';
import ContentMain from '../partials/ContentMain';
import Alert from '../partials/Alert';
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import { IconName, IconTime, IconRoom, IconDevice, IconIO, IconValue, IconTemperature, IconHumidity, IconLight } from '../partials/IconsForm';
import { IconDone } from '../partials/IconsScenarios';



interface ScenariosAddProps {
  router: { navigate: (to: string) => any },
  room: any,
  device: any,
  scenario: any,
  ws: { wsConnected: boolean },
  fetchAllDevices: () => Promise<any>,
  fetchMasterDevice: () => Promise<any>,
  fetchAllRooms: () => Promise<any>,
  fetchMasterRoom: () => Promise<any>,
  addNewScenario: (options: {scenario: any}) => Promise<any>,
  wsUpdateScenarioSettingsByDeviceSerial: (options: { device_serial: string, scenario_settings: any }) => Promise<void>,
}


interface ScenariosAddState {
  days_option: Array<string>,
  selectedScenario: any,
  rooms: any,
  roomMaster: any,
  devices: any,
  deviceMaster: any,
  formSubmitted: boolean,
  formInvalid: boolean,
  scenarioIfValid: boolean,
  scenarioThenValid: boolean,
}


export class ScenariosAdd extends React.Component<ScenariosAddProps, ScenariosAddState> {


  state = {
    days_option: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    selectedScenario: { 
      scenario_id: '', 
      sc_id: 0,
      sc_title: '',
      sc_stat: 0, 
      sc_act: 0,
      tr_act: 0,
      tr_fro: '07:00',
      tr_to: '19:00',
      btn_act: 0,
      btn_room: '',
      tst_cre: 0,
      tst_upd: 0,
      tst_con: 0,
      action: { 
        id: '', 
        if: [],
        then: [],
      }
    },
    rooms: [],
    roomMaster: { room_id: '', room_name: ''},
    devices: [],
    deviceMaster: { device_serial: '', device_capabilities: { din: [{id: '', title: ''}], dou: [{id: '', title: ''}] }},
    formSubmitted: false,
    formInvalid: true,
    scenarioIfValid: false,
    scenarioThenValid: false,
  }


  componentDidMount() {
    this.fetchMasterDevice();
    this.fetchMasterRoom();
    this.fetchAllRooms();
    this.fetchAllDevices();
    this.initiateScenario();
  }


  /* DATA & INITIATE */
  fetchMasterDevice = async () => {
    await this.props.fetchMasterDevice();
    const { status, deviceMaster } = this.props.device;
    if(status !== fetchStates.success) { return; }
    this.setState({ deviceMaster });
  }


  fetchMasterRoom = async () => {
    await this.props.fetchMasterRoom();
    const { status, roomMaster } = this.props.room;
    if(status !== fetchStates.success) { return; }
    this.setState({ roomMaster });
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices();
    const { status, devices } = this.props.device;
    if(status !== fetchStates.success) { return; }
    this.setState({ devices });
  }


  fetchAllRooms = async () => {
    await this.props.fetchAllRooms();
    const { status, rooms } = this.props.room;
    if(status !== fetchStates.success) { return; }
    this.setState({ rooms });
  }


  initiateScenario = () => {
    const tst_cre = moment().format('X');
    const last_4digits = String(tst_cre).slice(-4);
    this.setState({ selectedScenario: { ...this.state.selectedScenario, sc_title: 'New scenario ' + last_4digits, scenario_id: uuidv4() } });
  }


  validateScenario = () => {
    let scenarioIfValid = true;
    let scenarioThenValid = true;
    const { action } = this.state.selectedScenario;
    // check if we have any row IF/THEN
    if(action.if.length === 0) {
      scenarioIfValid = false;
    }
    if(action.then.length === 0) {
      scenarioThenValid = false;
    }
    // check if rooms empty IF/THEN
    action.if.map((item_if: any) => {
      if(item_if.room_devices_options_empty) {
        scenarioIfValid = false;
      }
      return null       ;
    })
    action.then.map((item_then: any) => {
      if(item_then.room_devices_options_empty) {
        scenarioThenValid = false;
      }
      return null;
    });
    this.setState({ scenarioIfValid, scenarioThenValid });
    if(scenarioIfValid && scenarioThenValid) {
      this.setState({ formInvalid: false });
    } else {
      this.setState({ formInvalid: true });
    }
  }


  filterScenario = (scenarioDuplicate:any) => {
    // remove top level items
    delete scenarioDuplicate.scenario_id;
    delete scenarioDuplicate.account_id;
    delete scenarioDuplicate.tst_cre;
    delete scenarioDuplicate.tst_con;
    delete scenarioDuplicate.action.id;
    delete scenarioDuplicate.btn_act;
    delete scenarioDuplicate.btn_room;
    // make sure this is INT
    scenarioDuplicate.tst_upd = parseInt(scenarioDuplicate.tst_upd);
    // loop through if rows and remove
    scenarioDuplicate.action.if.map((scenario_if: any) => {
      delete scenario_if.id;
      delete scenario_if.room;
      delete scenario_if.device_io_options;
      delete scenario_if.room_devices_options;
      delete scenario_if.room_devices_options_empty;
      scenario_if.days = scenario_if.days.map(Number);
      return scenario_if;
    })
    // loop through then rows and remove
    scenarioDuplicate.action.then.map((scenario_then: any) => {
      delete scenario_then.id;
      delete scenario_then.room;
      delete scenario_then.device_io_options;
      delete scenario_then.room_devices_options;
      delete scenario_then.room_devices_options_empty;
      return scenario_then;
    })
    // return filtered result
    return scenarioDuplicate;
  }


  filterAction = (action: any) => {
    // loop through if rows and remove
    action.if.map((scenario_if: any) => {
      scenario_if.device_io_options = [];
      scenario_if.room_devices_options = [];
      delete scenario_if.room_devices_options_empty;
      return scenario_if;
    })
    // loop through then rows and remove
    action.then.map((scenario_then: any) => {
      scenario_then.device_io_options = [];
      scenario_then.room_devices_options = [];
      delete scenario_then.room_devices_options_empty;
      return scenario_then;
    })
    return action;
  }


  handleSubmitAddScenario = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    if(!this.props.ws.wsConnected) { 
      danger('You are disconnected from the WS server'); 
      this.setState({ formSubmitted: false }); 
      return;
    }

    const { action } = this.state.selectedScenario;
    const tst_upd = moment().format('X');
    const scenarioDuplicateAction = JSON.parse(JSON.stringify(action));
    const filteredAction = this.filterAction(scenarioDuplicateAction);
    const tst_cre = moment().format('X');
    await this.props.addNewScenario({ scenario: {...this.state.selectedScenario, tst_cre, action: filteredAction } });
    const { status } = this.props.scenario;
    if(status !== fetchStates.success) { 
      this.setState({ formSubmitted: false });
      return; 
    }
    const scenarioDuplicate = JSON.parse(JSON.stringify(this.state.selectedScenario));
    scenarioDuplicate.tst_upd = parseInt(tst_upd);
    const filteredScenario = this.filterScenario(scenarioDuplicate);
    // JSON output for MQTT
    console.log('[DEBUG]: FILTERED SCENARIO - ', JSON.stringify(filteredScenario));
    await this.props.wsUpdateScenarioSettingsByDeviceSerial({ device_serial: this.state.deviceMaster.device_serial, scenario_settings: filteredScenario });
    this.setState({ formSubmitted: false });
    this.props.router.navigate('/scenarios');
  }


  /* IF MANAGEMENT */
  addIfRow = () => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }
    const id = nanoid(6);
    const device = this.state.deviceMaster.device_serial;
    const room = this.state.roomMaster.room_id;
    // set defaults for IF condition
    const defaultIfCondition = { id, device, room, dt_act: 0, time: '12:00', days: [], in: 'temp', logic: 'and', comparison: '=', value: "20" };
    if_section.push(defaultIfCondition);
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: if_section }}
    });
    this.assignIfRoomDeviceOptions(room, id);
    this.assignIfDeviceRowIoOptions(device, id);
    this.validateScenario();
  }


  assignIfRoomDeviceOptions = (room_id: string, row_id: string) => {
    let room_devices_options: any = [];
    room_devices_options = this.state.devices.filter((device: any) => {
      if(device.room_id === room_id) {
        return { device_id: device.device_id, device_title: device.device_title }
      }
      return null;
    });
    if(room_devices_options.length > 0) {
      const if_rows = this.state.selectedScenario.action.if.map((row:any) => {
        if(row.id === row_id) {
          row.room_devices_options = room_devices_options;
          row.room_devices_options_empty = false;
          return row;
        }
        return row;
      })
      this.setState({ selectedScenario: { ...this.state.selectedScenario, action: { ...this.state.selectedScenario.action, if: if_rows }} });
      this.assignIfDeviceRowIoOptions(room_devices_options[0].device_serial,row_id);
    } else {
      const if_rows = this.state.selectedScenario.action.if.map((row:any) => {
        if(row.id === row_id) {
          row.room_devices_options_empty = true;
          return row;
        }
        return row;
      })
      this.setState({ selectedScenario: { ...this.state.selectedScenario, action: { ...this.state.selectedScenario.action, if: if_rows }} });
    }
    this.validateScenario();
  }


  assignIfDeviceRowIoOptions = (device_serial: string, row_id: string) => {
    let device_io_options: any = [];
    let device_io_din: any = [];
    let device_io_temp: any = [];
    let device_io_hum: any = [];
    let device_io_light: any = [];
    this.state.devices.map((device: any) => {
      if(device.device_serial === device_serial) {
        device_io_din = device.device_capabilities.din;
        if(device.device_capabilities.temp === true) {
          device_io_temp = [{ id: 'temp', title: 'temp' }];
        }
        if(device.device_capabilities.hum === true) {
          device_io_hum = [{ id: 'hum', title: 'hum' }];
        }
        if(device.device_capabilities.light === true) {
          device_io_light = [{ id: 'light', title: 'light' }];
        }
        return null;
      }
      return null;
    })
    const if_rows = this.state.selectedScenario.action.if.map((row:any) => {
      if(row.id === row_id) {
        if(device_io_din.length > 0) {
          row.in = device_io_din[0].id;
        } else {
          row.in = 'temp';
          row.value = '20';
        }
        device_io_options.push(...device_io_din);
        device_io_options.push(...device_io_temp);
        device_io_options.push(...device_io_hum);
        device_io_options.push(...device_io_light);
        row.device_io_options = device_io_options;
        return row;
      }
      return row;
    })
    this.setState({ selectedScenario: { ...this.state.selectedScenario, action: { ...this.state.selectedScenario.action, if: if_rows }} });
  }


  removeIfRow = (if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string}) => {
      if(condition.id !== if_id){
        return condition;
      }
      return null;
    })
    this.setState({
      selectedScenario: {...this.state.selectedScenario, action: {...this.state.selectedScenario.action, if: updatedIfCondition }}
    }, () => this.validateScenario());
  }


  setIfRowLogic = (e: any, condition_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string, logic: string}) => {
      if(condition.id === condition_id){
        condition.logic = e.target.value;
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  setIfRowDateTimeActive = (if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string, dt_act: number, time: string, days: Array<number>, in: string}) => {
      if(condition.id === if_id){
        condition.dt_act = condition.dt_act === 1 ? 0 : 1;
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  setIfRowTime = (e: any, if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string, time: string, in: string}) => {
      if(condition.id === if_id){
        condition.time = e.target.value;
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  setIfRowDay = (index: any, if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string, days: string, in: string}) => {
      if(condition.id === if_id){
        // check and add day to the array
        let days_array: any = [];
        if(condition && condition.days) {
          days_array = condition.days;
        }
        if(days_array.includes(index)) {
          days_array = days_array.filter((i:string) => i !== index);
        } else {
          days_array.push(index);
        }
        condition.days = days_array;
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  setIfRowRoom = (e: any, if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }
    const room_id = e.target.value  ; 
    const updatedIfCondition = if_section.filter((condition: {id: string, room: string}) => {
      if(condition.id === if_id){
        condition.room = room_id;
        const id = condition.id;
        this.assignIfRoomDeviceOptions(room_id,id);
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  setIfRowDevice = (e: any, condition_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const device = e.target.value;
    const updatedIfCondition = if_section.filter((condition: {id: string, device: string}) => {
      if(condition.id === condition_id){
        condition.device = device;
        const id = condition.id;
        this.assignIfDeviceRowIoOptions(device,id);
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  setIfRowSensor = (e: any, if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string, in: string, value: string}) => {
      if(condition.id === if_id){
        condition.in = e.target.value;
        if(e.target.value === 'temp') {
          condition.value = '20';
        }
        if(e.target.value === 'hum') {
          condition.value = '50';
        }
        if(e.target.value === 'light') {
          condition.value = '100';
        }
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  renderDeviceIoOptions = (device_io_options:any) => {
    const options:any = [];
    if(device_io_options && device_io_options.length > 0) {
      device_io_options.map((option: {id: string, title: string}) => {
        return options.push(<option key={option.id} value={option.id}>{option.title}</option>);
      })
    }
    return options;
  }


  setIfRowComparison = (e: any, if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string, comparison: string, in: string}) => {
      if(condition.id === if_id){
        condition.comparison = e.target.value;
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }


  setIfRowValue = (e: any, if_id: string) => {
    const { selectedScenario } = this.state;
    let if_section: any = [];
    if(selectedScenario.action.if) {
      if_section = selectedScenario.action.if;
    }    
    const updatedIfCondition = if_section.filter((condition: {id: string, value: string, in: string}) => {
      if(condition.id === if_id){
        if(condition.in === 'temp' || condition.in === 'hum' || condition.in === 'light') {
          // if(/^\d+$/.test(e.target.value)) {
            condition.value = e.target.value;
          // }
        } else {
          condition.value = e.target.value;
        }
      }
      return condition;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, if: updatedIfCondition }}
    });
  }
  
  



  /* THEN MANAGEMENT */
  addThenRow = () => {
    const { selectedScenario } = this.state;
    let then_section: any = [];
    if(selectedScenario.action.then) {
      then_section = selectedScenario.action.then;
    }
    const id = nanoid(6);
    const device = this.state.deviceMaster.device_serial;
    const room = this.state.roomMaster.room_id;
    const defaultThenResponse = { id, device, room, out: this.state.deviceMaster.device_capabilities.dou[0].title, value: "on" };
    then_section.push(defaultThenResponse);
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, then: then_section }}
    });
    this.assignThenRoomDeviceOptions(room,id);
    this.assignThenDeviceRowIoOptions(device,id);
    this.validateScenario();
  }


  assignThenDeviceRowIoOptions = (device_serial: string, row_id: string) => {
    let device_io_options: any = [];
    let device_io_dou: any = [];
    let device_io_text: any = [];
    let device_io_beep: any = [];
    this.state.devices.map((device: any) => {
      if(device.device_serial === device_serial) {
        device_io_dou = device.device_capabilities.dou;
        if(device.device_capabilities.text === true) {
          device_io_text = [{ id: 'text', title: 'text' }];
        }
        if(device.device_capabilities.beep === true) {
          device_io_beep = [{ id: 'beep', title: 'beep' }];
        }
        return null;
      }
      return null;
    })
    const then_rows = this.state.selectedScenario.action.then.map((row:any) => {
      if(row.id === row_id) {
        device_io_options.push(...device_io_dou);
        device_io_options.push(...device_io_text);
        device_io_options.push(...device_io_beep);
        row.device_io_options = device_io_options;
        return row;
      }
      return row;
    })
    this.setState({ selectedScenario: { ...this.state.selectedScenario, action: { ...this.state.selectedScenario.action, then: then_rows }} });
  }


  assignThenRoomDeviceOptions = (room_id: string, row_id: string) => {
    let room_devices_options: any = [];
    room_devices_options = this.state.devices.filter((device: any) => {
      if(device.room_id === room_id) {
        return {device_id: device.device_id, device_title: device.device_title}
      }
      return null;
    })
    if(room_devices_options.length > 0) {
      const then_rows = this.state.selectedScenario.action.then.map((row:any) => {
        if(row.id === row_id) {
          row.room_devices_options = room_devices_options;
          row.room_devices_options_empty = false;
          return row;
        }
        return row;
      })
      this.setState({ selectedScenario: { ...this.state.selectedScenario, action: { ...this.state.selectedScenario.action, then: then_rows }} });
      this.assignThenDeviceRowIoOptions(room_devices_options[0].device_serial,row_id);
    } else {
      const then_rows = this.state.selectedScenario.action.then.map((row:any) => {
        if(row.id === row_id) {
          row.room_devices_options_empty = true;
          return row;
        }
        return row;
      })
      this.setState({ selectedScenario: { ...this.state.selectedScenario, action: { ...this.state.selectedScenario.action, then: then_rows }} });
    }
    this.validateScenario();
  }


  removeThenRow = (then_id: string) => {
    const { selectedScenario } = this.state;
    let then_section: any = [];
    if(selectedScenario.action.then) {
      then_section = selectedScenario.action.then;
    }    
    const updatedThenResponse = then_section.filter((response: {id: string}) => {
      if(response.id !== then_id){
        return response;
      }
      return null;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, then: updatedThenResponse }}
    }, () => this.validateScenario());
  }


  setThenRowRoom = (e: any, then_id: string) => {
    const { selectedScenario } = this.state;
    let then_section: any = [];
    if(selectedScenario.action.then) {
      then_section = selectedScenario.action.then;
    }
    const room_id = e.target.value;
    const updatedThenResponse = then_section.filter((response: {id: string, room: string}) => {
      if(response.id === then_id){
        response.room = room_id;
        const id = response.id;
        this.assignThenRoomDeviceOptions(room_id,id);
      }
      return response;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, then: updatedThenResponse }}
    });
  }


  setThenRowDevice = (e: any, then_id: string) => {
    const { selectedScenario } = this.state;
    let then_section: any = [];
    if(selectedScenario.action.then) {
      then_section = selectedScenario.action.then;
    }
    const device = e.target.value   ;
    const updatedThenResponse = then_section.filter((response: {id: string, device: string}) => {
      if(response.id === then_id){
        response.device = device;
        const id = response.id;
        this.assignThenDeviceRowIoOptions(device,id);
      }
      return response;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, then: updatedThenResponse }}
    })
  }


  setThenRowSensor = (e: any, then_id: string) => {
    const { selectedScenario } = this.state;
    let then_section: any = [];
    if(selectedScenario.action.then) {
      then_section = selectedScenario.action.then;
    }    
    const updatedThenResponse = then_section.filter((response: {id: string, out: string}) => {
      if(response.id === then_id){
        response.out = e.target.value;
      }
      return response;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, then: updatedThenResponse }}
    });
  }


  setThenRowValue = (e: any, then_id: string) => {
    const { selectedScenario } = this.state;
    let then_section: any = [];
    if(selectedScenario.action.then) {
      then_section = selectedScenario.action.then;
    }    
    const updatedThenResponse = then_section.filter((response: {id: string, value: string, out: string}) => {
      if(response.id === then_id){
        if(response.out === 'text') {
          if(e.target.value.length < 21) {
            response.value = e.target.value;
          }
        } else {
          response.value = e.target.value;
        }
        
      }
      return response;
    })
    this.setState({
      selectedScenario: {...selectedScenario, action: {...selectedScenario.action, then: updatedThenResponse }}
    });
  }






  render() {

    const { fields } = this.props.scenario;
    const { selectedScenario, rooms, devices, formSubmitted, formInvalid, scenarioIfValid, scenarioThenValid } = this.state;

    return (
      <TemplatePage title="Scenarios → Add Scenario" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          { 
            (rooms && rooms.length === 0) || (devices && devices.length === 0) ? (
              <div className="scenario-wrapper">
                <Alert type="info">Please add at least one room and add at least one device to be able to create scenario.</Alert>
              </div>
            ) : (
              <div className="scenario-wrapper">
                <form className="scenario-form" onSubmit={this.handleSubmitAddScenario}>


                  { /* SCENARIO TITLE */}
                  <div className="scenario-form__group">
                    <label htmlFor="email">
                      Scenario title (required)
                    </label>
                    <div className="input-group">
                      <span className="input__icon">
                        <IconName color="#A19BCD" />
                      </span>
                      <input 
                        type="text" 
                        name="sc_title"
                        id="sc_title" 
                        value={selectedScenario.sc_title}
                        onChange={e => this.setState({ selectedScenario: { ...selectedScenario, sc_title: e.target.value } })}
                        className={classnames('', { 'input-error': fields && fields.includes('sc_title') })}
                      />
                    </div>
                  </div>


                  {
                    selectedScenario.action && Object.keys(selectedScenario.action).length > 0 && (
                      <>






                        { /* IF WRAPPER */}
                        <div className="action__wrapper action__wrapper--if">
                          { /* IF TITLE */ }
                          <div
                            className={classnames('action__header', { 'action-label--active': selectedScenario.action.if && selectedScenario.action.if.length > 0 })}
                          >      
                            <h3>IF</h3>
                            <span className={classnames('icon icon--medium', { 'icon--success': scenarioIfValid })}>
                              <IconDone size={18} color="#ffffff" />
                            </span>
                          </div>


                          { /* IF BODY */ }
                          <div className="action__body">
                            { 
                              selectedScenario.action.if.map((condition: { id: string, dt_act: number, time:string, days: Array<number>, room: string, device: string, in: string, logic: string, comparison: string, value: string, device_io_options: any, room_devices_options: any, room_devices_options_empty: boolean }, index) => (
                                  <div key={condition.id} className="action__row">




                                    { /* IF SECTION LOGIC */ }
                                    {
                                      index !== 0 && 
                                      (
                                        <div className="action-row__item">
                                          <select
                                            className="action-row__select"
                                            name="if_logic" 
                                            value={ condition.logic }
                                            onChange={ e => this.setIfRowLogic(e, condition.id) }
                                          >
                                            <option value="and">and</option>
                                            <option value="or">or</option>
                                          </select>
                                        </div>
                                      )
                                    }


                                    { /* IF SECTION TIME */ }
                                    <div className="action-row__item">
                                      <label htmlFor="room_button" onClick={e => this.setIfRowDateTimeActive(condition.id)}>
                                      <label className="action-row__label">DATE & TIME OPTIONS</label>
                                        <SwitchTrueFalseHorizontal switchState={ condition.dt_act === 1 ? true : false } />
                                      </label>
                                    </div>



                                    {
                                      condition.dt_act === 1 && (
                                        <>
                                          { /* IF SECTION TIME */ }
                                          <div className="action-row__item">
                                            <label className="action-row__label">TIME</label>
                                            <div className="input-group">
                                              <span className="input-icon">
                                                <IconTime color="#A19BCD" />
                                              </span>
                                              <input 
                                                className="action-row__time"
                                                type="time" 
                                                name="if_time"
                                                value={ condition.time === undefined ? '12:00' : condition.time } 
                                                onChange={ e => this.setIfRowTime(e, condition.id) } 
                                              />
                                            </div>
                                          </div>


                                          { /* IF SECTION DATE */ }
                                          <div className="action-row__item">
                                            <label className="action-row__label">DAYS</label>
                                            <div className="input-group">
                                              <div className="day-selection">
                                                {
                                                  this.state.days_option.map((day, i) => {
                                                    return <div 
                                                      key={i} 
                                                      className={classnames('day-selection__day', { 'day-selection__day--selected': condition.days && condition.days.includes(i) })} 
                                                      onClick={() => this.setIfRowDay(i, condition.id)}
                                                    >
                                                      {day}
                                                    </div>
                                                  })
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    }






                                    { /* IF SECTION ROOM */ }
                                    <div className="action-row__item">
                                      <label className="action-row__label">ROOM</label>
                                      <div className="input-group">
                                        <span className="input-icon">
                                          <IconRoom color="#A19BCD" />
                                        </span>
                                        <select
                                          className="action-row__select"
                                          name="if_room" 
                                          value={ condition.room }
                                          onChange={ e => this.setIfRowRoom(e, condition.id) }
                                        >
                                          {rooms.map((room: {room_id:string, room_name: string}) => (
                                            room.room_id !== null && <option key={room.room_id} value={room.room_id}>{room.room_name}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>


                                    { /* IF SECTION CHECK DEVICE ASSIGNED */ }
                                    { 
                                      condition.room_devices_options_empty && (
                                        <Alert type="info">This room does not have any devices assigned to it yet.</Alert>
                                      )
                                    }


                                    { 
                                      !condition.room_devices_options_empty && (
                                        <>
                                          { /* IF DEVICE */ }
                                          <div className="action-row__item">
                                            <label className="action-row__label">DEVICE</label>
                                            <div className="input-group">
                                              <span className="input-icon">
                                                <IconDevice color="#A19BCD" />
                                              </span>
                                              <select
                                                className="action-row__select"
                                                name="if_device" 
                                                value={ condition.device }
                                                onChange={ e => this.setIfRowDevice(e, condition.id) }
                                              >
                                                {condition.room_devices_options.map((device: {device_serial:string, device_title: string, room_name: string}) => (
                                                  device.device_serial !== null && <option key={device.device_serial} value={device.device_serial}>{device.device_title}</option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>


                                          { /* IF SECTION I/O */ }
                                          <div className="action-row__item">
                                            <label className="action-row__label">IO / SENSORS</label>
                                            <div className="input-group">
                                              <span className="input-icon">
                                                {
                                                  (
                                                    condition.in === 'input1' ||
                                                    condition.in === 'input2' || 
                                                    condition.in === 'input3' || 
                                                    condition.in === 'input4'
                                                  ) && <IconIO color="#A19BCD" />
                                                }
                                                {
                                                  condition.in === 'temp'
                                                  && <IconTemperature color="#A19BCD" />
                                                }
                                                {
                                                  condition.in === 'hum'
                                                  && <IconHumidity color="#A19BCD" />
                                                }
                                                {
                                                  condition.in === 'light'
                                                  && <IconLight color="#A19BCD" />
                                                }
                                              </span>
                                              <select
                                                className="action-row__select"
                                                name="if_in" 
                                                value={ condition.in }
                                                onChange={ e => this.setIfRowSensor(e, condition.id) }
                                              >
                                                {
                                                  this.renderDeviceIoOptions(condition.device_io_options)                    
                                                }
                                              </select>
                                            </div>
                                          </div>



                                          { /* IF SECTION COMPARISON */ }
                                          {
                                            (
                                              condition.in === 'temp' ||
                                              condition.in === 'hum' ||
                                              condition.in === 'light'
                                            ) &&
                                            <div className="action-row__item">
                                              <label className="action-row__label">LOGICAL OPERATOR</label>
                                              <div className="input-group">
                                                <span className="input-icon">
                                                  <IconValue color="#A19BCD" />
                                                </span>
                                                <select
                                                  className="action-row__select"
                                                  name="if_comparison" 
                                                  value={ condition.comparison }
                                                  onChange={ e => this.setIfRowComparison(e, condition.id) }
                                                >
                                                  <option value="=">{'='}</option>
                                                  <option value=">">{'>'}</option>
                                                  <option value="<">{'<'}</option>
                                                </select>
                                              </div>
                                            </div>
                                          }



                                          { /* IF SECTION VALUE */ }
                                          <div className="action-row__item">
                                          <label className="action-row__label">
                                            
                                            {
                                              condition.in === 'temp' && <span className="action-row__label-unit">VALUE (°C)</span>
                                            }
                                            {
                                              condition.in === 'hum' && <span className="action-row__label-unit">VALUE (%)</span>
                                            }
                                            {
                                              condition.in === 'light' && <span className="action-row__label-unit">VALUE (lux)</span>
                                            }
                                            {
                                              (condition.in === 'input1' ||
                                              condition.in === 'input2' || 
                                              condition.in === 'input3' || 
                                              condition.in === 'input4') && (
                                                <span className="action-row__label-unit">INPUT STATE</span>
                                              )
                                            }
                                          </label>
                                            { 
                                              condition.in === 'input1' ||
                                              condition.in === 'input2' || 
                                              condition.in === 'input3' || 
                                              condition.in === 'input4' ?
                                              (
                                                <div className="input-group">
                                                  <span className="input-icon">
                                                    <IconValue color="#A19BCD" />
                                                  </span>
                                                  <select
                                                    className="action-row__select"
                                                    name="if_value" 
                                                    value={ condition.value }
                                                    onChange={ e => this.setIfRowValue(e, condition.id) }
                                                  >
                                                    <option value="on">on</option>
                                                    <option value="off">off</option>
                                                  </select>
                                                </div>
                                              ) : (
                                                <div className="input-group">
                                                  <span className="input-icon">
                                                    <IconValue color="#A19BCD" />
                                                  </span>
                                                  <input 
                                                    name="if_value"
                                                    className="action-row__input" 
                                                    value={ condition.value } 
                                                    onChange={ e => this.setIfRowValue(e, condition.id) }
                                                  />
                                                </div>
                                              )
                                            }
                                          </div>


                                          { /* IF SECTION REMOVE BUTTON */ }
                                          <div className="action-row__item">
                                            <button className="btn btn--primary btn--small" onClick={() => this.removeIfRow(condition.id)} type="button">
                                              Remove IF Row
                                            </button>
                                          </div>


                                        </>
                                    )}


                                  </div>
                                )
                              )
                            }
                            { /* IF ADD BUTTON */ }
                            <div>
                              <button className="btn btn--primary btn--small" onClick={this.addIfRow} type="button">
                                Add IF Row
                              </button>
                            </div>
                          </div>
                        </div>




                        { /* THEN WRAPPER */}
                        <div className="action__wrapper action__wrapper--then">
                          { /* THEN TITLE */}
                          <div className={classnames('action__header', { 'action-label--active': selectedScenario.action.then && selectedScenario.action.then.length > 0 })}>
                            <h3>THEN</h3>
                            <span className={classnames('icon icon--medium', { 'icon--success': scenarioThenValid })}>
                              <IconDone size={18} color="#ffffff" />
                            </span>
                          </div>




                          { /* THEN BODY */ }
                          <div className="action__body">
                          {
                              selectedScenario.action.then && selectedScenario.action.then.map((response: { id: string, room: string, device: string, out: string, value: string, device_io_options: any, room_devices_options: any, room_devices_options_empty: boolean }, index) => (
                                <div key={response.id} className="action__row">




                                  { /* THEN LOGIC */ }
                                  {
                                    index !== 0 && 
                                    (
                                      <div className="action-row__item">
                                        <input
                                          className="action-row__input" 
                                          value="and"
                                          disabled
                                        />
                                      </div>
                                    )                                   
                                  }



                                  { /* THEN ROOM */ }
                                  <div className="action-row__item">
                                    <div className="input-group">
                                      <label className="action-row__label">ROOM</label>
                                      <span className="input-icon">
                                        <IconRoom color="#A19BCD" />
                                      </span>
                                      <select
                                        className="action-row__select"
                                        name="then_room" 
                                        value={ response.room }
                                        onChange={ e => this.setThenRowRoom(e, response.id) }
                                      >
                                        {rooms.map((room: {room_id:string, room_name: string}) => (
                                          room.room_id !== null && <option key={room.room_id} value={room.room_id}>{room.room_name}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>



                                  { response.room_devices_options_empty ? (
                                    <Alert type="info">This room does not have any devices assigned to it yet.</Alert>
                                  ) : (
                                    <>



                                      { /* THEN DEVICE */ }
                                      <div className="action-row__item">
                                        <label className="action-row__label">DEVICE</label>
                                        <div className="input-group">
                                          <span className="input-icon">
                                            <IconDevice color="#A19BCD" />
                                          </span>
                                          <select
                                            className="action-row__select"
                                            name="then_device" 
                                            value={ response.device }
                                            onChange={ e => this.setThenRowDevice(e, response.id) }
                                          >
                                            {response.room_devices_options.map((device: {device_serial:string, device_title: string, room_name: string}) => (
                                              device.device_serial !== null && <option key={device.device_serial} value={device.device_serial}>{device.device_title}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>



                                      { /* THEN I/O */ }
                                      <div className="action-row__item">
                                        <label className="action-row__label">OUTPUT</label>
                                        <div className="input-group">
                                          <span className="input-icon">
                                            <IconIO color="#A19BCD" />
                                          </span>
                                          <select
                                            className="action-row__select"
                                            name="then_out" 
                                            value={ response.out }
                                            onChange={ e => this.setThenRowSensor(e, response.id) }
                                          >
                                            {
                                              this.renderDeviceIoOptions(response.device_io_options)                    
                                            }
                                          </select>
                                        </div>
                                      </div>



                                      { /* THEN VALUE */ }
                                      <div className="action-row__item">
                                        <label className="action-row__label">OUTPUT VALUE</label>
                                        <div className="input-group">
                                          <span className="input-icon">
                                            <IconValue color="#A19BCD" />
                                          </span>
                                          {
                                            response.out === 'text' ? (
                                              <input className="action-row__input" type="text" value={response.value} onChange={ e => this.setThenRowValue(e, response.id)} />
                                            ) : (
                                              <select
                                              className="action-row__select"
                                              name="then_value" 
                                              value={ response.value }
                                              onChange={ e => this.setThenRowValue(e, response.id) }
                                            >
                                              <option value="on">on</option>
                                              <option value="off">off</option>
                                            </select>
                                            )
                                          }
                                        </div>
                                      </div>


                                      
                                      <div className="action-row__item">
                                        <button className="btn btn--primary btn--small" onClick={() => this.removeThenRow(response.id)} type="button">
                                          Remove THEN Row
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))
                            }
                            { /* THEN ADD BUTTON */ }
                            <div>
                              <button className="btn btn--primary btn--small" onClick={this.addThenRow} type="button">
                                Add THEN Row
                              </button>
                            </div>
                          </div>
                        </div>





                        { /* TIME LIMIT */}
                        <div className="scenario-form__group">
                          <label htmlFor="enabled" onClick={e => this.setState({ selectedScenario: {...selectedScenario, tr_act: this.state.selectedScenario.tr_act === 0 ? 1 : 0 } })}>
                            <div className="label__title label__title--small">
                              Time range active
                            </div>
                            <SwitchTrueFalseHorizontal switchState={this.state.selectedScenario.tr_act === 0 ? false : true} />
                          </label>
                        </div>
                        {
                          selectedScenario.tr_act === 1 && (
                            <>
                              <div className="scenario-form__group">
                                <div className="input-group">
                                  <span className="input-icon">
                                    <IconTime color="#A19BCD" />
                                  </span>
                                  <input 
                                    type="time" 
                                    name="tr_fro"
                                    id="tr_fro" 
                                    value={this.state.selectedScenario.tr_fro}
                                    onChange={e => this.setState({ selectedScenario: {...selectedScenario, tr_fro: e.target.value } })}
                                    className={classnames('', { 'input-error': fields && fields.includes(`tr_fro`) })}
                                  />
                                </div>
                              </div>
                              <div className="scenario-form__group">
                                <div className="input-group">
                                  <span className="input-icon">
                                    <IconTime color="#A19BCD" />
                                  </span>
                                  <input 
                                    type="time" 
                                    name="tr_to"
                                    id="tr_to" 
                                    value={this.state.selectedScenario.tr_to}
                                    onChange={e => this.setState({ selectedScenario: {...selectedScenario, tr_to: e.target.value } })}
                                    className={classnames('', { 'input-error': fields && fields.includes(`tr_to`) })}
                                  />
                                </div>
                              </div>
                            </>
                          )
                        }



                        { /* ADD BUTTON TO THE ROOM */}
                        <div className="scenario-form__group">
                          <label htmlFor="room_button" onClick={e => this.setState({ selectedScenario: {...selectedScenario, btn_act: this.state.selectedScenario.btn_act === 0 ? 1 : 0 } })}>
                            <div className="label__title label__title--small">
                              Add button to the room
                            </div>
                            <SwitchTrueFalseHorizontal switchState={this.state.selectedScenario.btn_act === 0 ? false : true} />
                          </label>
                        </div>
                        {
                          selectedScenario.btn_act === 1 && (
                            <>
                              <div className="scenario-form__group">
                                <div className="input-group">
                                  <span className="input-icon">
                                    <IconRoom color="#A19BCD" />
                                  </span>
                                  <select 
                                    onChange={e => this.setState({ selectedScenario: {...selectedScenario, btn_room: e.target.value }})}
                                    className={classnames('', { 'input-error': fields && fields.includes('btn_room') })}
                                  >
                                    <option key="0" value="">Select Room</option>
                                    {
                                      rooms && rooms.length > 0 && rooms.map((room:{room_id: string, room_name: string}) => 
                                        <option key={room.room_id} value={room.room_id}>{room.room_name}</option>
                                      )
                                    }
                                  </select>
                                </div>
                              </div>
                            </>
                          )
                        }


                      </>
                    )
                  }


                  <div className="scenario-form__group">
                    <input 
                      type="submit" 
                      value="Save Scenario" 
                      className="btn btn--primary btn--large"
                      disabled={ formSubmitted || formInvalid ? true : false }
                    />
                  </div>
                </form>
              </div>
            )
          }
        </ContentMain>
      </TemplatePage>
    )
  }
}






export default withAuthorization(withRouter(connect(
  ({ ws, device, room, scenario }: RootState) => ({ ws, device, room, scenario }),
  { fetchAllRooms, fetchMasterRoom, fetchAllDevices, fetchMasterDevice, addNewScenario, wsUpdateScenarioSettingsByDeviceSerial }
)(ScenariosAdd)));