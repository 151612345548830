interface CardAlertProps {
  children: any,
  title?: string,
}


const CardAlert: React.FC<CardAlertProps> = ({ children, title }) => {
  return (
    <div className="content-card-alert">
      {
        title && (
          <div className="content-card-alert__title">
            <h3>{title}</h3>
          </div>
        )
      }
      <div className="content-card-alert__body">
        { children }
      </div>
    </div>
  )
}


export default CardAlert;