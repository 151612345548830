import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDevice } from '../../actions/device'
import { fetchAllRooms } from '../../actions/room'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Alert from '../partials/Alert'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import IconNavDeviceSettings from '../partials/IconNavDeviceSettings'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'


interface DevicesEditProps {
  router: { location: string, navigate: (to: string) => any, params: { id: string } },
  device: any,
  fetchDeviceById: (id: string) => Promise<void>,
}


interface DevicesEditState {
  device: { device_serial: string }
  noData: boolean,
}


export class DevicesEdit extends React.Component<DevicesEditProps, DevicesEditState> {


  state = {
    device: {
      device_serial: '',
      device_title: '',
    },
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      this.setState({ device, noData: false })
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  render() {

    const { device, noData } = this.state;

    if(noData) {
      return (
        <TemplatePage title="Device → Manage Device" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Manage Device" buttonBack={true} navigate={this.props.router.navigate} button={true} buttonLink={`/devices/delete/${this.props.router.params.id}`} buttonType="delete" buttonText="Remove Device">
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <IconNavDeviceSettings location={this.props.router.location} tabnavId={`${this.props.router.params.id}`} deviceCategory={checkDeviceCapabilities(device.device_serial.substring(0, 2)).device_category} />
        </ContentMain>
      </TemplatePage>
    )

  }
}


export default withAuthorization(withRouter(connect(
  ({ device, room }: RootState) => ({ device, room }),
  { fetchDeviceById, updateDevice, fetchAllRooms }
)(DevicesEdit)))