import { PERSON } from '../types';
import fetchStates from '../types/fetchStates';


interface PersonState {
  person: [],
  people: [],
  peopleCount: number,
}

const DEFAULT_PERSON: PersonState = {
  person: [],
  people : [],
  peopleCount: 0
};

const person = (state = DEFAULT_PERSON, action: any) => {
  switch(action.type) {
    case PERSON.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case PERSON.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item:any) => item.path !== undefined && item.path.toString())
      };
    case PERSON.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        people: action.people,
        peopleCount: parseInt(action.peopleCount, 10),
      };
    case PERSON.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        person: action.person,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case PERSON.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case PERSON.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case PERSON.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case PERSON.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case PERSON.FETCH_WEATHER_SETTINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        weather_settings: action.weather_settings,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      }
    case PERSON.FETCH_UPDATE_WEATHER_SETTINGS_SUCCESS:
      console.log(action)
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case PERSON.PUSH_MESSAGE:
      return {
        ...state,
        status: action.status,
        msg: action.message,
        fields: action.fields
      };
    case PERSON.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};

export default person;