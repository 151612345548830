import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchEnergyUsageHistory, fetchEnergyUsageChart } from '../../actions/data';
import { fetchAccountById } from '../../actions/account'
import withAuthorization from '../auth/withAuthorization';
import TemplatePage from '../templates/TemplatePage';
import ContentMain from '../partials/ContentMain';
import withRouter from '../partials/WithRouter';
import TabNavEnergy from '../partials/TabnavEnergy';
import { IconFilterCalendarDay, IconFilterCalendarMonth } from '../partials/IconsFilter';
import classnames from 'classnames';
import Chart from 'react-apexcharts';
import { energy_usage_settings } from '../../helpers/charts';
import fetchStates from '../../types/fetchStates';
import Alert from '../partials/Alert';
import Preloader from '../partials/Preloader';


interface EnergyUsageProps {
  router: {navigate: (to: string) => any, location: string},
  auth: any,
  data: any,
  account: any,
  fetchEnergyUsageHistory: () => Promise<void>,
  fetchAccountById: (account_id: string) => Promise<void>,
  fetchEnergyUsageChart: (options: { data_range: string }) => Promise<void>,
}


interface EnergyUsageState {
  data_range: string,
  data_ranges: any,
  dataLoading: boolean,
  noData: boolean,
  series: any,
  options: any,
  energy_usage_today: number, 
  energy_usage_this_month: number, 
  energy_usage_previous_month: number,
  energy_usage_cost_today: number,
  energy_usage_cost_this_month: number,
  energy_usage_cost_previous_month: number,
  account_currency: string
}


export class EnergyUsage extends React.Component<EnergyUsageProps, EnergyUsageState> {


  state = {
    dataLoading: false,
    noData: false,
    data_range: 'today',
    data_ranges: [
      { range_id: 'today', range_title: 'Today' },
      { range_id: 'this_month', range_title: 'This Month' },
      { range_id: 'previous_month', range_title: 'Previous Month' }
    ],

    options: energy_usage_settings.options,
    series: [],
    energy_usage_today: 0, 
    energy_usage_this_month: 0, 
    energy_usage_previous_month: 0,
    energy_usage_cost_today: 0,
    energy_usage_cost_this_month: 0,
    energy_usage_cost_previous_month: 0,
    account_currency: ''
  };


  async componentDidMount(): Promise<void> {
    this.fetchAccount();
    this.fetchHistoryData();
    this.fetchChartDataByTypeRange({ data_range: this.state.data_ranges[0].range_id });
  }


  fetchAccount = async () => {
    const { account_id } = this.props.auth.person;
    await this.props.fetchAccountById(account_id);
    const { status } = this.props.account;
    const { account_currency } = this.props.account.account;
    if( status !== fetchStates.success ) { return; }
    this.setState({ account_currency });
  }


  fetchHistoryData = async () => {
    await this.props.fetchEnergyUsageHistory();
    if(this.props.data.status === fetchStates.success) {
      const { energy_usage_today, energy_usage_this_month, energy_usage_previous_month, energy_usage_cost_today, energy_usage_cost_this_month, energy_usage_cost_previous_month } = this.props.data;
      console.log(this.props.data)
      this.setState({ energy_usage_today, energy_usage_this_month, energy_usage_previous_month, energy_usage_cost_today, energy_usage_cost_this_month, energy_usage_cost_previous_month });
    }
  }


  filterChartsByTimeRange = (range_id: string) => {
    this.setState({ data_range: range_id });
    this.fetchChartDataByTypeRange({ data_range: range_id });
  }


  fetchChartDataByTypeRange = async (options: { data_range: string }) => {
    this.setState({ dataLoading: true });
    const { data_range } = options;
    await this.props.fetchEnergyUsageChart({ data_range });
    if(this.props.data.status === fetchStates.success) {
      const { series, categories } = this.props.data;
      if(series.length > 0) {

        const options = { ...energy_usage_settings.options, xaxis: { ...energy_usage_settings.options.xaxis, categories } };
        this.setState({ series, options });

      } else {
        this.setState({ noData: true });
      }
      this.setState({ dataLoading: false });
    }
  }


  render() {

    const { data_range, data_ranges, noData, dataLoading, energy_usage_today, energy_usage_this_month, energy_usage_previous_month, energy_usage_cost_today, energy_usage_cost_this_month, energy_usage_cost_previous_month, account_currency } = this.state;

    return (
      <TemplatePage title="Energy → Energy Usage" buttonBack={true} navigate={this.props.router.navigate}>
        <TabNavEnergy location={this.props.router.location} />
        <ContentMain>


          {
            dataLoading ? (
              <Preloader />
            ) : (
              <>
                <div className="tiles margin-bottom--xxxl">
                  <div className="simple-tile">
                    <div className="simple-tile__item">Today</div>
                    <div className="simple-tile__item"><strong>{ Math.round(energy_usage_today * 10) / 10 }</strong> kWh</div>
                    <div className="simple-tile__item"><strong>{ energy_usage_cost_today }</strong> { account_currency }</div>
                  </div>
                  <div className="simple-tile">
                    <div className="simple-tile__item">This Moth</div>
                    <div className="simple-tile__item"><strong>{ Math.round(energy_usage_this_month * 10) / 10 }</strong> kWh</div>
                    <div className="simple-tile__item"><strong>{ energy_usage_cost_this_month }</strong> { account_currency }</div>
                  </div>
                  <div className="simple-tile">
                    <div className="simple-tile__item">Previous Month</div>
                    <div className="simple-tile__item"><strong>{ Math.round(energy_usage_previous_month * 10) / 10 }</strong> kWh</div>
                    <div className="simple-tile__item"><strong>{ energy_usage_cost_previous_month }</strong> { account_currency }</div>
                  </div>
                </div>


                <div className="labels-filter">
                  <div className="labels-filter__title">
                    FILTER BY TIME:
                  </div>
                  { 
                    data_ranges && data_ranges.map((range: {range_id: string, range_title: string }) => {
                      return <span key={range.range_id} className={classnames(`labels-filter__item`, { 'labels-filter__item--event--selected': range.range_id === data_range })} onClick={() => this.filterChartsByTimeRange(range.range_id)}>
                        <span className="labels-filter__item-icon">
                          { range.range_id === 'today' && <IconFilterCalendarDay size={24} color="#828BA9" /> }
                          { range.range_id === 'this_month' && <IconFilterCalendarMonth size={24} color="#828BA9" /> }
                          { range.range_id === 'previous_month' && <IconFilterCalendarMonth size={24} color="#828BA9" /> }
                        </span>
                        <span className="labels-filter__item-text">
                          {range.range_title}
                        </span>
                      </span>
                    })
                  }
                </div>

                {
                  noData ? (
                    <div>
                      <Alert type="info">There are no data to be shown</Alert>
                    </div>
                  ) : (
                    <div className="chart">
                      <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        width="100%"
                      />
                    </div>
                  )
                }
              </>

            )
          }


        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ data, account }: RootState) => ({ data, account }),
  { fetchAccountById, fetchEnergyUsageHistory, fetchEnergyUsageChart }
)(EnergyUsage)))