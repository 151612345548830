import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchRoomById } from '../../actions/room'
import { fetchRoomDevices } from '../../actions/device'
import { wsGetDataByDeviceSerial, wsPressButtonByDeviceSerial, wsPressDouByDeviceSerial, wsPressIdentifyByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import fetchStates from '../../types/fetchStates'
import { motion } from 'framer-motion'
import { roomsContainer, roomsItem } from '../../helpers/framer'
import withRouter from '../partials/WithRouter'
import AddItem from '../partials/AddItem'
import classnames from 'classnames'
import DeviceMagicBox from '../devices/DeviceMagicBox'
import DeviceMagicLight from '../devices/DeviceMagicLight'
import DeviceMagicDoors from '../devices/DeviceMagicDoors'
import DeviceMagicGarage from '../devices/DeviceMagicGarage'
import DeviceMagicMailbox from '../devices/DeviceMagicMailbox'
import DeviceMagicBlinds from '../devices/DeviceMagicBlinds'
import Alert from '../partials/Alert'
import { danger } from '../../helpers/notifications'
import ButtonLink from '../partials/ButtonLink'



interface RoomsProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  room: { status: string, room: {room_name: string}},
  ws: { wsConnected: boolean },
  fetchRoomById: (id: string) => Promise<void>,
  fetchRoomDevices: (id: string) => Promise<void>,
  wsGetDataByDeviceSerial: (device_serial: string) => Promise<void>,
  wsPressIdentifyByDeviceSerial: (device_serial: string) => Promise<void>,
  wsPressButtonByDeviceSerial: (options: { device_serial: string, btn: number }) => Promise<void>,
  wsPressDouByDeviceSerial: (options: { device_serial: string, dou_array: Array<number> }) => Promise<void>,
}


interface RoomsState {
  room_name: string,
  roomDevices: Array<any>,
  wsConnected: boolean,
  dataReady: boolean,
  error: string,
  noData: boolean,
}


export class Rooms extends React.Component<RoomsProps, RoomsState> {


  state = {
    room_name: '',
    roomDevices: [],
    wsConnected: false,
    dataReady: false,
    error: '',
    noData: false,
  }


  componentDidMount() {
    this.getRoomData()
    this.getRoomDevices()
  }


  componentDidUpdate(prevProps:any) {
    if (this.props.device.devicesMagicBox !== prevProps.device.devicesMagicBox) {
      const { roomDevices } = this.props.device
      this.setState({ roomDevices })
    }
  }


  getRoomData = () => {
    this.props.fetchRoomById(this.props.router.params.id)
    .then(() => {
      if(this.props.room.status === fetchStates.success) {
        const { room_name } = this.props.room.room
        this.setState({ room_name, noData: false })
      }
      if(this.props.room.status === fetchStates.error) {
        this.setState({ noData: true })
      }
    })
  }


  getRoomDevices = () => {
    this.props.fetchRoomDevices(this.props.router.params.id)
    .then(() => {
      if(this.props.device.status === fetchStates.success) {
        const { roomDevices } = this.props.device
        this.setState({ roomDevices }, () => this.dataRequest())
      }
    })
  }


  // WS: requesting data
  dataRequest = () => {
    if(this.props.ws.wsConnected) {
      if(this.state.roomDevices && this.state.roomDevices.length > 0) {
        this.state.roomDevices.map((device:{device_serial: string}) => {
          console.log('DEBUG: [WS] requesting the data', device.device_serial)
          this.props.wsGetDataByDeviceSerial(device.device_serial)
          return null
        })
      }
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  // WS: pressing the button
  pressMagicboxButton = (btn: number, device_serial: string) => {
    if(this.props.ws.wsConnected) {
      console.log('DEBUG: pressed the corner button on the device', btn, device_serial)
      this.props.wsPressButtonByDeviceSerial({ device_serial, btn })
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  // WS: updating DOU array
  pressMagicBoxDou = (options: {device_serial: string, position: number, value: number, dou_array: Array<number>}) => {
    if(this.props.ws.wsConnected) {
      const { device_serial, position, value, dou_array } = options;
      let updated_dou_array: Array<number> = []
      updated_dou_array = dou_array.map((item: any, index: number) => {
        if(index === position) {
          return value === 1 ? 0 : 1
        }
        return 2
      })
      console.log('[DEBUG]: sending to the server', updated_dou_array)
      this.props.wsPressDouByDeviceSerial({ device_serial, dou_array: updated_dou_array })
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  render() {

    const { room_name, roomDevices, noData } = this.state

    if(noData) {
      return (
        <TemplatePage title="Rooms → Edit Room" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Room does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title={`Rooms → ${room_name}`} buttonBack={true} navigate={this.props.router.navigate} button={true} buttonLink={`/rooms/edit/${this.props.router.params.id}`} buttonType="edit" buttonText="Edit room">
        <ContentMain>
          <motion.div
            variants={roomsContainer}
            initial="hidden"
            animate="visible"
            className="room-devices"
          >
            {
              roomDevices && roomDevices.length > 0 && (
                roomDevices.map((item: {device_id: string, device_serial: string, device_title: string, device_type: string, device_data: any, device_set_btn: any }) => (
                  <motion.div 
                    key={item.device_id} 
                    variants={roomsItem}
                    className={classnames('room-device-card', { 'device-card--active': item.device_data && Object.keys(item.device_data).length > 0 })}
                  >
                    <div className="room-device-card__header">
                      <h3>{item.device_title}</h3>
                      <div className="device-card__tools">
                        <ButtonLink iconType='edit' buttonLink={`/devices/settings/${item.device_id}`} />
                      </div>
                    </div>
                    <div className="room-device-card__body">

                      {
                        // NO UPDATE, DEFAULT STATE
                        <>
                          { 
                            // MAGIC BOX
                            (item.device_type === 'XL' || item.device_type === 'XS' || item.device_type === 'XP') && 
                            (
                              <DeviceMagicBox device={item} pressMagicboxButton={this.pressMagicboxButton} />
                            )
                          }
  

                          {
                            // MAGIC LIGHT
                            (item.device_type === 'LI') && (
                              <DeviceMagicLight device={item} pressMagicBoxDou={this.pressMagicBoxDou} />
                            )
                          }
    
    
                          {
                            // MAGIC DOORS
                            (item.device_type === 'DO') && (
                              <DeviceMagicDoors device={item} />
                            )
                          }
    
    
                          {
                            // MAGIC GARAGE
                            (item.device_type === 'GA') && (
                              <DeviceMagicGarage device={item} />
                            )
                          }
    
    
                          {
                            // MAGIC MAILBOX
                            (item.device_type === 'MA') && (
                              <DeviceMagicMailbox device={item} />
                            )
                          }
  
  
                          {
                            // MAGIC BLINDS
                            (item.device_type === 'BL') && (
                              <DeviceMagicBlinds device={item} />
                            )
                          }
                        </>
                      }

                    </div>

                    {
                      /*
                        <Link to={`/devices/settings/${item.device_id}`} className="room-device-card__footer">
                          Manage Device &rarr;
                        </Link>
                      */
                    }
                  </motion.div>
                )) 
              )
            }
            <AddItem title="Add Device" link={`/devices/add/${this.props.router.params.id}`} type="device" />
          </motion.div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ room, device, ws }: RootState) => ({ room, device, ws }),
  { fetchRoomById, fetchRoomDevices, wsGetDataByDeviceSerial, wsPressButtonByDeviceSerial, wsPressDouByDeviceSerial, wsPressIdentifyByDeviceSerial }
)(Rooms)))