import { DEVELOPER } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchDeveloper = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: DEVELOPER.FETCH });
  return fetch(`${API_ADDRESS}/developer/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: DEVELOPER.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: string}) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: DEVELOPER.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchDevSettingsByDeviceId = (device_id: string) => fetchDeveloper({
  endpoint: `device-settings/${device_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVELOPER.FETCH_DEV_SETTINGS_SUCCESS
});


export const updateDevSettingsByDeviceId = (options: { device_id: string, dev_settings: any }) => fetchDeveloper({
  endpoint: `device-settings/${options.device_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.dev_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVELOPER.FETCH_UPDATE_DEV_SETTINGS_SUCCESS
});