import React from 'react'
import { connect } from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import ContentMain from '../partials/ContentMain'
import { fetchAccountById, updateAccountEnergyPrice } from '../../actions/account'
import { fetchAllDevices } from '../../actions/device'
import { wsGetEnergyUsageFromDataProvider } from '../../actions/ws';
import { IconCurrency, IconPriceSettings } from '../partials/IconsForm'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import { danger } from '../../helpers/notifications'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'


interface SettingsEnergyPriceProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  fetchAccountById: (account_id: string) => Promise<void>,
  updateAccountEnergyPrice: (options: { id: string, account_currency: string, account_energy_cost: string }) => Promise<void>,
  fetchAllDevices: () => Promise<void>,
  wsGetEnergyUsageFromDataProvider: (options: { device_serial: string }) => Promise<void>,
  auth: any,
  account: any,
  ws: any,
  device: any,
}


interface SettingsEnergyPriceState {
  devices: Array<any>,
  account_energy_cost: string,
  account_currency: string,
  formSubmitted: boolean
}


export class SettingsEnergyPrice extends React.Component<SettingsEnergyPriceProps, SettingsEnergyPriceState> {


  state = {
    devices: [],
    account_energy_cost: '',
    account_currency: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.fetchAllDevices();
    this.fetchAccount();
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      const { devices } = this.props.device
      this.setState({ devices })
    }
  }


  fetchAccount = async () => {
    const { account_id } = this.props.auth.person;
    await this.props.fetchAccountById(account_id);
    const { status } = this.props.account;
    const { account_currency, account_energy_cost } = this.props.account.account;
    if( status !== fetchStates.success ) { return; }
    this.setState({ account_currency, account_energy_cost });
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { account_id } = this.props.account.account;
    const { account_currency, account_energy_cost, devices } = this.state;
    await this.props.updateAccountEnergyPrice({ id: account_id, account_currency, account_energy_cost });
    // check at least one device exists
    if(devices.length > 0) {
      // check we have wsConnection
      if(this.props.ws.wsConnected === true) {
        devices.forEach((device:{device_serial: string, device_set_alarm: any, device_id: string}) => {
          // make sure we have MB category
          const prefixToCheck = device.device_serial.substring(0, 2).toUpperCase();
          if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
            this.props.wsGetEnergyUsageFromDataProvider({ device_serial: device.device_serial });
          }
        })
      } else {
        danger('You are disconnected from the WS server')
      }
    }


    this.setState({ formSubmitted: false });
  }



  render() {

    const { account_energy_cost, account_currency, formSubmitted } = this.state;
    const { status, fields } = this.props.account;

    return (
      <TemplatePage title="Settings → Energy Price" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <form className="form" onSubmit={this.handleOnSubmit}>


            <div className="form-group">
              <label htmlFor="account_currency">
                Currency
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconCurrency color="#A19BCD" />
                </span>
                <input 
                  maxLength={3}
                  type="text" 
                  name="account_currency"
                  id="account_currency" 
                  value={ account_currency }
                  onChange={e => this.setState({ account_currency: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('account_currency') })}
                />
              </div>
            </div>


            <div className="form-group">
              <label htmlFor="account_energy_cost">
                Energy Cost
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconPriceSettings color="#A19BCD" />
                </span>
                <input 
                  maxLength={10}
                  type="text" 
                  name="account_energy_cost"
                  id="account_energy_cost" 
                  value={ account_energy_cost }
                  onChange={e => this.setState({ account_energy_cost: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('account_energy_cost') })}
                />
              </div>
            </div>


            <div className="content-box__footer">
              <input 
                type="submit" 
                value="Update Energy Price Settings"
                className="btn btn--primary btn--left"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>

          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, account, ws, device }: RootState) => ({ auth, account, ws, device }),
  { fetchAccountById, updateAccountEnergyPrice, fetchAllDevices, wsGetEnergyUsageFromDataProvider }
)(SettingsEnergyPrice)));