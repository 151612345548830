export const IconScreenOrderStocks = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--screen-order-stocks">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 470 470" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(0.333333, 0.333333)" fill={props.color ? props.color : '#2A2C44'}>
              <path d="M443.562667,115.221333 C443.349333,124.053333 436.032,131.050667 427.2,130.858667 C418.368,130.645333 411.370667,123.328 411.562667,114.496 L412.437333,75.968 L373.930667,75.0933333 C365.098667,74.88 358.101333,67.5626667 358.293333,58.7306667 C358.506667,49.8986667 365.824,42.9013333 374.656,43.0933333 L429.184,44.352 C438.016,44.544 445.013333,51.8613333 444.8,60.6933333 L443.562667,115.221333 Z" id="Path"></path>
              <path d="M102.976,178.048 L102.976,437.056 C102.976,451.946667 90.9226667,464 76.032,464 L32.2773333,464 C17.3866667,464 5.33333333,451.946667 5.33333333,437.056 L5.33333333,178.048 C5.33333333,163.178667 17.3866667,151.104 32.2773333,151.104 L76.032,151.104 C90.9226667,151.104 102.976,163.178667 102.976,178.048 Z" id="Path"></path>
              <path d="M343.658667,309.333333 L343.658667,437.056 C343.658667,444.202667 340.821333,451.050667 335.765333,456.106667 C330.709333,461.162667 323.861333,464 316.714667,464 L272.96,464 C265.813333,464 258.965333,461.162667 253.909333,456.106667 C248.853333,451.050667 246.016,444.202667 246.016,437.056 L246.016,309.333333 C246.016,302.186667 248.853333,295.338667 253.909333,290.282667 C258.965333,285.226667 265.813333,282.389333 272.96,282.389333 L316.714667,282.389333 C323.861333,282.389333 330.709333,285.226667 335.765333,290.282667 C340.821333,295.338667 343.658667,302.186667 343.658667,309.333333 Z" id="Path"></path>
              <path d="M223.317333,243.690667 L223.317333,437.056 C223.317333,451.946667 211.264,464 196.373333,464 L152.618667,464 C137.728,464 125.674667,451.946667 125.674667,437.056 L125.674667,243.690667 C125.674667,236.544 128.512,229.696 133.568,224.64 C138.624,219.584 145.472,216.746667 152.618667,216.746667 L196.373333,216.746667 C211.264,216.746667 223.317333,228.821333 223.317333,243.690667 Z" id="Path"></path>
              <path d="M464,243.690667 L464,437.056 C464,451.946667 451.946667,464 437.056,464 L393.301333,464 C378.410667,464 366.357333,451.946667 366.357333,437.056 L366.357333,243.690667 C366.357333,236.544 369.194667,229.696 374.250667,224.64 C379.306667,219.584 386.154667,216.746667 393.301333,216.746667 L437.056,216.746667 C451.946667,216.746667 464,228.821333 464,243.690667 Z" id="Path"></path>
              <path d="M30.9333333,34.1333333 C23.8506667,28.8426667 22.4213333,18.7946667 27.7333333,11.7333333 C33.024,4.672 43.0506667,3.24266667 50.1333333,8.53333333 L177.130667,103.786667 L237.44,103.786667 C242.368,103.786667 246.997333,106.048 250.026667,109.909333 L298.816,172.010667 L411.882667,54.016 C417.984,47.6373333 428.117333,47.424 434.496,53.5466667 C440.874667,59.648 441.088,69.7813333 434.986667,76.16 L309.162667,207.445333 C305.962667,210.794667 301.461333,212.586667 296.832,212.352 C292.202667,212.138667 287.893333,209.898667 285.034667,206.250667 L229.674667,135.786667 L171.797333,135.786667 C168.341333,135.786667 164.970667,134.677333 162.197333,132.586667 L30.9333333,34.1333333 Z" id="Path"></path>
          </g>
      </g>
  </svg>
  </span>
);


export const IconScreenOrderHistory = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--screen-order-history">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 480 480" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="data-report" fill={props.color ? props.color : '#2A2C44'}>
              <polygon id="Path" points="0 448 480 448 480 480 0 480"></polygon>
              <path d="M72,176 C58.745166,176 48,186.745166 48,200 L48,416 L144,416 L144,200 C144,186.745166 133.254834,176 120,176 L72,176 Z" id="Path"></path>
              <path d="M216,304 C202.745166,304 192,314.745166 192,328 L192,416 L288,416 L288,328 C288,314.745166 277.254834,304 264,304 L216,304 Z" id="Path"></path>
              <path d="M360,352 C346.745166,352 336,362.745166 336,376 L336,416 L432,416 L432,376 C432,362.745166 421.254834,352 408,352 L360,352 Z" id="Path"></path>
              <path d="M196.8,209.28 C207.606033,245.240966 235.925522,273.259183 272,283.68 C282.422538,286.558956 293.187158,288.01218 304,288 C365.855892,288 416,237.855892 416,176 C416,167.163444 408.836556,160 400,160 L344,160 C330.745166,160 320,149.254834 320,136 L320,80 C320,71.163444 312.836556,64 304,64 C270.166115,63.9331876 238.115323,79.1646651 216.8,105.44 C193.395103,134.627039 185.910384,173.487701 196.8,209.28 Z" id="Path"></path>
              <path d="M368,128 L464,128 C472.836556,128 480,120.836556 480,112 C480,50.144108 429.855892,3.15303339e-14 368,-4.4408921e-16 C359.163444,-4.4408921e-16 352,7.163444 352,16 L352,104.16 C352,120.8 359.2,128 368,128 Z" id="Path"></path>
              <polygon id="Path" points="0 0 224 0 224 32 0 32"></polygon>
              <polygon id="Path" points="0 64 144 64 144 96 0 96"></polygon>
          </g>
      </g>
  </svg>
  </span>
);


export const IconScreenOrderWeather = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--screen-order-weather">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 512 444" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={props.color ? props.color : '#2A2C44'}>
              <path d="M184.743,61.179 C193.027,61.179 199.743,54.463 199.743,46.179 L199.743,15.974 C199.743,7.69 193.027,0.974 184.743,0.974 C176.459,0.974 169.743,7.69 169.743,15.974 L169.743,46.179 C169.743,54.463 176.459,61.179 184.743,61.179 Z" id="Path"></path>
              <path d="M48.661,116.086 L22.502,100.983 C15.327,96.839 6.153,99.299 2.012,106.473 C-2.13,113.648 0.328,122.822 7.502,126.963 L33.661,142.066 C40.819,146.2 50,143.767 54.151,136.576 C58.293,129.401 55.835,120.227 48.661,116.086 Z" id="Path"></path>
              <path d="M33.661,281.881 L7.502,296.983 C0.328,301.125 -2.13,310.298 2.012,317.473 C6.175,324.683 15.362,327.087 22.502,322.963 L48.661,307.86 C55.835,303.718 58.293,294.545 54.151,287.37 C50.009,280.197 40.837,277.736 33.661,281.881 Z" id="Path"></path>
              <path d="M335.824,142.066 L361.983,126.963 C369.157,122.821 371.615,113.648 367.473,106.473 C363.331,99.299 354.159,96.839 346.983,100.983 L320.824,116.086 C313.65,120.228 311.192,129.401 315.334,136.576 C319.497,143.786 328.684,146.19 335.824,142.066 Z" id="Path"></path>
              <path d="M398.906,233.268 C381.735,190.836 340.083,162.78 294.261,162.978 C275.484,121.169 233.465,91.973 184.742,91.973 C118.574,91.973 64.743,145.805 64.743,211.973 C64.743,254.424 86.911,291.782 120.268,313.12 C84.701,369.308 125.141,442.973 191.678,442.973 C427.69,442.727 414.776,443.577 418.886,442.301 C471.313,436.388 512,391.922 512,337.974 C512,276.367 459.358,228.533 398.906,233.268 Z M94.743,211.974 C94.743,162.348 135.117,121.974 184.743,121.974 C218.208,121.974 247.448,140.344 262.954,167.521 C215.555,181.41 182.644,224.683 182.116,274.331 C167.216,275.966 153.167,281.533 141.067,290.632 C113.462,275.243 94.743,245.758 94.743,211.974 Z M413.212,412.716 C407.869,413.15 271.748,412.898 191.678,412.974 C142.217,412.974 118.114,351.664 154.915,318.058 C165.961,307.971 180.67,302.604 196.698,304.062 C206.367,304.934 214.246,296.569 212.911,287.042 C205.957,237.411 244.39,192.974 294.6,192.974 C331.744,192.974 364.482,217.976 374.212,253.775 C376.292,261.424 383.965,266.131 391.724,264.53 C438.477,254.861 482,290.601 482,337.974 C482,376.73 451.785,409.561 413.212,412.716 Z" id="Shape" fillRule="nonzero"></path>
          </g>
      </g>
  </svg>
  </span>
);


export const IconScreenOrderAlarm = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--screen-order-alarm">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 447 417" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#2A2C44'}>
                <path d="M326.39,218.237 L326.39,187.897 C326.39,139.634 293.193,99.131 248.39,87.959 L248.39,57.124 C248.39,43.317 237.197,32.124 223.39,32.124 C209.583,32.124 198.39,43.317 198.39,57.124 L198.39,87.958 C153.587,99.13 120.39,139.634 120.39,187.896 L120.39,218.236 C120.39,250.673 107.504,281.782 84.568,304.719 C74.486,314.801 71.47,329.963 76.926,343.136 C82.382,356.309 95.236,364.897 109.494,364.897 L171.642,364.897 C171.642,393.476 194.81,416.644 223.389,416.644 C251.968,416.644 275.136,393.476 275.136,364.897 L337.284,364.897 C351.542,364.897 364.396,356.308 369.852,343.136 C375.308,329.963 372.292,314.801 362.21,304.719 C339.275,281.783 326.39,250.674 326.39,218.237 Z M223.39,386.644 C211.398,386.644 201.643,376.888 201.643,364.897 L245.138,364.897 C245.137,376.889 235.381,386.644 223.39,386.644 Z M342.137,331.655 C341.321,333.624 339.417,334.897 337.285,334.897 L109.494,334.897 C107.362,334.897 105.458,333.625 104.642,331.656 C103.826,329.687 104.273,327.44 105.78,325.933 C134.547,297.167 150.389,258.92 150.389,218.237 L150.389,187.897 C150.389,147.645 183.137,114.897 223.389,114.897 C263.641,114.897 296.389,147.645 296.389,187.897 L296.389,218.237 C296.389,258.919 312.231,297.166 340.998,325.933 C342.506,327.44 342.953,329.686 342.137,331.655 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M86.146,218.065 C93.988,215.394 98.18,206.872 95.508,199.03 C78.949,150.414 105.029,97.391 153.645,80.831 C161.487,78.16 165.679,69.638 163.007,61.796 C160.336,53.954 151.814,49.76 143.972,52.434 C79.698,74.326 45.218,144.429 67.11,208.703 C69.235,214.942 75.064,218.87 81.307,218.87 C82.912,218.87 84.544,218.611 86.146,218.065 Z" id="Path"></path>
                <path d="M130.078,29.416 C137.92,26.745 142.112,18.223 139.441,10.381 C136.769,2.539 128.247,-1.655 120.405,1.019 C27.665,32.606 -22.086,133.755 9.503,226.496 C11.628,232.735 17.457,236.663 23.7,236.663 C25.304,236.663 26.935,236.404 28.538,235.858 C36.38,233.187 40.572,224.665 37.9,216.823 C11.646,139.742 52.996,55.671 130.078,29.416 Z" id="Path"></path>
                <path d="M302.806,52.434 C294.963,49.761 286.442,53.955 283.771,61.796 C281.1,69.638 285.292,78.16 293.133,80.831 C341.749,97.391 367.829,150.414 351.271,199.03 C348.6,206.872 352.791,215.394 360.633,218.065 C362.236,218.611 363.867,218.87 365.471,218.87 C371.714,218.87 377.543,214.941 379.668,208.703 C401.561,144.429 367.08,74.326 302.806,52.434 Z" id="Path"></path>
                <path d="M428.523,90.788 C407.576,48.201 371.299,16.32 326.374,1.018 C318.531,-1.656 310.01,2.538 307.339,10.38 C304.668,18.222 308.86,26.744 316.701,29.415 C354.041,42.134 384.193,68.631 401.603,104.028 C419.013,139.425 421.597,179.483 408.878,216.822 C406.207,224.664 410.399,233.186 418.24,235.857 C419.843,236.403 421.474,236.662 423.078,236.662 C429.321,236.662 435.15,232.733 437.275,226.495 C452.578,181.571 449.47,133.375 428.523,90.788 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);


export const IconScreenOrderFotovoltaic = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--screen-order-fotovoltaic">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#2A2C44'}>
                <path d="M112.485,289.022 C106.826,289.022 101.649,292.208 99.097,297.259 L54.521,385.511 L241,385.511 L241,289.022 L112.485,289.022 Z" id="Path"></path>
                <path d="M412.9,297.259 C410.348,292.208 405.171,289.022 399.512,289.022 L270.998,289.022 L270.998,385.511 L457.476,385.511 L412.9,297.259 Z" id="Path"></path>
                <path d="M510.373,490.237 L472.63,415.511 L270.998,415.511 L270.998,512 L496.985,512 C508.14,512 515.409,500.21 510.373,490.237 Z" id="Path"></path>
                <path d="M39.369,415.511 L1.626,490.237 C-3.402,500.193 3.841,512 15.013,512 L241,512 L241,415.511 L39.369,415.511 Z" id="Path"></path>
                <path d="M255.999,77.011 C225.673,77.011 201.002,101.684 201.002,132.011 C201.002,162.338 225.674,187.011 255.999,187.011 C286.324,187.011 310.996,162.338 310.996,132.011 C310.996,101.684 286.325,77.011 255.999,77.011 Z" id="Path"></path>
                <path d="M255.999,0 C247.715,0 240.999,6.716 240.999,15 L240.999,48.007 C240.999,56.291 247.715,63.007 255.999,63.007 C264.283,63.007 270.999,56.291 270.999,48.007 L270.999,15 C270.999,6.716 264.283,0 255.999,0 Z" id="Path"></path>
                <path d="M255.999,201.016 C247.715,201.016 240.999,207.732 240.999,216.016 L240.999,249.023 C240.999,257.307 247.715,264.023 255.999,264.023 C264.283,264.023 270.999,257.307 270.999,249.023 L270.999,216.016 C270.999,207.732 264.283,201.016 255.999,201.016 Z" id="Path"></path>
                <path d="M207.209,62.005 L183.872,38.666 C178.014,32.808 168.517,32.808 162.658,38.666 C156.801,44.523 156.8,54.021 162.658,59.879 L185.995,83.218 C191.855,89.077 201.353,89.075 207.209,83.218 C213.066,77.36 213.067,67.863 207.209,62.005 Z" id="Path"></path>
                <path d="M349.34,204.145 L326.003,180.806 C320.145,174.948 310.648,174.948 304.789,180.806 C298.932,186.663 298.931,196.161 304.789,202.019 L328.126,225.358 C333.987,231.217 343.484,231.214 349.34,225.358 C355.197,219.5 355.198,210.003 349.34,204.145 Z" id="Path"></path>
                <path d="M171.999,117.011 L138.994,117.011 C130.71,117.011 123.994,123.727 123.994,132.011 C123.994,140.295 130.71,147.011 138.994,147.011 L171.999,147.011 C180.283,147.011 186.999,140.295 186.999,132.011 C186.999,123.727 180.283,117.011 171.999,117.011 Z" id="Path"></path>
                <path d="M373.004,117.011 L339.999,117.011 C331.715,117.011 324.999,123.727 324.999,132.011 C324.999,140.295 331.715,147.011 339.999,147.011 L373.004,147.011 C381.288,147.011 388.004,140.295 388.004,132.011 C388.004,123.727 381.288,117.011 373.004,117.011 Z" id="Path"></path>
                <path d="M207.209,180.805 C201.35,174.947 191.855,174.948 185.995,180.805 L162.658,204.144 C156.8,210.002 156.801,219.499 162.658,225.357 C168.518,231.215 178.013,231.214 183.872,225.357 L207.209,202.018 C213.067,196.16 213.066,186.662 207.209,180.805 Z" id="Path"></path>
                <path d="M349.34,38.666 C343.481,32.809 333.986,32.809 328.126,38.666 L304.789,62.005 C298.931,67.863 298.932,77.36 304.789,83.218 C310.649,89.076 320.144,89.075 326.003,83.218 L349.34,59.879 C355.198,54.021 355.197,44.523 349.34,38.666 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);


export const IconScreenOrderEnergyUsage = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--screen-order-energy-usage">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 514 494" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#2A2C44'}>
                <path d="M247.07,395.97 C246.56,395.97 246.04,395.9 245.53,395.77 C242.9,395.07 241.06,392.69 241.06,389.96 L241.06,278.05 L171.67,278.05 C169.51,278.05 167.52,276.89 166.45,275.02 C165.39,273.14 165.41,270.83 166.52,268.98 L266.84,100.96 C268.23,98.64 270.99,97.53 273.59,98.25 C276.2,98.97 278,101.33 278,104.03 L278,216.41 L342.33,216.41 C344.46,216.41 346.44,217.55 347.52,219.39 C348.59,221.24 348.6,223.52 347.54,225.38 L252.28,392.93 C251.2,394.84 249.19,395.97 247.07,395.97 Z" id="Path"></path>
                <path d="M463.87,239.87 C462.18,239.87 460.58,239.16 459.44,237.91 L416.27,190.62 C414.67,188.86 414.25,186.32 415.21,184.15 C416.17,181.97 418.33,180.57 420.7,180.57 L437.99,180.57 C413.45,101.95 340.13,47.71 257,47.71 C171.24,47.71 95.91,105.45 73.82,188.12 C73.12,190.75 70.74,192.57 68.02,192.57 L32.23,192.57 C30.41,192.57 28.69,191.75 27.55,190.32 C26.41,188.9 25.98,187.04 26.37,185.26 C38.03,133.34 67.41,86.34 109.1,52.92 C150.93,19.39 203.45,0.93 257,0.93 C310.54,0.93 363.07,19.39 404.89,52.92 C445.33,85.33 474.18,130.51 486.52,180.57 L507.04,180.57 C509.42,180.57 511.57,181.97 512.53,184.15 C513.49,186.32 513.08,188.86 511.47,190.62 L468.3,237.92 C467.17,239.16 465.56,239.87 463.87,239.87 Z" id="Path"></path>
                <path d="M257,493.07 C203.45,493.07 150.93,474.61 109.11,441.08 C68.67,408.67 39.82,363.49 27.48,313.43 L6.96,313.43 C4.58,313.43 2.43,312.03 1.47,309.85 C0.51,307.68 0.92,305.14 2.53,303.39 L45.7,256.09 C46.83,254.84 48.44,254.13 50.13,254.13 C51.81,254.13 53.42,254.84 54.56,256.09 L97.73,303.39 C99.33,305.14 99.75,307.68 98.79,309.85 C97.83,312.03 95.68,313.43 93.3,313.43 L76.01,313.43 C100.55,392.04 173.88,446.29 257,446.29 C342.76,446.29 418.09,388.56 440.18,305.88 C440.88,303.26 443.26,301.43 445.97,301.43 L481.77,301.43 C483.59,301.43 485.31,302.26 486.45,303.68 C487.59,305.1 488.02,306.97 487.62,308.74 C475.97,360.66 446.59,407.66 404.89,441.08 C363.07,474.61 310.55,493.07 257,493.07 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);


export const IconScreenOrderMain = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--screen-order-main">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={props.color ? props.color : '#2A2C44'} fillRule="nonzero">
              <path d="M197.332031,170.667969 L37.332031,170.667969 C16.746094,170.667969 0,153.921875 0,133.332031 L0,37.332031 C0,16.746094 16.746094,0 37.332031,0 L197.332031,0 C217.921875,0 234.667969,16.746094 234.667969,37.332031 L234.667969,133.332031 C234.667969,153.921875 217.921875,170.667969 197.332031,170.667969 Z M37.332031,32 C34.390625,32 32,34.390625 32,37.332031 L32,133.332031 C32,136.277344 34.390625,138.667969 37.332031,138.667969 L197.332031,138.667969 C200.277344,138.667969 202.667969,136.277344 202.667969,133.332031 L202.667969,37.332031 C202.667969,34.390625 200.277344,32 197.332031,32 L37.332031,32 Z" id="Shape"></path>
              <path d="M197.332031,512 L37.332031,512 C16.746094,512 0,495.253906 0,474.667969 L0,250.667969 C0,230.078125 16.746094,213.332031 37.332031,213.332031 L197.332031,213.332031 C217.921875,213.332031 234.667969,230.078125 234.667969,250.667969 L234.667969,474.667969 C234.667969,495.253906 217.921875,512 197.332031,512 Z M37.332031,245.332031 C34.390625,245.332031 32,247.722656 32,250.667969 L32,474.667969 C32,477.609375 34.390625,480 37.332031,480 L197.332031,480 C200.277344,480 202.667969,477.609375 202.667969,474.667969 L202.667969,250.667969 C202.667969,247.722656 200.277344,245.332031 197.332031,245.332031 L37.332031,245.332031 Z" id="Shape"></path>
              <path d="M474.667969,512 L314.667969,512 C294.078125,512 277.332031,495.253906 277.332031,474.667969 L277.332031,378.667969 C277.332031,358.078125 294.078125,341.332031 314.667969,341.332031 L474.667969,341.332031 C495.253906,341.332031 512,358.078125 512,378.667969 L512,474.667969 C512,495.253906 495.253906,512 474.667969,512 Z M314.667969,373.332031 C311.722656,373.332031 309.332031,375.722656 309.332031,378.667969 L309.332031,474.667969 C309.332031,477.609375 311.722656,480 314.667969,480 L474.667969,480 C477.609375,480 480,477.609375 480,474.667969 L480,378.667969 C480,375.722656 477.609375,373.332031 474.667969,373.332031 L314.667969,373.332031 Z" id="Shape"></path>
              <path d="M474.667969,298.667969 L314.667969,298.667969 C294.078125,298.667969 277.332031,281.921875 277.332031,261.332031 L277.332031,37.332031 C277.332031,16.746094 294.078125,2.84217094e-14 314.667969,2.84217094e-14 L474.667969,2.84217094e-14 C495.253906,2.84217094e-14 512,16.746094 512,37.332031 L512,261.332031 C512,281.921875 495.253906,298.667969 474.667969,298.667969 Z M314.667969,32 C311.722656,32 309.332031,34.390625 309.332031,37.332031 L309.332031,261.332031 C309.332031,264.277344 311.722656,266.667969 314.667969,266.667969 L474.667969,266.667969 C477.609375,266.667969 480,264.277344 480,261.332031 L480,37.332031 C480,34.390625 477.609375,32 474.667969,32 L314.667969,32 Z" id="Shape"></path>
          </g>
      </g>
  </svg>
  </span>
);