import React from 'react';
import { connect } from 'react-redux';
import { fetchAllScenarios, toggleScenarioActiveById } from '../../actions/scenario';
import { wsUpdateScenarioSettingsByDeviceSerial } from '../../actions/ws';
import { fetchMasterDevice } from '../../actions/device';
import fetchStates from '../../types/fetchStates';
import withAuthorization from '../auth/withAuthorization';
import TemplatePage from '../templates/TemplatePage';
import ContentMain from '../partials/ContentMain';
import { RootState } from '../../reducers';
import { motion } from 'framer-motion';
import { roomsContainer, roomsItem } from '../../helpers/framer';
import AddItem from '../partials/AddItem';
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal';
import moment from 'moment';
import { danger } from '../../helpers/notifications';
import classnames from 'classnames';
import ButtonLink from '../partials/ButtonLink';


interface ScenariosProps {
  device: { status: string, deviceMaster: any },
  scenario: { status: string, scenarios: {}[], scenariosCount: number },
  ws: { wsConnected: boolean },
  fetchMasterDevice: () => Promise<any>,
  fetchAllScenarios: () => Promise<void>,
  toggleScenarioActiveById: (options: {scenario_id: string, tst_upd: string, sc_act: number}) => Promise<void>,
  wsUpdateScenarioSettingsByDeviceSerial: (options: { device_serial: string, scenario_settings: any }) => Promise<void>,
}


interface ScenariosState {
  scenariosLimit: number,
  scenariosCount: number,
  scenarios: any,
  deviceMaster: any,
  wsConnected: boolean,
}


export class Scenarios extends React.Component<ScenariosProps, ScenariosState> {


  state = {
    scenariosLimit: 10,
    scenariosCount: 0,
    scenarios: [],
    deviceMaster: { device_serial: '' },
    wsConnected: false,
  }


  componentDidMount = async () => {
    await this.fetchMasterDevice();
    await this.fetchAllScenarios();
  }


  fetchMasterDevice = async () => {
    await this.props.fetchMasterDevice();
    const { status, deviceMaster } = this.props.device;
    if(status !== fetchStates.success) { return; }
    this.setState({ deviceMaster });
  }


  fetchAllScenarios = async () => {
    await this.props.fetchAllScenarios();
    const { status, scenarios } = this.props.scenario;
    if(status !== fetchStates.success) { return; }
    this.setState({ scenarios, scenariosCount: scenarios.length });
  }


  updateScenarios = (scenario_id: string, sc_act: number) => {
    let updatedScenarios: any = [];
    updatedScenarios = this.state.scenarios;
    updatedScenarios.map((scenario: { scenario_id: string, sc_act: number }) => {
      if(scenario_id === scenario.scenario_id) {
        scenario.sc_act = sc_act;
      }
      return null;
    })
    return updatedScenarios;
  }


  toggleScenarioActive = async (options: { scenario_id: string, sc_id: number, sc_act: number }) => {
    if(!this.props.ws.wsConnected) { danger('You are disconnected from the WS server'); return;}
    const tst_upd = moment().format('X');
    const {scenario_id, sc_id, sc_act} = options;
    await this.props.toggleScenarioActiveById({ scenario_id, tst_upd, sc_act });
    const { status } = this.props.scenario;
    if(status !== fetchStates.success) { return; }
    const scenarios = this.updateScenarios(scenario_id, sc_act);
    this.setState({ scenarios });
    const scenario_settings = { sc_id, tst_upd, sc_act };
    this.props.wsUpdateScenarioSettingsByDeviceSerial({ device_serial: this.state.deviceMaster.device_serial, scenario_settings });
  }


  render() {

    const { scenarios, scenariosCount, scenariosLimit } = this.state;

    return (
      <TemplatePage title={`Scenarios ${scenariosCount}/10`} button={scenariosCount >= scenariosLimit ? false : true} buttonLink="/scenarios/add" buttonType="add" buttonText="Add Scenario">
        <ContentMain>
          {
            scenarios && scenarios.length > 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="scenarios"
              >
                {
                  scenarios.map((item: { scenario_id: string, sc_id: number, sc_title: string, sc_act: number }) => (
                    <motion.div key={item.scenario_id} variants={roomsItem} className={classnames('scenario', { 'scenario--disabled': item.sc_act === 0 })}>
                        <div className="scenario__header">
                          <span className="room__title-button">
                            <ButtonLink iconType='edit' buttonLink={`/scenarios/edit/${item.scenario_id}`} />
                          </span>
                          <div className="scenario__title">
                            {item.sc_title}
                          </div>
                        </div>
                        <div className="scenario__switch">
                          <label htmlFor="enabled" onClick={() => this.toggleScenarioActive({ scenario_id: item.scenario_id, sc_id: item.sc_id, sc_act: item.sc_act === 0 ? 1 : 0 })}>
                            <SwitchTrueFalseHorizontal small switchState={item.sc_act === 0 ? false : true} />
                          </label>
                        </div>
                    </motion.div>
                  )) 
                }
              <motion.div key={10000} variants={roomsItem}>
                <AddItem title="Add Scenario" link="/scenarios/add" type="scenarios" disabled={scenariosCount >= scenariosLimit ? true : false} />
              </motion.div>
            </motion.div>
            )
          }

          {
            scenarios && scenarios.length === 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="scenarios"
              >
                <motion.div key={10000} variants={roomsItem}>
                  <AddItem title="Add Scenario" link="/scenarios/add" type="scenarios" />
                </motion.div>
              </motion.div>
            )
          }
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default (withAuthorization(connect(
  ({ scenario, device, ws }: RootState) => ({ scenario, device, ws }),
  { fetchAllScenarios, toggleScenarioActiveById, fetchMasterDevice, wsUpdateScenarioSettingsByDeviceSerial }
)(Scenarios)));