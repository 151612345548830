import React from 'react'
import { connect } from 'react-redux'
import { confirmRegistration } from '../../actions/auth'
import { Navigate } from 'react-router-dom'
import { VERSION } from '../../config'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { IconBack } from '../partials/IconsNavigation'
import fetchStates from '../../types/fetchStates'
import { container, item } from '../../helpers/framer'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'


interface AuthRegisterConfirmationProps {
  router: {navigate: (to: string) => any, params: { token: string }},
  auth: { status: string, authenticated: boolean },
  confirmRegistration: (options: { token: string }) => Promise<void>,
}


export class AuthRegisterConfirmation extends React.Component<AuthRegisterConfirmationProps> {


  componentDidMount(){
    this.confirmRegistration()
  }


  confirmRegistration = async () => {
    const token = this.props.router.params.token; 
    this.setState({ token });
    await this.props.confirmRegistration({ token })
    if(this.props.auth.status === fetchStates.success) {
      this.props.router.navigate('/');
    }
  }


  render() {

    const { status, authenticated } = this.props.auth

    return authenticated ? <Navigate to="/" /> : (

      <div className="template template-auth">
        <div className="page-content">

        <motion.div className="auth-container" variants={container}
        initial="hidden"
        animate="visible">
            <div className="auth-back-button">
              <Link to="/" className="btn-square">
                <IconBack color="#ffffff" />
              </Link>
            </div>
            <div className="auth-logo" />
            <div className="auth-box">
              <div className="auth-box__wrapper">
                <motion.div className="auth-form__header" key={1} variants={item}>
                  <h1 className="auth-box__title">Registration confirmation</h1>
                </motion.div>

                { status === fetchStates.fetching && (
                  <motion.p className="auth-box__text" key={2} variants={item}>Please wait, we are confirming your registration...</motion.p>
                )}
                { status === fetchStates.success && (
                  <motion.p className="auth-box__text" key={2} variants={item}>Your confirmation was completed successfully. You can now sign in.</motion.p>
                )}
                { status === fetchStates.error && (
                  <motion.p className="auth-box__text" key={2} variants={item}>There was an error completing your registration. Please contact support.</motion.p>
                )}

              </div>
            </div>
          <div className="auth-version">v{VERSION}</div>
        </motion.div>

      </div>
    </div>

    )
  }
}

export default withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { confirmRegistration }
)(AuthRegisterConfirmation));