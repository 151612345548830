import {
  IconButtonsBlinds, 
  IconButtonsGarage,
  IconButtonsGate,
  IconButtonsLight,
  IconButtonsAlarm,
  IconButtonsDoorsLeaving,
  IconButtonsDoorsEntering,
  IconButtonsScenario,
  IconButtonsNone
} from '../components/partials/IconsButtons'


interface getRoomIconOptions {
  button_image: string;
  size: number;
  color: string;
}


export const getButtonIcon = (options: getRoomIconOptions) => {
  switch(parseInt(options.button_image)) {
    case 1:
      return <IconButtonsBlinds size={options.size} color={options.color} />
    case 2:
      return <IconButtonsGarage size={options.size} color={options.color} />
    case 3:
      return <IconButtonsGate size={options.size} color={options.color} />
    case 4:
      return <IconButtonsLight size={options.size} color={options.color} />
    case 5:
      return <IconButtonsAlarm size={options.size} color={options.color} />
    case 6:
      return <IconButtonsDoorsLeaving size={options.size} color={options.color} />
    case 7:
      return <IconButtonsDoorsEntering size={options.size} color={options.color} />
    case 8:
      return <IconButtonsScenario size={options.size} color={options.color} />
    default:
      return <IconButtonsNone size={options.size} color={options.color} />
  }
}