interface ContentMainProps {
  children: any,
}


const ContentMain: React.FC<ContentMainProps> = ({ children }) => {
  return (
    <div className="content-main">
      { children }
    </div>
  )
}


export default ContentMain;