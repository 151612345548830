import { DEVICE, DATA } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchDevice = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: DEVICE.FETCH });
  return fetch(`${API_ADDRESS}/device/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: DEVICE.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: string}) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: DEVICE.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllDevices = () => fetchDevice({
  endpoint: '',
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_ALL_SUCCESS
});


export const fetchMasterDevice = () => fetchDevice({
  endpoint: 'master',
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_MASTER_SUCCESS
});

export const fetchSlaveDevices = () => fetchDevice({
  endpoint: 'slave',
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_SLAVE_SUCCESS
});


export const fetchRoomDevices = (id: string) => fetchDevice({
  endpoint: `room/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_ROOM_DEVICES_SUCCESS
});


export const fetchDeviceById = (id: string) => fetchDevice({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_ID_SUCCESS
});

export const fetchDeviceGeneralSettings = (device_serial: string) => fetchDevice({
  endpoint: `general-settings/${device_serial}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_GENERAL_SETTINGS_SUCCESS
});


export const fetchMagicBoxAll = (options:{ limit: number, skip: number }) => fetchDevice({
  endpoint: 'magicbox/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_ALL_MAGICBOX_SUCCESS
});


export const fetchMagicSwitchAll = (options:{ limit: number, skip: number }) => fetchDevice({
  endpoint: 'magicswitch/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_ALL_MAGICSWITCH_SUCCESS
});


export const removeDeviceById = (options:{ id: string, device_serial: string }) => fetchDevice({
  endpoint: `${options.id}`,
  options: {
    method: 'DELETE',
    body: JSON.stringify({ device_serial: options.device_serial }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_REMOVE_ID_SUCCESS
});


export const addNewDevice = (options: { device_serial: string, device_title: string, device_capabilities: any, room_id: string }) => fetchDevice({
  endpoint: ``,
  options: {
    method: 'POST',
    body: JSON.stringify({ device_serial: options.device_serial, device_title: options.device_title, device_capabilities: options.device_capabilities, room_id: options.room_id }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_ADD_SUCCESS
});


export const registerNewDevice = (options: { device_serial: string, device_title: string, device_capabilities: any, room_id: string }) => fetchDevice({
  endpoint: `register`,
  options: {
    method: 'POST',
    body: JSON.stringify({ device_serial: options.device_serial, device_title: options.device_title, device_capabilities: options.device_capabilities, room_id: options.room_id }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_REGISTER_SUCCESS
});


export const updateDevice = (options: { device_id: string, device_serial: string, device_title: string, device_master: boolean, room_id: string }) => fetchDevice({
  endpoint: `${options.device_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ device_serial: options.device_serial, device_title: options.device_title, device_master: options.device_master, room_id: options.room_id }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_ID_SUCCESS
});


export const updateDeviceClock = (options: { id: string, clock_settings: any }) => fetchDevice({
  endpoint: `settings/clock/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ clock_settings: options.clock_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_CLOCK_SUCCESS
});


export const updateDeviceAlarm = (options: { id: string, alarm_settings: any }) => fetchDevice({
  endpoint: `settings/alarm/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ alarm_settings: options.alarm_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_ALARM_SUCCESS
});


export const updateDeviceStocks = (options: { id: string, stocks_settings: any }) => fetchDevice({
  endpoint: `settings/stocks/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ stocks_settings: options.stocks_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_STOCKS_SUCCESS
});


export const updateDeviceButtons = (options: { id: string, btn_settings: any }) => fetchDevice({
  endpoint: `settings/buttons/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ btn_settings: options.btn_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_BUTTONS_SUCCESS
});


export const updateDeviceScreens = (options: { id: string, screens_settings: any }) => fetchDevice({
  endpoint: `settings/screens/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ screens_settings: options.screens_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_SCREENS_SUCCESS
});


export const updateDeviceWeather = (options: { id: string, weather_settings: any }) => fetchDevice({
  endpoint: `settings/weather/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ weather_settings: options.weather_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_WEATHER_SUCCESS
});


export const updateDevicePresenceSensor = (options: { id: string, presence_sensor_settings: any }) => fetchDevice({
  endpoint: `settings/presence-sensor/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ presence_sensor_settings: options.presence_sensor_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_PRESENCE_SENSOR_SUCCESS
});


export const updateDeviceThermostat = (options: { id: string, thermostat_settings: any }) => fetchDevice({
  endpoint: `settings/thermostat/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ thermostat_settings: options.thermostat_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_THERMOSTAT_SUCCESS
});


export const updateDeviceIo = (options: { id: string, device_capabilities: string }) => fetchDevice({
  endpoint: `settings/io/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ device_capabilities: options.device_capabilities }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_IO_SUCCESS
});


export const updateDeviceStatusIcons = (options: { id: string, status_settings: any }) => fetchDevice({
  endpoint: `settings/status-icons/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ status_settings: options.status_settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_STATUS_SUCCESS
});


export const fetchOutputButtonOptions = () => fetchDevice({
  endpoint: `output-button-options`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_OUTPUT_BUTTON_OPTIONS_SUCCESS
});


export const updateAndGetAlarmSettings = () => fetchDevice({
  endpoint: `settings/update-get-alarm-settings`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_GET_ALARM_SETTINGS
});


export const updatePinAndGetAlarmSettings = (alarm_pin: string) => fetchDevice({
  endpoint: `settings/update-pin-get-alarm-settings`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ alarm_pin }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: DEVICE.FETCH_UPDATE_PIN_GET_ALARM_SETTINGS
});


export const wsGotDataByDeviceSerial = (device_data:any) => async (dispatch: any) => {
  await dispatch({ type: DEVICE.GOT_DATA_BY_DEVICE_SERIAL, device_data }); 
}


export const wsGotAlert = () => async (dispatch: any) => {
  await dispatch({ type: DATA.GOT_ALERT }); 
}