import DeviceUpdate from '../partials/DeviceUpdate'
import { IconDeviceDoors } from '../partials/IconsDevices'


interface DeviceMagicDoorsProps {
  device: any,
}


const DeviceMagicDoors: React.FC<DeviceMagicDoorsProps> = ({ device }) => {


  if(device.device_data && device.device_data.updSt === -1) {
    // UPDATE ERROR
    return <DeviceUpdate type="error" />
  }
  

  if(device.device_data && device.device_data.updSt === 1) {
    // UPDATE IN PROGRESS
    return <DeviceUpdate type="updating" />
  }


  if(device.device_data && device.device_data.updSt === 2) {
    // UPDATE COMPLETED, GETTING DATA...    
    return <DeviceUpdate type="updated" />
  }


  return (
    <div className="device-type device-type__magicdoors">
      <div className="doors-row__icon">
        <IconDeviceDoors color={device.device_data ? '#000000' : '#828BA9'} />
      </div>
      <div className="doors-row__tools">
        <div className="doors-tools__description">
          <div className="doors-description__title">State:</div>
          <div className="doors-description__state">{ device.device_data ? (<span className="state--on">LOCKED</span>) : 'UNKNOWN'}</div>
        </div>
        <div className="doors-tools__switch">
        </div>
      </div>
    </div>
  )
}


export default DeviceMagicDoors