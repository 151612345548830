import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import { wsResetConnection } from '../../actions/ws'
import { Navigate } from 'react-router-dom'
import Spinner from '../partials/Preloader'

const withAuthorization = (Component: any, requiresPermissions?: string) => {


  interface WithAuthorizationProps {
    auth: { status: string, fields: Array<string>, authenticated: boolean, person: { permissions: any } },
    ws: { wsConnected: boolean },
    checkAuth: () => Promise<void>,
    wsResetConnection: () => Promise<void>,
  }
  
  
  interface WithAuthorizationState {
    authTest: boolean
  }

  class WithAuthorization extends React.Component<WithAuthorizationProps, WithAuthorizationState> {

    state = {
      authTest: false
    }

    componentDidMount(){
      this.props.checkAuth()
      .then(() => {
        this.setState({ authTest: true })
        if(this.props.auth.authenticated === true) {
          if(this.props.ws.wsConnected === false) {
            this.props.wsResetConnection()
          }
        }
      })
    }

    resolveComponent () {
      if(this.props.auth.authenticated === true) {
        if(requiresPermissions) {
          if(this.props.auth.person.permissions[requiresPermissions] === true) {
            return <Component {...this.props} />
          } else {
            return <Navigate to="/notfound" />
          }
        } else {
          return <Component {...this.props} />
        }
      } else {
        return <Navigate to="/login" />
      } 
    }

    render() {
      const { authTest } = this.state
      
      if(authTest) {
        return this.resolveComponent()
      } else {
        return <Spinner />
      }
      
    }
  }


  return connect(
    ({ auth, ws }: RootState) => ({ auth, ws }),
    { checkAuth, wsResetConnection }
  )(WithAuthorization);

}


export default withAuthorization;