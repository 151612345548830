export const IconDownArrow = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--down-arrow">
    <svg width={props.size ? props.size : '10px'} height={props.size ? props.size : '17px'} viewBox="0 0 378 464" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={props.color ? props.color : '#2A2C44'}>
              <path d="M373.483,265.441 C370.779,262.664 367.064,261.105 363.188,261.12 L287.668,261.12 C281.777,261.12 277.001,256.344 277.001,250.453 L277.001,14.293 C277.001,6.399 270.602,0 262.708,0 L115.721,0 C107.827,0 101.428,6.399 101.428,14.293 L101.428,250.453 C101.428,256.344 96.652,261.12 90.761,261.12 L15.028,261.12 C7.135,260.971 0.616,267.248 0.467,275.14 C0.392,279.091 1.957,282.897 4.788,285.653 L178.868,459.733 C181.516,462.423 185.12,463.957 188.895,464 C192.744,464.014 196.435,462.476 199.135,459.733 L373.215,285.653 C378.87,280.146 378.99,271.097 373.483,265.441 Z" id="Path"></path>
          </g>
      </g>
    </svg>
  </span>
);


export const IconUpArrow = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--down-arrow">
    <svg width={props.size ? props.size : '10px'} height={props.size ? props.size : '17px'} viewBox="0 0 448 513" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="up-arrow" fill={props.color ? props.color : '#2A2C44'}>
                <path d="M444.723,216.64 L231.305,3.115 C229.299,1.109 226.59,0 223.753,0 C220.916,0 218.206,1.131 216.201,3.136 L3.422,216.64 C0.371,219.691 -0.525,224.277 1.118,228.267 C2.782,232.256 6.665,234.838 10.974,234.838 L128.307,234.838 L128.307,501.505 C128.307,507.393 133.086,512.172 138.974,512.172 L309.641,512.172 C315.529,512.172 320.308,507.393 320.308,501.505 L320.308,234.837 L437.193,234.837 C441.502,234.837 445.385,232.234 447.049,228.245 C448.713,224.256 447.774,219.691 444.723,216.64 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);