import { Link } from 'react-router-dom'
import { IconAdd } from '../partials/IconsNavigation'
import classnames from 'classnames'


interface AddItemProps {
  title: string,
  link: string,
  type?: string,
  disabled?: boolean,
}


const AddItem: React.FC<AddItemProps> = ({ title, link, type, disabled }) => {

  if(disabled) {
    return (
      <div className={classnames('add-box', { 
        'add-box--scenarios': type === 'scenarios',
        'add-box--rooms': type === 'rooms',
        'add-box--user': type === 'user',
        'add-box--device': type === 'device',
        'add-box--magic-box': type === 'magic-box',
        'add-box--magic-switch': type === 'magic-switch',
        'add-box--disabled': disabled === true
      })}>
        <div className="add-box__items">
          <div className="add-box__circle">
            <IconAdd size={32} color="#A19BCD" />
          </div>
          <div className="add-box__link">{title}</div>
        </div>
      </div>
    )
  }

  return (
    <Link to={link} className={classnames('add-box', { 
      'add-box--scenarios': type === 'scenarios',
      'add-box--rooms': type === 'rooms',
      'add-box--user': type === 'user',
      'add-box--device': type === 'device',
      'add-box--magic-box': type === 'magic-box',
      'add-box--magic-switch': type === 'magic-switch',
    })}>
      <div className="add-box__items">
        <div className="add-box__circle">
          <IconAdd size={32} color="#593995" />
        </div>
        <div className="add-box__link">{title}</div>
      </div>
    </Link>
  )
}


export default AddItem