import { combineReducers } from 'redux'
import auth from './auth'
import person from './person'
import account from './account'
import room from './room'
import device from './device'
import scenario from './scenario'
import data from './data'
import notification from './notification'
import ws from './ws'
import developer from './developer'

export const rootReducer = combineReducers({
  auth,
  person,
  account,
  room,
  device,
  scenario,
  data,
  notification,
  ws,
  developer
})

export type RootState = ReturnType<typeof rootReducer>