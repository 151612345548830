import { NOTIFICATION } from '../types';
import fetchStates from '../types/fetchStates';

interface NotificationState {
  notifications: [],
  notification_id: string,
}

const DEFAULT_NOTIFICATION: NotificationState = {
  notifications: [],
  notification_id: ''
};

const notification = (state = DEFAULT_NOTIFICATION, action: any) => {
  switch(action.type) {
    case NOTIFICATION.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case NOTIFICATION.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case NOTIFICATION.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        notifications: action.notifications,
      };
    case NOTIFICATION.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        notification_id: action.notification_id,
      }
    case NOTIFICATION.UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case NOTIFICATION.REMOVE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case NOTIFICATION.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};

export default notification;