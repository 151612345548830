import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { checkTokenValid, resetPassword } from '../../actions/auth'
import classnames from 'classnames'
import { VERSION } from '../../config'
import { IconVisibility, IconVisibilityOff, IconLock } from '../partials/IconsForm'
import { IconBack } from '../partials/IconsNavigation'
import { Link } from 'react-router-dom'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import { motion } from 'framer-motion'
import { container, item } from '../../helpers/framer'
import { RootState } from '../../reducers'
import fetchStates from '../../types/fetchStates'
import withRouter from '../partials/WithRouter'


interface AuthResetPasswordProps {
  router: {params: {token: string}, navigate: any},
  auth: { status: string, fields: Array<string>, authenticated: boolean, token: string },
  checkTokenValid: (options: { token: string }) => Promise<void>,
  resetPassword: (options: { reset_password: string, confirm_reset_password: string, token: string }) => Promise<void>,
}


interface AuthResetPasswordState {
  formSubmitted: boolean,
  token: string,
  reset_password: string,
  confirm_reset_password: string,
  passwordShow: boolean,
  passwordConfirmShow: boolean,
  number1: string,
  number2: string,
  number3: string,
  number4: string,
  number5: string,
  number6: string,
  tokenConfirmed: boolean
}


export class AuthResetPassword extends React.Component<AuthResetPasswordProps & AuthResetPasswordState> {

  public inputRef1: React.RefObject<HTMLInputElement>;
  public inputRef2: React.RefObject<HTMLInputElement>;
  public inputRef3: React.RefObject<HTMLInputElement>;
  public inputRef4: React.RefObject<HTMLInputElement>;
  public inputRef5: React.RefObject<HTMLInputElement>;
  public inputRef6: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props)
    this.inputRef1 = React.createRef()
    this.inputRef2 = React.createRef()
    this.inputRef3 = React.createRef()
    this.inputRef4 = React.createRef()
    this.inputRef5 = React.createRef()
    this.inputRef6 = React.createRef()
  }
  

  state = {
    formSubmitted: false,
    token: '',
    reset_password: '',
    confirm_reset_password: '',
    passwordShow: false,
    passwordConfirmShow: false,
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    number5: '',
    number6: '',
    tokenConfirmed: false
  }


  componentDidMount() {
    if(this.props.router.params.token !== undefined) {
      this.setState({ token: this.props.router.params.token })
    }
  }

  
  updateNumber1 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number1: e.target.value }, () => this.submitPIN())
      if(e.target.value.length > 5) {
        this.setState({
          number1: e.target.value[0],
          number2: e.target.value[1],
          number3: e.target.value[2],
          number4: e.target.value[3],
          number5: e.target.value[4],
          number6: e.target.value[5],
        })
      }
      if (this.inputRef2.current && e.target.value.length > 0) {
        this.inputRef2.current.focus()
        this.inputRef2.current.select()
      }
    }
  }

  
  updateNumber2 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number2: e.target.value }, () => this.submitPIN())
      if (this.inputRef3.current && e.target.value.length > 0) {
        this.inputRef3.current.focus()
        this.inputRef3.current.select()
      }
    }
  }


  updateNumber3 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number3: e.target.value }, () => this.submitPIN())
      if (this.inputRef4.current && e.target.value.length > 0) {
        this.inputRef4.current.focus()
        this.inputRef4.current.select()
      }
    }
  }


  updateNumber4 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number4: e.target.value }, () => this.submitPIN())
      if (this.inputRef5.current && e.target.value.length > 0) {
        this.inputRef5.current.focus()
        this.inputRef5.current.select()
      }
    }
  }


  updateNumber5 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number5: e.target.value }, () => this.submitPIN())
      if (this.inputRef6.current && e.target.value.length > 0) {
        this.inputRef6.current.focus()
        this.inputRef6.current.select()
      }
    }
  }


  updateNumber6 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number6: e.target.value }, () => this.submitPIN())
    }
  }

  clearNumber2 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef1.current && e.target.value.length === 0) {
        this.inputRef1.current.focus()
        this.inputRef1.current.select()
      }
    }
  }

  clearNumber3 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef2.current && e.target.value.length === 0) {
        this.inputRef2.current.focus()
        this.inputRef2.current.select()
      }
    }
  }

  clearNumber4 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef3.current && e.target.value.length === 0) {
        this.inputRef3.current.focus()
        this.inputRef3.current.select()
      }
    }
  }

  clearNumber5 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef4.current && e.target.value.length === 0) {
        this.inputRef4.current.focus()
        this.inputRef4.current.select()
      }
    }
  }


  clearNumber6 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef5.current && e.target.value.length === 0) {
        this.inputRef5.current.focus()
        this.inputRef5.current.select()
      }
    }
  }


  submitPIN = () => {
    if(this.state.number1 !== '' &&
    this.state.number2 !== '' &&
    this.state.number3 !== '' &&
    this.state.number4 !== '' &&
    this.state.number5 !== '' &&
    this.state.number6 !== ''
    ) {
      const { number1, number2, number3, number4, number5, number6, token } = this.state;
      const pin = number1.toString() + number2.toString() + number3.toString() + number4.toString() + number5.toString() + number6.toString()
      this.props.checkTokenValid({ token: pin + '|' + token })
      .then(() => {
        if(this.props.auth.status === fetchStates.success) {
          this.setState({ tokenConfirmed: true })
        }
      });
    }
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { reset_password, confirm_reset_password, number1, number2, number3, number4, number5, number6, token } = this.state;
    const pin = number1.toString() + number2.toString() + number3.toString() + number4.toString() + number5.toString() + number6.toString()
    await this.props.resetPassword({ reset_password, confirm_reset_password, token: pin + '|' + token })
    if(this.props.auth.status === 'success') {
      this.setState({ password: '', confirm_password: '', formSubmitted: false });
      this.props.router.navigate('/');
    }
  }


  render() {

    const { formSubmitted, reset_password, confirm_reset_password, passwordShow, passwordConfirmShow, number1, number2, number3, number4, number5, number6, tokenConfirmed } = this.state
    const { authenticated, fields, status } = this.props.auth
    
    return authenticated ? <Navigate to="/" /> : (
        <div className="template template-auth">
          <div className="page-content">
            <motion.div
                className="auth-box" 
                variants={container}
                initial="hidden"
                animate="visible"
              >
              <div className="auth-container">
                <div className="auth-back-button">
                  <Link to="/" className="btn-square">
                    <IconBack color="#ffffff" />
                  </Link>
                </div>
              <div className="auth-logo" />
                <div className="auth-box__wrapper">
                  <motion.div className="auth-form__header" key={1} variants={item}>
                    <h1 className="auth-box__title">Reset Password</h1>
                  </motion.div>
                  { tokenConfirmed === false ? (
                    <>
                      <motion.p className="auth-box__text" key={2} variants={item}>Please enter your reset PIN number:</motion.p>
                      <form className="auth-form" onSubmit={this.handleOnSubmit}>
                        <div className="form-group">
                          <input
                            id="number1"
                            type="test"
                            value={number1}
                            ref={this.inputRef1} 
                            onChange={e => this.updateNumber1(e)}
                            className="pin-number"
                          />
                          <input
                            id="number2"
                            type="test"
                            value={number2}
                            ref={this.inputRef2} 
                            onChange={e => this.updateNumber2(e)}
                            onKeyDown={e => this.clearNumber2(e)}
                            className="pin-number"
                          />
                          <input
                            id="number3"
                            type="test"
                            value={number3}
                            ref={this.inputRef3} 
                            onChange={e => this.updateNumber3(e)}
                            onKeyDown={e => this.clearNumber3(e)}
                            className="pin-number"
                          />
                          <input
                            id="number4"
                            type="test"
                            value={number4}
                            ref={this.inputRef4} 
                            onChange={e => this.updateNumber4(e)}
                            onKeyDown={e => this.clearNumber4(e)}
                            className="pin-number"
                          />
                          <input
                            id="number5"
                            type="test"
                            value={number5}
                            ref={this.inputRef5} 
                            onChange={e => this.updateNumber5(e)}
                            onKeyDown={e => this.clearNumber5(e)}
                            className="pin-number"
                          />
                          <input
                            id="number6"
                            type="test"
                            value={number6}
                            ref={this.inputRef6} 
                            onChange={e => this.updateNumber6(e)}
                            onKeyDown={e => this.clearNumber6(e)}
                            className="pin-number"
                          />
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <motion.p className="auth-box__text" key={2} variants={item}>Please enter your new password.</motion.p>
                      <form className="auth-form" onSubmit={this.handleOnSubmit}>
                        <motion.div className="form-group" key={3} variants={item}>
                          <label htmlFor="reset_password">
                            Password
                          </label>

                          <div className="input-group">
                            <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                              { passwordShow === false ? (
                                <IconVisibility password size={18} color="#000000" />
                              ) : (
                                <IconVisibilityOff password size={18} color="#000000" />
                              )}
                            </span>
                            <span className="input-icon">
                              <IconLock color="#A19BCD" />
                            </span>
                            <input
                              id="password"
                              placeholder="New password"
                              type={ passwordShow === false ? 'password' : 'text' } 
                              autoComplete="current-password"
                              value={reset_password}
                              className={classnames('', { 'input-error': fields && fields.includes('reset_password') })}
                              onChange={e => this.setState({ reset_password: e.target.value })}
                            />
                          </div>
                          <PasswordStrengthMeter password={reset_password} />
                        </motion.div>
                        <motion.div className="form-group" key={4} variants={item}>
                          <label htmlFor="confirm_reset_password">
                            Password Confirmation
                          </label>
                          <div className="input-group">
                            <span className="password-reveal" onClick={e => this.setState({ passwordConfirmShow: !passwordConfirmShow })}>
                              { passwordConfirmShow === false ? (
                                <IconVisibility password size={18} color="#000000" />
                              ) : (
                                <IconVisibilityOff password size={18} color="#000000" />
                              )}
                            </span>
                            <span className="input-icon">
                              <IconLock color="#A19BCD" />
                            </span>
                            <input
                              id="confirm_password"
                              placeholder="Confirm your new password"
                              type={ passwordConfirmShow === false ? 'password' : 'text' } 
                              autoComplete="current-password"
                              value={confirm_reset_password}
                              className={classnames('', { 'input-error': fields && fields.includes('confirm_reset_password') })}
                              onChange={e => this.setState({ confirm_reset_password: e.target.value })}
                            />
                          </div>
                        </motion.div>
                        <motion.div className="form-group" key={5} variants={item}>
                          <input 
                            type="submit"
                            className="btn btn--primary btn--large btn--fullwidth" 
                            value="Reset Your Password"
                            disabled={formSubmitted && status === 'fetching' ? true : false} 
                          />
                        </motion.div>
                      </form>
                    </>
                  )}
                </div>
              </div>
              <div className="auth-version">v{VERSION}</div>
            </motion.div>
          </div>
        </div>
      )
    }
  }
  
  
  export default withRouter(connect(
    ({ auth }: RootState) => ({ auth }),
    { checkTokenValid, resetPassword }
  )(AuthResetPassword));