import { NavLink } from 'react-router-dom'
import { IconTabNotifications, IconTabChangePassword, IconTabSecurity, IconRemoveAccount, IconTabLogout, IconEnergy } from './IconsTabs'
import { IconWeatherPartiallyCloudy } from './IconsWeather'


interface IconNavSettingsProps {
  location: any
}


const IconNavSettings: React.FC<IconNavSettingsProps> = ({ location }) => (
    <nav className="iconnav">

      <NavLink 
        to={{ pathname: `/settings/weather` }} 
        className={location.pathname === '/settings/weather' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
      >
        <IconWeatherPartiallyCloudy size={40} color={location.pathname === '/settings/weather' ? '#000000' : '#4D409E'} />
        <div className="iconnav-tab--title">Weather Settings</div>
      </NavLink>

      <NavLink 
        to={{ pathname: `/settings/energy-price` }} 
        className={location.pathname === '/settings/energy-price' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
      >
        <IconEnergy size={40} color={location.pathname === '/settings/energy-price' ? '#000000' : '#4D409E'} />
        <div className="iconnav-tab--title">Energy Price Settings</div>
      </NavLink>

      <NavLink 
        to={{ pathname: `/settings/security` }} 
        className={location.pathname === '/settings/security' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
      >
        <IconTabSecurity size={40} color={location.pathname === '/settings/security' ? '#000000' : '#4D409E'} />
        <div className="iconnav-tab--title">Security PIN</div>
      </NavLink>

      <NavLink 
        to={{ pathname: `/settings/notifications` }} 
        className={location.pathname === '/settings/notifications' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
      >
        <IconTabNotifications size={40} color={location.pathname === '/settings/notifications' ? '#000000' : '#4D409E'} />
        <div className="iconnav-tab--title">Notifications</div>
      </NavLink>

      <NavLink 
        to={{ pathname: `/settings/password` }} 
        className={location.pathname === '/settings/password' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
      >
        <IconTabChangePassword size={40} color={location.pathname === '/settings/password' ? '#000000' : '#4D409E'} />
        <div className="iconnav-tab--title">Change Password</div>
      </NavLink>

      {
        /*
          <NavLink 
            to={{ pathname: `/settings/email` }} 
            className={location.pathname === '/settings/email' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
          >
            <IconTabChangeEmail size={40} color={location.pathname === '/settings/email' ? '#000000' : '#4D409E'} />
            <div className="iconnav-tab--title">Change Email</div>
          </NavLink>
        */
      }

      <NavLink 
        to={{ pathname: `/settings/logout` }} 
        className={location.pathname === '/settings/logout' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
      >
        <IconTabLogout size={40} color={location.pathname === '/settings/logout' ? '#000000' : '#4D409E'} />
        <div className="iconnav-tab--title">Logout from sessions</div>
      </NavLink>

      <NavLink 
        to={{ pathname: `/settings/remove-account` }} 
        className={location.pathname === '/settings/remove-account' ? 'iconnav-tab iconnav-tab--active' : 'iconnav-tab'} 
      >
        <IconRemoveAccount size={40} color={location.pathname === '/settings/remove' ? '#000000' : '#4D409E'} />
        <div className="iconnav-tab--title">Remove Account</div>
      </NavLink>
      
    </nav>
)


export default IconNavSettings