import React from 'react'
import { connect } from 'react-redux'
import { fetchPersonById, updatePerson } from '../../actions/person'
import { fetchAllRooms } from '../../actions/room'
import { fetchAllDevices, fetchDeviceById, updateDeviceAlarm, updateAndGetAlarmSettings } from '../../actions/device'
import { wsUpdateAlarmSettings } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import { IconClose } from '../partials/Icons'
import { IconVisibility, IconVisibilityOff, IconLock, IconEmail, IconPin, IconUsers, IconName, IconRoom } from '../partials/IconsForm'
import { numberToRole } from '../../helpers/roles'
import { RootState } from '../../reducers'
import fetchStates from '../../types/fetchStates'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
import { danger } from '../../helpers/notifications'
import Alert from '../partials/Alert'


const requiresPermissions = 'manage_users'


interface UsersEditProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  auth: { person: { role: number, person_id: string } },
  person: {status: string, fields: Array<string>, person: { 
    person_id: string,
    role: string,
    email: string,
    name: string,
    alarm_pin: string,
    rooms: Array<any>,
  }},
  room: {status: string, rooms:[]},
  device: {status: string, devices:{}, device:{ pins: Array<number>, id: number}, devices_alarm_settings: any },
  ws: { wsConnected: boolean },
  fetchPersonById: (options: { id: string }) => Promise<void>,
  updatePerson: (options: { person:{}}) => Promise<void>,
  fetchAllRooms: () => Promise<void>,
  fetchAllDevices: () => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
  updateDeviceAlarm: (options: {id: string, alarm_settings: { tst: number, time_out: string, time_in: string, sensitivity: string }}) => Promise<void>,
  wsUpdateAlarmSettings: (options: { device_serial: string, alarm_settings: any }) => Promise<void>,
  updateAndGetAlarmSettings: () => Promise<void>,
}


interface UsersEditState {
  devices: any,
  allRooms: Array<any>,
  rooms: Array<any>,
  userRooms: Array<any>,
  selectedRooms: Array<any>,
  id: string,
  role: string,
  email: string,
  password: string,
  passwordShow: boolean,
  name: string,
  alarm_pin: string,
  formSubmitted: boolean,
  noData: boolean,
}


export class UsersEdit extends React.Component<UsersEditProps, UsersEditState> {


  state = {
    devices: [],
    allRooms: [],
    rooms: [],
    userRooms: [],
    selectedRooms: [],
    id: '',
    role: '',
    email: '',
    password: '',
    passwordShow: false,
    name: '',
    alarm_pin: '',
    formSubmitted: false,
    noData: false,
  }


  componentDidMount = async () => {
    await this.fetchAllRooms()
    await this.fetchAllDevices()
    await this.fetchPersonById()
  }


  fetchPersonById = async () => {
    await this.props.fetchPersonById({ id: this.props.router.params.id })
    if(this.props.person.status === fetchStates.success) {
      if(this.props.person.person !== undefined) {
        const { person_id, role, email, name, alarm_pin, rooms } = this.props.person.person
        this.setState({
          id: person_id !== null ? person_id : '',
          role: role !== null ? role.toString() : '',
          email: email !== null ? email : '',
          name: name !== null ? name : '',
          alarm_pin: alarm_pin !== null ? alarm_pin : '',
          userRooms: rooms !== null ? [...rooms] : [],
          noData: false
        })
      }
    }
    if(this.props.person.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  fetchAllRooms = async () => {
    await this.props.fetchAllRooms()
    if(this.props.room.status === fetchStates.success) {
      let allRooms: any[] = []
      allRooms.push(...this.props.room.rooms)
      let rooms: any[] = []
      rooms.push(...this.props.room.rooms)
      let userRooms: any[] = []
      userRooms.push(...this.state.userRooms)
      const filteredRooms = rooms.filter((room:{room_id:string}) => !userRooms.includes(room.room_id))
      const selectedRooms = rooms.filter((room:{room_id:string}) => userRooms.includes(room.room_id))
      this.setState({ rooms: filteredRooms, selectedRooms, allRooms })
    }
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      const { devices } = this.props.device
      this.setState({ devices })
    }
  }


  handleSelectRoom = (id:string) => {
    const filteredRooms = this.state.rooms.filter((room:{room_id:string}) => {
      if(room.room_id === id) {
        let selectedRooms: any[] = []
        selectedRooms.push(...this.state.selectedRooms, room)
        this.setState({ selectedRooms })
      }
      return room.room_id !== id
    })
    this.setState({ rooms: filteredRooms })
  }


  handleRoomRemove = (id:string) => {
    const filteredRooms = this.state.selectedRooms.filter((room:{room_id:string}) => {
      if(room.room_id === id) {
        let rooms: any[] = []
        rooms.push(...this.state.rooms, room)
        this.setState({ rooms })
      }
      return room.room_id !== id
    })
    this.setState({ selectedRooms: filteredRooms })
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { id, role, email, password, name, alarm_pin, selectedRooms } = this.state;
    let rooms: any = this.state.allRooms.map((room:{room_id:string}) => room.room_id)
    if(role !== '1') {
      rooms = selectedRooms.map((room:{room_id:string}) => room.room_id)
    }
    let person = {}
    if(password === '') {  person = { id, role, email, name, alarm_pin, rooms }  }
    if(password !== '') {  person = { id, role, email, password, name, alarm_pin, rooms }  }
    await this.props.updatePerson({ person })
      if(this.props.person.status === fetchStates.success) {
        await this.props.updateAndGetAlarmSettings()
        if(this.props.device.status === fetchStates.success) {
          const { devices_alarm_settings } = this.props.device;
          // check at least one device exists
          if(devices_alarm_settings.length > 0) {
            // check we have wsConnection
            if(this.props.ws.wsConnected === true) {
              devices_alarm_settings.forEach((device: {device_serial: string, device_set_alarm: any, device_id: string}) => {
                // make sure we have MB category
                const prefixToCheck = device.device_serial.substring(0, 2).toUpperCase();
                if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
                  this.props.wsUpdateAlarmSettings({ device_serial: device.device_serial, alarm_settings: device.device_set_alarm })
                }
              })
            } else {
              danger('You are disconnected from the WS server')
            }
          }
        }
      }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { fields, status } = this.props.person;
    const { role, email, password, name, alarm_pin, passwordShow, rooms, selectedRooms, formSubmitted, noData } = this.state;
    const creatorRole = this.props.auth.person.role;

    if(noData) {
      return (
        <TemplatePage title="Users → Edit User" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">User does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Users → Edit User" buttonBack={true} navigate={this.props.router.navigate} button={ this.props.auth.person.person_id !== this.props.router.params.id ? true : false } buttonLink={`/users/delete/${this.props.router.params.id}`} buttonType="delete" buttonText="Delete User">
        <ContentMain>
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="role">
                Role (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconUsers color="#A19BCD" />
                </span>
                <select 
                  name="role" 
                  value={role}
                  onChange={e => this.setState({ role: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('role') })}
                >
                  {[1].includes(creatorRole) && <option value={2}>{numberToRole(2)}</option>}
                  {[1].includes(creatorRole) && <option value={1}>{numberToRole(1)}</option>}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">
                Email (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconEmail color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="email"
                  id="email" 
                  value={email}
                  autoComplete="email"
                  onChange={e => this.setState({ email: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('email') })}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="password">
                Password (required)
              </label>
              <div className="input-group">
                <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                  { passwordShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
                <span className="input-icon">
                  <IconLock color="#A19BCD" />
                </span>
                <input 
                  type={ passwordShow === false ? 'password' : 'text' } 
                  name="password"
                  id="password" 
                  value={ password }
                  autoComplete="current-password"
                  onChange={e => this.setState({ password: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('password') })}
                />
              </div>
              <PasswordStrengthMeter password={password} />
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Name
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconName color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="name"
                  id="name" 
                  value={ name }
                  autoComplete="username"
                  onChange={e => this.setState({ name: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('name') })}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="alarm_pin">
                Alarm PIN (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconPin color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ alarm_pin }
                  onChange={e => this.setState({ alarm_pin: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('alarm_pin') })}
                />
              </div>
            </div>

            { role === '2' && (
              <div className="form-group">
                <label htmlFor="rooms">
                  Rooms
                </label>
                <div className="selected-tags">
                  { selectedRooms && selectedRooms.map((room:{room_id: string, room_name: string}) => (
                    <span className="tag tag--small" key={room.room_id}>
                      <span className="tag__title">{room.room_name}</span>
                      <span className="tag__remove" onClick={e => this.handleRoomRemove(room.room_id)}>
                        <IconClose color="#ffffff"/>
                      </span>
                    </span>
                  ))}
                </div>
                { rooms && rooms.length > 0 && (
                  <div className="input-group">
                    <span className="input-icon">
                      <IconRoom color="#A19BCD" />
                    </span>
                    <select 
                      name="rooms" 
                      onChange={e => this.handleSelectRoom(e.target.value)}
                      className={classnames('', { 'input-error': fields && fields.includes('rooms') })}
                    >
                      <option key="0">Select room</option>
                      { 
                        rooms.map((room:{room_id:string, room_name:string}) => <option key={room.room_id} value={room.room_id}>{room.room_name}</option>)
                      }
                    </select>
                  </div>
                )}
              </div>
            )}

            <div className="content-box__footer">
              <input 
                type="submit" 
                value="Update User"
                className="btn btn--primary btn--left"
                disabled={ formSubmitted || status === 'fetching' ? true : false }
              />
            </div>
                
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, person, room, device, ws }: RootState) => ({ auth, person, room, device, ws }),
  { fetchPersonById, updatePerson, fetchAllRooms, fetchAllDevices, fetchDeviceById, updateDeviceAlarm, wsUpdateAlarmSettings, updateAndGetAlarmSettings }
)(UsersEdit)), requiresPermissions)