import { ROOM } from '../types'
import { API_ADDRESS } from '../config'
import { danger, success } from '../helpers/notifications'


const fetchRoom = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: ROOM.FETCH });
  return fetch(`${API_ADDRESS}/room/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: ROOM.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: string}) => {
              if(item.msg) {
                danger(item.msg)
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: string}) => {
              if(item.msg) {
                success(item.msg)
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: ROOM.FETCH_ERROR, errors: [{ msg: error }]
        }));
}



export const fetchAllRooms = () => fetchRoom({
  endpoint: '',
  options: { credentials: 'include' },
  SUCCESS_TYPE: ROOM.FETCH_ALL_SUCCESS
});


export const fetchMasterRoom = () => fetchRoom({
  endpoint: 'master',
  options: { credentials: 'include' },
  SUCCESS_TYPE: ROOM.FETCH_MASTER_SUCCESS
});


export const addNewRoom = (options: { room_order: number, room_name: string, room_image: number, account_id: string, assigned_devices: Array<string> }) => fetchRoom({
  endpoint: ``,
  options: {
    method: 'POST',
    body: JSON.stringify({ room_order: options.room_order, room_name: options.room_name, room_image: options.room_image, account_id: options.account_id, assigned_devices: options.assigned_devices }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ROOM.ADD_SUCCESS
});


export const fetchRoomById = (id: string) => fetchRoom({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ROOM.FETCH_ID_SUCCESS
});


export const removeRoomById = (options: { id: string, room_name: string }) => fetchRoom({
  endpoint: `${options.id}`,
  options: {
    method: 'DELETE',
    body: JSON.stringify({ room_name: options.room_name }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ROOM.REMOVE_ID_SUCCESS
});


export const updateRoomById = (options: { id: string, room_name: string, room_image: number, assigned_devices: Array<string> }) => fetchRoom({
  endpoint: `${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ room_name: options.room_name, room_image: options.room_image, assigned_devices: options.assigned_devices }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ROOM.UPDATE_ID_SUCCESS
});