import { WS } from '../types';
import { wsConnect, wsDisconnect } from '../actions/ws';
import { wsGotDataByDeviceSerial } from '../actions/device';
import {getNewAlarmsCount } from '../actions/data';
import { store } from '../store';
import { io } from 'socket.io-client';
import { WS_TYPE, WS_DOMAIN, WS_PORT } from '../config';

// socket variable
let socket: any;

socket = io(`${WS_TYPE}://${WS_DOMAIN}:${WS_PORT}`, { transports: ["websocket"] });

// socket ID
let SOCKET_ID: string;





if(socket) {
  // SOCKET WS CONNECTED
  socket.on("connect", () => {
    console.log('CONNECTED')
    store.dispatch(wsConnect());
    SOCKET_ID = socket.id;
    console.log('DEBUG: [REDUCER] connected to socket ID:', SOCKET_ID);
  });

  // SOCKET WS DISCONNECTED
  socket.on("disconnect", () => {
    console.log('DISCONNECTED')
    store.dispatch(wsDisconnect());
    console.log('DEBUG: [REDUCER] disconnected from socket ID:', SOCKET_ID); // undefined
  });


  // SOCKET ON RECEIVING DATA
  socket.on("data", (message: string) => { 
    const data = JSON.parse(message);
    console.log('DEBUG: [REDUCER] received following data:', data.data);
    // OK - process the data
    if(data.status === 'ok') {
      const deviceData = data.data;
      store.dispatch(wsGotDataByDeviceSerial(deviceData));
    }
    // ERROR - show message
    if(data.status === 'error') {
      console.error('[REDUCER ERROR]', data.msg);
    }
  })


  // SOCKET ON RECEIVING ALERT
  socket.on("alr", (message: string) => { 
    const data = JSON.parse(message);
    console.log('DEBUG: [REDUCER] received following alert:', data.data);
    // OK - process the data
    if(data.status === 'ok') {
      store.dispatch(getNewAlarmsCount());
    }
    // ERROR - show message
    if(data.status === 'error') {
      console.error('[REDUCER ERROR]', data.msg);
    }
  })
}



interface WsState {
  wsConnected: boolean
}


const DEFAULT_WS: WsState = {
  wsConnected: false
}


const ws = (state = DEFAULT_WS, action: any) => {
  switch(action.type) {
    case WS.RESET_CONNECTION:
      socket.disconnect();
      socket.connect();
      return {
        ...state
      }
    case WS.CONNECT:
      console.log('DEBUG: [REDUCER] WS.CONNECT');
      // connect to socket IO
      socket.connect()
      return { 
        ...state,
        wsConnected: true
      }
    case WS.DISCONNECT:
      console.log('DEBUG: [REDUCER] WS.DISCONNECT');
      // disconnect from socket IO
      socket.disconnect();
      return { 
        ...state,
        wsConnected: false
      }
    case WS.GET_DATA_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] getting device data for serial', action.device_serial);
      socket.emit("data", action.device_serial);
      return {
        ...state
      }
    case WS.DEVICE_UPDATE_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating firmware for device', action.device_serial);
      socket.emit("update", action.device_serial);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_ALARM_SETTINGS:
      console.log('DEBUG: [REDUCER] updating alarm settings', action.device_serial, action.alarm_settings);
      socket.emit("alarm", action.device_serial, action.alarm_settings)
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_NOTIFICATIONS_SETTINGS:
      console.log('DEBUG: [REDUCER] updating notifications settings', action.device_serial, action.notifications_settings);
      socket.emit("notifications", action.device_serial, action.notifications_settings)
      return {
        ...state
      }

    case WS.DEVICE_PRESS_BUTTON_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] pressing  button by device serial', action.device_serial, action.btn);
      socket.emit("btnPress", action.device_serial, action.btn );
      return {
        ...state
      }

    case WS.DEVICE_PRESS_DOU_ARRAY_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] pressing and updating DOU array', action.device_serial, action.dou_array);
      socket.emit("setDigO", action.device_serial, action.dou_array );
      return {
        ...state
      }
  
    case WS.DEVICE_UPDATE_ALARM_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device alarm', action.device_serial, action.alarm_settings);
      socket.emit("alarm", action.device_serial, action.alarm_settings);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_CLOCK_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device alarm clock', action.device_serial, action.clock_settings);
      socket.emit("clock", action.device_serial, action.clock_settings);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_GENERAL_SETTINGS_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device general settings', action.device_serial, action.general_settings);
      socket.emit("general", action.device_serial, action.general_settings);
      return {
        ...state
      }

    case WS.DEVICE_PRESS_IDENTIFY_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] pressing identity button (buzzer)', action.device_serial);
      socket.emit("identify",  action.device_serial);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_CORNER_BUTTONS_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device corner buttons', action.device_serial, action.corner_buttons);
      socket.emit("cornerbuttons",  action.device_serial, action.corner_buttons);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_STATUS_ICONS_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device status icons', action.device_serial, action.status_icons);
      socket.emit("statusicons",  action.device_serial, action.status_icons);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_THERMOSTAT_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device thermostat', action.device_serial, action.thermostat_settings);
      socket.emit("thermostat", action.device_serial, action.thermostat_settings);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_SCENARIO_SETTINGS_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device scenario settings', action.device_serial, action.scenario_settings);
      socket.emit("scenario", action.device_serial, action.scenario_settings);
      return {
        ...state
      }

    case WS.DEVICE_PRESS_RUN_SCENARIO_BY_SCENARIO_ID:
      console.log('DEBUG: [REDUCER] running scenario by scenario ID', action.device_serial, action.scenario_id);
      socket.emit("scenBtn", action.device_serial, action.scenario_id);
      return {
        ...state
      }

    case WS.DEVICE_UPDATE_DEVICE_LIST_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating device list', action.device_serial, action.devices);
      socket.emit("devices", action.device_serial, action.devices);
      return {
        ...state
      }

    case WS.DEVICE_GET_DEVELOPER_DEVICE_SETTINGS_BY_DEVICE_SERIAL:
      console.log('DEBUG: [REDUCER] updating dev device list', action.device_serial, action.dev_settings);
      socket.emit("developer", action.device_serial, action.dev_settings);
      return {
        ...state
      }

    case WS.DEVICE_GET_ENERGY_USAGE_FROM_DATA_PROVIDER:
      console.log('DEBUG: [REDUCER] requesting energy usage from DATA PROVIDER', action.device_serial);
      socket.emit("energyusage", action.device_serial);
      return {
        ...state
      }

    default:
      return state
  }
}


export default ws