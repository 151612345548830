import React from 'react'
import { Provider } from 'react-redux'
import initStore from './store'
import MagicBoxApp from './MagicBoxApp'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


const store = initStore()


class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ReactNotifications />
        <MagicBoxApp />
      </Provider>
    )
  }
}

export default App