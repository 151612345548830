import React from 'react'
import { connect } from 'react-redux'
import { fetchMagicSwitchAll, fetchMagicBoxAll } from '../../actions/device'
import { wsGetDataByDeviceSerial, wsPressIdentifyByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Pagination from '../partials/Pagination'
import AddItem from '../partials/AddItem'
import { IconDeviceMagicSwitch } from '../partials/IconsDevices'
import { Link } from 'react-router-dom' 
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { RootState } from '../../reducers'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import { roomsContainer, roomsItem } from '../../helpers/framer'
import { danger } from '../../helpers/notifications'
import TabNavDevices from '../partials/TabNavDevices'
import { IconEdit } from '../partials/IconsNavigation'
import { IconLocationRooms } from '../partials/Icons'
import { IconSpeaker } from '../partials/IconsDevices'


interface DevicesMagisSwitchProps {
  router: { location: string },
  device: { status: string, devicesMagicSwitch: Array<Object>,
  devicesMagicSwitchCount: number, devicesMagicBoxCount: number, devicesRegisterCount: number },
  ws: { wsConnected: boolean },
  fetchMagicBoxAll: (options: { limit: number , skip: number }) => Promise<void>,
  fetchMagicSwitchAll: (options: { limit: number , skip: number }) => Promise<void>,
  wsGetDataByDeviceSerial: (device_serial: string) => Promise<void>,
  wsPressIdentifyByDeviceSerial: (device_serial: string) => Promise<void>,
}


interface DevicesMagisSwitchState {
  currentPage: number,
  perPage: number,
  devicesMagicSwitch: Array<Object>,
  devicesMagicSwitchCount: number,
  devicesMagicBoxCount: number,
  wsConnected: boolean,
  error: string,
  buzzing: string,
}


export class DevicesMagisSwitch extends React.Component<DevicesMagisSwitchProps, DevicesMagisSwitchState> {


  state = {
    currentPage: 0,
    perPage: 10,
    devicesMagicSwitch: [],
    devicesMagicBoxCount: 0,
    devicesMagicSwitchCount: 0,
    wsConnected: false,
    error: '',
    buzzing: '',
  }


  componentDidMount() {
    this.getMagicSwitchDevices({ limit: this.state.perPage, skip: this.state.currentPage })
    this.getMagicBoxDevicesCount()
  }


  componentDidUpdate(prevProps:any) {
    if (this.props.device.devicesMagicSwitch !== prevProps.device.devicesMagicSwitch) {
      const { devicesMagicSwitch, devicesMagicBoxCount } = this.props.device
      this.setState({ devicesMagicSwitch, devicesMagicBoxCount })
    }
  }


  getMagicSwitchDevices = (opitons: { limit: number, skip: number }) => {
    this.props.fetchMagicSwitchAll({ limit: opitons.limit, skip: opitons.skip })
    .then(() => {
      if(this.props.device.status === fetchStates.success) {
        const { devicesMagicSwitch, devicesMagicSwitchCount } = this.props.device
        this.setState({ devicesMagicSwitch, devicesMagicSwitchCount }, () => this.dataRequest())
      }
    })
  }


  getMagicBoxDevicesCount = () => {
    this.props.fetchMagicBoxAll({ limit: 1000, skip: 0 })
    .then(() => {
      if(this.props.device.status === fetchStates.success) {
        const { devicesMagicBoxCount } = this.props.device
        this.setState({ devicesMagicBoxCount })
      }
    })
  }


  updatePagination = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(event.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(event.target.value), currentPage: 0  })
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
  }


  reloadPage = ({perPage, currentPage}: {perPage:number, currentPage: number}) => {
    this.getMagicSwitchDevices({ limit: perPage, skip: perPage * currentPage })
    this.getMagicBoxDevicesCount()
  }


  // WS: requesting data
  dataRequest = () => {
    if(this.props.ws.wsConnected) {
      this.state.devicesMagicSwitch.map((device: { device_serial: string }) => {
        this.props.wsGetDataByDeviceSerial(device.device_serial)
        return null
      })
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  // WS: pressing the button
  pressMagicBoxIdentify = (device_serial: string) => {
    this.setState({ buzzing: device_serial })
    setTimeout(() => this.setState({ buzzing: '' }), 5000);
    if(this.props.ws.wsConnected) {
      console.log('DEBUG: pressed identify (buzzer)', device_serial)
      this.props.wsPressIdentifyByDeviceSerial(device_serial)
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  render() {

    const { perPage, currentPage, devicesMagicSwitch, devicesMagicSwitchCount, devicesMagicBoxCount, buzzing } = this.state

    return (
      <TemplatePage title="Devices → Magic Switch" button={true} buttonLink="/devices/add" buttonType="add" buttonText="Add Device">
        <TabNavDevices location={this.props.router.location} magicboxDevices={devicesMagicBoxCount} magicswitchDevices={devicesMagicSwitchCount} />
        <ContentMain>
          {
            devicesMagicSwitch && devicesMagicSwitch.length > 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="devices"
              >
                { 
                  devicesMagicSwitch.map((item: {device_id: number, device_serial: string, id: number, device_type: string, device_title: string, device_registered: number, device_data: any, room_name: string, device_parent_serial: string, room_id: string}) => {
                    return (
                      <motion.div key={item.device_id} variants={roomsItem}>
                        <div className={classnames('device-card', {'device-card--active': item.device_data && Object.keys(item.device_data).length})}>
                          <div className="device-card__header">
                            <span className="device-card__title">{item.device_title}</span>
                            <span>
                              {
                                item.device_data && Object.keys(item.device_data).length > 0 ? (

                                  <>
                                    { buzzing === item.device_serial ? (
                                      <span className="device-card__header-button header-button--inactive">
                                        <IconSpeaker color="#828BA9" />
                                      </span>
                                    ) : (
                                      <span onClick={() => this.pressMagicBoxIdentify(item.device_serial)} className="device-card__header-button">
                                        <IconSpeaker color="#ffffff" />
                                      </span>               
                                    )}
                                  </>

                                ) : (
                                  <span className="device-card__header-button header-button--inactive">
                                    <IconSpeaker color="#ffffff" />
                                  </span>
                                )
                              }
                            </span>
                            <Link to={`/devices/settings/${item.device_id}`}>
                              <span className="device-card__header-button">
                                <IconEdit color="#ffffff" />
                              </span>
                            </Link>
                          </div>
                          <div className="device-card__body">
                            <div className="device-card__device-icon">
                              <IconDeviceMagicSwitch color={item.device_data && Object.keys(item.device_data).length ? '#000' : '#828BA9'} size={48} />
                            </div>
                            <div className="device-card__device-serial">
                              <span className="label-device-serial">{item.device_serial}</span>
                            </div>
                            {
                              item.device_data && item.device_data.fvV && (
                                <div className="device-card__device-firmware">FIRMWARE: {item.device_data.fvV}</div>
                              )
                            }
                            <div className="device-card__device-location">
                              <Link to={`/rooms/${item.room_id}`} className="label-location">
                                <IconLocationRooms color="#4D409E" size={24} />
                                {item.room_name}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    )
                  })
                }
                <motion.div key={10000} variants={roomsItem}>
                  <AddItem title="Add Device" link="/devices/add" type="magic-switch" />
                </motion.div>
              </motion.div>
            ) 
          }
          {
            devicesMagicSwitch && devicesMagicSwitch.length === 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="devices"
              >
                <motion.div key={10000} variants={roomsItem}>
                  <AddItem title="Add Device" link="/devices/add" />
                </motion.div>
              </motion.div>
            )
          }
          <Pagination 
            perPage={perPage}
            currentPage={currentPage} 
            itemsCount={devicesMagicSwitchCount} 
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
          />
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, ws }: RootState) => ({ device, ws }),
  { fetchMagicSwitchAll, fetchMagicBoxAll, wsGetDataByDeviceSerial, wsPressIdentifyByDeviceSerial }
)(DevicesMagisSwitch)))