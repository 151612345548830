import React from 'react'
import { connect } from 'react-redux'
import { fetchPersonById, updatePersonNotificationsSettings } from '../../actions/person'
import { fetchAllDevices, updateDeviceAlarm, updatePinAndGetAlarmSettings } from '../../actions/device'
import { wsUpdateAlarmSettings } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import ContentMain from '../partials/ContentMain'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { IconPin } from '../partials/IconsForm'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
import { danger, success } from '../../helpers/notifications'


interface SettingsSecurityProps {
  router: { location: string, navigate: (to: string) => any },
  auth: { status: string, person: { person_id: string, alarm_pin: string }},
  person: {status: string, fields: Array<string>, person: any},
  device: {status: string, notifications_settings: any, devices: any, device: any, devices_alarm_settings: any },
  ws: { wsConnected: boolean },
  fetchAllDevices: () => Promise<void>,
  updateDeviceAlarm: (options: {id: string, alarm_settings: { tst: number, time_out: string, time_in: string, sensitivity: string }}) => Promise<void>,
  wsUpdateAlarmSettings: (options: { device_serial: string, alarm_settings: any }) => Promise<void>,
  updatePinAndGetAlarmSettings: (alarm_pin:string) => Promise<void>,
}


interface SettingsSecurityState {
  person_id: string,
  alarm_pin: string,
  formSubmitted: boolean,
  devices: Array<any>,
}


export class SettingsSecurity extends React.Component<SettingsSecurityProps, SettingsSecurityState> {


  state = {
    person_id: '',
    alarm_pin: '',
    formSubmitted: false,
    devices: [],
  }


  async componentDidMount(): Promise<void> {
    const { person_id, alarm_pin } = this.props.auth.person
    this.setState({ person_id, alarm_pin: alarm_pin === null ? '' : alarm_pin })
    await this.fetchAllDevices();
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      const { devices } = this.props.device
      this.setState({ devices })
    }
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });

    
    this.props.updatePinAndGetAlarmSettings(this.state.alarm_pin)
    .then(() => {
      console.log(this.props.device)
      if(this.props.device.status === fetchStates.success) {
        const { devices_alarm_settings } = this.props.device;
        // check at least one device exists
        if(devices_alarm_settings.length > 0) {
          // check we have wsConnection
          if(this.props.ws.wsConnected === true) {
            devices_alarm_settings.forEach((device: {device_serial: string, device_set_alarm: any, device_id: string}) => {
              // make sure we have MB category
              const prefixToCheck = device.device_serial.substring(0, 2).toUpperCase();
              if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
                this.props.wsUpdateAlarmSettings({ device_serial: device.device_serial, alarm_settings: device.device_set_alarm })
              }
            });
            success('PIN was updated successfuly');
          } else {
            danger('You are disconnected from the WS server');
          }
        }
      }
    })
  
    this.setState({ formSubmitted: false });
  }


  render() {

    const { alarm_pin, formSubmitted } = this.state;
    const { fields, status } = this.props.person;


    return (
      <TemplatePage title="Settings → Security PIN" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="alarm_pin">
                Security Alarm PIN
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconPin color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="alarm_pin"
                  id="alarm_pin" 
                  value={ alarm_pin }
                  onChange={e => this.setState({ alarm_pin: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('alarm_pin') })}
                />
              </div>
            </div>
            <div className="form-group">
              <input 
                type="submit" 
                value="Update Security Alarm PIN"
                className="btn btn--primary btn--left"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person, auth, device, ws }: RootState) => ({ person, auth, device, ws }),
  { fetchPersonById, updatePersonNotificationsSettings, fetchAllDevices, updateDeviceAlarm, wsUpdateAlarmSettings, updatePinAndGetAlarmSettings }
)(SettingsSecurity)))