import React from 'react'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import { updatePersonEmailSettings } from '../../actions/person'
import { fetchAllDevices, fetchDeviceById } from '../../actions/device'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import { IconVisibility, IconVisibilityOff, IconLock, IconEmail } from '../partials/IconsForm'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'


interface SettingsEmailProps {
  router: { location: string, navigate: (to: string) => any },
  person: { status: string, fields: Array<string> },
  auth: { person: { person_id: string, current_email: string, email: string, email_change: string, notifications_email: number, settings: { theme: string } }, status: string },
  device: {status: string, devices: Array<any>, device: any},
  ws: { wsConnected: boolean },
  updatePersonEmailSettings: (options: { id: string, new_email: string, password_email: string }) => Promise<void>,
  checkAuth: () => Promise<void>,
  fetchAllDevices: () => Promise<void>,
  fetchDeviceById: (id: string) => Promise<void>,
}


interface SettingsEmailState {
  formSubmitted: boolean,
  current_email: string,
  email_change: string,
  notifications_email: number,
  new_email: string,
  password_email: string,
  passwordEmailShow: boolean,
  devices: Array<any>,
}


export class SettingsEmail extends React.Component<SettingsEmailProps, SettingsEmailState> {


  state = {
    formSubmitted: false,
    current_email: '',
    email_change: '',
    notifications_email: 0,
    new_email: '',
    password_email: '',
    passwordEmailShow: false,
    devices: [],
  }

  componentDidMount() {
    const { email, notifications_email, email_change } = this.props.auth.person
    this.setState({ current_email: email, notifications_email, email_change })
    this.fetchAllDevices()
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      const { devices } = this.props.device
      this.setState({ devices })
    }
  } 


  handleOnEmailUpdateSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    const { new_email, password_email } = this.state;
    this.props.updatePersonEmailSettings({ id: this.props.auth.person.person_id, new_email, password_email })
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        this.setState({ new_email: '', password_email: '' })
        this.props.checkAuth()
        .then(() => {
          if(this.props.auth.status === fetchStates.success) {
            const { email, email_change } = this.props.auth.person
            this.setState({ current_email: email, email_change })
          }
        });
      }
    })
    this.setState({ formSubmitted: false })
  }
  

  render() {

    const { status, fields } = this.props.person;
    const { passwordEmailShow, formSubmitted, current_email, new_email, password_email, email_change } = this.state;

    return (
      <TemplatePage title="Settings → Change Email" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <form className="form" onSubmit={this.handleOnEmailUpdateSubmit}>
            <div className="form-section">

              <div className="form-group">
                <label htmlFor="email">
                  Current Email
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconEmail color="#A19BCD" />
                  </span>
                  <input 
                    type="text" 
                    name="current_email"
                    id="current_email" 
                    value={ current_email }
                    disabled={true}
                  />
                </div>
              </div>

              {
                email_change !== null && (
                  <div className="form-group">
                  <label htmlFor="email">
                    Unconfirmed Email
                  </label>
                  <div className="input-group">
                    <span className="input-icon">
                      <IconEmail color="#A19BCD" />
                    </span>
                    <input 
                      type="text" 
                      name="email_change"
                      id="email_change" 
                      value={ email_change }
                      disabled={true}
                    />
                  </div>
                  <span className="form-explanation">You haven't confirmed this email yet.</span>
                </div>
                )
              }

              <div className="form-group">
                <label htmlFor="email">
                  New Email (required)
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconEmail color="#A19BCD" />
                  </span>
                  <input 
                    type="text" 
                    name="new_email"
                    id="new_email" 
                    value={ new_email }
                    onChange={e => this.setState({ new_email: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('new_email') })}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="password_email">
                  Current password (required)
                </label>
                <div className="input-group">
                  <span className="password-reveal" onClick={e => this.setState({ passwordEmailShow: !passwordEmailShow })}>
                    { passwordEmailShow === false ? (
                      <IconVisibility color="#000" password size={18} />
                    ) : (
                      <IconVisibilityOff color="#000" password size={18} />
                    )}
                  </span>
                  <span className="input-icon">
                    <IconLock color="#A19BCD" />
                  </span>
                  <input 
                    type={ passwordEmailShow === false ? 'password' : 'text' } 
                    name="password_email"
                    id="password_email" 
                    value={ password_email }
                    onChange={e => this.setState({ password_email: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('password_email') })}
                    autoComplete="current_password"
                  />
                </div>
              </div>
              <div className="form-group">
                <input 
                  type="submit" 
                  value="Change Email" 
                  className="btn btn--primary"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, person, device, ws }: RootState) => ({ auth, person, device, ws }),
  { updatePersonEmailSettings, fetchAllDevices, fetchDeviceById, checkAuth }
)(SettingsEmail)))