export const checkDeviceCapabilities = (prefix: string) => {
  switch(prefix) {

    // MagicBox Panel
    case 'XS':
      return { 
        device_category:'MB', 
        device_type: 'XS', 
        device_type_description: 'MagicPanel',
        device_capabilities: {
          "din":[],
          "dou":[
            {"id": "output1", "title":"Output_1", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
            {"id": "output2", "title":"Output_2", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true},
            {"id": "output2", "title":"Output_2", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true},
          ], 
          "text": true, 
          "temp": true,
          "hum" : true,
          "light": true,
          "beep": true,
          "doors": true
        }
      }

    // MagicBox Light
    case 'LI':
      return { 
        device_category:'MS', 
        device_type: 'LI', 
        device_type_description: 'MagicLight',
        device_capabilities: {
          "din":[
            {"id": "input1", "title":"Input_1", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
            {"id": "input2", "title":"Input_2", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true}
          ],
          "dou":[
            {"id": "output1", "title":"Output_1", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
            {"id": "output2", "title":"Output_2", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true}
          ],
          "beep": true
        } 
      }

    // MagicBox Meter
    case 'ME':
      return { 
        device_category:'MS', 
        device_type: 'ME', 
        device_type_description: 'MagicMeter',
        device_capabilities: {
          "din":[],
          "dou":[
            {"id": "output1", "title":"Output_1", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
            {"id": "output2", "title":"Output_2", "state_1": "on", "state_0": "off", "icon_0": 0, "icon_1": 1, "icon_selectable": true}
          ],
          "beep": true
        } 
      }



    /*
    // magic blind
    case 'BL':
      return { 
        device_category:'MS', 
        device_type: 'BL', 
        device_type_description: 'MagicBlinds',
        device_capabilities: {
          "din":[
            {"id": "input1", "title":"Input_1", "state_1": "up", "state_0": "down", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
            {"id": "input2", "title":"Input_2", "state_1": "up", "state_0": "down", "icon_0": 0, "icon_1": 1, "icon_selectable": true},
            {"id": "input3", "title":"Input_3", "state_1": "up", "state_0": "down", "icon_0": 0, "icon_1": 1, "icon_selectable": true}
          ],
          "dou":[
            {"id": "output1", "title":"Output_1", "state_1": "up", "state_0": "down", "icon_0": 6, "icon_1": 7, "icon_selectable": true}
          ], 
          "beep": true
        } 
      }

    // magic garage
    case 'GA':
      return {
        device_category:'MS', 
        device_type: 'GA', 
        device_type_description: 'MagicGarage',
        device_capabilities: {
          "din":[
            {"id": "input1", "title":"Input_1", "state_1": "up", "state_0": "down", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
          ],
          "dou":[
            {"id": "output1", "title":"Output_1", "state_1": "up", "state_0": "down", "icon_0": 4, "icon_1": 5, "icon_selectable": true}
          ], 
          "beep": true
        } 
      }

    // magic doors
    case 'DO':
      return { 
        device_category:'MS', 
        device_type: 'DO', 
        device_type_description: 'MagicDoors',
        device_capabilities: {
          "din":[
            {"id": "input1", "title":"Input_1", "state_1": "open", "state_0": "close", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
          ],
          "dou":[
            {"id": "output1", "title":"Output_1", "state_1": "open", "state_0": "close", "icon_0": 4, "icon_1": 5, "icon_selectable": true}
          ],
          "beep": true
        } 
      }

    // magic mailbox
    case 'MA':
      return { 
        device_category:'MS', 
        device_type: 'MA', 
        device_type_description: 'MagicMailbox',
        device_capabilities: {
          "din":[
            {"id": "input1", "title":"Input_1", "state_1": "full", "state_0": "empty", "icon_0": 0, "icon_1": 1, "icon_selectable": true}, 
          ],
          "dou":[
            {"id": "output1", "title":"Output_1", "state_1": "full", "state_0": "empty", "icon_0": 4, "icon_1": 5, "icon_selectable": true}
          ],
          "beep": true
        } 
      }

    */

    // unknown type
    default: 
      return { 
        device_category:'MB', 
        device_type: 'XX', 
        device_type_description: 'Unknown Type',
        device_capabilities: {
          "din":[],
          "dou":[]
        }
      }

  }
}