import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceIo } from '../../actions/device'
import { fetchAllScenarios } from '../../actions/scenario'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { IconName } from '../partials/IconsForm'
import Alert from '../partials/Alert'


interface DevicesEditProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  fetchDeviceById: (id: string) => Promise<void>,
  updateDeviceIo: (options: {id: string, device_capabilities: any}) => Promise<void>,
}


interface DevicesEditState {
  device: { room_id: string, device_serial: string, device_id: string, device_capabilities: any, device_title: string },
  formSubmitted: boolean,
  error: string,
  noData: boolean,
}


export class DevicesEdit extends React.Component<DevicesEditProps, DevicesEditState> {


  state = {
    device: {
      room_id: '',
      device_serial: '',
      device_id: '',
      device_title: '',
      device_capabilities: { din: [], dou: []}
    },
    formSubmitted: false,
    error: '',
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      let device_capabilities: any
      device_capabilities = { din: [], dou: [] }
      if(device.device_capabilities !== null) {
        device_capabilities = device.device_capabilities
      }
      this.setState({ device: { ...device, device_capabilities}, noData: false })
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  updateDeviceCapabilityTitle = (options: {type: string, id: string, title: string}) => {
    const { type, id, title } = options
    let device_capabilities = this.state.device.device_capabilities
    if(type === 'din') {
      device_capabilities.din.map((item: {id: string, title: string}) => item.id === id ? item.title = title : item)
    }
    if(type === 'dou') {
      device_capabilities.dou.map((item: {id: string, title: string}) => item.id === id ? item.title = title : item)
    }
    this.setState({ device: {...this.state.device, device_capabilities} })
  }


  showDeviceCapabilitesDin = () => {
    if(this.state.device.device_capabilities.din)
    return (
      <>
        {
          this.state.device.device_capabilities.din.map((din: any, index: number) => ( 
            <div key={din.id} className="form-group form-group-capabilities">
              <div>
                <label htmlFor={din.id}>
                  {din.id}
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconName color="#A19BCD" />
                  </span>
                  <input 
                    type="text" 
                    name={din.id}
                    id={din.id} 
                    value={ din.title }
                    onChange={e => this.updateDeviceCapabilityTitle({ type: 'din', id: din.id, title: e.target.value})}
                    className={classnames('', { 'input-error': this.props.device.fields && this.props.device.fields.includes(`device_capabilities.din[${index}].title`) })}
                  />
                </div>
              </div>
              {
                /*
                  <div>
                    <label>
                      {din.state_1}
                    </label>
                    { getCapabilityIcon(din.icon_1) }
                  </div>
                  <div>
                    <label>
                      {din.state_0}
                    </label>
                    { getCapabilityIcon(din.icon_0) }
                  </div>
                */
              }
            </div>
          ))
        }
      </>
    )
  }


  showDeviceCapabilitesDou = () => {
    if(this.state.device.device_capabilities.dou)
    return (
      <>
        {
          this.state.device.device_capabilities.dou.map((dou: any, index: number) => {
            return <div key={dou.id} className="form-group form-group-capabilities">
                    <div>
                      <label htmlFor={dou.id}>
                        {dou.id}
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconName color="#A19BCD" />
                        </span>
                        <input 
                          type="text" 
                          name={dou.id}
                          id={dou.id} 
                          value={ dou.title }
                          onChange={e => this.updateDeviceCapabilityTitle({ type: 'dou', id: dou.id, title: e.target.value })}
                          className={classnames('', { 'input-error': this.props.device.fields && this.props.device.fields.includes(`device_capabilities.dou[${index}].title`) })}
                        />
                      </div>
                    </div>
                    {
                      /*
                        <div>
                          <label>
                            {dou.state_1}
                          </label>
                          { getCapabilityIcon(dou.icon_1) }
                        </div>
                        <div>
                          <label>
                            {dou.state_0}
                          </label>
                          { getCapabilityIcon(dou.icon_0) }
                        </div>
                      */
                    }

                  </div>
          })
        }
      </>
    )
  }


  handleButtonSubmit = async () => {
    const { device } = this.state
    await this.props.updateDeviceIo({ id: device.device_id, device_capabilities: device.device_capabilities })
  }


  render() {

    const { device, formSubmitted, noData } = this.state

    if(noData) {
      return (
        <TemplatePage title="Device → I/O Naming" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → I/O Naming" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">
            { device.device_capabilities && this.showDeviceCapabilitesDin() }
            { device.device_capabilities && this.showDeviceCapabilitesDou() }
          </form>
          <div className="form__footer">
            <input 
              onClick={() => this.handleButtonSubmit()}
              type="submit" 
              value="Update Device Settings" 
              className="btn btn--primary btn--large"
              disabled={ formSubmitted ? true : false }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, scenario }: RootState) => ({ device, scenario }),
  { fetchDeviceById, updateDeviceIo, fetchAllScenarios }
)(DevicesEdit)))