import { DEVELOPER } from '../types';
import fetchStates from '../types/fetchStates';


interface DeveloperState {
  dev_settings: any
}

const DEFAULT_DEVELOPER: DeveloperState = {
  dev_settings: {}
}

const developer = (state = DEFAULT_DEVELOPER, action: any) => {
  switch(action.type) {
    case DEVELOPER.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case DEVELOPER.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: any) => item.path && item.path.toString())
      };
    case DEVELOPER.FETCH_UPDATE_DEV_SETTINGS_SUCCESS:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: any) => item.path && item.path.toString())
      };
    case DEVELOPER.FETCH_DEV_SETTINGS_SUCCESS:
      return { 
        ...state,
        status: fetchStates.success,
        dev_settings: action.dev_settings,
      };
    case DEVELOPER.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};

export default developer;