import React from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { VERSION } from '../../config'
import { IconNavigationRooms, IconNavigationScenarios, IconNavigationDevices, IconNavigationUsers, IconNavigationSettings, IconNavigationHelp, IconNavigationLogout, IconNavigationHistory } from './IconsNavigation'
import { connect } from 'react-redux'
import { checkAuth, logout } from '../../actions/auth'
import { wsDisconnect } from '../../actions/ws'
import { resetPerson } from '../../actions/person'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import AuthByPermissions from './AuthByPermissions'
import { getNewAlarmsCount } from '../../actions/data'


interface NavigationProps {
  router: { location: any },
  auth: {status: string, authenticated: boolean, person: { role: number }}
  data: any,
  alarmsNewCount: any,
  checkAuth: () => Promise<void>,
  resetPerson: () => any,
  logout: () => Promise<void>,
  wsDisconnect: () => Promise<void>,
  getNewAlarmsCount: () => Promise<void>,
}

interface NavigationState {
  alarmsNewCount: string
}


export class Navigation extends React.Component<NavigationProps, NavigationState> {

  state = {
    alarmsNewCount: '0'
  }


  componentDidMount() {
    this.reloadNewAlarmCount()
  }


  componentDidUpdate(prevProps:any) {
    if (this.props.data.alarmsNewCount !== prevProps.data.alarmsNewCount) {
      const { alarmsNewCount } = this.props.data
      this.setState({ alarmsNewCount })
    }
  }


  reloadNewAlarmCount = () => {
    this.props.getNewAlarmsCount()
    .then(() => {
      const { alarmsNewCount } = this.props.data
      this.setState({ alarmsNewCount })
    })
  }


  handleLogout = () => {
    this.props.resetPerson()
    this.props.wsDisconnect()
    this.props.logout()
  }


  render() {

    const { alarmsNewCount } = this.state
    const { location } = this.props.router

    return (
      <div className="navigation" onClick={() => this.reloadNewAlarmCount()}>
        <div className="navigation__menu">

          <div className="navigation__logo navigation__logo--vertical" />
          <div className="navigation__logo navigation__logo--horizontal" />


          <div className="navigation__main">
            <div className="navigation-item">
              <NavLink 
                to="/" 
                className={classnames('navigation-item__link', { 'navigation-item__link--current': location.pathname === '/' || location.pathname.substring(0,6) === '/rooms'})}
              >
                <span className="navigation-item__hightlight"></span>
                <span className="navigation-item__navigation-item">
                  <IconNavigationRooms size={1.5} color={location.pathname === '/' || location.pathname.substring(0,6) === '/rooms' ? '#000000' : '#828BA9'} /> 
                  <span className="navigation-item__title">Rooms</span>
                </span>
              </NavLink>
            </div>


            <div className="navigation-item">
              <NavLink 
                to="/history" 
                className={classnames('navigation-item__link', { 'navigation-item__link--current': location.pathname === '/history' || location.pathname.substring(0,8) === '/history'})}
              >
                <span className="navigation-item__hightlight"></span>
                <span className="navigation-item__navigation-item">
                  <IconNavigationHistory size={1.5} color={location.pathname === '/history' || location.pathname.substring(0,8) === '/history' ? '#000000' : '#828BA9'} /> 
                  <span className="navigation-item__title">History</span>
                </span>
                {
                  alarmsNewCount !== '0' && <span className="navigation-item__item-count navigation-item__item-count--alarm">
                  { alarmsNewCount }
                  </span>
                }
              </NavLink>
            </div>


            <div className="navigation-item">
              <NavLink 
                to="/scenarios" 
                className={classnames('navigation-item__link', {'navigation-item__link--current': location.pathname.substring(0,10) === '/scenarios'})}
              >
                <span className="navigation-item__hightlight"></span>
                <span className="navigation-item__navigation-item">
                <IconNavigationScenarios size={1.5} color={location.pathname.substring(0,10) === '/scenarios' ? '#000000' : '#828BA9'} /> 
                <span className="navigation-item__title">Scenarios</span>
                </span>
              </NavLink>
            </div>


            <div className="navigation-item">
              <NavLink 
                to="/devices/magicbox" 
                className={classnames('navigation-item__link', {'navigation-item__link--current': location.pathname.substring(0,8) === '/devices'})}
              >
                <span className="navigation-item__hightlight"></span>
                <span className="navigation-item__navigation-item">
                <IconNavigationDevices size={1.5} color={location.pathname.substring(0,8) === '/devices' ? '#000000' : '#828BA9'} /> 
                <span className="navigation-item__title">Devices</span>
                </span>
              </NavLink>
            </div>


            <AuthByPermissions requiresPermissions='manage_users'>
              <div className="navigation-item">
                <NavLink 
                  to="/users" 
                  className={classnames('navigation-item__link', {'navigation-item__link--current': location.pathname.substring(0,6) === '/users'})}
                >
                  <span className="navigation-item__hightlight"></span>
                  <span className="navigation-item__navigation-item">
                  <IconNavigationUsers size={1.5} color={location.pathname.substring(0,6) === '/users' ? '#000000' : '#828BA9'} /> 
                  <span className="navigation-item__title">Users</span>
                  </span>
                </NavLink>
              </div>
            </AuthByPermissions>

          </div>

          <div className="navigation__tools">

            <div className="navigation-item">
              <NavLink 
                to="/settings" 
                className={classnames('navigation-item__link', {'navigation-item__link--current': location.pathname.substring(0,9) === '/settings'})}
              >
                <span className="navigation-item__hightlight"></span>
                <span className="navigation-item__navigation-item">
                  <IconNavigationSettings size={1.5} color={location.pathname.substring(0,9) === '/settings' ? '#000000' : '#828BA9'} /> 
                  <span className="navigation-item__title">Settings</span>
                </span>
              </NavLink>
            </div>

            <div className="navigation-item">
              <a href="https://help.magicbox.com.pl" className="navigation-item__link">
                <span className="navigation-item__hightlight"></span>
                <span className="navigation-item__navigation-item">
                  <IconNavigationHelp size={1.5} color="#828BA9" /> 
                  <span className="navigation-item__title">Help</span>
                </span>
              </a>
            </div>

            <div className="navigation-item" onClick={() => this.handleLogout()}>
              <NavLink to="/" className="navigation-item__link">
                <span className="navigation-item__hightlight"></span>
                <span className="navigation-item__navigation-item">
                  <IconNavigationLogout size={1.5} color="#828BA9" /> 
                  <span className="navigation-item__title">Logout</span>
                </span>
              </NavLink>
            </div>

          </div>

          <div className="navigation__version">v{VERSION} - {process.env.REACT_APP_WS_DOMAIN}</div>
          
        </div>
      </div>
    )
  }

}


export default withRouter(connect(
  ({ auth, data }: RootState) => ({ auth, data }),
  { checkAuth, logout, resetPerson, wsDisconnect, getNewAlarmsCount }
)(Navigation))