import React from 'react'
import { connect } from 'react-redux'
import { wsUpdateDevicesList } from '../../actions/ws'
import { addNewDevice, fetchSlaveDevices, fetchMasterDevice } from '../../actions/device'
import { fetchRoomById, fetchAllRooms } from '../../actions/room'
import fetchStates from '../../types/fetchStates'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { IconName, IconDevice, IconRoom } from '../partials/IconsForm'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import { knownDeviceTypesMagicBox, knownDeviceTypesMagicSwitch } from '../../helpers/deviceTypeList'
import { Link } from 'react-router-dom'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
import InfoBox from '../partials/InfoBox'


interface DevicesAddProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: {status: string, fields: Array<string>, devicesSlave: any, deviceMaster: any },
  room: {status: string, rooms: { room_id: string }[], emptyRooms: { room_id: string }[]},
  fetchAllRooms: () => Promise<void>,
  addNewDevice: (options: {device_serial: string, device_title: string, device_capabilities: any, room_id: string}) => Promise<void>,
  wsUpdateDevicesList: (options: {device_serial: string, devices: any }) => Promise<void>,
  fetchSlaveDevices: () => Promise<void>,
  fetchMasterDevice: () => Promise<any>,
}


interface DevicesAddState {
  device_serial: string,
  device_type: string,
  device_title: string,
  device_capabilities: {din: any, dou: any},
  room_id: string,
  rooms: { room_id: string }[],
  formSubmitted: boolean,
}


export class DevicesAdd extends React.Component<DevicesAddProps, DevicesAddState> {


  state = {
    device_serial: '',
    device_type: '',
    device_title: '',
    device_capabilities: { din: [], dou: [] },
    room_id: '',
    rooms: [],
    formSubmitted: false
  }


  componentDidMount() {
    this.fetchAllRooms()
  }


  fetchAllRooms = async () => {
    await this.props.fetchAllRooms()
    if(this.props.room.status === fetchStates.success) {
      const { rooms } = this.props.room
      this.setState({ rooms, room_id: rooms.length > 0 ? rooms[0].room_id : '' })
    }
    const { id } = this.props.router.params
    if(id) {
      this.setState({ room_id: id })
    }
  }


  handleUnitSerialChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.toUpperCase()
    let device: any
    device = checkDeviceCapabilities(value.substring(0, 2))
    let device_capabilities = { din: [], dou: [] }
    if(value && value.length === 14) {
      device_capabilities = device.device_capabilities
    }
    const { rooms } = this.props.room
    if(device.device_category === 'MB' && value.length === 14) {
      const filteredEmptyRooms = rooms.filter((room:any) => room.devices_count === '0')
      this.setState({ rooms: filteredEmptyRooms })
    } else {
      this.setState({ rooms })
    }
    this.setState({ device_serial: value, device_type: device.device_type, device_capabilities })
  }


  updateDeviceCapabilityTitle = (options: {type: string, id: string, title: string}) => {
    const { type, id, title } = options
    let device_capabilities = this.state.device_capabilities
    if(type === 'din') {
      device_capabilities.din.map((item: {id: string, title: string}) => item.id === id ? item.title = title : item)
    }
    if(type === 'dou') {
      device_capabilities.dou.map((item: {id: string, title: string}) => item.id === id ? item.title = title : item)
    }
    this.setState({ device_capabilities })
  }


  showDeviceCapabilitesDin = () => {
    if(this.state.device_capabilities.din)
    return (
      <>
        {
          this.state.device_capabilities.din.map((din: any, index) => {
            return <div key={din.id} className="form-group form-group-capabilities">
                    <div>
                      <label htmlFor={din.id}>
                        {din.id}
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconName color="#A19BCD" />
                        </span>
                        <input 
                          maxLength={20}
                          type="text" 
                          name={din.id}
                          id={din.id} 
                          value={ din.title }
                          onChange={e => this.updateDeviceCapabilityTitle({ type: 'din', id: din.id, title: e.target.value})}
                          className={classnames('', { 'input-error': this.props.device.fields && this.props.device.fields.includes(`device_capabilities.din[${index}].title`) })}
                        />
                      </div>
                    </div>
                  </div>
          })
        }
      </>
    )
  }


  showDeviceCapabilitesDou = () => {
    if(this.state.device_capabilities.dou)
    return (
      <>
        {
          this.state.device_capabilities.dou.map((dou: any, index: number) => {
            return <div key={dou.id} className="form-group form-group-capabilities">
                    <div>
                      <label htmlFor={dou.id}>
                        {dou.id}
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconName color="#A19BCD" />
                        </span>
                        <input 
                          maxLength={20}
                          type="text" 
                          name={dou.id}
                          id={dou.id} 
                          value={ dou.title }
                          onChange={e => this.updateDeviceCapabilityTitle({ type: 'dou', id: dou.id, title: e.target.value })}
                          className={classnames('', { 'input-error': this.props.device.fields && this.props.device.fields.includes(`device_capabilities.dou[${index}].title`) })}
                        />
                      </div>
                    </div>
                  </div>
          })
        }
      </>
    )
  }


  handleSubmitAddDevice = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    const { device_serial, device_title, device_capabilities, room_id } = this.state
    await this.props.addNewDevice({ device_serial, device_title, device_capabilities, room_id })
    if(this.props.device.status === fetchStates.success) {
      // update device slave list on the master device
      await this.props.fetchSlaveDevices();
      await this.props.fetchMasterDevice();
      const master_device = this.props.device.deviceMaster;
      const devices = this.props.device.devicesSlave.map((device: {device_serial: string}) => device.device_serial);
      await this.props.wsUpdateDevicesList({ device_serial: master_device.device_serial, devices: { tst: Date.now(), devices } });
      // reset form and redirect
      this.setState({ device_serial: '', formSubmitted: false });
      const device_category: any = checkDeviceCapabilities(device_serial.substring(0, 2)).device_category
      if(device_category === 'MB') {
        this.props.router.navigate('/devices/magicbox');
      } else {
        this.props.router.navigate('/devices/magicswitch');
      }
    }
  }


  render() {

    const { device_serial, device_title, device_capabilities, rooms, room_id, formSubmitted } = this.state;
    const { fields, status } = this.props.device;

    return (
      <TemplatePage title="Devices → Add Device" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
            { rooms && rooms.length === 0 ? (

              <InfoBox>
                <p>You can register only one MagicBox Panel per room. In order to add any device you must have at least one room available.</p>
                <Link to="/rooms/add" className="btn btn--primary">Add Room Now</Link>
              </InfoBox>

            ) : (

              <form className="form" onSubmit={this.handleSubmitAddDevice}>
                <div className="form-section">

                  <div className="form-group">
                    <label htmlFor="device_serial">
                      Device serial number (required)
                    </label>
                    <div className="input-group">
                      <span className="input-icon">
                        <IconDevice color="#A19BCD" />
                      </span>
                      <input 
                        maxLength={14}
                        type="text" 
                        name="device_serial"
                        id="device_serial" 
                        value={ device_serial }
                        onChange={e => this.handleUnitSerialChange(e)}
                        className={classnames('', { 'input-error': fields && fields.includes('device_serial') })}
                      />
                    </div>
                    <span className="form-explanation">Existing device types: {knownDeviceTypesMagicBox.join(", ")}, {knownDeviceTypesMagicSwitch.join(", ")}</span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="device_title">
                      Title (required)
                    </label>
                    <div className="input-group">
                      <span className="input-icon">
                        <IconName color="#A19BCD" />
                      </span>
                      <input 
                        maxLength={25}
                        disabled={device_serial && device_serial.length === 14 ? false : true}
                        type="text" 
                        name="device_title"
                        id="device_title" 
                        value={ device_title }
                        onChange={e => this.setState({ device_title: e.target.value })}
                        className={classnames('', { 'input-error': fields && fields.includes('device_title') })}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="rooms">
                      Rooms (required)
                    </label>
                    <div className="input-group">
                      <span className="input-icon">
                        <IconRoom color="#A19BCD" />
                      </span>
                      <select 
                        disabled={device_serial && device_serial.length === 14 ? false : true}
                        name="rooms" 
                        onChange={e => this.setState({ room_id: e.target.value })}
                        className={classnames('', { 'input-error': fields && fields.includes('rooms') })}
                        value={room_id}
                      >
                        { 
                          rooms && rooms.map((room: {room_id: string, room_name: string}) => <option key={room.room_id} value={room.room_id} >{room.room_name}</option>)
                        }
                      </select>
                    </div>
                  </div>

                  { device_capabilities && this.showDeviceCapabilitesDin() }

                  { device_capabilities && this.showDeviceCapabilitesDou() }

                  <div className="form-group">
                    <input 
                      type="submit" 
                      value="Add Device" 
                      className="btn btn--primary btn--large"
                      disabled={ formSubmitted && status === 'fetching' ? true : false }
                    />
                  </div>

                </div>
              </form>

            )}
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, room }: RootState) => ({ device, room }),
  { fetchRoomById, addNewDevice, fetchAllRooms, wsUpdateDevicesList, fetchSlaveDevices, fetchMasterDevice }
)(DevicesAdd)))