import { NavLink } from 'react-router-dom'


interface TabNavDevicesProps {
  location: any,
  magicboxDevices: number,
  magicswitchDevices: number,
}

const TabNavDevices: React.FC<TabNavDevicesProps> = ({ location, magicboxDevices, magicswitchDevices }) => (
    <nav className="tabnav">
      <NavLink 
        to={{ pathname: `/devices/magicbox` }} 
        className={location.pathname === '/devices/magicbox' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        MagicBox
        <span className="tabnav-tab__devices-count">{magicboxDevices}</span>
      </NavLink>
      <NavLink 
        to={{ pathname: `/devices/magicswitch` }} 
        className={location.pathname === '/devices/magicswitch' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        MagicSwitch
        <span className="tabnav-tab__devices-count">{magicswitchDevices}</span>
      </NavLink>
    </nav>
)


export default TabNavDevices