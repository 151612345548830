import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceClock } from '../../actions/device'
import { wsUpdateClockByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import moment from 'moment'
import { motion } from 'framer-motion'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { devicePresets } from '../../helpers/devicePresets'
import { IconClose } from '../partials/Icons'
import SwitchTrueFalseHorizontal from '../partials/SwitchTrueFalseHorizontal'
import classnames from 'classnames'
import { IconName, IconTime } from '../partials/IconsForm'
import { IconAdd } from '../partials/IconsNavigation'
import { danger } from '../../helpers/notifications'
import Alert from '../partials/Alert'


interface DeviceSettingsClockProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  ws: { wsConnected: boolean },
  fetchDeviceById: (id: string) => Promise<void>,
  updateDeviceClock: (options: {id: string, clock_settings: {}}) => Promise<void>,
  handleRemoveClockItem: (id: string) => Promise<void>,
  handleSelectDay: (day: any) => Promise<void>,
  updateSelectDay: (day: any) => Promise<void>,
  handleButtonSubmit: () => Promise<void>,
  wsUpdateClockByDeviceSerial: (options: { device_serial: string, clock_settings: any }) => Promise<void>,
}


interface DeviceSettingsClockState {
  device: { room_id: string, device_serial: string, device_id: string, device_title: string }
  clock: any,
  formSubmitted: boolean,
  wsConnected: boolean,
  days: any,
  noData: boolean,
}


export class DeviceSettingsClock extends React.Component<DeviceSettingsClockProps, DeviceSettingsClockState> {


  state = {
    device: {
      room_id: '',
      device_serial: '',
      device_id: '',
      device_title: ''
    },
    wsConnected: false,
    formSubmitted: false,
    days_option: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    clock: [],
    days: [],
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      if(device.device_set_clock !== null ) {
        this.setState({ device, clock: device.device_set_clock.clock })
      } else {
        this.setState({ device, clock: devicePresets.device_set_clock.clock })
      }
      this.setState({ noData: false })
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  handleRemoveClockItem = (id: number) => {
    const clock = this.state.clock.filter((item, index) => index !== id)
    this.setState({ clock })
  }


  handleAddClockItem = (e:any) => {
    e.preventDefault()
    if(this.state.clock.length < 9) {
      let clock: any[] = [];
      clock = this.state.clock 
      const day_index: any = moment().format('e')
      clock.unshift({ title: '', enabled: 1, time: moment().format('HH:mm'), days: [day_index - 1]})
      this.setState({ clock })
    }
  }


  handleSelectDay = (day: string) => {
    let days: any[] = [];
    days = this.state.days
    if(days.includes(day)) {
      const index = days.indexOf(day);
      if (index > -1) {
        days.splice(index, 1);
      }
      this.setState({ days })
    } else {
      days.push(day)
      this.setState({ days })
    }
  }


  updateClockTitle = (index: number, title: string) => {
    let clock: any[] = [];
    clock = this.state.clock
    clock[index].title = title
    this.setState({ clock })
  }


  updateClockEnabled = (index: number, enabled: number) => {
    let clock: any[] = [];
    clock = this.state.clock
    clock[index].enabled = enabled
    this.setState({ clock })
  }


  updateClockTime = (index: number, time: string) => {
    let clock: any[] = [];
    clock = this.state.clock
    clock[index].time = time
    this.setState({ clock })
  }


  updateSelectDay = (index: number, day: number) => {
    let clock: any[] = [];
    clock = this.state.clock
    if(clock[index].days.includes(day.toString())) {
      const i = clock[index].days.indexOf(day.toString());
      if (i > -1) {
        clock[index].days.splice(i, 1);
      }
    } else {
      clock[index].days.push(day.toString())
    }
    this.setState({ clock })
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true })
    if(this.props.ws.wsConnected) {
      const { clock } = this.state
      const clock_settings = {
        tst: moment().valueOf(),
        clock
      }
      await this.props.updateDeviceClock({ id: this.props.router.params.id, clock_settings })
      console.log('[DEBUG]: - emitting following data:', this.state.device.device_serial, clock_settings)
      // WS
      await this.props.wsUpdateClockByDeviceSerial({ device_serial: this.state.device.device_serial, clock_settings })
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { device, clock, days_option, formSubmitted, noData } = this.state;
    const { fields } = this.props.device;

    if(noData) {
      return (
        <TemplatePage title="Device → Alarm Clock" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title={`Device → Alarm Clock ${clock.length}/10`} buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">

          { clock && clock.map((item: {title: string, enabled: string, time: string, days: Array<string>}, index) => {
            return <div className="clock-item" key={index}>
              <div className="clock-item__header">
                <span className="clock-item__header-title">
                  <h3>{item.title.toUpperCase()}</h3>
                </span>
                <span className="clock-item__header-settings">
                  <span className="btn-pure" onClick={() => this.handleRemoveClockItem(index)}>
                    <IconClose color="#593995" />
                  </span>
                </span>
              </div>
              <div className="clock-item__body">
              <div className="form-group">
                <label htmlFor="title">
                  Title
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconName color="#A19BCD" />
                  </span>
                  <input 
                    type="text" 
                    name="title"
                    id="title" 
                    value={item.title}
                    onChange={e => this.updateClockTitle(index, e.target.value)}
                    className={classnames('', { 'input-error': fields && fields.includes(`clock_settings.clock[${index}].title`) })}
                  />
                </div>
              </div>


              <div className="form-group">
                <label htmlFor="enabled" onClick={e => this.updateClockEnabled(index, parseInt(item.enabled) === 0 ? 1 : 0)}>
                  <div className="label-title">
                    Enabled
                  </div>
                  <SwitchTrueFalseHorizontal switchState={parseInt(item.enabled) === 0 ? false : true} />
                </label>
              </div>


              <div className="form-group">
                <label htmlFor="time">
                  Time
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconTime color="#A19BCD" />
                  </span>
                  <input 
                    type="time" 
                    name="time"
                    id="time" 
                    value={item.time}
                    onChange={e => this.updateClockTime(index, e.target.value)}
                    className={classnames('', { 'input-error': fields && fields.includes(`clock_settings.clock[${index}].time`) })}
                  />
                </div>
              </div>


              { 
                <div className="form-group">
                  <label htmlFor="device_title">
                    Select day
                  </label>
                  <div className="day-selection-wrapper">
                  <div className="day-selection">
                    {
                      days_option.map((day, i) => {
                        return <motion.div 
                          key={i} 
                          className={classnames('day-selection__day', { 'day-selection__day--selected': item.days.includes(i.toString()) })} 
                          onClick={() => this.updateSelectDay(index, i)}
                          whileHover={{ scale: 1.08 }}
                          transition={{ duration: 0.05 }}
                        >
                          {day}
                        </motion.div>
                      })
                    }
                  </div>
                  </div>
                </div>
              }
            </div>
            </div>
          })}
          <div className="form__footer">
            <button 
              className="btn-square"
              onClick={ e => this.handleAddClockItem(e) }
            >
              <IconAdd color="#ffffff"/>
            </button>
          </div>
        </form>
        <div className="form__footer">
          <input 
            onClick={() => this.handleButtonSubmit()}
            type="submit" 
            value="Update Device Settings" 
            className="btn btn--primary btn--large"
            disabled={ formSubmitted ? true : false }
          />
        </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, ws }: RootState) => ({ device, ws }),
  { fetchDeviceById, updateDeviceClock, wsUpdateClockByDeviceSerial }
)(DeviceSettingsClock)))