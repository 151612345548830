import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceThermostat } from '../../actions/device'
import { wsUpdateThermostatSettingsByDeviceSerial } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Card from '../partials/Card'
import { RootState } from '../../reducers'
import { IconTime } from '../partials/IconsForm'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import moment from 'moment'
import classnames from 'classnames'
import { danger } from '../../helpers/notifications'
import Alert from '../partials/Alert'


interface DevicesEditProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  ws: { wsConnected: boolean }, 
  fetchDeviceById: (id: string) => Promise<void>,
  handleButtonSubmit: () => Promise<void>,
  updateDeviceThermostat: (options: {id: string, thermostat_settings: { tst: number, day: string, d_start: string, night: string, n_start: string, leave: string }}) => Promise<void>,
  wsUpdateThermostatSettingsByDeviceSerial: (options: { device_serial: string, thermostat_settings: any }) => Promise<void>,
}


interface DevicesEditState {
  device: { room_id: string, device_id: string, device_serial: string, device_title: string, device_set_thermostat: { day: string, d_start: string, night: string, n_start: string, leave: string } },
  formSubmitted: boolean,
  day: string,
  d_start: string,
  night: string,
  n_start: string,
  leave: string,
  noData: boolean,
}


export class DevicesEdit extends React.Component<DevicesEditProps, DevicesEditState> {


  state = {
    device: { room_id: '', device_id: '', device_serial: '', device_title: '', device_set_thermostat: { day: '21.5', d_start: '7:00', night: '17', n_start: '19:00', leave: '16' }},
    day: '21.5',
    d_start: '07:00',
    night: '17',
    n_start: '19:00',
    leave: '16',
    formSubmitted: false,
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      if(device.device_set_thermostat !== null) {
        const { day, d_start, night, n_start, leave } = device.device_set_thermostat
        this.setState({ 
          device,
          day,
          d_start,
          night,
          n_start,
          leave,
          noData: false
        })
      } else {
        this.setState({ device })
      }
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true })
    if(this.props.ws.wsConnected) {
      const { day, d_start, night, n_start, leave } = this.state
      const thermostat_settings = {
        tst: moment().valueOf(),
        day,
        d_start,
        night,
        n_start,
        leave
      }
      await this.props.updateDeviceThermostat({ id: this.props.router.params.id, thermostat_settings })
      console.log('[DEBUG]: - emitting following data:', this.props.device.device.device_serial, thermostat_settings)
      await this.props.wsUpdateThermostatSettingsByDeviceSerial({ device_serial: this.props.device.device.device_serial, thermostat_settings })      
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { device, day, d_start, night, n_start, leave, formSubmitted, noData } = this.state
    const { fields } = this.props.device

    if(noData) {
      return (
        <TemplatePage title="Device → Thermostat" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Thermostat" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <form className="form">
            <Card title="Day Settings">
              <div className="form-group">
                <label htmlFor="d_start">
                  Day mode starts
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconTime color="#A19BCD" />
                  </span>
                  <input 
                    type="time" 
                    name="d_start"
                    id="d_start" 
                    value={d_start}
                    onChange={e => this.setState({ d_start: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes(`d_start`) })}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="day">
                  Day temperature ({day}&deg;C)
                </label>
                <div className="slidecontainer">
                  <input 
                    name="day" 
                    id="day" 
                    type="range" 
                    min="1" 
                    max="40" 
                    step="0.5"
                    value={day} 
                    className="slider" 
                    onChange={e => this.setState({ day: e.target.value })} 
                  />
                </div>
              </div>
            </Card>


            <Card title="Night Settings">
              <div className="form-group">
                <label htmlFor="n_start">
                  Night mode starts
                </label>
                <div className="input-group">
                  <span className="input-icon">
                    <IconTime color="#A19BCD" />
                  </span>
                  <input 
                    type="time" 
                    name="n_start"
                    id="n_start" 
                    value={n_start}
                    onChange={e => this.setState({ n_start: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes(`n_start`) })}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="night">
                  Night temperature ({night}&deg;C)
                </label>
                <div className="slidecontainer">
                  <input 
                    name="night" 
                    id="night" 
                    type="range" 
                    min="1" 
                    max="40" 
                    step="0.5"
                    value={night} 
                    className="slider" 
                    onChange={e => this.setState({ night: e.target.value })} 
                  />
                </div>
              </div>
            </Card>


            <Card title="Leaving the House">
              <div className="form-group">
                <label htmlFor="leave">
                  Empty house temperature ({leave}&deg;C)
                </label>
                <div className="slidecontainer">
                  <input 
                    name="leave" 
                    id="leave" 
                    type="range" 
                    min="1" 
                    max="40" 
                    step="0.5"
                    value={leave} 
                    className="slider" 
                    onChange={e => this.setState({ leave: e.target.value })} 
                  />
                </div>
              </div>
            </Card>

          </form>
          <div className="form__footer">
            <input 
              onClick={() => this.handleButtonSubmit()}
              type="submit" 
              value="Update Device Settings" 
              className="btn btn--primary btn--large"
              disabled={ formSubmitted ? true : false }
            />
          </div>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, device, ws }: RootState) => ({ auth, device, ws }),
  { fetchDeviceById, updateDeviceThermostat, wsUpdateThermostatSettingsByDeviceSerial }
)(DevicesEdit)))