import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { login } from '../../actions/auth'
import { IconVisibility, IconVisibilityOff, IconEmail, IconLock } from '../partials/IconsForm'
import classnames from 'classnames'
import { VERSION } from '../../config'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { container, item } from '../../helpers/framer'
import { RootState } from '../../reducers'
// import KeepMeSigned from '../partials/KeepMeSigned'
import OAuth from '../partials/GoogleSignin'



interface  AuthLoginProps {
  auth: { status: string, fields: Array<string>, authenticated: boolean },
  login: (options: { email: string, password: string, keepMeSigned: boolean }) => Promise<void>,
}


interface  AuthLoginState {
  formSubmitted: boolean,
  password: string,
  email: string,
  passwordShow: boolean,
  keepMeSigned: boolean,
}


export class  AuthLogin extends React.Component< AuthLoginProps,  AuthLoginState> {


  state = {
    formSubmitted: false,
    password: '',
    email: '',
    passwordShow: false,
    keepMeSigned: true,
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { email, password, keepMeSigned } = this.state;
    this.props.login({ email, password, keepMeSigned });
  }


  handleKeepMeSigned = () => {
    this.setState({ keepMeSigned: !this.state.keepMeSigned })
  }


  render() {

    const { formSubmitted, password, email, passwordShow } = this.state;
    const { authenticated, fields, status } = this.props.auth;
    
    return authenticated ? <Navigate to="/" /> : (

      <div className="template template-auth">
        <div className="page-content">

          <motion.div className="auth-container" variants={container}
            initial="hidden"
            animate="visible">
            <div className="auth-logo" />
              <div className="auth-box">
                <div className="auth-box__wrapper">
                  { fields && fields.includes('resend_confirmation') && <Navigate to="/resend-confirmation" /> }

                  <motion.div className="auth-form__header" key={1} variants={item}>
                    <h1 className="auth-box__title">Login</h1>
                  </motion.div>

                  <form className="auth-form" method="post" action="" onSubmit={this.handleOnSubmit}>

                    <motion.div className="form-group" key={2} variants={item}>
                      <label htmlFor="email">Email</label>
                      <div className="input-group">
                        <span className="input-icon input-icon--email">
                          <IconEmail color="#A19BCD" />
                        </span>
                        <input
                          id="email"
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          value={email}
                          onChange={e => this.setState({ email: e.target.value })}
                          className={classnames('', { 'input-error': fields && fields.includes('email') })}
                        />
                      </div>
                    </motion.div>

                    <motion.div className="form-group" key={3} variants={item}>
                      <label htmlFor="password">
                        Password
                      </label>
                      <div className="input-group">
                        <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                          { passwordShow === false ? (
                            <IconVisibility password size={18} />
                          ) : (
                            <IconVisibilityOff password size={18} />
                          )}
                        </span>
                        <span className="input-icon">
                          <IconLock color="#A19BCD" />
                        </span>
                        <input
                          id="password"
                          type={ passwordShow === false ? 'password' : 'text' } 
                          placeholder="Password"
                          autoComplete="password"
                          value={password}
                          className={classnames('', { 'input-error': fields && fields.includes('password') })}
                          onChange={e => this.setState({ password: e.target.value })}
                        />
                      </div>
                    </motion.div>
                    
                    <motion.div className="form-group" key={5} variants={item}>
                      <input 
                        type="submit"
                        className="btn btn--primary btn--large btn--fullwidth"
                        value="Login" 
                        disabled={formSubmitted && status === 'fetching' ? true : false}
                      />
                    </motion.div>

                    {
                      /*
                      <KeepMeSigned keepMeSigned={keepMeSigned} handleKeepMeSigned={this.handleKeepMeSigned} />
                      */
                    }

                    <motion.div className="form-group" key={6} variants={item}>
                      <OAuth />
                    </motion.div>


                  </form>

                </div>
                <div className="auth-box__support-links">
                  <div className="auth-box__forgot-password">
                    Forgot password? <Link to="/forgot-password">Reset your password</Link>
                  </div>
                  <div className="auth-box__register">
                    Don’t have an account? <Link to="/register">Register</Link>
                  </div>
                </div>
              </div>
            <div className="auth-version">v{VERSION}</div>
          </motion.div>
        </div>
      </div>
    )
  }
}



export default connect(
  ({ auth }: RootState) => ({ auth }),
  { login }
)( AuthLogin)