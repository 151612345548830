import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchAllScenarios } from '../../actions/scenario';
import { fetchAllRooms } from '../../actions/room'
import { fetchAllDevices, fetchMasterDevice } from '../../actions/device'
import { wsGetDataByDeviceSerial, wsPressDouByDeviceSerial, wsPressButtonByDeviceSerial, wsPressRunScenarioByScenarioId } from '../../actions/ws'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import AddItem from '../partials/AddItem'
import fetchStates from '../../types/fetchStates'
import { motion } from 'framer-motion'
import { roomsContainer, roomsItem } from '../../helpers/framer'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { IconEdit, IconSettings } from '../partials/IconsNavigation'
import { IconButtonsLight } from '../partials/IconsButtons'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
import { danger } from '../../helpers/notifications'
import { getButtonIcon } from '../../helpers/getButtonIcon'
import moment from 'moment'
import { IconScreenHumidity } from '../partials/IconsScreenTempx'
import { IconScreenButtonBottomLeft, IconScreenButtonBottomRight, IconScreenButtonTopLeft, IconScreenButtonTopRight, IconScreenlWifi } from '../partials/IconsScreen'
import objectIsEmpty from '../../helpers/objectIsEmpty'
import { getWeatherIcon } from '../../helpers/getWeatherIcon'
import { getStatusIcon } from '../../helpers/getStatusIcon'
import { IconScenarios } from '../partials/Icons';


interface RoomsProps {
  room: { status: string, rooms: {}[] },
  scenario: any,
  ws: { wsConnected: boolean },
  device: any,
  fetchMasterDevice: () => Promise<any>,
  fetchAllRooms: () => Promise<any>,
  fetchAllDevices: () => Promise<any>,
  fetchAllScenarios: () => Promise<void>,
  wsGetDataByDeviceSerial: (device_serial: string) => Promise<void>,
  wsPressDouByDeviceSerial: (options: { device_serial: string, dou_array: Array<number> }) => Promise<void>,
  wsPressButtonByDeviceSerial: (options: { device_serial: string, btn: number }) => Promise<void>,
  wsPressRunScenarioByScenarioId: (options: { device_serial: string, scenario_id: number }) => Promise<void>
}


interface RoomsState {
  scenarios: Array<Object>,
  rooms: Array<Object>,
  devices: Array<Object>,
  deviceMaster: any,
}


export class Rooms extends React.Component<RoomsProps, RoomsState> {


  state = {
    scenarios: [],
    rooms: [],
    devices: [],
    deviceMaster: { device_serial: '' },
  }


  componentDidMount = () => {
    this.fetchMasterDevice();
    this.fetchAllRooms();
    this.fetchAllScenarios();
  }


  fetchAllRooms = async () => {
    await this.props.fetchAllRooms();
    if(this.props.room.status === fetchStates.success) {
      const { rooms } = this.props.room;
      this.setState({ rooms }, () => this.fetchAllDevices());
    }
  }


  fetchAllScenarios = async () => {
    await this.props.fetchAllScenarios();
    const { status, scenarios } = this.props.scenario;
    if(status !== fetchStates.success) { return; }
    this.setState({ scenarios });
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices();
    if(this.props.device.status === fetchStates.success) {
      const { devices } = this.props.device;
      this.setState({ devices }, () => this.dataRequest());
    }
  }


  fetchMasterDevice = async () => {
    await this.props.fetchMasterDevice();
    const { status, deviceMaster } = this.props.device;
    if(status !== fetchStates.success) { return; }
    this.setState({ deviceMaster });
  }


  // WS: requesting data
  dataRequest = async () => {
    if(this.props.ws.wsConnected) {
      const devices: Array<any> = this.state.devices
      if(devices.length > 0) {
        for (const device of devices) {
          //console.log('DEBUG: [WS] requesting the data', device)
          await this.props.wsGetDataByDeviceSerial(device.device_serial)
        }
      }
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  componentDidUpdate(prevProps:any) {
    if (this.props.device.devices !== prevProps.device.devices) {

      const { devices } = this.props.device;
      const { rooms } = this.state;
      let updated_rooms: any = rooms;
    
      updated_rooms = rooms.map((room:any) => {
        let room_devices: any = [];

        if(room.devices && room.devices.length > 0) {
          room_devices = devices && devices.length > 0 && room.devices.map((room_device: any) => {
            let device_data = {};
            devices && devices.length > 0 && devices.map((device:any) => {
              if(device) {
                if(room_device.device_serial === device.device_serial) {
                  device_data = device.device_data;
                }
              }
              return null
            })
            return { ...room_device, device_data };
          })
        }

        return {...room, devices: room_devices };
      })
      this.setState({ devices, rooms: updated_rooms });
    }
  }


  // WS: pressing the button
  pressMagicboxButton = (btn: number, device_serial: string) => {
    if(this.props.ws.wsConnected) {
      this.props.wsPressButtonByDeviceSerial({ device_serial, btn })
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  // WS: updating DOU array
  pressMagicBoxDou = (options: {device_serial: string, position: number, value: number, dou_array: Array<number>}) => {
    if(this.props.ws.wsConnected) {
      const { device_serial, position, value, dou_array } = options;
      let updated_dou_array: Array<number> = []
      updated_dou_array = dou_array.map((item: any, index: number) => {
        if(index === position) {
          return value === 1 ? 0 : 1
        }
        return 2
      })
      // console.log('[DEBUG]: sending to the server', updated_dou_array)
      this.props.wsPressDouByDeviceSerial({ device_serial, dou_array: updated_dou_array })
    } else {
      danger('You are disconnected from the WS server')
    }
  }


  // WS: running scenario
  pressRunScenario = (options: {device_serial: string, scenario_id: number}) => {
    if(this.props.ws.wsConnected) {
      this.props.wsPressRunScenarioByScenarioId({ device_serial: options.device_serial, scenario_id: options.scenario_id })
    } else {
      danger('You are disconnected from the WS server')
    }
  }

  

  render() {

    const { rooms, scenarios, deviceMaster } = this.state;

    return (
      <TemplatePage title="Rooms" button={true} buttonLink="/rooms/add" buttonType="add" buttonText="Add Room">
        <ContentMain>
          {
            rooms && rooms.length > 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="rooms-new"
              >
                {
                  rooms.map((room: {room_name: string, room_id: string, room_image: string, devices_count: string, devices: Array<string>, room_active: true}) => {
                    

                    let room_panel_device: any = {}
                    let ms_count = 0
                    room.devices && room.devices.length > 0 && room.devices.map((device: any) => {
                      if(device.device_type === 'XS') {
                        return room_panel_device = device
                      } else {
                        ms_count = ms_count+1
                      }
                      return null
                    })


                    let room_panel_temperature = 0;
                    let room_panel_temperature_decimal = '0';
                    if(room_panel_device.device_data && room_panel_device.device_data.tem) {
                      room_panel_temperature_decimal = (room_panel_device.device_data.tem + "").split(".")[1]
                      room_panel_temperature = Math.round(room_panel_device.device_data.tem)
                    }


                    let room_panel_humidity = 0
                    if(room_panel_device.device_data && room_panel_device.device_data.hum) {
                      room_panel_humidity = room_panel_device.device_data.hum
                    }


                    let room_panel_weather = 0
                    if(room_panel_device.device_data && room_panel_device.device_data.ws) {
                      room_panel_weather = room_panel_device.device_data.ws
                    }


                    let room_panel_status_icons = null
                    if (room_panel_device.device_set_status && room_panel_device.device_set_status.stat) {
                      room_panel_status_icons = <>
                        {
                          room_panel_device.device_set_status.stat.map((status_icon: { icon: string, io: string }, index: number) => (
                            <div 
                              key={index}
                              className={classnames('display__status-icon', { 'display__status-icon--active': room_panel_device.device_data && room_panel_device.device_data.statusIco[index] === 1 })}
                            >
                              <span className="display__status-icon-icon">
                                { getStatusIcon({ icon_number: parseInt(status_icon.icon), size: 24, color: room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? room_panel_device.device_data && room_panel_device.device_data.statusIco[index] === 1 ? '#7C22A4' : '#000' : '#D9D6E9' }) }
                              </span>
                              { /* <span className="display__status-icon-title">{ status_icon.io }</span> */ }
                            </div>
                          ))
                        }
                      </>
                    }


                    let room_panel_corner_buttons = null
                    if(room_panel_device.device_set_btn && room_panel_device.device_set_btn.btn) {
                      room_panel_corner_buttons = <>
                        {
                          room_panel_device.device_set_btn.btn.map((btn: any, index: number) => (
                            <div 
                              key={index}
                              className={classnames('', { 
                                'ink-panel__button-top-left': index === 0,
                                'ink-panel__button-top-right': index === 1,
                                'ink-panel__button-bottom-left': index === 2,
                                'ink-panel__button-bottom-right': index === 3,
                              })}
                              onClick={ () => this.pressMagicboxButton(index + 1, room_panel_device.device_serial) }
                            >
                              <div className={classnames('', { 
                                  'ink-panel__button-marker-top-left': index === 0,
                                  'ink-panel__button-marker-top-right': index === 1,
                                  'ink-panel__button-marker-bottom-left': index === 2,
                                  'ink-panel__button-marker-bottom-right': index === 3,
                                })}
                              >
                                {
                                  index === 0 && <IconScreenButtonTopLeft size={11} color={ room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? room_panel_device.device_data && room_panel_device.device_data.btnIco[index] ? '#7C22A4' : '#000000' : '#D9D6E9' } />
                                }
                                {
                                  index === 1 && <IconScreenButtonTopRight size={11} color={ room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? room_panel_device.device_data && room_panel_device.device_data.btnIco[index] ? '#7C22A4' : '#000000' : '#D9D6E9' } />
                                }
                                {
                                  index === 2 && <IconScreenButtonBottomLeft size={11} color={ room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? room_panel_device.device_data && room_panel_device.device_data.btnIco[index] ? '#7C22A4' : '#000000' : '#D9D6E9' } />
                                }
                                {
                                  index === 3 && <IconScreenButtonBottomRight size={11} color={ room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? room_panel_device.device_data && room_panel_device.device_data.btnIco[index] ? '#7C22A4' : '#000000' : '#D9D6E9' } />
                                }
                              </div>
                            </div>
                          ))
                        }
                      </>
                    }


                    let room_panel_corner_button_icons = null
                    if(room_panel_device.device_set_btn && room_panel_device.device_set_btn.btn) {
                      room_panel_corner_button_icons = <>
                        {
                          room_panel_device.device_set_btn.btn.map((btn: { icon: string }, index: number) => (
                            <div
                              key={index}
                              className={classnames('', { 
                                'display__corner-icon-top-left': index === 0,
                                'display__corner-icon-top-right': index === 1,
                                'display__corner-icon-bottom-left': index === 2,
                                'display__corner-icon-bottom-right': index === 3,
                              })}
                            >
                              { getButtonIcon({ button_image: btn.icon, size: 24, color: room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? room_panel_device.device_data && room_panel_device.device_data.btnIco[index] ? '#7C22A4' : '#000000' : '#D9D6E9' }) }
                            </div>
                          ))
                        }
                      </>
                    }


                    return (

                      <motion.div key={room.room_id} variants={roomsItem} className="room-new">
                        <div className="room-new__header">
                          <span className="room-new__title">{room.room_name}</span>
              

                          <span className="room-new__toolbar-item">
                            {
                              room_panel_device.device_id && (
                                <span className="room-new__title-button">
                                  <Link to={`/devices/settings/${room_panel_device.device_id}`}>
                                    <IconSettings color="#ffffff" />
                                  </Link>
                                </span>
                              )
                            }
                          </span>

                          <span className="room-new__toolbar-item">
                            <span className="room-new__title-button">
                              <Link to={`/rooms/edit/${room.room_id}`}>
                                <IconEdit color="#ffffff" />
                              </Link>
                            </span>
                          </span>

    
                        </div>



                        {
                          !objectIsEmpty(room_panel_device) && (
                            <div className="room-panel">

                            <div 
                              className={classnames('ink-panel', { 'ink-panel--active': room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 })}
                            >
                              <div className="ink-panel-ui">
  
                                <div className="ink-panel-buttons">

                                  { room_panel_corner_buttons }

                                </div>
  
                                <div className="ink-panel__visual">
                                  <div className="ink-panel__divider-top"></div>
                                  <div className="ink-panel__divider-right"></div>
                                  <div className="ink-panel__divider-bottom"></div>
                                  <div className="ink-panel__divider-left"></div>
  
                                  <div className="ink-panel__sensor-top"></div>
                                  <div className="ink-panel__sensor-bottom-left"></div>
                                  <div className="ink-panel__sensor-bottom-right"></div>
  
                                  <div className="ink-panel__branding">MAGIC<span>BOX</span></div>
                                </div>
  
                                <div className="ink-panel__display">

                                  { room_panel_corner_button_icons }

                                  <div className="display__wifi-icon">
                                    <IconScreenlWifi size={12} color={room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? '#000' : '#D9D6E9'} />
                                  </div>
                                  <div className="display__time">
                                    {moment().format('HH:mm')}
                                  </div>
                                  <div className="display__tempertature">
                                    { room_panel_temperature }.<span className="display__temperature-decimal">{ room_panel_temperature_decimal }</span>°C
                                  </div>
                                  <div className="display__humidity">
                                    { room_panel_humidity }<IconScreenHumidity size={22} color={room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? '#000' : '#D9D6E9'} />
                                  </div>
                                  <div className="display__weather">
                                    { getWeatherIcon({ ws: room_panel_weather, color: room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? '#000' : '#D9D6E9', size: 24 }) }
                                  </div>
                                  <div className="display__status">
                                    { room_panel_status_icons }
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            </div>
                          )
                        }


                        {
                          <div className="room-new__buttons">
                            {
                              parseInt(room.devices_count) > 0 && room.devices && room.devices.map((device: any) => {
                                // MB LIGHT
                                if(checkDeviceCapabilities(device.device_serial.substring(0, 2)).device_type === 'LI') {
                                  return device.device_capabilities && device.device_capabilities.dou && device.device_capabilities.dou.map((din: any, index: number) => {
                                    return <div 
                                      onClick={() => this.pressMagicBoxDou({ device_serial: device.device_serial, position: index, value: device.device_data && device.device_data.dou && device.device_data.dou[index], dou_array: device.device_data && device.device_data.dou })}
                                        className={classnames('room-button', { 
                                        'room-button--on': device.device_data && device.device_data.dou && device.device_data.dou[index] === 1,
                                        'room-button--off': device.device_data && device.device_data.dou && device.device_data.dou[index] === 0
                                        })} 
                                        key={index}
                                      >
                                      <div className="room-button__icon">
                                        <IconButtonsLight size={32} color={device.device_data && Object.keys(device.device_data).length > 0 ? device.device_data.dou && device.device_data.dou[index] === 1 ? '#7C22A4' : '#828BA9' : '#D9D6E9'} />
                                      </div>
                                      <div className="room-button__title">
                                        { din.title }
                                      </div>
                                    </div>
                                  }) 
                                }
                                return null;
                              })
                            }
                            {
                              scenarios && scenarios.length > 0 && scenarios.map((scenario: any) => {
                                if(scenario.btn_room === room.room_id) {
                                  return <div 
                                  className={classnames('room-button', { 
                                    'room-button--on': scenario.sc_act === 1 && room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0
                                    })} 
                                  key={scenario.scenario_id}
                                  onClick={ scenario.sc_act === 1 ? () => this.pressRunScenario({ device_serial:   deviceMaster.device_serial, scenario_id: scenario.sc_id }) : () => null }
                                  >
                                    <div className="room-button__icon">
                                      <IconScenarios size={32} color={scenario.sc_act && room_panel_device.device_data && Object.keys(room_panel_device.device_data).length > 0 ? '#828BA9' : '#D9D6E9'} />
                                    </div>
                                    <div className="room-button__title">
                                      { scenario.sc_title }
                                    </div>
                                  </div>
                                }
                                return null;
                              })
                            }
                          </div>
                        }




                        
                      </motion.div>
                    )
                  })
                }

              <motion.div key={10000} variants={roomsItem}>
                <AddItem title="Add Room" link="/rooms/add" type="rooms" />
              </motion.div>
              
            </motion.div>
            )
          }



          {
            rooms && rooms.length === 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="room-cards"
              >
                <motion.div key={10000} variants={roomsItem}>
                  <AddItem title="Add Room" link="/rooms/add" type="rooms" />
                </motion.div>
              </motion.div>
            )
          }

        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(connect(
  ({ room, device, ws, scenario }: RootState) => ({ room, device, ws, scenario }),
  { fetchAllScenarios, fetchAllRooms, fetchAllDevices, wsGetDataByDeviceSerial, wsPressDouByDeviceSerial, wsPressButtonByDeviceSerial, wsPressRunScenarioByScenarioId, fetchMasterDevice }
)(Rooms))