export const IconAlertSuccess = (props: { size?: number, color?: string }) => (
  <span className="icons icon-alert-success">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="Shape" fill={props.color ? props.color : '#2A2C44'} fillRule="nonzero"></path>
        </g>
      </g>
    </svg>
  </span>
);



export const IconAlertError = (props: { size?: number, color?: string }) => (
  <span className="icons icon-alert-error">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path d="M15.73,3 L8.27,3 L3,8.27 L3,15.73 L8.27,21 L15.73,21 L21,15.73 L21,8.27 L15.73,3 Z M12,17.3 C11.28,17.3 10.7,16.72 10.7,16 C10.7,15.28 11.28,14.7 12,14.7 C12.72,14.7 13.3,15.28 13.3,16 C13.3,16.72 12.72,17.3 12,17.3 Z M13,13 L11,13 L11,7 L13,7 L13,13 Z" id="Shape" fill={props.color ? props.color : '#2A2C44'} fillRule="nonzero"></path>
        </g>
      </g>
    </svg>
  </span>
);



export const IconAlertWarning = (props: { size?: number, color?: string }) => (
  <span className="icons icon-alert-warning">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path d="M1,21 L23,21 L12,2 L1,21 Z M13,18 L11,18 L11,16 L13,16 L13,18 Z M13,14 L11,14 L11,10 L13,10 L13,14 Z" id="Shape" fill={props.color ? props.color : '#2A2C44'} fillRule="nonzero"></path>
        </g>
      </g>
    </svg>
  </span>
);


export const IconAlertInfo = (props: { size?: number, color?: string }) => (
  <span className="icons icon-alert-info">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,11 L13,11 L13,17 Z M13,9 L11,9 L11,7 L13,7 L13,9 Z" id="Shape" fill={props.color ? props.color : '#2A2C44'} fillRule="nonzero"></path>
        </g>
      </g>
    </svg>
  </span>
);