import React from 'react'
import { connect } from 'react-redux'
import { wsUpdateDevicesList } from '../../actions/ws'
import { fetchDeviceById, removeDeviceById, fetchSlaveDevices, fetchMasterDevice } from '../../actions/device'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
import classnames from 'classnames'
import { IconDevice } from '../partials/IconsForm'
import Alert from '../partials/Alert'


interface DevicesDeleteProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: { status: string, fields: Array<string>, device: { device_serial: string }, devicesSlave: any, deviceMaster: any },
  fetchDeviceById: (id: string) => Promise<void>,
  removeDeviceById: (options: { id: string, device_serial: string }) => Promise<void>,
  wsUpdateDevicesList: (options: {device_serial: string, devices: any }) => Promise<void>,
  fetchSlaveDevices: () => Promise<void>,
  fetchMasterDevice: () => Promise<any>,
}


interface DevicesDeleteState {
  formSubmitted: boolean,
  device_serial: string,
  device: any,
  noData: boolean,
}


export class DevicesDelete extends React.Component<DevicesDeleteProps, DevicesDeleteState> {


  state = {
    formSubmitted: false,
    device_serial: '',
    device: {},
    noData: false
  }


  componentDidMount() {
    this.fetchDeviceById()
  }


  fetchDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      this.setState({ device, noData: false })
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  handleSubmitRemoveDevice = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { device_serial } = this.state;
    console.log(device_serial)
    await this.props.removeDeviceById({ id: this.props.router.params.id, device_serial });
    if(this.props.device.status === fetchStates.success) {
      // update device slave list on the master device
      await this.props.fetchSlaveDevices();
      await this.props.fetchMasterDevice();
      const master_device = this.props.device.deviceMaster;
      const devices = this.props.device.devicesSlave.map((device: {device_serial: string}) => device.device_serial);
      await this.props.wsUpdateDevicesList({ device_serial: master_device.device_serial, devices: { tst: Date.now(), devices } });
      // redirect
      const prefixToCheck = this.state.device_serial.substring(0, 2).toUpperCase();
      this.setState({ device_serial: '', formSubmitted: false });
      if(checkDeviceCapabilities(prefixToCheck).device_category === 'MS') {
        this.props.router.navigate('/devices/magicswitch');
      } else {
        this.props.router.navigate('/devices/magicbox');
      }
    }
  }


  render() {

    const { device_serial, formSubmitted, noData } = this.state;
    const { device, fields, status } = this.props.device;

    if(noData) {
      return (
        <TemplatePage title="Devices → Remove Device" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Devices → Remove Device" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <p>
            You are about to remove device with a serial number: <strong>"{device.device_serial}"</strong>. All related data to this device will be deleted. This action can not be reversed. Are you sure you want to proceed?
          </p>
          <form className="form" onSubmit={this.handleSubmitRemoveDevice}>
            <div className="form-group">
              <label htmlFor="device_serial">
                Device serial number (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconDevice color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="device_serial"
                  id="device_serial" 
                  value={device_serial}
                  onChange={e => this.setState({ device_serial: e.target.value.toUpperCase() })}
                  className={classnames('', { 'input-error': fields && fields.includes('device_serial') })}
                />
              </div>
              <span className="form-explanation">Confirm your choice by typing the device serial number</span>
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value="Remove Device" 
                className="btn btn--primary btn--large"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device }: RootState) => ({ device }),
  { fetchDeviceById, removeDeviceById, fetchSlaveDevices, wsUpdateDevicesList, fetchMasterDevice }
)(DevicesDelete)))