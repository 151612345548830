import { Link } from 'react-router-dom';
import { IconEdit, IconAdd, IconDelete, IconBack } from '../partials/IconsNavigation'
import { IconSpeaker } from './IconsDevices';


interface ButtonLinkProps {
  buttonLink: string,
  iconType: string,
  inactive?: boolean
}


const ButtonLink: React.FC<ButtonLinkProps> = ({ buttonLink, iconType, inactive }) => (
  <Link to={buttonLink} className="button-link">
    { iconType === 'edit' &&  <IconEdit color={inactive ? '#593995' : 'white'} /> }
    { iconType === 'add' &&  <IconAdd color={inactive ? '#593995' : 'white'} /> }
    { iconType === 'delete' &&  <IconDelete color={inactive ? '#593995' : 'white'} /> }
    { iconType === 'back' &&  <IconBack color={inactive ? '#593995' : 'white'} /> }
    { iconType === 'speaker' &&  <IconSpeaker color={inactive ? '#593995' : 'white'} /> }
  </Link>
)


export default ButtonLink