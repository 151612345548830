import React from 'react'
import { connect } from 'react-redux'
import { fetchDeviceById, updateDeviceStatusIcons, fetchAllDevices } from '../../actions/device'
import { wsGetDataByDeviceSerial, wsUpdateStatusIconsByDeviceSerial } from '../../actions/ws'
import { fetchAllScenarios } from '../../actions/scenario'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { IconScreenHumidity } from '../partials/IconsScreen'
import { getWeatherIcon } from '../../helpers/getWeatherIcon'
import { getButtonIcon } from '../../helpers/getButtonIcon'
import { getStatusIcon } from '../../helpers/getStatusIcon'
import { IconDevice, IconIO } from '../partials/IconsForm'
import { IconClose } from '../partials/Icons'
import moment from 'moment'
import { danger } from '../../helpers/notifications'
import { devicePresets } from '../../helpers/devicePresets'
import Alert from '../partials/Alert'


interface DevicesSettingsStatusIconsProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  device: any,
  scenario: any,
  ws: { wsConnected: boolean },
  fetchDeviceById: (id: string) => Promise<any>,
  fetchAllDevices: () => Promise<void>,
  fetchAllScenarios: () => Promise<void>,
  updateDeviceStatusIcons: (opitons: {id: string, status_settings: any}) => Promise<any>,
  wsGetDataByDeviceSerial: (device_serial: string) => Promise<void>,
  wsUpdateStatusIconsByDeviceSerial: (options: { device_serial: string, status_icons: any }) => Promise<void>,
}


interface DevicesSettingsStatusIconsState {
  devices: any,
  defaultDevice: { device_serial: string, device_capabilities: { dou: any, din: any, doors: boolean }},
  device: {
    room_id: string,
    device_id: string,
    device_serial: string,
    device_title: string,
    device_set_status: {
      tst: number,
      stat: any
    },
    device_set_btn: {
      tst: number,
      btn: any
    },
    device_data: {
      tem: number,
      hum: number,
      ws: number,
      statusIco: any,
      btnIco: any,
    }
  },
  scenarios: any,
  defaultScenario: any,
  formSubmitted: boolean,
  iconListOpened: number,
  statusIconSelected: number,
  iconSelectedIO: any,
  wsError: string,
  noData: boolean,
}


export class DevicesSettingsStatusIcons extends React.Component<DevicesSettingsStatusIconsProps, DevicesSettingsStatusIconsState> {


  state = {
    devices: [],
    defaultDevice: { device_serial: '', device_capabilities: { dou: [], din: [], doors: false}},
    device: {
      room_id: '',
      device_id: '',
      device_serial: '',
      device_title: '',
      device_set_status: devicePresets.device_set_status,
      device_set_btn: devicePresets.device_set_btn,
      device_data: {
        tem: 21,
        hum: 50,
        ws: 1,
        statusIco: [],
        btnIco: []
      }
    },
    scenarios: [],
    defaultScenario: {},
    formSubmitted: false,
    iconListOpened: 0,
    statusIconSelected: 0,
    iconSelectedIO: [],
    wsError: '',
    noData: false
  }


  componentDidMount() {
    this.getDeviceById()
    this.fetchAllScenarios()
  }


  // WS: requesting data
  dataRequest = () => {
    console.log('DEBUG: [WS] requesting the data', this.state.device.device_serial)
    this.props.wsGetDataByDeviceSerial(this.state.device.device_serial)
  }


  getDeviceById = async () => {
    await this.props.fetchDeviceById(this.props.router.params.id)
    if(this.props.device.status === fetchStates.success) {
      const { device } = this.props.device
      if(device) {
        this.setState({ 
          device: { ...device, device_data: this.state.device.device_data, device_set_status: device.device_set_status ? device.device_set_status : devicePresets.device_set_status, device_set_btn: device.device_set_btn ? device.device_set_btn : devicePresets.device_set_btn  },
          noData: false
        }, () => this.dataRequest())
      }
      this.fetchAllDevices();
    }
    if(this.props.device.status === fetchStates.error) {
      this.setState({ noData: true })
    }
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      let devices: any = {};
      devices = this.props.device.devices
      if(devices.length > 0) {
        let defaultDevice = devices[0]
        const filteredDefaultDevices = devices.filter((device: {master: boolean}) => {
          if(device.master === true){
            return device
          }
          return null
        })
        if(filteredDefaultDevices.length > 0) {
          defaultDevice = filteredDefaultDevices[0]
        }
        console.log('defaultDevice', defaultDevice)
        this.setState({ devices, defaultDevice }, () => this.inicializeStatusIconIoOptions())
      }
    }
  }


  fetchAllScenarios = async () => {
    await this.props.fetchAllScenarios()
    if(this.props.scenario.status === fetchStates.success) {
      let scenarios: any = {};
      scenarios = this.props.scenario.scenarios
      if(scenarios.length > 0) {
        let defaultScenario = scenarios[0]
        this.setState({ scenarios, defaultScenario }, () => this.inicializeStatusIconIoOptions())
      }
    }
  }


  inicializeStatusIconIoOptions = () => {
    let stat : any[] = []
    stat = JSON.parse(JSON.stringify(this.state.device.device_set_status.stat))
    const amendedStat = stat.map((item: any) => {
      this.state.devices.map((device: any) => {
        if(item.device_serial === device.device_serial) {
          // doors - list if we have any
          let doors: any = []
          if(this.state.defaultDevice.device_capabilities.doors === true) {
            doors.push({ "id": "doors", "title":"Doors", "state_1": "closed", "state_0": "unknown", "icon_0": 5, "icon_1": 5, "icon_selectable": false, "type": "doors" })
          }
          // scenarios - list if there are any
          let scenarios: any = []
          this.state.scenarios.map((scenario:any) => {
            return scenarios.push({ "id": scenario.sc_id, "title": scenario.sc_title, "state_1": "active", "state_0": "inactive", "icon_0": 8, "icon_1": 8, "icon_selectable": false, "type": "scenario" })
          })
          // io_options - list them all
          return item.io_options = [ ...this.state.defaultDevice.device_capabilities.din, ...this.state.defaultDevice.device_capabilities.dou, ...doors, ...scenarios ]
        }
        return null
      })
      return item
    })

    this.setState({ device: { ...this.state.device, device_set_status: { ...this.state.device.device_set_status, stat: amendedStat } } })
  }


  changeStatusIcon = (iconIndex: number, icon: number) => {
    let stat : any[] = []
    stat = JSON.parse(JSON.stringify(this.state.device.device_set_status.stat))
    const amendedStat = stat.map((item: {icon: number}, index: number) => index === iconIndex ? {...item, icon} : item)
    this.setState({ device: { ...this.state.device, device_set_status: { ...this.state.device.device_set_status, stat: amendedStat } }, iconListOpened: 0})
  }


  setIconStatusDevice = (iconIndex: number, device_serial: string) => {
    let stat : any[] = []
    let io_options : any[] = []
    let io : string = ''
    stat = JSON.parse(JSON.stringify(this.state.device.device_set_status.stat))
    this.state.devices.map((device: any) => {
      if(device_serial === device.device_serial) {
        // doors - list if we have any
        let doors: any = []
        if(this.state.defaultDevice.device_capabilities.doors === true) {
          doors.push({ "id": "doors", "title":"Doors", "state_1": "closed", "state_0": "unknown", "icon_0": 5, "icon_1": 5, "icon_selectable": false, "type": "doors" })
        }
        // scenarios - list if there are any
        let scenarios: any = []
        this.state.scenarios.map((scenario:any) => {
          return scenarios.push({ "id": scenario.sc_id, "title": scenario.sc_title, "state_1": "active", "state_0": "inactive", "icon_0": 8, "icon_1": 8, "icon_selectable": false, "type": "scenario" })
        })
        // io_options - list them all
        if(device.device_capabilities.dou[0] && device.device_capabilities.dou[0].id) {
          io = device.device_capabilities.dou[0].id
        }
        if(device.device_capabilities.din[0] && device.device_capabilities.din[0].id) {
          io = device.device_capabilities.din[0].id
        }
      }
    })
    console.log('io_options', io_options)
    const amendedStat = stat.map((item: {icon: number}, index: number) => index === iconIndex ? {...item, device_serial, io, io_options} : item)
    this.setState({ device: { ...this.state.device, device_set_status: { ...this.state.device.device_set_status, stat: amendedStat } }})
  }


  setIconStatusIO = (iconIndex: number, io: string) => {
    let stat : any[] = []
    stat = JSON.parse(JSON.stringify(this.state.device.device_set_status.stat))
    const amendedStat = stat.map((item: {icon: number}, index: number) => index === iconIndex ? {...item, io} : item)
    this.setState({ device: { ...this.state.device, device_set_status: { ...this.state.device.device_set_status, stat: amendedStat } }})
  }


  removeIcon = (iconIndex: number) => {
    let stat : any[] = []
    stat = JSON.parse(JSON.stringify(this.state.device.device_set_status.stat))
    const filteredStat = stat.filter((item: any, index: number) => index !== iconIndex);
    this.setState({ device: { ...this.state.device, device_set_status: { ...this.state.device.device_set_status, stat: filteredStat } } })
  }


  addIcon = () => {
    let stat : any[] = [];
    stat = JSON.parse(JSON.stringify(this.state.device.device_set_status.stat))
    let device_serial = ''
    device_serial = this.state.defaultDevice.device_serial
    let dou : any = [];
    dou = this.state.defaultDevice.device_capabilities.dou[0]
    let io_options : any[] = []
    // doors - list if we have any
    let doors: any = []
    if(this.state.defaultDevice.device_capabilities.doors === true) {
      doors.push({ "id": "doors", "title":"Doors", "state_1": "closed", "state_0": "unknown", "icon_0": 5, "icon_1": 5, "icon_selectable": false, "type": "doors" })
    }
    // scenarios - list if there are any
    let scenarios: any = []
    this.state.scenarios.map((scenario:any) => {
      return scenarios.push({ "id": scenario.sc_id, "title": scenario.sc_title, "state_1": "active", "state_0": "inactive", "icon_0": 8, "icon_1": 8, "icon_selectable": false, "type": "scenario" })
    })
    io_options = [ ...this.state.defaultDevice.device_capabilities.din, ...this.state.defaultDevice.device_capabilities.dou, ...doors, ...scenarios ]
    stat.push({ icon: '1', device_serial, io: dou.id, io_options })
    this.setState({ device: { ...this.state.device, device_set_status: { ...this.state.device.device_set_status, stat } }})
  }


  handleButtonSubmit = async () => {
    this.setState({ formSubmitted: true })
    if(this.props.ws.wsConnected) {
      let stat : any[] = [];
      stat = JSON.parse(JSON.stringify(this.state.device.device_set_status.stat))
      stat.map((item: any) => delete item.io_options)
      const status_settings = {
        tst: moment().valueOf(),
        stat
      }
      await this.props.updateDeviceStatusIcons({ id: this.props.router.params.id, status_settings })
      await this.props.wsUpdateStatusIconsByDeviceSerial({ device_serial: this.props.device.device.device_serial, status_icons: status_settings })
    } else {
      danger('You are disconnected from the WS server')
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { devices, defaultDevice, device, statusIconSelected, formSubmitted, iconListOpened, noData } = this.state

    if(noData) {
      return (
        <TemplatePage title="Device → Status Icons" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Device does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Device → Status Icons" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <h3>{device.device_title} ({device.device_serial})</h3>
          <div className="mb-screen-wrapper mb-screen-wrapper--boxed">
            <div className="mb-screen">
              <div className="mb-screen-overlay">

                <div className="mb-screen__temperature mb-screen-item--inactive">
                  {device.device_data && device.device_data.tem && device.device_data.tem.toFixed(1) + '°C'}
                </div>

                <div className="mb-screen__humidity mb-screen-item--inactive">
                  {device.device_data && device.device_data.hum} <IconScreenHumidity color="#ffffff" size={30} />
                </div>

                <div className="mb-screen__weather mb-screen-item--inactive">
                  {device.device_data && getWeatherIcon({ ws: device.device_data.ws, color: '#fff', size: 34 })}
                </div>

                {
                  // STATUS ICONS
                  device.device_data && device.device_data.statusIco && (
                    <div className="mb-screen__status-bar">
                      <div className="mb-screen__nav-item nav-item--left">
                        { /* <IconStatusLeftArrow size={17} color="#ffffff" /> */ }
                      </div>
                      <div className="mb-screen__nav-item mb-screen__nav-items">
                        {
                          device.device_set_status && device.device_set_status.stat && device.device_set_status.stat.map((item: any, index: number) => {
                            return  <div key={index} className={classnames('mb-screen__status-bar-item-wrapper', { 'mb-screen__status-bar-item-wrapper--selected': index + 1 === statusIconSelected })}>
                              <div className={classnames('mb-screen__status-bar-item mb-screen-item--active', { 'status-bar-item--active': device.device_data.statusIco[0] === 1})}>
                                { 
                                  device.device_set_status.stat[index] && item.icon && getStatusIcon({ icon_number: parseInt(item.icon), size: 24, color: '#ffffff' })
                                }
                              </div>
                            </div>
                          })
                        }
                      </div>
                      <div className="mb-screen__nav-item nav-item--right">
                        { /* <IconStatusRightArrow size={17}color="#ffffff" /> */ }
                      </div>
                    </div>
                  )
                }

                {
                  device.device_data && device.device_data.btnIco && (
                    <>
                      <div
                      className={classnames('mb-screen__button mb-screen__button--one', { 'mb-screen__button--active': device.device_data.btnIco[0]})}>
                        { 
                          device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[0] && device.device_set_btn.btn[0].icon && device.device_set_btn.btn[0].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[0].icon, size: 34, color: '#ffffff' })
                        }
                      </div>
                      <div className={classnames('mb-screen__button mb-screen__button--two', { 'mb-screen__button--active': device.device_data.btnIco[1]})}>
                        {
                          device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[1] && device.device_set_btn.btn[1].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[1].icon, size: 34, color: '#ffffff' })
                        }
                      </div>
                      <div className={classnames('mb-screen__button mb-screen__button--three', { 'mb-screen__button--active': device.device_data.btnIco[2]})}>
                        {
                          device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[2] && device.device_set_btn.btn[2].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[2].icon, size: 34, color: '#ffffff' })
                        }
                      </div>
                      <div className={classnames('mb-screen__button mb-screen__button--four', { 'mb-screen__button--active': device.device_data.btnIco[3]})}>
                        { device.device_set_btn && device.device_set_btn.btn && device.device_set_btn.btn[3] && device.device_set_btn.btn[3].icon !== '0' && getButtonIcon({ button_image: device.device_set_btn.btn[3].icon, size: 34, color: '#ffffff' })}
                      </div>
                    </>
                  )
                }

              </div>
            </div>
          </div>


          <div className="status-icons__form">
            <form className="form">
              <div className="status-icon__setup-form">
                {
                  device.device_set_status && device.device_set_status.stat && device.device_set_status.stat.map((item: any, index: number) => {
                    return <div key={index} className="form-group status-icon__form-row" onClick={() => this.setState({ statusIconSelected: index + 1 })}>
                        <div className="status-icon-selector">
                          <div onClick={() => this.setState({ iconListOpened: index + 1 })}>
                            { getStatusIcon({ icon_number: parseInt(item.icon), size: 24, color: '#000000' }) }
                          </div>
                          <div className={classnames('screen-icon-list', { 'screen-icon-list--active': iconListOpened === index + 1 })}>
                            <div className="screen-icon-list__title">
                              SELECT ICON
                            </div>
                            <div className="screen-icon-list__body">
                              <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': parseInt(item.icon) === 1 })} onClick={() => this.changeStatusIcon(index, 1)}>
                                { getStatusIcon({ icon_number: 1, size: 24, color: '#000000' }) }
                              </span>
                              <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': parseInt(item.icon) === 2 })} onClick={() => this.changeStatusIcon(index, 2)}>
                                { getStatusIcon({ icon_number: 2, size: 24, color: '#000000' }) }
                              </span>
                              <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': parseInt(item.icon) === 3 })} onClick={() => this.changeStatusIcon(index, 3)}>
                                { getStatusIcon({ icon_number: 3, size: 24, color: '#000000' }) }
                              </span>
                              <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': parseInt(item.icon) === 4 })} onClick={() => this.changeStatusIcon(index, 4)}>
                                { getStatusIcon({ icon_number: 4, size: 24, color: '#000000' }) }
                              </span>
                              <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': parseInt(item.icon) === 5 })} onClick={() => this.changeStatusIcon(index, 5)}>
                                { getStatusIcon({ icon_number: 5, size: 24, color: '#000000' }) }
                              </span>
                              <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': parseInt(item.icon) === 6 })} onClick={() => this.changeStatusIcon(index, 6)}>
                                { getStatusIcon({ icon_number: 6, size: 24, color: '#000000' }) }
                              </span>
                              <span className={classnames('screen-icon-list__icon', { 'screen-icon-list__icon--active': parseInt(item.icon) === 7 })} onClick={() => this.changeStatusIcon(index, 7)}>
                                { getStatusIcon({ icon_number: 7, size: 24, color: '#000000' }) }
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="input-group">
                          <span className="input-icon">
                            <IconDevice color="#A19BCD" />
                          </span>
                          <select 
                            name="device" 
                            value={item.device_serial !== '' ? item.device_serial : defaultDevice.device_serial}
                            onChange={e => this.setIconStatusDevice(index, e.target.value)}
                          >
                            {
                              devices.map((item: { device_serial: string, device_title: string }) => {
                                return <option key={item.device_serial} value={item.device_serial}>{item.device_title} ({item.device_serial})</option>
                              })
                            }
                          </select>
                        </div>


                        <div className="input-group">
                          <span className="input-icon">
                            <IconIO color="#A19BCD" />
                          </span>
                          <select 
                            name="io" 
                            value={item.io}
                            onChange={e => this.setIconStatusIO(index, e.target.value)}
                          >
                            {
                              item.io_options && item.io_options.map((option: {id: string, title: string}, index: number) => {
                                return <option key={index} value={option.id}>{option.title}</option>
                              })
                            }
                          </select>
                        </div>

                        <div className="status-icon__button" onClick={() => this.removeIcon(index)}>
                          <IconClose size={40} color="#ffffff" />
                        </div>
                    </div>
                  })
                }
                {
                  device.device_set_status && device.device_set_status.stat && device.device_set_status.stat.length < 4 && (
                    <div className="form-group status-icon__form-row-button">
                      <div className="btn btn--primary" onClick={() => this.addIcon()}>
                        Add Icon
                      </div>
                    </div>
                  )
                }
              </div>
            </form>
            <div className="form__footer">
              <input 
                onClick={() => this.handleButtonSubmit()}
                type="submit" 
                value="Update Device Settings" 
                className="btn btn--primary btn--large"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </div>

        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ device, ws, scenario }: RootState) => ({ device, ws, scenario }),
  { fetchDeviceById, updateDeviceStatusIcons, fetchAllDevices, wsGetDataByDeviceSerial, wsUpdateStatusIconsByDeviceSerial, fetchAllScenarios }
)(DevicesSettingsStatusIcons)))