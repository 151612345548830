import React from 'react'
import { connect } from 'react-redux'
import { fetchEventsHistory, markEventsHistoryAsRed, fetchDataBySerialsTypeRange } from '../../actions/data'
import { fetchAllDevices } from '../../actions/device'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import TabNavHistory from '../partials/TabNavHistory'
import classnames from 'classnames'
import { IconDevice } from '../partials/IconsForm'
import fetchStates from '../../types/fetchStates'
import Alert from '../partials/Alert'
import Chart from 'react-apexcharts'
import Card from '../partials/Card'  
import moment from 'moment'
import { temperature_chart_settings, humidity_chart_settings, light_chart_settings, movement_chart_settings } from '../../helpers/charts'
import { IconClose } from '../partials/Icons'


interface HistoryDataProps {
  router: {navigate: (to: string) => any, params: { id: string }, location: string},
  device: any,
  data: any,
  fetchAllDevices: () => Promise<void>,
  fetchDataBySerialsTypeRange: (options: { device_serials: Array<string>, data_type: string, data_range: string }) => Promise<void>,
}


interface HistoryDataState {
  data_range: string,
  data_ranges: any,
  data_type: string,
  data_types: any,
  device_serial: string,
  device_title: string,
  devices: any,
  selectedDevices: Array<string>,
  defaultDevice: string,
  chart_settings: {options: { chart: any}}
  temperature_chart_settings: {options: { chart: any}},
  humidity_chart_settings: {options: { chart: any}},
  light_chart_settings: {options: { chart: any}},
  movement_chart_settings: {options: { chart: any}},
  noData: boolean,
}


export class HistoryData extends React.Component<HistoryDataProps, HistoryDataState> {

  state = {
    data_type: 'temperature',
    data_types: [
      { type_id: 'temperature', type_title: 'Temperature History', type_color: 'red' },
      { type_id: 'humidity', type_title: 'Humidity History', type_color: 'blue' },
      { type_id: 'light', type_title: 'Light History', type_color: 'purple' },
      { type_id: 'movement', type_title: 'Movement History', type_color: 'green' }
    ],
    data_range: 'day',
    data_ranges: [
      { range_id: 'day', range_title: 'Day (24 H)', range_color: 'purple' },
      { range_id: 'week', range_title: 'Week (7 Days)', range_color: 'blue' },
      { range_id: 'month', range_title: 'Month', range_color: 'green' }
    ],
    device_serial: '',
    device_title: '',
    devices: [],
    defaultDevice: '',
    selectedDevices: [],
    chart_settings: temperature_chart_settings,
    temperature_chart_settings,
    humidity_chart_settings,
    light_chart_settings,
    movement_chart_settings,
    noData: true,
  }


  async componentDidMount(): Promise<void> {
    await this.fetchAllDevices();
  }


  fetchAllDevices = async () => {
    await this.props.fetchAllDevices()
    if(this.props.device.status === fetchStates.success) {
      let devices: any = {};
      devices = this.props.device.devices;
      if(devices.length > 0) {
        let defaultDevice = devices[0];
        const filteredDefaultDevices = devices.filter((device: {device_master: boolean}) => {
          if(device.device_master === true){
            return device;
          }
          return null;
        })
        defaultDevice = filteredDefaultDevices[0];
        this.setState({ devices, device_serial: defaultDevice.device_serial }, () => {
          this.fetchDataBySerialsTypeRange({ device_serials: this.state.selectedDevices.map((device:{device_serial: string}) => device.device_serial), data_type: this.state.data_type, data_range: this.state.data_range });
        });
      }
    }
  }


  filterChartsByType = (type_id: string) => {
    this.setState({ data_type: type_id });
    this.fetchDataBySerialsTypeRange({ device_serials: this.state.selectedDevices.map((device:{device_serial: string}) => device.device_serial), data_type: type_id, data_range: this.state.data_range });
  }


  filterChartsByTimeRange = (range_id: string) => {
    this.setState({ data_range: range_id });
    this.fetchDataBySerialsTypeRange({ device_serials: this.state.selectedDevices.map((device:{device_serial: string}) => device.device_serial), data_type: this.state.data_type, data_range: range_id });
  }


  formatNewSeriesData = (data: any) => {
    let new_series_data = [];
    for(const series of data) {
      let new_data = [];
      new_data = series.data.map((sample: any) => {
        let ts
        if(this.state.data_range === 'month') {
          ts = new Date(moment(sample.x).format('DD MMM YYYY HH:mm:ss')).getTime();
        } else {
          ts = new Date(moment.unix(sample.x).format('DD MMM YYYY HH:mm:ss')).getTime();
        }

        return { x:ts, y:sample.y };
      });
      new_series_data.push({ name: series.name, data: new_data.reverse() }); //
    }
    return new_series_data;
  }


  handleSelectDevice = (id: string) => {
    let selectedDevices: any[] = []
    const filteredDevices = this.state.devices.filter((device:{device_serial: string}) => {
      if(device.device_serial === id) {
        selectedDevices.push(...this.state.selectedDevices, device)
        this.setState({ selectedDevices })
      }
      return device.device_serial !== id
    })
    this.setState({ devices: filteredDevices })
    this.fetchDataBySerialsTypeRange({ device_serials: selectedDevices.map((device) => device.device_serial), data_type: this.state.data_type, data_range: this.state.data_range });
  }


  handleDeviceRemove = (id: string) => {
    const filteredDevices = this.state.selectedDevices.filter((device:{device_serial:string}) => {
      if(device.device_serial === id) {
        let devices: any[] = []
        devices.push(...this.state.devices, device)
        this.setState({ devices })
      }
      return device.device_serial !== id
    })
    this.setState({ selectedDevices: filteredDevices });
    this.fetchDataBySerialsTypeRange({ device_serials: filteredDevices.map((device:{device_serial:string}) => device.device_serial), data_type: this.state.data_type, data_range: this.state.data_range });
  }


  fetchDataBySerialsTypeRange = async (options: { device_serials: Array<string>, data_type: string, data_range: string }) => {
    const { device_serials, data_type, data_range } = options;
    this.props.fetchDataBySerialsTypeRange({ device_serials, data_type, data_range })
    if(this.props.data.status === fetchStates.success) {
      const { chart_data } = this.props.data;
      if(chart_data.length > 0) {

        const new_series_data_formated = this.formatNewSeriesData(chart_data);
        
        let chart_settings: any;
        if(data_type === 'temperature') {
          chart_settings = { ...temperature_chart_settings, series: new_series_data_formated };
        }
        if(data_type === 'humidity') {
          chart_settings = { ...humidity_chart_settings, series: new_series_data_formated };
        }
        if(data_type === 'light') {
          chart_settings = { ...light_chart_settings, series: new_series_data_formated };
        }
        if(data_type === 'movement') {
          chart_settings = { ...movement_chart_settings, series: new_series_data_formated };
        }

        if(data_range === 'month') {
          const xaxis = {
            categories: [],
            labels: {
              formatter: function (value:any) {
                return moment.unix(value/1000).format('YYYY/MM/DD');
              }
            }
          }
          chart_settings = { ...chart_settings, options: { ...chart_settings.options, xaxis } };
        }
        this.setState({ chart_settings, noData: false });
        
      } else {
        this.setState({ noData: true });
      }
    }
  }


  render() {

    const { data_type, data_types, data_range, data_ranges, devices, selectedDevices, noData, chart_settings } = this.state;
    const current_chart = data_types.filter((item:any) => item.type_id === data_type);
    const current_range = data_ranges.filter((item:any) => item.range_id === data_range);

    return (
      <TemplatePage title="History → Data" buttonBack={true} navigate={this.props.router.navigate}>
        <TabNavHistory location={this.props.router.location} />
        <ContentMain>


          <div className="form form--margin-bottom">
            <div className="form-group">
              <label htmlFor="devices">
                SELECT DEVICES:
              </label>
              <div className="selected-tags">
                { selectedDevices.map((device:{device_serial: string, device_title: string}) => (
                  <span className="tag tag--small" key={device.device_serial}>
                    <span className="tag__title">{device.device_title}</span>
                    <span className="tag__remove" onClick={e => this.handleDeviceRemove(device.device_serial)}>
                      <IconClose color="#ffffff"/>
                    </span>
                  </span>
                ))}
              </div>
              { devices.length > 0 ? (
                <div className="input-group">
                  <span className="input-icon">
                  <IconDevice color="#A19BCD" />
                  </span>
                  <select 
                    name="devices" 
                    onChange={e => this.handleSelectDevice(e.target.value)}
                  >
                    <option key="0">Select device</option>
                    { 
                      devices && devices.map((device: { device_serial: string, device_title: string }) => <option key={device.device_serial} value={device.device_serial}>{device.device_title} ({device.device_serial})</option>)
                    }
                  </select>
                </div>
              ) : (
                <Alert type="info">There are currently no devices to select from.</Alert>
              )}
            </div>
          </div>

          


          <div className="labels-filter">
            <div className="labels-filter__title">
              FILTER BY CHART TYPE:
            </div>
            { 
              data_types && data_types.map((type: {type_id: string, type_title: string, type_color: string }) => {
                return <span key={type.type_id} className={classnames(`labels-filter__item labels-filter__item--${type.type_color}`, { 'labels-filter__item--event--selected': type.type_id === data_type })} onClick={() => this.filterChartsByType(type.type_id)}>
                  <span className="labels-filter__item-color"></span>
                  {type.type_title}
                </span>
              })
            }
          </div>


          <div className="labels-filter">
            <div className="labels-filter__title">
              FILTER BY TIME:
            </div>
            { 
              data_ranges && data_ranges.map((range: {range_id: string, range_title: string, range_color: string }) => {
                return <span key={range.range_id} className={classnames(`labels-filter__item labels-filter__item--${range.range_color}`, { 'labels-filter__item--event--selected': range.range_id === data_range })} onClick={() => this.filterChartsByTimeRange(range.range_id)}>
                  <span className="labels-filter__item-color"></span>
                  {range.range_title}
                </span>
              })
            }
          </div>


          <Card title={`${current_chart[0].type_title} for the last ${current_range[0].range_title}`}>
            {
              noData ? (
                <div>
                  <Alert type="info">There are no data to be shown</Alert>
                </div>
              ) : (
                <Chart
                  options={chart_settings.options}
                  series={chart_settings.series}
                  type="line"
                  width="100%"
                />
              )
            }
          </Card>

        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ data, device }: RootState) => ({ data, device }),
  { fetchEventsHistory, markEventsHistoryAsRed, fetchAllDevices, fetchDataBySerialsTypeRange }
)(HistoryData)))