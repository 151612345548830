import React from 'react'
import { connect } from 'react-redux'
import { registerNewDevice } from '../../actions/device'
import { fetchAllRooms } from '../../actions/room'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import { RootState } from '../../reducers'
import { checkDeviceCapabilities } from '../../helpers/checkDeviceCapabilities'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { knownDeviceTypesMagicSwitch } from '../../helpers/deviceTypeList'
import InfoBox from '../partials/InfoBox'
import { IconName, IconDevice, IconRoom } from '../partials/IconsForm'


interface DevicesAddRegisterProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  room: any,
  device: any,
  fetchAllRooms: () => Promise<void>,
  registerNewDevice: (options: { device_serial: string, device_title: string, device_capabilities: any, room_id: string }) => Promise<void>,
}


interface DevicesAddRegisterState {
  device_serial: string,
  device_title: string,
  device_capabilities: any,
  device_type: string,
  room_id: string,
  rooms: any,
  dataReady: boolean,
  formSubmitted: boolean,
}


export class DevicesAddRegister extends React.Component<DevicesAddRegisterProps, DevicesAddRegisterState> {


  state = {
    device_serial: '',
    device_title: '',
    device_capabilities: { din: [], dou: [] },
    device_type: '',
    room_id: '',
    rooms: [],
    dataReady: false,
    formSubmitted: false,
  }


  componentDidMount() {
    this.fetchAllRooms()
    const device_serial = this.props.router.params.id
    const prefixToCheck = this.props.router.params.id.substring(0, 2).toUpperCase();
    const device_type = checkDeviceCapabilities(prefixToCheck)
    if(device_type) {
      this.setState({ device_serial, device_capabilities: device_type.device_capabilities })
    }
  }


  fetchAllRooms = () => {
    this.props.fetchAllRooms()
    .then(() => {
      if(this.props.room.status === fetchStates.success) {
        const { rooms } = this.props.room
        this.setState({ rooms, room_id: rooms && rooms.length > 0 ? rooms[0].room_id : '', dataReady: true })
      }
    });
  }


  handleSelectIcon = (index: number, id: number) => {
    let device_capabilities:any = []
    device_capabilities = this.state.device_capabilities
    device_capabilities[index].icon = id
    this.setState({ device_capabilities })
  }


  handleSetIconTitle = (index: number, value: string) => {
    let device_capabilities:any = []
    device_capabilities = this.state.device_capabilities
    device_capabilities[index].title = value
    this.setState({ device_capabilities })
  }


  updateDeviceCapabilityTitle = (options: {type: string, id: string, title: string}) => {
    const { type, id, title } = options
    let device_capabilities = this.state.device_capabilities
    if(type === 'din') {
      device_capabilities.din.map((item: {id: string, title: string}) => item.id === id ? item.title = title : item)
    }
    if(type === 'dou') {
      device_capabilities.dou.map((item: {id: string, title: string}) => item.id === id ? item.title = title : item)
    }
    this.setState({ device_capabilities })
  }


  showDeviceCapabilitesDin = () => {
    if(this.state.device_capabilities.din)
    return (
      <>
        {
          this.state.device_capabilities.din.map((din: any, index) => {
            return <div key={din.id} className="form-group form-group-capabilities">
                    <div>
                      <label htmlFor={din.id}>
                        {din.id}
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconName color="#A19BCD" />
                        </span>
                        <input 
                          maxLength={20}
                          type="text" 
                          name={din.id}
                          id={din.id} 
                          value={ din.title }
                          onChange={e => this.updateDeviceCapabilityTitle({ type: 'din', id: din.id, title: e.target.value})}
                          className={classnames('', { 'input-error': this.props.device.fields && this.props.device.fields.includes(`device_capabilities.din[${index}].title`) })}
                        />
                      </div>
                    </div>
                  </div>
          })
        }
      </>
    )
  }


  showDeviceCapabilitesDou = () => {
    if(this.state.device_capabilities.dou)
    return (
      <>
        {
          this.state.device_capabilities.dou.map((dou: any, index: number) => {
            return <div key={dou.id} className="form-group form-group-capabilities">
                    <div>
                      <label htmlFor={dou.id}>
                        {dou.id}
                      </label>
                      <div className="input-group">
                        <span className="input-icon">
                          <IconName color="#A19BCD" />
                        </span>
                        <input 
                          maxLength={20}
                          type="text" 
                          name={dou.id}
                          id={dou.id} 
                          value={ dou.title }
                          onChange={e => this.updateDeviceCapabilityTitle({ type: 'dou', id: dou.id, title: e.target.value })}
                          className={classnames('', { 'input-error': this.props.device.fields && this.props.device.fields.includes(`device_capabilities.dou[${index}].title`) })}
                        />
                      </div>
                    </div>
                  </div>
          })
        }
      </>
    )
  }



  handleSubmitAddDevice = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();    
    this.setState({ formSubmitted: true })
    const { device_serial, device_title, device_capabilities, room_id } = this.state
    await this.props.registerNewDevice({ 
      device_serial,
      device_title,
      device_capabilities,
      room_id
    })
    if(this.props.device.status === fetchStates.success) {
      const prefixToCheck = this.state.device_serial.substring(0, 2).toUpperCase();
      if(checkDeviceCapabilities(prefixToCheck).device_category === 'MS') {
        this.setState({ device_serial: '', formSubmitted: false });
        this.props.router.navigate('/devices/magicswitch');
      }
      if(checkDeviceCapabilities(prefixToCheck).device_category === 'MB') {
        this.setState({ device_serial: '', formSubmitted: false });
        this.props.router.navigate('/devices/magicbox');
      }
    }
    this.setState({ formSubmitted: false })
  }


  render() {

    const { device_serial, device_title, device_capabilities, rooms, formSubmitted } = this.state;
    const { fields, status } = this.props.device;

    return (
      <TemplatePage title="Devices → Register Device">
        <ContentMain>

        { rooms && rooms.length === 0 ? (

            <InfoBox>
              <p>In order to be able to add device, you must create a room first.</p>
              <Link to={`/rooms/add`} className="btn btn--primary">Add Room Now</Link>
            </InfoBox>

          ) : (

            <form className="form" onSubmit={this.handleSubmitAddDevice}>
              <div className="form-section">

                <div className="form-group">
                  <label htmlFor="device_serial">
                    Serial number (required)
                  </label>
                  <div className="input-group">
                    <span className="input-icon">
                      <IconDevice color="#A19BCD" />
                    </span>
                    <input 
                      maxLength={14}
                      type="text" 
                      name="device_serial"
                      id="device_serial" 
                      value={ device_serial }
                      className={classnames('', { 'input-error': fields && fields.includes('device_serial') })}
                      disabled
                    />
                  </div>
                  <span className="form-explanation">Existing device types: {knownDeviceTypesMagicSwitch.join(", ")}</span>
                </div>


                <div className="form-group">
                  <label htmlFor="device_title">
                    Title (required)
                  </label>
                  <div className="input-group">
                    <span className="input-icon">
                      <IconName color="#A19BCD" />
                    </span>
                    <input 
                      maxLength={25}
                      type="text" 
                      name="device_title"
                      id="device_title" 
                      value={ device_title }
                      onChange={e => this.setState({ device_title: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('device_title') })}
                    />
                  </div>
                </div>


                <div className="form-group">
                  <label htmlFor="rooms">
                    Rooms
                  </label>
                  <div className="input-group">
                    <span className="input-icon">
                      <IconRoom color="#A19BCD" />
                    </span>
                    <select 
                      name="rooms" 
                      onChange={e => this.setState({ room_id: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('rooms') })}
                    >
                      { 
                        rooms.map((room: { room_id: string, room_name: string }) => <option key={room.room_id} value={room.room_id} >{room.room_name}</option>)
                      }
                    </select>
                  </div>
                </div>


                { device_capabilities && this.showDeviceCapabilitesDin() }

                { device_capabilities && this.showDeviceCapabilitesDou() }

                <div className="form-group">
                  <input 
                    type="submit" 
                    value="Add Device" 
                    className="btn btn--primary btn--large"
                    disabled={ formSubmitted && status === 'fetching' ? true : false }
                  />
                </div>

              </div>
            </form>

        )}

        </ContentMain>
      </TemplatePage>
    )
  }
}


export default (withAuthorization(withRouter(connect(
  ({ device, room }: RootState) => ({ device, room }),
  { registerNewDevice, fetchAllRooms }
)(DevicesAddRegister))))