import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchEnergyConsumedHistory, fetchEnergyProducedHistory } from '../../actions/data';
import { fetchAccountById } from '../../actions/account'
import withAuthorization from '../auth/withAuthorization';
import TemplatePage from '../templates/TemplatePage';
import ContentMain from '../partials/ContentMain';
import withRouter from '../partials/WithRouter';
import TabNavEnergy from '../partials/TabnavEnergy';
import fetchStates from '../../types/fetchStates';
import Preloader from '../partials/Preloader';


interface EnergyProductionProps {
  router: {navigate: (to: string) => any, location: string},
  auth: any,
  data: any,
  account: any,
  fetchEnergyConsumedHistory: () => Promise<void>,
  fetchEnergyProducedHistory: () => Promise<void>,
  fetchAccountById: (account_id: string) => Promise<void>,
}


interface EnergyProductionState {
  energy_consumed_today: number, 
  energy_consumed_this_month: number, 
  energy_consumed_this_year: number,
  energy_consumed_cost_today: number,
  energy_consumed_cost_this_month: number,
  energy_consumed_cost_this_year: number,
  energy_produced_today: number, 
  energy_produced_this_month: number, 
  energy_produced_this_year: number,
  energy_produced_cost_today: number,
  energy_produced_cost_this_month: number,
  energy_produced_cost_this_year: number,
  account_currency: string,
  dataLoading: boolean
}


export class EnergyProduction extends React.Component<EnergyProductionProps, EnergyProductionState> {

  state = {
    energy_consumed_today: 0, 
    energy_consumed_this_month: 0, 
    energy_consumed_this_year: 0,
    energy_consumed_cost_today: 0,
    energy_consumed_cost_this_month: 0,
    energy_consumed_cost_this_year: 0,
    energy_produced_today: 0, 
    energy_produced_this_month: 0, 
    energy_produced_this_year: 0,
    energy_produced_cost_today: 0,
    energy_produced_cost_this_month: 0,
    energy_produced_cost_this_year: 0,
    account_currency: '',
    dataLoading: false
  };


  async componentDidMount(): Promise<void> {
    this.fetchAccount();
    this.fetchConsumedHistoryData();
    this.fetchProducedHistoryData();
  }


  fetchAccount = async () => {
    const { account_id } = this.props.auth.person;
    await this.props.fetchAccountById(account_id);
    const { status } = this.props.account;
    const { account_currency } = this.props.account.account;
    if( status !== fetchStates.success ) { return; }
    this.setState({ account_currency });
  }


  fetchConsumedHistoryData = async () => {
    await this.props.fetchEnergyConsumedHistory();
    if(this.props.data.status === fetchStates.success) {
      const { energy_consumed_today, energy_consumed_this_month, energy_consumed_this_year, energy_consumed_cost_today, energy_consumed_cost_this_month, energy_consumed_cost_this_year } = this.props.data;
      this.setState({ energy_consumed_today, energy_consumed_this_month, energy_consumed_this_year, energy_consumed_cost_today, energy_consumed_cost_this_month, energy_consumed_cost_this_year });
    }
  }


  fetchProducedHistoryData = async () => {
    await this.props.fetchEnergyProducedHistory();
    if(this.props.data.status === fetchStates.success) {
      const { energy_produced_today, energy_produced_this_month, energy_produced_this_year, energy_produced_cost_today, energy_produced_cost_this_month, energy_produced_cost_this_year } = this.props.data;
      this.setState({ energy_produced_today, energy_produced_this_month, energy_produced_this_year, energy_produced_cost_today, energy_produced_cost_this_month, energy_produced_cost_this_year });
    }
  }


  render() {

    const { dataLoading, energy_consumed_today, energy_consumed_this_month, energy_consumed_this_year, energy_consumed_cost_today, energy_consumed_cost_this_month, energy_consumed_cost_this_year, account_currency, energy_produced_today, energy_produced_this_month, energy_produced_this_year, energy_produced_cost_today, energy_produced_cost_this_month, energy_produced_cost_this_year } = this.state;


    return (
      <TemplatePage title="Energy → Energy Production" buttonBack={true} navigate={this.props.router.navigate}>
        <TabNavEnergy location={this.props.router.location} />
        <ContentMain>


          {
            dataLoading ? (
              <Preloader />
            ) : (
              <>
                <div className="content-section margin-bottom--xl">
                  <h4>Usage</h4>
                  <div className="simple-tile">
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Today</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round(energy_consumed_today * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_consumed_cost_today }</strong> { account_currency }</div>
                    </div>
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Month</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round(energy_consumed_this_month * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_consumed_cost_this_month }</strong> { account_currency }</div>
                    </div>
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Year</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round(energy_consumed_this_year * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_consumed_cost_this_year }</strong> { account_currency }</div>
                    </div>
                  </div>
                </div>

                <div className="content-section margin-bottom--xl">
                  <h4>Production</h4>
                  <div className="simple-tile">
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Today</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round(energy_produced_today * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_produced_cost_today }</strong> { account_currency }</div>
                    </div>
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Month</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round(energy_produced_this_month * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_produced_cost_this_month }</strong> { account_currency }</div>
                    </div>
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Year</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round(energy_produced_this_year * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_produced_cost_this_year }</strong> { account_currency }</div>
                    </div>
                  </div>
                </div>

                <div className="content-section">
                  <h4>Balance</h4>
                  <div className="simple-tile">
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Today</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round((energy_produced_today - energy_consumed_today) * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_produced_cost_today - energy_consumed_cost_today }</strong></div>
                    </div>
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Month</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round((energy_produced_this_month - energy_consumed_this_month) * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_produced_cost_this_month - energy_consumed_cost_this_month }</strong></div>
                    </div>
                    <div className="simple-tile__item simple-tile__item--column">
                      <div className="simple-tile__item-title">Year</div>
                      <div className="simple-tile__item-value"><strong>{ Math.round((energy_produced_this_year - energy_consumed_this_year) * 10) / 10 }</strong> kWh</div>
                      <div className="simple-tile__item-value"><strong>{ energy_produced_cost_this_year - energy_consumed_cost_this_year }</strong></div>
                    </div>
                  </div>
                </div>
              </>
            )
          }

        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ data, account }: RootState) => ({ data, account }),
  { fetchAccountById, fetchEnergyConsumedHistory, fetchEnergyProducedHistory }
)(EnergyProduction)))