import classnames from 'classnames'
import { IconAlertError, IconAlertInfo, IconAlertWarning, IconAlertSuccess } from './IconsAlerts'


interface AlertProps {
  children?: JSX.Element|JSX.Element[]|string,
  type: string,
}


const Alert: React.FC<AlertProps> = ({type, children}) => (
  <div className="alert-wrapper">
    <div className={classnames('alert', { 
      'alert--success': type === 'success', 
      'alert--warning': type === 'warning',
      'alert--info': type === 'info',
      'alert--error': type === 'error',
      })}>
        <span className="alert-icon">
          {type === 'error' && <IconAlertError color="#771a36" size={18} /> }
          {type === 'info' && <IconAlertInfo color="#1c6785" size={18} /> }
          {type === 'warning' && <IconAlertWarning color="#7e6d29" size={18} /> }
          {type === 'success' && <IconAlertSuccess color="#3b7c21" size={18} /> }
        </span>
        <span className="alert-content">
          {children}
        </span>
    </div>
  </div>
)


export default Alert