import { SCENARIO } from '../types';
import fetchStates from '../types/fetchStates';

interface ScenarioState {
  scenario: {},
  scenarios: [],
}

const DEFAULT_SCENARIO: ScenarioState = {
  scenario: {},
  scenarios: [],
};

const scenario = (state = DEFAULT_SCENARIO, action: any) => {
  switch(action.type) {
    case SCENARIO.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case SCENARIO.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case SCENARIO.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        scenarios: action.scenarios,
      };
    case SCENARIO.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        scenario: action.scenario,
      };
    case SCENARIO.ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case SCENARIO.UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case SCENARIO.DELETE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case SCENARIO.UPDATE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case SCENARIO.UPDATE_ID_TITLE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case SCENARIO.UPDATE_ID_ACTIVE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case SCENARIO.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};

export default scenario;