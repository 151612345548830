import React from 'react';
import { connect } from 'react-redux';
import { fetchMasterDevice } from '../../actions/device';
import { fetchScenarioById, deleteScenarioById } from '../../actions/scenario';
import { wsUpdateScenarioSettingsByDeviceSerial } from '../../actions/ws';
import withAuthorization from '../auth/withAuthorization';
import TemplatePage from '../templates/TemplatePage';
import ContentMain from '../partials/ContentMain';
import { RootState } from '../../reducers';
import withRouter from '../partials/WithRouter';
import fetchStates from '../../types/fetchStates';
import classnames from 'classnames';
import { IconName } from '../partials/IconsForm';
import { danger } from '../../helpers/notifications';
import Alert from '../partials/Alert';


interface ScenariosDeleteProps {
  router: {navigate: (to: string) => any, params: { id: string }},
  scenario: { status: string, fields: Array<string>, scenario: any },
  device: any,
  ws: { wsConnected: boolean },
  fetchMasterDevice: () => Promise<void>,
  fetchScenarioById: (id: string) => Promise<void>,
  deleteScenarioById: (id: string) => Promise<any>,
  wsUpdateScenarioSettingsByDeviceSerial: (options: { device_serial: string, scenario_settings: any }) => Promise<void>,
}


interface ScenariosDeleteState {
  scenario_title: string,
  scenario: { sc_title: string, scenario_id: string, sc_id: string },
  deviceMaster: { device_serial: string },
  formSubmitted: boolean,
  noData: boolean,
}


export class ScenariosDelete extends React.Component<ScenariosDeleteProps, ScenariosDeleteState> {


  state = {
    scenario_title: '',
    scenario: { sc_title: '', scenario_id: '', sc_id:'' },
    deviceMaster: { device_serial: '' },
    formSubmitted: false,
    noData: false,
  }


  componentDidMount = async () => {
    await this.fetchMasterDevice();
    await this.fetchScenarioById();
  }


  fetchMasterDevice = async () => {
    await this.props.fetchMasterDevice();
    const { status, deviceMaster } = this.props.device;
    if(status !== fetchStates.success) { return; }
    this.setState({ deviceMaster });
  }


  fetchScenarioById = async () => {
    await this.props.fetchScenarioById(this.props.router.params.id);
    const { status, scenario } = this.props.scenario;
    if(status !== fetchStates.success) { this.setState({ noData: true }); return; }
    this.setState({ scenario, noData: false });
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { sc_title } = this.props.scenario.scenario;
    const { scenario_title } = this.state;
    if(scenario_title !== sc_title) { 
      this.props.scenario.fields = ['scenario_title'];
      danger('The scenario title does not match');
      this.setState({ formSubmitted: false });
      return; 
    }
    if(!this.props.ws.wsConnected) { 
      danger('You are disconnected from the WS server'); 
      this.setState({ formSubmitted: false }); 
      return;
    }
    await this.props.deleteScenarioById(this.state.scenario.scenario_id);
    const { status } = this.props.scenario;
    if(status !== fetchStates.success) { this.setState({ formSubmitted: false }); return; }
    this.props.wsUpdateScenarioSettingsByDeviceSerial({ device_serial: this.state.deviceMaster.device_serial, scenario_settings: { sc_id: this.state.scenario.sc_id } });
    this.setState({ formSubmitted: false });
    this.props.router.navigate('/scenarios');
  }


  render() {

    const { fields } = this.props.scenario
    const { scenario, scenario_title, formSubmitted, noData } = this.state;

    if(noData) {
      return (
        <TemplatePage title="Scenarios → Delete Scenario" buttonBack={true} navigate={this.props.router.navigate} button={false}>
          <ContentMain>
            <Alert type="error">Scenario does not exist</Alert>
          </ContentMain>
        </TemplatePage>
      )
    }

    return (
      <TemplatePage title="Scenarios → Delete Scenario" buttonBack={true} navigate={this.props.router.navigate}>
        <ContentMain>
          <p>
            You are about to delete scenario: <strong>"{scenario.sc_title}"</strong>. This action can not be reversed. Are you sure you want to proceed?
          </p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="email">
                Scenario title (required)
              </label>
              <div className="input-group">
                <span className="input-icon">
                  <IconName color="#A19BCD" />
                </span>
                <input 
                  type="text" 
                  name="scenario_title"
                  id="scenario_title" 
                  value={scenario_title}
                  onChange={e => this.setState({ scenario_title: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('scenario_title') })}
                />
              </div>
              <span className="form-explanation">Confirm your choice by typing in the scenario title</span>
            </div>
            <div className="content-box__footer">
              <input 
                type="submit" 
                value="Delete Scenario"
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ scenario, device, ws }: RootState) => ({ scenario, device, ws }),
  { fetchMasterDevice, fetchScenarioById, deleteScenarioById, wsUpdateScenarioSettingsByDeviceSerial }
)(ScenariosDelete)));