import React from 'react'
import { NavLink } from 'react-router-dom'
import { IconNavigationRooms, IconNavigationScenarios, IconNavigationDevices, IconNavigationUsers, IconNavigationSettings, IconNavigationHistory } from './IconsNavigation'
import classnames from 'classnames'
import AuthByPermissions from './AuthByPermissions'
import { RootState } from '../../reducers'
import withRouter from '../partials/WithRouter'
import { connect } from 'react-redux'
import { getNewAlarmsCount } from '../../actions/data'


interface MobileNavigationProps {
  router: { location: any },
  auth: {status: string, authenticated: boolean, person: { role: number }}
  data: any,
  alarmsNewCount: any,
  getNewAlarmsCount: () => Promise<void>,
}

interface MobileNavigationState {
  alarmsNewCount: string
}


export class MobileNavigation extends React.Component<MobileNavigationProps, MobileNavigationState> {

  state = {
    alarmsNewCount: '0'
  }


  componentDidMount() {
    this.reloadNewAlarmCount()
  }
  

  componentDidUpdate(prevProps:any) {
    if (this.props.data.alarmsNewCount !== prevProps.data.alarmsNewCount) {
      const { alarmsNewCount } = this.props.data
      this.setState({ alarmsNewCount })
    }
  }


  reloadNewAlarmCount = () => {
    this.props.getNewAlarmsCount()
    .then(() => {
      const { alarmsNewCount } = this.props.data
      this.setState({ alarmsNewCount })
    })
  }


  render() {

    const { alarmsNewCount } = this.state
    const { location } = this.props.router

    return (
    <div className="mobile-navigation" onClick={() => this.reloadNewAlarmCount()}>
      <NavLink to="/" 
        className={classnames('mobile-navigation__item-link', { 'mobile-navigation__item--current': location.pathname === '/' || location.pathname.substring(0,6) === '/rooms'})}
      >
        <IconNavigationRooms color={location.pathname === '/' || location.pathname.substring(0,6) === '/rooms' ? '#000000' : '#828BA9'} />
        <div className="mobile-navigation__item-title">Rooms</div>
      </NavLink>
      
      <NavLink to="/history" 
        className={classnames('mobile-navigation__item-link', {'mobile-navigation__item--current': location.pathname.substring(0,8) === '/history'})}
      >
        <IconNavigationHistory color={location.pathname.substring(0,8) === '/history' ? '#000000' : '#828BA9'} />
        <div className="mobile-navigation__item-title">History</div>
        {
          alarmsNewCount !== '0' && <span className="mobile-navigation-item__item-count mobile-navigation-item__item-count--alarm">
          { alarmsNewCount }
          </span>
        }
      </NavLink>

      <NavLink to="/scenarios" 
        className={classnames('mobile-navigation__item-link', {'mobile-navigation__item--current': location.pathname.substring(0,10) === '/scenarios'})}
      >
        <IconNavigationScenarios color={location.pathname.substring(0,10) === '/scenarios' ? '#000000' : '#828BA9'} />
        <div className="mobile-navigation__item-title">Scenarios</div>
      </NavLink>

      <NavLink to="/devices/magicbox" 
        className={classnames('mobile-navigation__item-link', { 'mobile-navigation__item--current': location.pathname.substring(0,8) === '/devices'})}
      >
        <IconNavigationDevices color={location.pathname.substring(0,8) === '/devices' ? '#000000' : '#828BA9'} />
        <div className="mobile-navigation__item-title">Devices</div>
      </NavLink>

      <AuthByPermissions requiresPermissions='manage_users'>
        <NavLink to="/users" 
          className={classnames('mobile-navigation__item-link', { 'mobile-navigation__item--current': location.pathname.substring(0,6) === '/users'})}
        >
          <IconNavigationUsers color={location.pathname.substring(0,6) === '/users' ? '#000000' : '#828BA9'} />
          <div className="mobile-navigation__item-title">Users</div>
        </NavLink>
      </AuthByPermissions>

      <NavLink 
        to="/settings" 
        className={classnames('mobile-navigation__item-link', { 'mobile-navigation__item--current': location.pathname.substring(0,9) === '/settings'})}
      >
        <IconNavigationSettings color={location.pathname.substring(0,9) === '/settings' ? '#000000' : '#828BA9'} />
        <div className="mobile-navigation__item-title">Settings</div>
      </NavLink>
    </div>
    )
  }

}


export default withRouter(connect(
  ({ data }: RootState) => ({ data }),
  { getNewAlarmsCount }
)(MobileNavigation))