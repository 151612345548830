export const IconDeviceUpdateSucccess = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--device-update-success">
    <svg width={props.size ? props.size : '512px'} height={props.size ? props.size : '512px'} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#2A2C44'}>
                <path d="M256,0 C114.833,0 0,114.833 0,256 C0,397.167 114.833,512 256,512 C397.167,512 512,397.147 512,256 C512,114.853 397.167,0 256,0 Z M256,472.341 C136.725,472.341 39.659,375.295 39.659,256 C39.659,136.705 136.725,39.659 256,39.659 C375.295,39.659 472.341,136.705 472.341,256 C472.341,375.295 375.275,472.341 256,472.341 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M373.451,166.965 C365.38,159.628 352.828,160.203 345.452,168.313 L224.491,301.509 L166.053,242.1 C158.339,234.287 145.807,234.168 138.014,241.862 C130.201,249.536 130.082,262.088 137.776,269.901 L210.927,344.262 C214.675,348.069 219.751,350.191 225.065,350.191 C225.184,350.191 225.323,350.191 225.442,350.211 C230.915,350.092 236.071,347.752 239.739,343.707 L374.798,194.985 C382.156,186.854 381.561,174.322 373.451,166.965 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);


export const IconDeviceUpdateError = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--device-update-error">
    <svg width={props.size ? props.size : '512px'} height={props.size ? props.size : '512px'} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#2A2C44'}>
                <path d="M437.126,74.939 C337.3,-24.887 174.819,-24.887 74.993,74.939 C26.637,123.314 0,187.617 0,256.005 C0,324.393 26.637,388.696 74.993,437.052 C124.916,486.975 190.488,511.926 256.059,511.926 C321.63,511.926 387.203,486.975 437.125,437.052 C536.951,337.226 536.951,174.784 437.126,74.939 Z M409.08,409.006 C324.705,493.381 187.413,493.381 103.038,409.006 C62.18,368.148 39.668,313.802 39.668,256.005 C39.668,198.208 62.18,143.862 103.038,102.984 C187.413,18.609 324.705,18.629 409.08,102.984 C493.435,187.359 493.435,324.651 409.08,409.006 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M341.525,310.827 L285.374,254.756 L341.525,198.685 C349.26,190.95 349.26,178.395 341.545,170.639 C333.79,162.864 321.235,162.884 313.48,170.619 L257.29,226.73 L201.1,170.619 C193.345,162.884 180.79,162.864 173.035,170.639 C165.3,178.394 165.3,190.949 173.055,198.685 L229.206,254.756 L173.055,310.827 C165.3,318.562 165.3,331.117 173.035,338.873 C176.903,342.76 182,344.684 187.078,344.684 C192.156,344.684 197.233,342.74 201.101,338.892 L257.291,282.781 L313.481,338.892 C317.349,342.76 322.426,344.684 327.504,344.684 C332.582,344.684 337.679,342.74 341.547,338.873 C349.28,331.117 349.28,318.562 341.525,310.827 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);


export const IconDeviceUpdateProgress = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--device-update-progress">
    <svg width={props.size ? props.size : '512px'} height={props.size ? props.size : '512px'} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#2A2C44'}>
                <path d="M256,500.704 C191.052174,500.921511 128.718013,475.140197 82.8984,429.10944 C75.1782796,421.342262 67.9728596,413.079933 61.328,404.37504 C56.8509188,398.44526 55.8774252,390.577423 58.7742248,383.735267 C61.6710244,376.893111 67.9980245,372.116123 75.3719051,371.203747 C82.7457858,370.291371 90.0462792,373.38222 94.52336,379.312 C100.004663,386.477889 105.941409,393.283631 112.2968,399.68704 C171.811228,459.219565 261.870312,475.893117 338.753187,441.613209 C415.636063,407.333301 463.422659,329.198243 458.91408,245.14016 C458.608122,239.630764 460.505541,234.225829 464.188137,230.116628 C467.870734,226.007427 473.036213,223.531272 478.54608,223.23392 C479.175874,223.156722 479.809987,223.120166 480.44448,223.12448 C491.237155,223.481014 499.9437,232.071935 500.44448,242.85888 C504.077795,309.965149 479.942771,375.610587 433.706238,424.381843 C387.469706,473.153099 323.204537,500.754022 256,500.704 Z" id="Path"></path>
                <path d="M32.304,288.7968 C21.2673361,288.789078 12.1591614,280.160743 11.55472,269.14064 C6.08294057,167.864644 63.6351082,73.7043237 156.261711,32.3874966 C248.888315,-8.92933047 357.400881,11.1565047 429.1016,82.89056 C433.2656,87.04688 436.93744,91.06256 440.59376,95.25008 C445.51077,100.833693 447.069477,108.619086 444.681074,115.665309 C442.292672,122.711532 436.321236,127.944493 429.022493,129.387427 C421.723749,130.830362 414.210281,128.263317 409.32032,122.656 C406.2656,119.1872 403.21872,115.84336 399.8672,112.484 C340.393169,52.850519 250.296019,36.0871886 173.35379,70.3392805 C96.4115613,104.591372 48.5742505,182.758485 53.08592,266.85936 C53.3918739,272.368887 51.4943461,277.773938 47.8115793,281.883162 C44.1288124,285.992386 38.9631289,288.468453 33.45312,288.7656 L32.304,288.7968 Z" id="Path"></path>
                <path d="M357.82032,129.71872 C350.37657,129.739367 343.48941,125.780026 339.761562,119.336968 C336.033713,112.89391 336.033713,104.94977 339.761562,98.5067123 C343.48941,92.0636545 350.37657,88.1043132 357.82032,88.12496 L404.90624,88.12496 L404.90624,41.04688 C404.938036,29.583601 414.239797,20.3076045 425.70312,20.3076045 C437.166443,20.3076045 446.468204,29.583601 446.5,41.04688 L446.5,108.92192 C446.487918,120.402667 437.183947,129.706638 425.7032,129.71872 L357.82032,129.71872 Z" id="Path"></path>
                <path d="M74.98432,480.43744 C63.5035726,480.425358 54.1996017,471.121387 54.18752,459.64064 L54.18752,391.7656 C54.1996017,380.284853 63.5035726,370.980882 74.98432,370.9688 L142.85936,370.9688 C150.30311,370.948153 157.19027,374.907494 160.918118,381.350552 C164.645967,387.79361 164.645967,395.73775 160.918118,402.180808 C157.19027,408.623866 150.30311,412.583207 142.85936,412.56256 L95.78128,412.56256 L95.78128,459.64064 C95.7691981,471.12145 86.4651298,480.425446 74.98432,480.43744 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);