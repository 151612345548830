import { NavLink } from 'react-router-dom';


interface TabNavDevicesProps {
  location: any,
  id: string,
}

const TabNavDevices: React.FC<TabNavDevicesProps> = ({ location, id }) => (
    <nav className="tabnav">
      <NavLink 
        to={{ pathname: `/devices/settings/developer/${id}` }} 
        className={location.pathname === '/devices/settings/developer/' || location.pathname.substring(0,28) === '/devices/settings/developer/' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        Movement
      </NavLink>
      <NavLink 
        to={{ pathname: `/devices/settings/developer-settings/${id}` }} 
        className={location.pathname === '/devices/settings/developer-settings' || location.pathname.substring(0,36) === '/devices/settings/developer-settings' ? 'tabnav-tab tabnav-tab--active' : 'tabnav-tab'} 
      >
        <span className="tabnav-tab--highlight"></span>
        Settings
      </NavLink>
    </nav>
)


export default TabNavDevices;