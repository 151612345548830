import { ACCOUNT } from '../types';
import fetchStates from '../types/fetchStates';


interface PersonState {
  account: [],
  fields: []
}


const DEFAULT_ACCOUNT: PersonState = {
  account: [],
  fields: []
}


const account = (state = DEFAULT_ACCOUNT, action: any) => {
  switch(action.type) {
    case ACCOUNT.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case ACCOUNT.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item:any) => item.path !== undefined && item.path.toString())
      };
    case ACCOUNT.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        account: action.account,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      };
    case ACCOUNT.UPDATE_ENERGY_PRICE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      }
    case ACCOUNT.UPDATE_WEATHER_SETTINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item:any) => item.path && item.path.toString())
      }
    case ACCOUNT.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
};


export default account;