import React from 'react'
import { connect } from 'react-redux'
import { fetchAllPeople } from '../../actions/person'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import ContentMain from '../partials/ContentMain'
import Pagination from '../partials/Pagination'
import AddItem from '../partials/AddItem'
import { motion } from 'framer-motion'
import { roomsContainer, roomsItem } from '../../helpers/framer'
import { IconUser } from '../partials/Icons'
import { RootState } from '../../reducers'
import fetchStates from '../../types/fetchStates'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import moment from 'moment'
import ButtonLink from '../partials/ButtonLink'


const requiresPermissions = 'manage_users'



interface UsersProps {
  router: {location: {pathname: string, search: string}, navigate: (to: string) => any},
  person: any,
  fetchAllPeople: (options: { limit: number , skip: number }) => Promise<void>,
}


interface UsersState {
  currentPage: number,
  perPage: number,
  people: Array<any>, 
  peopleCount: number,
}


export class Users extends React.Component<UsersProps, UsersState> {


  state = {
    currentPage: 0,
    perPage: 10,
    people: [], 
    peopleCount: 0
  }


  componentDidMount() {
    const { currentPage, perPage } = this.state
    this.reloadPage({ perPage, currentPage })
  }


  updatePagination = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(event.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(event.target.value), currentPage: 0  })
  }


  prevPage = (prevPage: number) => {
    const { perPage } = this.state
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage })
  }


  nextPage = (nextPage: number) => {
    const { perPage } = this.state
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage })
  }


  reloadPage = async ({perPage, currentPage}: {perPage:number, currentPage: number}) => {
    await this.props.fetchAllPeople({ limit: perPage, skip: perPage * currentPage })
    if(this.props.person.status === fetchStates.success) {
      const { people, peopleCount } = this.props.person
      this.setState({ people, peopleCount })
    }
  }


  render() {

    const { perPage, currentPage, people, peopleCount } = this.state

    return (
      <TemplatePage title="Users" button={true} buttonLink="/users/add" buttonType="add" buttonText="Add User">
        <ContentMain>
          {
            people && people.length > 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="users"
              >
              {
                people.map((item: {person_id: number, name: string, email: string, phone: string, role: number, registered: string}) => {
                  return (
                    <motion.div key={item.person_id} variants={roomsItem}>
                      <div className="user-card">
                        <div className="user-card__header">
                          <span className="user-card__title">{item.name}</span>
                          <span className="user-card__header-button">
                            <ButtonLink iconType='edit' buttonLink={`/users/edit/${item.person_id}`} />
                          </span>
                        </div>
                        <div className="user-card__body">
                          <div className="user-card__icon">
                            <IconUser size={2} color="#000" />
                          </div>
                          <div className={classnames('label-info', { 
                            'admin': item.role === 1,
                            'user': item.role === 2  
                          })}>
                            {item.role === 1 && 'ADMIN' }
                            {item.role === 2 && 'USER' }
                          </div>
                          <div className="user-card__email">
                            {item.email}
                          </div>
                          <div className="user-card__registered">
                            {moment(item.registered).format('DD/MM/YYYY HH:mm:ss')}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )
                })
              }
              <motion.div key={10000} variants={roomsItem}>
                <AddItem title="Add User" link="/users/add" type="user" />
              </motion.div>
            </motion.div>
            )
          }
          {
            people && people.length === 0 && (
              <motion.div
                variants={roomsContainer}
                initial="hidden"
                animate="visible"
                className="users"
              >
                <motion.div key={10000} variants={roomsItem}>
                  <AddItem title="Add User" link="/users/add" />
                </motion.div>
              </motion.div>
            )
          }
          <Pagination 
            perPage={perPage}
            currentPage={currentPage} 
            itemsCount={peopleCount} 
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
          />
        </ContentMain>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person }: RootState) => ({ person }),
  { fetchAllPeople }
)(Users)), requiresPermissions)